import React, {ChangeEvent} from 'react';
import {colors} from "patient-ping-remedy/packages/theme";
import Icon from "patient-ping-remedy/packages/icon";
import {StyledInput, StyledInputDiv} from "./SearchBarComponent.styles";

type Props = {
  id?: string;
  searchIcon: boolean;
  searchTerm: string;
  searchPlaceholder?: string;
  searchFunction: Function;
  class?: string[];
}

const SearchBarComponent = (props: Props) => {
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    props.searchFunction(event.target.value);
  }

  return (
    <>
      <StyledInputDiv width={props.class?.includes('large') ? '75%' : '25%'} data-testid={'search-bar'}>
        { props.searchIcon &&
        <Icon
          iconClass={'search'}
          color={colors.purple4}
          iconSize={'1x'}
        />
        }
        <StyledInput
          id={`${props.id}-search-component`}
          type="text"
          value={props.searchTerm ?? ''}
          onChange={onChange}
          placeholder={props.searchPlaceholder}
        />
      </StyledInputDiv>
    </>
  );
};

export default React.memo(SearchBarComponent);
