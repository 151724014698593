import React, { Component } from 'react';
import classNames from '../utils/classnames';
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import styled from "@emotion/styled";

const StyledInput = styled('input')`
  width: 100%;
  font-size: 1.5rem;
  padding: 0 ${sizes.xSmall};
  margin: 0 ${sizes.small} 0 0;
  height: ${sizes.large};
  border-radius: 0.3rem;
  border: 0.1rem solid ${colors.gray2};
  display: inline-block;

  &:focus {
    border: 0.1rem solid ${colors.purple3};
    outline: none;
  }
  &:disabled {
    background: ${colors.gray2};
  }
  &::placeholder {
    color: ${colors.gray4};
  }
  &.inputError {
    background-color: ${colors.yellow1};
    border-color: ${colors.yellow2};
  }
`;

class DelayedTextInput extends Component {
  static defaultProps = {
    type: 'text',
    className: '',
    min: undefined,
    onChange: () => null,
    onDelayedChange: () => null,
  };

  constructor(props) {
    super(props);
    this.inFlight = false;
    this.timeoutCounter = props.delay;
    this.event = null;
  }

  shouldComponentUpdate() {
    if (this.handlingChange) {
      this.handlingChange = false;
      return true;
    }
    return !this.inFlight;
  }

  render() {
    const {
      className,
      noFormControl,
      value,
      disabled,
      placeholder,
      onSelect,
      onBlur,
      onFocus,
      onKeyDown,
      type,
      min,
      error,
    } = this.props;
    return (
      <StyledInput
        placeholder={placeholder}
        disabled={disabled}
        value={value !== null ? value : ''}
        onChange={this._handleChange}
        type={type}
        className={classNames(className, error && 'inputError', {
          'form-control': !noFormControl,
        })}
        onSelect={onSelect}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        min={min}
      />
    );
  }

  _handleChange = (e) => {
    this.handlingChange = true;
    e.persist();
    this.event = e;
    this.props.onChange(e);
    if (!this.inFlight) {
      this._performDelayRecurse();
    }
    this.inFlight = true;
  };

  _performDelayRecurse = () => {
    setTimeout(() => {
      if (this.timeoutCounter <= 0) {
        this.timeoutCounter = this.props.delay;
        this.inFlight = false;
        this.props.onDelayedChange(this.event);
      } else {
        this.timeoutCounter -= 10;
        this._performDelayRecurse();
      }
    }, 10);
  };
}

export default DelayedTextInput;
