import {ClientUserAssignment, Roster, User} from "./dto";
import {NotificationFrequencyType} from "./notification";

export enum JobStatus {
  Complete = "COMPLETE",
  InProgress = "IN_PROGRESS",
  Retrying = "RETRYING",
  Failed = "FAILED",
}

export interface JobResult {
  jobId: string;
  apiResultStatusCode?: number;
  status: JobStatus;
  data?: string;
}

export enum EncounterType {
  ADT = "ADT",
  OPENBEDS_REFERRAL = "OPENBEDS_REFERRAL",
  OPENBEDS_CRISIS = "OPENBEDS_CRISIS",
  JUSTICE_INVOLVED = "JUSTICE_INVOLVED",
  FINDHELP_SDOH = "FINDHELP_SDOH",
}

export const EncounterTypes: Record<string, string> = {
  'JUSTICE_INVOLVED': 'Justice Involved',
  'OPENBEDS_REFERRAL': 'Referral - OpenBeds',
  'OPENBEDS_CRISIS': 'Crisis',
  'PAC': 'Post Acute Care',
  'FINDHELP_SDOH': 'Referral - FindHelp',
  'HOSPITAL': 'Hospital'
};

export interface Client {
  publicPatientId: string;
  patientId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  phone?: string;
  address?: Address;
  ssn?: string;
  roster: Roster;
  notifications?: Notification[];
  userAssignments?: ClientUserAssignment[];
}

export type InsurancePlan = {
  id: number;
  insurerId: number;
  rosterPatientId?: number;
  canceled: boolean;
  code: string;
  insuranceCompanyName: string;
  insuranceTranslatedName?: string;
  value: string;
  selected: boolean;
  insurancePlanName?: string;
  insurancePlanOrder?: number;
  type?: string;
};

export interface Notification {
  id: number;
  user: User;
  client: Client;
  groupId: number;
  eventId: number;
  viewed: boolean;
  canceled: boolean;
  eventType: string;
  eventSubtype: string;
  eventDate: string;
  externalNotifications: ExternalNotification[];
}

export interface ExternalNotification {
  id: number;
  notification: Notification;
  sent: boolean;
  method: string;
  notificationFrequency: NotificationFrequencyType;
}

export interface Address {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state: string;
  zip: string;
}

export interface Patient {
  patientId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  phone: string;
  address: Address;
  ssn: string;

  creatorGroupId: number;
  creatorGroupName: string;
}

export interface MatchedPatient {
  patientId: string;
  groupId: number;
}

export interface PatientPhoneNumber {
  groupId: number;
  groupName: string;
  groupType: string;
  phone: string;
  type: string;
  updatedDate: string;
  currentOrganization: boolean;
}

export interface PatientEncounterInsurance {
  groupId: number;
  groupName: string;
  groupType: string;
  encounterStartDate: string;
  encounterEndDate: string;
  insurances: Insurance[];
}

export interface PatientRosterInsurance {
  groupId: number;
  groupName: string;
  uploadDate: string;
  insurances: string[];
}

export interface Insurance {
  insuranceCompanyName: string;
  value: string;
}

export interface Diagnosis {
  fullCode?: string;
  synonym?: string;
}

export interface ADTEvent {
  eventId: number,
  eventDate: string;
  payor?: {
    insuranceCompanyName?: string;
    value?: string;
  };
  setting?: string;
  status?: string;
}

export interface ClientProfile {
  patient: Patient;
  patientPhoneNumbers: PatientPhoneNumber[];
  patientEncounterInsurances: PatientEncounterInsurance[]
  patientRosterInsurances: PatientRosterInsurance[];
  matchedPatients?: MatchedPatient[];
  currentInsurancePlans?: InsurancePlan[];
  currentPingEncounter?: ADTItem;
  currentPing?: ADTEvent;
}

export interface ADTItem {
  admittedFrom?: string;
  encounterId?: string;
  isOpen: boolean;
  admittingFacility?: {
    groupId: number;
    npi?: string;
    name?: string;
    address?: Address;
    type?: string;
  };
  dischargeDisposition?: string,
  attendingProvider?: {
    firstName?: string,
    lastName?: string,
    honorific?: string,
    email?: string,
    phone?: string,
  },
  diagnoses: Diagnosis[];
  events: ADTEvent[];
  patientLocation?: {
    bed?: string,
    pointOfCare?: string,
    room?: string,
  },
  dischargeSummaryInfo?: DischargeInfo;
  isManual: boolean;
  admittingFacilityPublicPatientId: string;
}

export type DischargeInfo = {
  uuid: string;
  hasDischargeDiagnosis: boolean;
  hasReasonForVisit: boolean;
  hasHospitalCourse: boolean;
  hasDischargeInstructions: boolean;
  hasDischargeMedication: boolean;
  hasUpcomingEncounters: boolean;
  hasTests: boolean;
  type: string;
  encounterId: string;
  receivedDate: string;
};

export type DischargeEvent = { status: string; facility: { name: string }; eventDate: string };

export interface ClientEncounter {
  type: EncounterType;
  date: string;
  isPart2?: boolean;
  content: { [key: string]: any };
}

export interface ClientProfileResponse {
  status: JobStatus;
  patientProfileJobResult?: JobResult;
  adtJobResult?: JobResult;
  openBedsReferralJobResult?: JobResult;
  openBedsCrisisJobResult?: JobResult;
  justiceInvolvedJobResult?: JobResult;
  findHelpSdohJobResult?: JobResult;
  patientProfileResponse?: ClientProfile;
  encounters?: ClientEncounter[];
}

export interface Form {
  id: number,
  form_definition: any | null, // TODO anys in here need to be impl when electronic forms are implemented
  form_upload: FormUpload | null,
  created_by: User,
  s3_key: string,
  created_at: string,
  saved_at: string,
  completed_at: string | null,
  deleted_at: string | null,
  score: string | null,
  form_fields: any[] | null,
  client: Client,
}

export interface FormUpload {
  id: number,
  name: string,
  form_type: string,
}

export interface FormRequest {
  roster_id: number,
  completed_at: string,
  score?: string | null,
  client_id: string,
  form_definition_id?: number | null,
}

export interface FormUploadRequest {
  name: string,
  form_type: string,
  form: FormRequest,
}
