import {ICONS} from "patient-ping-remedy/packages/icon";
import IconButton from "../../../common/IconButton";
import {useRosterStore} from "../../../../store/roster_store";
import {useNotifications} from "../../../../hooks/useNotifications";

export default function NotificationBellButton() {
  const { currentRoster } = useRosterStore();

  const { totalUnreadNotifications } = useNotifications({
    currentRosterId: currentRoster?.id || 0,
    onlyShowUnreadNotifications: true,
    enabled: !!currentRoster,
  });

  const displayTotalUnreadNotifications =
    totalUnreadNotifications === 0 ? null :
      totalUnreadNotifications > 99 ? '99+'
        : totalUnreadNotifications;

  return (
    <IconButton
      iconClass={ICONS.bell}
      overflowBackground
      onClick={() => {}}
      badge={displayTotalUnreadNotifications}
    />
  );
}
