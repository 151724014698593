import {
  CrisisStatus,
  CrisisStatuses,
  CrisisStatusType
} from "components/pages/clients/profile_page/summary_tab/timeline_components/CrisisCard.constants";
import {Dispatch, IntakeDetails} from "../api/dto/crisis";

const CrisisHelpers = {
  getSortedDispatches(dispatches: Dispatch[]) {
    return dispatches.sort((a: Dispatch, b: Dispatch) => {
      return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
    });
  },
  getAcceptedDispatch(sortedDispatches: Dispatch[]) {
    return sortedDispatches.find((dispatch: Dispatch) => {
      return dispatch.mcu_status === CrisisStatuses.ACCEPTED
                || dispatch.mcu_status === CrisisStatuses.ARRIVED
                || dispatch.mcu_status === CrisisStatuses.DEPARTED
                || dispatch.mcu_status === CrisisStatuses.COMPLETED;
    });
  },
  getActiveDispatches(sortedDispatches: Dispatch[]) {
    return sortedDispatches.filter((dispatch: Dispatch) => {
      return dispatch.mcu_status !== CrisisStatuses.CANCELLED && dispatch.mcu_status !== CrisisStatuses.DECLINED;
    });
  },
  getInactiveDispatches(sortedDispatches: Dispatch[]) {
    return sortedDispatches.filter((dispatch: Dispatch) => dispatch.mcu_status === CrisisStatuses.DECLINED);
  },
  getAcknowledgedDispatch(sortedDispatches: Dispatch[]) {
    return sortedDispatches.filter((dispatch: Dispatch) => {
      return dispatch.mcu_status === CrisisStatuses.ACKNOWLEDGED;
    });
  },
  getDispatchAndIntakeEvents(
    event: IntakeDetails,
    sortedDispatches: Dispatch[]
  ) {
    let intakeAndCallEvents: Dispatch[] = [{
      id: 'call-start',
      datetime: event.call_start,
      mcu_status: CrisisStatuses.CALL_STARTED,
      responders: [],
    }];

    intakeAndCallEvents.push(...sortedDispatches);

    if(event.call_end) {
      intakeAndCallEvents.push({
        id: 'call-end',
        datetime: event.call_end,
        mcu_status: CrisisStatuses.CALL_ENDED,
        responders: [],
      });
    }

    const latestDispatch = sortedDispatches.at(0);
    const eventEndDatetime = this.getEventEndDateTime(event, latestDispatch);

    if(event.status === CrisisStatuses.CANCELLED) {
      intakeAndCallEvents.push({
        id: 'intake-cancelled',
        datetime: eventEndDatetime.toISOString(),
        mcu_status: CrisisStatuses.INTAKE_CANCELLED,
        responders: [],
      });
    }

    if(event.status === CrisisStatuses.COMPLETED) {
      intakeAndCallEvents.push({
        id: 'intake-completed',
        datetime: eventEndDatetime.toISOString(),
        mcu_status: CrisisStatuses.INTAKE_COMPLETED,
        responders: [],
      });
    }

    return intakeAndCallEvents
      .sort((a: Dispatch, b: Dispatch) => {
        return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
      });
  },
  isEncounterActive(
    overallCardStatus: CrisisStatusType,
  ) {
    const isIntakeCancelled = overallCardStatus === CrisisStatus.INTAKE_CANCELLED;
    const isIntakeCompleted = overallCardStatus === CrisisStatus.INTAKE_COMPLETED;
      
    return !isIntakeCancelled && !isIntakeCompleted;
  },
  getOverallEncounterStatus(
    dispatchAndIntakeEvents: Dispatch[],
    acknowledgedDispatchEvents: Dispatch[],
    acceptedDispatch: Dispatch | undefined
  ) : CrisisStatusType {
    const intakeCompletedEvent = dispatchAndIntakeEvents.find((dispatch: Dispatch) => {
      return dispatch.mcu_status === CrisisStatuses.INTAKE_COMPLETED;
    });

    if(intakeCompletedEvent) {
      return CrisisStatus.INTAKE_COMPLETED;
    }

    const intakeCancelledEvent = dispatchAndIntakeEvents.find((dispatch: Dispatch) => {
      return dispatch.mcu_status === CrisisStatuses.INTAKE_CANCELLED;
    });

    if(intakeCancelledEvent) {
      return CrisisStatus.INTAKE_CANCELLED;
    }
    
    if(acceptedDispatch) {
      return CrisisStatus[acceptedDispatch.mcu_status];
    }

    if(acknowledgedDispatchEvents.length > 0) {
      return CrisisStatus.ACKNOWLEDGED;
    }

    const sortedDispatchAndIntakeEvents = dispatchAndIntakeEvents.sort((a: Dispatch, b: Dispatch) => {
      return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
    });

    const latestEvent = sortedDispatchAndIntakeEvents.at(0);
    if(latestEvent) {
      return CrisisStatus[latestEvent.mcu_status];
    }

    return CrisisStatus.UNKNOWN;
  },
  getReceivingContactStatus(
    acceptedDispatch: Dispatch | undefined,
    activeDispatchEvents: Dispatch[],
  ) {
    if(acceptedDispatch) {
      return CrisisStatus.ACCEPTED;
    }

    const acknowledgeDispatch = activeDispatchEvents.find((dispatch) => {
      return dispatch.mcu_status === CrisisStatuses.ACKNOWLEDGED;
    });

    if(acknowledgeDispatch) {
      return CrisisStatus.ACKNOWLEDGED;
    }

    // if no one has accepted/acknowledge the dispatch, then it is pending
    if(activeDispatchEvents && activeDispatchEvents.length > 0) {
      return CrisisStatus.REQUESTED;
    }

    return CrisisStatus.UNKNOWN;
  },
  getEventEndDateTime(event: IntakeDetails, latestDispatch: Dispatch | undefined) {
    let eventEndDatetime;

    if(event.duration_total_seconds) {
      eventEndDatetime = new Date(event.call_start);
      eventEndDatetime = new Date(eventEndDatetime.getTime() + (event.duration_total_seconds + 1) * 1000);
    } else {
      if(latestDispatch) {
        const latestDispatchPlusOneSecond = new Date(latestDispatch.datetime);
        latestDispatchPlusOneSecond.setSeconds(latestDispatchPlusOneSecond.getSeconds() + 1);
        eventEndDatetime = latestDispatchPlusOneSecond;
      } else {
        eventEndDatetime = new Date(event.created_at);
      }
    }

    return eventEndDatetime;
  }
};

export default CrisisHelpers;
