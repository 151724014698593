import styled  from '@emotion/styled';
import {StandardSemiBoldTypography, StandardTypography} from 'patient-ping-remedy/packages/typography';

const menuItemTypographyStyle = {
  wordBreak: 'break-word',
  '& > *': {
    color: 'inherit',
  },
} as const;

export const MenuItemStandardTypography = styled(StandardTypography)(menuItemTypographyStyle);

export const MenuItemStandardSemiBoldTypography = styled(StandardSemiBoldTypography)(menuItemTypographyStyle);
