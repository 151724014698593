import styled from '@emotion/styled';
import {colors, sizes} from "patient-ping-remedy/packages/theme";

const DaysOfWeekWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
  
  & > button {
    border: 1px solid ${colors.red5};
  }
`;

interface DayButtonProps {
  selected: boolean;
  disabled: boolean;
}

const DayButtonWrapper = styled.div<DayButtonProps>`
  & > button {
    color: ${({selected, disabled}) => disabled ? colors.gray4 :
    selected ? colors.white : colors.tertiary2};

    background-color: ${({selected, disabled}) => disabled ? colors.gray2 :
    selected ? colors.primary1 : colors.white};

    border: 1px solid ${({selected, disabled}) => disabled ? colors.white :
    selected ? colors.white : colors.tertiary2};

    padding: 0.3rem ${sizes.small};
  }
`;

interface AlertScheduleWrapperProps {
  enabled: boolean;
}
const AlertScheduleWrapper = styled.div<AlertScheduleWrapperProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > label div {
    background-color: ${({enabled}) => enabled ? colors.charcoal : colors.white};
  }
`;

const AlertFrequencyWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${sizes.small};
  
  & > button {
    font-size: 1.3rem;
    height: ${sizes.medium};
    &:hover {
      background-color: ${colors.primary1};
      color: ${colors.white};
      border: 1px solid ${colors.primary1};
    }
  }

  & > .alert-frequency-button-selected {
    background-color: ${colors.primary1};
    color: ${colors.white};
    border: 1px solid ${colors.primary1};
  }
`;

const NotificationTimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div label {
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: 0.01rem;
    font-weight: 400;
    color: ${colors.gray5};
  }
`;

export { NotificationTimeWrapper, DaysOfWeekWrapper, DayButtonWrapper,
  AlertScheduleWrapper, AlertFrequencyWrapper };
