import React from "react";
import {NotificationDto} from "../../../../api/dto/notification";
import NotificationPopoverItem from "./NotificationPopoverItem";
import Loading from "../../../common/Loading";
import NotificationHelpers from "../../../../helpers/notification_helpers";
import {useNotifications} from "../../../../hooks/useNotifications";
import {
  MarkAllAsReadLink, NoNotificationsWrapper,
  NotificationGroup,
  TimeCategoryHeader,
  TodayCategoryHeader
} from "./NotificationPopoverContent.styles";
import {StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import DisplayHelpers from "../../../../helpers/display_helpers";
import {useNotificationStore} from "../../../../store/notification_store";

type Props = {
  currentRosterId: number;
  onlyShowUnreadNotifications: boolean;
}

const NotificationPopoverContent = (props: Props) => {
  const { currentRosterId, onlyShowUnreadNotifications } = props;
  const { notifications} = useNotificationStore();

  const { isLoading, error, markAllAsRead} = useNotifications({
    currentRosterId,
    onlyShowUnreadNotifications,
  });

  let todayNotifications;
  let yesterdayNotifications;
  let olderNotifications;

  if (notifications) {
    let categorizedNotifications = NotificationHelpers.getCategorizedNotifications(notifications.slice(0, 3));

    todayNotifications = categorizedNotifications.todayNotifications;
    yesterdayNotifications = categorizedNotifications.yesterdayNotifications;
    olderNotifications = categorizedNotifications.olderNotifications;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (notifications?.length === 0) {
    return <NoNotificationsWrapper>
      <StandardSemiBoldTypography>All caught up! No unread notifications.</StandardSemiBoldTypography>;
    </NoNotificationsWrapper>;
  }

  return (
    <>
      {error && <Banner type={BannerTypes.WARNING}>{DisplayHelpers.displayErrorMessage(error)}</Banner>}
      <NotificationGroup>
        <TodayCategoryHeader>
          <TimeCategoryHeader>TODAY</TimeCategoryHeader>
          <MarkAllAsReadLink
            onClick={() => markAllAsRead()}
            data-testid="mark-all-as-read"
          >
            Mark all as read
          </MarkAllAsReadLink>
        </TodayCategoryHeader>
        {
          todayNotifications?.map((notification: NotificationDto) => {
            return (
              <NotificationPopoverItem
                key={notification.id}
                notification={notification}
              />
            );
          })
        }
      </NotificationGroup>

      <NotificationGroup>
        <TimeCategoryHeader>YESTERDAY</TimeCategoryHeader>
        {
          yesterdayNotifications?.map((notification: NotificationDto) => {
            return (
              <NotificationPopoverItem
                key={notification.id}
                notification={notification}
              />
            );
          })
        }
      </NotificationGroup>

      <NotificationGroup>
        <TimeCategoryHeader>OLDER</TimeCategoryHeader>
        {
          olderNotifications?.map((notification: NotificationDto) => {
            return (
              <NotificationPopoverItem
                key={notification.id}
                notification={notification}
              />
            );
          })
        }
      </NotificationGroup>
    </>
  );
};

export default NotificationPopoverContent;
