import React, {PropsWithChildren} from 'react';
import {colors} from 'patient-ping-remedy/packages/theme';
import {globalStyles, Wrapper, Image, Heading, Content, List, Footer} from './ErrorPage.styles';
import gif from '../../../assets/gifs/error.gif';

type Props = PropsWithChildren & {
  title?: string;
}

export default function ErrorPage(props: Props) {
  return (
    <Wrapper>
      <style>{globalStyles.styles}</style>
      <Image src={gif} alt="error" />
      <Heading color={colors.charcoal}>{props.title || 'Oops! Something went wrong.'}</Heading>
      <Content>
        {props.children || (
          <>
            <span>The page you are trying to access is currently unavailable. Please try the following:</span>
            <List>
              <li>Refresh the page.</li>
              <li>Check your internet connection.</li>
              <li>Come back later.</li>
            </List>
            <span>If the problem persists, please contact your account manager.</span>
            <span>Thank you for your patience.</span>
          </>
        )}
      </Content>
      <Footer>©2024 Bamboo Health</Footer>
    </Wrapper>
  );
}
