import {colors} from "patient-ping-remedy/packages/theme";
import Icon from "patient-ping-remedy/packages/icon";
import styled from '@emotion/styled';

const IconWrapper = styled.div<{ active : boolean}> `
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.active ? colors.white : colors.primary1};
    border-radius: 50px;
    cursor: pointer;
    &:hover {
      background-color: ${props => props.active ? colors.white : colors.tertiary2};
      border-radius: 50%;
    }
`;

const StyledIcon = styled(Icon)`
    font-size: 24px;
`;

const Heading = styled.div`
    font-family: "Libre Franklin", sans-serif;
    font-size: 1.4rem;
    font-weight: 800;
    padding: 0;
    margin-top: 2rem;
    text-align: left;
`;

const Label = styled.div`
    color: ${colors.gray4};
    margin: 1rem 2rem;
`;

export { IconWrapper, StyledIcon, Heading, Label };
