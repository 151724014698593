import {
  EventDetailsSection,
  EventDetailsSectionDetails,
  EventDetailsSectionHeader,
} from "./Timeline.styles";
import React, {ReactNode} from "react";
import DisplayHelpers from "../../../helpers/display_helpers";

type Props = {
  children?: ReactNode;
  detailsHeaderText: string | null;
  hasDetails: boolean;
  lowerSection?: boolean;
}

const DetailsSection = (props : Props) => {
  return (
    <EventDetailsSection>
      {
        props.detailsHeaderText &&
        <EventDetailsSectionHeader className={props.lowerSection ? 'lower' : ''}>
          { props.detailsHeaderText }
        </EventDetailsSectionHeader>
      }

      { props.hasDetails
        ? <EventDetailsSectionDetails>{props.children}</EventDetailsSectionDetails>
        : <EventDetailsSectionDetails>
          <div>{ DisplayHelpers.humanize(`No ${props.detailsHeaderText} details available`)}</div>
        </EventDetailsSectionDetails>
      }
    </EventDetailsSection>
  );
};

export default DetailsSection;
