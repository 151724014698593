import React from 'react';
import {DISCHARGED} from "../../../constants/PingStatuses";

const AGAINST_MEDICAL_ADVICE = 'Against Medical Advice';
const LEFT_WITHOUT_BEING_SEEN = 'Left without being seen';

export function getSentenceText(disposition) {
  switch (disposition) {
  case null:
  case '':
    return '';
  case AGAINST_MEDICAL_ADVICE:
    return disposition;
  case LEFT_WITHOUT_BEING_SEEN:
    return 'without being seen';
  default:
    return `to ${disposition}`;
  }
}

const PingDispositionText = ({ ping, pingEncounter }) => {
  if (ping.status === DISCHARGED) {
    const text = getSentenceText(pingEncounter.dischargeDisposition);
    if (text) {
      return (
        <span className="ping-disposition-text" data-testid="ping-disposition-text">
          {text}
        </span>
      );
    }
  }
  return null;
};

export default PingDispositionText;
