import {SVG} from "@svgdotjs/svg.js";
import {colors} from "patient-ping-remedy/packages/theme";

const ORS_MAX = 999;
const CIRCLE_WIDTH = 80;

const labels = {
  below: 'Below Average',
  average: 'Average',
  above: 'Above Average',
};

export default class PdmpScoreGraph {
  container: HTMLElement;
  svg: any;
  score: number;
  riskCategory: string;
  labels: Record<string, string>;

  constructor(container: HTMLElement, score: number, riskCategory: string) {
    container.innerHTML = ''; // clear any previous svg content
    this.container = container;
    this.svg = SVG().addTo(this.container);

    this.score = score;
    this.riskCategory = riskCategory;
    this.labels = labels;

    this.render();
  }

  render() {
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;

    if (width <= 0) return;

    const radius = height * 2;
    const positionX = (width - radius) / 2;
    const innerCircleDelta = CIRCLE_WIDTH / 2;
    const deflection = 180 * (this.score / ORS_MAX);

    this.svg.clear().size(width, height);

    const clipRect = this.svg
      .rect(radius, radius / 2)
      .move(positionX, radius / 2);

    const clip = this.svg.clip();
    clip.add(clipRect);

    this.svg
      .circle(radius)
      .move(positionX, 0)
      .addClass('uors-g-background')
      .addClass(`uors-g-background--${this.riskCategory}`);

    const circle = this.svg
      .circle(radius)
      .move(positionX, 0)
      .addClass('uors-g-foreground')
      .addClass(`uors-g-foreground--${this.riskCategory}`);

    circle.clipWith(clipRect);
    circle.rotate(deflection);

    this.svg
      .circle(radius - CIRCLE_WIDTH)
      .move(positionX + innerCircleDelta, innerCircleDelta)
      .fill(colors.white);

    // labels
    // TODO when we get risk category back from blue uncomment this
    // this.svg
    //   .plain(this.labels[this.riskCategory])
    //   .move(width / 2, height - 55)
    //   .font({ family: null })
    //   .addClass('uors-g-label');

    this.svg
      .plain(this.score)
      .move(width / 2, height - 9)
      .font({ family: null })
      .addClass('uors-g-score');
  }
}
