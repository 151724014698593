
type Props = {
  id: string;
  url: string;
};

const FormPDFViewer = (props: Props) => {
  return (
    <>
      <embed
        id={props.id}
        data-testid="pdf-viewer"
        src={props.url}
        width="100%" height="100%"
        type="application/pdf"/>
    </>
  );
};

export default FormPDFViewer;
