import styled from "@emotion/styled";
import {colors} from "patient-ping-remedy/packages/theme";

export const Container = styled.div((props: { inline?: boolean }) => ({
  margin: 0,
  padding: 0,
  ...(props.inline && {
    display: 'inline',
    marginLeft: '10px',
  }),
}));

export const ExpandMoreButtonStyled = styled.button((props: { active: boolean, inline?: boolean, disabled?: boolean }) => ({
  background: 'none',
  border: 'none',
  fontSize: '16px',
  margin: props.inline ? 0 : '10px 0 0 0',
  textAlign: 'left',
  width: 'max-content',
  padding: 0,
  color: props.inline
    ? (props.active ? colors.tertiary2 : colors.primary1)
    : (props.active ? colors.primary1 : colors.gray4),
  cursor: 'pointer',

  ...(!props.disabled && {
    cursor: 'pointer',
    '&:hover': {
      color: colors.purple3,
    }}),
}));
