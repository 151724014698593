import {moment} from "vis-timeline/standalone";
import pluralize from 'pluralize';

const format = (value, dateFormat) => {
  if (!value) {
    return '';
  }

  return moment(value).format(dateFormat);
};

export const formatAge = (dob) => {
  const yrs = dob ? moment().diff(moment.utc(dob), 'years') : '';
  return yrs || yrs === 0 ? `${yrs}${pluralize('yr', yrs, false)}` : '';
};

export const formatDate = (date) => format(date, 'MM/DD/YY');
export const formatDateTime = (dateTime) => format(dateTime, `MM/DD/YY h:mm A`);
