import QuickFilter from "./QuickFilter";
import {nonGroupedValues} from "./FilterComponent";

export interface QuickFilterInterface {
  displayText: string;
  key: string;
  value: nonGroupedValues | null;
}

type Props = {
  quickFilters: QuickFilterInterface[];
  activeFilter?: QuickFilterInterface | null;
  filterFunction: Function;
}

const QuickFilterBar = (props: Props) => {
  const handleClick = (filter: QuickFilterInterface) => {
    props.filterFunction(filter);
  };

  return (
    <>
      { props.quickFilters.map((filter, index) => {
        return (
          <QuickFilter
            key={index}
            onClick={() => handleClick(filter)}
            negated={filter.displayText !== props.activeFilter?.displayText}
          >
            {filter.displayText}
          </QuickFilter>
        );
      }
      )}
    </>
  );
};

export default QuickFilterBar;
