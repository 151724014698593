import React from 'react';
import {Roster} from '../../../../../../api/dto/dto';
import {SDOHEvent, SDOHProgram, SDOHSender} from '../../../../../../api/dto/sdoh';
import {
  EventContainerEventsList,
  EventContainerEventsSession,
  EventContainerEventsStatus,
  EventContainerEventsTime,
  EventContainerEventsWrapper
} from '../../../../../common/timeline/Timeline.styles';
import ExpandMoreButton from '../../../../../common/timeline/ExpandMoreButton';
import {SDOHStatus} from './SDOHCard.constants';
import DisplayHelpers from '../../../../../../helpers/display_helpers';

type Props = {
  events: SDOHEvent[];
  program: SDOHProgram;
  sender: SDOHSender;
  roster: Roster;
  active: boolean;
}

export default function SDOHTimeline(props: Props) {
  return (
    <EventContainerEventsWrapper>
      <EventContainerEventsSession>
        {props.events.slice(0, 3).map((event, index) => {
          const status = SDOHStatus[event.derivedStatus];
          return (
            <EventContainerEventsList active={props.active} key={index}>
              <EventContainerEventsTime>
                {DisplayHelpers.formatDateUTC(event.eventDateTime)}
                <span className="pipe-separator">{DisplayHelpers.formatTimeUTC(event.eventDateTime)}</span>
              </EventContainerEventsTime>
              <EventContainerEventsStatus>
                {status.displayMessage(props.program, props.sender, props.roster)}
              </EventContainerEventsStatus>
            </EventContainerEventsList>
          );
        })}
        {props.events.length > 3 && (
          <ExpandMoreButton
            useExpandHideText={true}
            active={props.active}
            buttonText={`${props.events.length - 3} more`}
            expandItems={props.events.slice(3).map((event, index) => {
              const status = SDOHStatus[event.derivedStatus];
              return (
                <EventContainerEventsList active={props.active} key={index}>
                  <EventContainerEventsTime>
                    {DisplayHelpers.formatDateUTC(event.eventDateTime)}
                    <span className="pipe-separator">{DisplayHelpers.formatTimeUTC(event.eventDateTime)}</span>
                  </EventContainerEventsTime>
                  <EventContainerEventsStatus>
                    {status.displayMessage(props.program, props.sender, props.roster)}
                  </EventContainerEventsStatus>
                </EventContainerEventsList>
              );})
            }
          />
        )}
      </EventContainerEventsSession>
    </EventContainerEventsWrapper>
  );
}
