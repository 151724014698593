import React from 'react';
import {ICONS} from 'patient-ping-remedy/packages/icon';
import IconButton from '../common/IconButton';

export default function HelpWidget() {
  return (
    <IconButton
      iconClass={ICONS['question-circle']}
      overflowBackground
      onClick={() => console.log('not implemented')}
    />
  );
}
