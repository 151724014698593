import {css} from "@emotion/css";
import styled from "@emotion/styled";

const truncate = css`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top; // needed to get rid of extra space generated by the inline-block
`;

const StyledInsuranceName = styled.span`
  font-size: 1.3rem;
`;

export { truncate, StyledInsuranceName };
