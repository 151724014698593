import React, { Component } from 'react';
import InputWithDropdown from './InputWithDropdown';
import Helpers from "../../../../../../../helpers/helpers";
import {CarecoApiContext} from "../../../../../../../app-context/careco-api-context";

class FacilitySelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      inFlight: false,
      menuStyle: {},
      showDropdown: true,
    };
    this.dropdownRef = React.createRef();
  }

  handleScroll = (e) => {
    const dropdownElement = this.dropdownRef.current;
    const modalElement = e.target.getBoundingClientRect();
    if(Helpers.isDefined(dropdownElement)){
      const { height, top, left, bottom } = dropdownElement.getBoundingClientRect();

      if(modalElement){
        if(bottom > modalElement.bottom + 10){
          this.setState({
            ...this.state,
            showDropdown: false,
          });
        }else{
          this.setState({
            ...this.state,
            showDropdown: true,
            menuStyle: { ...this.state.menuStyle, top: top + height, left },
          });
        }
      }
    }
  };

  componentDidMount() {
    const element = document.getElementById("modal-body");
    if (element) {
      element.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const element = document.getElementById("modal-body");
    if(element){
      element.removeEventListener('scroll', this.handleScroll);
    }
  }

  static contextType = CarecoApiContext;

  handleSearch(name) {
    const { height, top, left } = this.dropdownRef.current.getBoundingClientRect();
    this.setState({
      inFlight: true,
      menuStyle: { ...this.state.menuStyle, top: top + height, left },
    });

    const { disposition, groupId } = this.props;
    const { carecoApi } = this.context;

    carecoApi.searchFacilities(
      name,
      disposition,
      groupId,
    ).then((response) => {
      this.setState({
        options: response,
        inFlight: false,
      });
    });
  }

  onChange(label) {
    this.props.onSelect({ label, id: null });
  }

  onSelect(option) {
    option ? this.props.onSelect(option) : this.props.onSelect({ label: '', id: null });
  }

  render() {
    const { value, classes = '', disabled = false, floatOnModal, onBlur, error, testId, id } = this.props;
    const { options, inFlight, menuStyle, showDropdown } = this.state;
    return (
      <InputWithDropdown
        floatOnModal={floatOnModal}
        options={options}
        onSelect={this.onSelect.bind(this)}
        search={this.handleSearch.bind(this)}
        inFlight={inFlight}
        value={value}
        onChange={this.onChange.bind(this)}
        className={classes}
        disabled={disabled}
        menuStyle={menuStyle}
        onBlur={onBlur}
        error={error}
        testId={testId}
        ref={this.dropdownRef}
        id={id}
        showDropDown={showDropdown}
      />
    );
  }
}

export default FacilitySelect;
