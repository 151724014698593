import {
  CrisisSetting,
  FindHelpSetting,
  HospitalSetting,
  JISetting,
  NotificationFrequencyType,
  NotificationSettingsDto,
  NotificationSchedule,
  NotificationSettings,
  PacSetting,
  ReferralSetting,
  NotificationEventSettingDto,
  REFERRAL_EVENT,
  CRISIS_EVENT,
  JI_EVENT,
  FIND_HELP_EVENT,
  HOSPITAL_EVENT,
  POST_ACUTE_CARE_EVENT, NotificationDto,
} from "../api/dto/notification";

const NotificationHelpers = {
  buildNotificationSettings(notificationSettingsDto: NotificationSettingsDto): NotificationSettings {
    return {
      data: notificationSettingsDto,
      referralSetting: this.buildReferralSetting(notificationSettingsDto.notificationEventSettingDtos[REFERRAL_EVENT]),
      crisisSetting: this.buildCrisisSetting(notificationSettingsDto.notificationEventSettingDtos[CRISIS_EVENT]),
      findHelpSetting: this.buildFindHelpSetting(notificationSettingsDto.notificationEventSettingDtos[FIND_HELP_EVENT]),
      hospitalSetting: this.buildHospitalSetting(notificationSettingsDto.notificationEventSettingDtos[HOSPITAL_EVENT]),
      jiSetting: this.buildJiSetting(notificationSettingsDto.notificationEventSettingDtos[JI_EVENT]),
      pacSetting: this.buildPacSetting(notificationSettingsDto.notificationEventSettingDtos[POST_ACUTE_CARE_EVENT]),
      notificationSchedule: this.buildNotificationSchedule(notificationSettingsDto)
    };
  },

  buildReferralSetting(notificationEventSettingDto: NotificationEventSettingDto) : ReferralSetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      newReferral: categories['NEW_REFERRAL'],
      accepted: categories['REFERRAL_ACCEPTED'],
      declined: categories['REFERRAL_DECLINED'],
      show: categories['REFERRAL_SHOW'],
      noShow: categories['REFERRAL_NO_SHOW']

    };
  },

  buildCrisisSetting(notificationEventSettingDto: NotificationEventSettingDto) : CrisisSetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      intakeCompleted: categories['CRISIS_INTAKE_COMPLETED'],
      dispatchCanceled: categories['CRISIS_DISPATCH_CANCELED'],
      dispatchRequested: categories['CRISIS_DISPATCH_REQUESTED'],
      dispatchAccepted: categories['CRISIS_DISPATCH_ACCEPTED'],
      dispatchDeclined: categories['CRISIS_DISPATCH_DECLINED'],
      dispatchArrived: categories['CRISIS_DISPATCH_ARRIVED'],
      dispatchDeparted: categories['CRISIS_DISPATCH_DEPARTED'],
      dispatchAcknowledged: categories['CRISIS_DISPATCH_ACKNOWLEDGED'],
      intakeCanceled: categories['CRISIS_INTAKE_CANCELED'],
    };
  },

  buildFindHelpSetting(notificationEventSettingDto: NotificationEventSettingDto) : FindHelpSetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      newReferral: categories['NEW_REFERRAL'],
      accepted: categories['REFERRAL_ACCEPTED'],
      declined: categories['REFERRAL_DECLINED'],
      pending: categories['REFERRAL_PENDING']
    };
  },

  buildHospitalSetting(notificationEventSettingDto: NotificationEventSettingDto) : HospitalSetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      admitted: categories['ADMITTED'],
      presentedEr: categories['PRESENTED'],
      discharged: categories['DISCHARGED'],
      transferred: categories['TRANSFERRED'],
      deceased: categories['DECEASED']

    };
  },

  buildJiSetting(notificationEventSettingDto: NotificationEventSettingDto) : JISetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      booked: categories['BOOKED'],
      released: categories['RELEASED']
    };
  },

  buildPacSetting(notificationEventSettingDto: NotificationEventSettingDto) : PacSetting {
    const categories = notificationEventSettingDto.notificationEventSubtypes;

    return {
      enabled: notificationEventSettingDto.enabled,
      eventType: notificationEventSettingDto.eventType,
      emailEnabled: notificationEventSettingDto.emailEnabled,
      smsEnabled: notificationEventSettingDto.smsEnabled,

      admitted: categories['ADMITTED'],
      discharge: categories['DISCHARGED'],
      transferred: categories['TRANSFERRED'],
      deceased: categories['DECEASED']
    };
  },
  getDefaultFromTime(): Date {
    let defaultFromTime = new Date();
    defaultFromTime.setHours(7);
    defaultFromTime.setMinutes(30);
    return defaultFromTime;
  },
  getDefaultToTime(): Date {
    let defaultToTime = new Date();
    defaultToTime.setHours(18);
    defaultToTime.setMinutes(0);
    return defaultToTime;
  },
  parseTimeString(timeString: string): Date | null {
    const match = timeString.match(/(\d{2}):(\d{2}) (AM|PM)?/);
    if (!match) {
      return null; // Handle invalid format
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const period = match[3] || ''; // Default to undefined for missing AM/PM

    let time = new Date();
    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;  // Adjust for PM hours
    } else if (period === 'AM' && hours === 12) {
      adjustedHours = 0;  // Adjust for 12 AM
    }

    time.setHours(adjustedHours);
    time.setMinutes(minutes);

    return time;
  },
  buildNotificationSchedule(notificationSettingsDto: NotificationSettingsDto): NotificationSchedule {
    let notificationScheduleResponse = notificationSettingsDto.notificationScheduleDto;
    if (!notificationScheduleResponse) {
      return {
        fromTime: this.getDefaultFromTime(),
        toTime: this.getDefaultToTime(),
        timezone: "US/Eastern",
        notificationFrequency: "REAL_TIME",
        customScheduledEnabled: false,
        notificationScheduleDays: {
          "mon": true,
          "tue": true,
          "wed": true,
          "thu": true,
          "fri": true,
          "sat": true,
          "sun": true
        }
      };
    }

    let daysOfWeek = notificationScheduleResponse.notificationScheduleDays;

    return {
      fromTime: this.parseTimeString(notificationScheduleResponse.fromTime) ?? this.getDefaultFromTime(),
      toTime: this.parseTimeString(notificationScheduleResponse.toTime) ?? this.getDefaultToTime(),
      timezone: notificationScheduleResponse.timezone,
      notificationFrequency: notificationScheduleResponse.notificationFrequency as NotificationFrequencyType,

      customScheduledEnabled: notificationScheduleResponse.customScheduledEnabled,

      notificationScheduleDays: {
        "mon": daysOfWeek.includes("MONDAY"),
        "tue": daysOfWeek.includes("TUESDAY"),
        "wed": daysOfWeek.includes("WEDNESDAY"),
        "thu": daysOfWeek.includes("THURSDAY"),
        "fri": daysOfWeek.includes("FRIDAY"),
        "sat": daysOfWeek.includes("SATURDAY"),
        "sun": daysOfWeek.includes("SUNDAY")
      }
    };
  },
  getCategorizedNotifications(notifications: NotificationDto[]) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);


    let todayNotifications: NotificationDto[] = [];
    let yesterdayNotifications: NotificationDto[] = [];
    let olderNotifications: NotificationDto[] = [];

    notifications.forEach(notification => {
      const eventDate = new Date(notification.eventDate);
      eventDate.setHours(0, 0, 0, 0);

      if (eventDate.getTime() === today.getTime()) {
        todayNotifications.push(notification);
      } else if (eventDate.getTime() === yesterday.getTime()) {
        yesterdayNotifications.push(notification);
      } else {
        olderNotifications.push(notification);
      }
    });

    return {
      todayNotifications: todayNotifications,
      yesterdayNotifications: yesterdayNotifications,
      olderNotifications: olderNotifications
    };
  },
};

export default NotificationHelpers;
