import React, {useContext} from "react";
import {useAuthStore} from "../../../../store/auth_store";
import {useQuery} from "@tanstack/react-query";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import Input from "patient-ping-remedy/packages/input";
import Loading from "../../../common/Loading";
import {NotificationUserWrapper} from "./NotificationUserInfo.styles";
import Spacer from "patient-ping-remedy/packages/spacer";
import DisplayHelpers from "../../../../helpers/display_helpers";

const NotificationUserInfo = () => {
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentUser } = useAuthStore();

  const {
    isLoading: isLoadingUser,
  } = useQuery({
    queryKey: ['currentUser', currentUser?.id],
    queryFn: () => {
      return carecoApi?.getUser(currentUser?.id!);
    },
    enabled: !!currentUser?.id && !!carecoApi
  });

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <NotificationUserWrapper>
      <Input
        labelText={'Email'}
        disabled={true}
        required={"required"}
        value={currentUser?.email || ''}
        onChange={(e) => {}}
      />
      <Spacer itemHeight={'20px'} />
      <Input
        labelText={'Phone'}
        disabled={true}
        required={"required"}
        value={DisplayHelpers.formatPhoneNumber(currentUser?.phone || '')}
        onChange={(e) => {}}
      />
    </NotificationUserWrapper>
  );
};

export default NotificationUserInfo;
