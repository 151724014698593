import { RosterResponse, User } from "api/dto/dto";
import TabsComponent from "../TabsComponent";
import UserDetailsForm from "components/common/user_details/UserDetailsForm";
import Loading from "../Loading";
import { Value } from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import AccessDetails from "./AccessDetails";
import GroupDetailsForm from "./GroupDetailsForm";
import GroupDetailsDisplay from "./GroupDetailsDisplay";
type GenericObject = { [key: string]: any }

type Props = {
    availableRosterOptions: Value[];
    profilepage?:Boolean
    handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
    form: GenericObject;
    enableInputsForSelf: boolean;
    showGroupForm: boolean;
    showAdminLink: boolean;
    user: User;
    rosters: RosterResponse;
    isLoading: boolean;
    loadError: Error | null;
  }
  
const UserDetailsTabs = (props: Props) => {
  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <TabsComponent
        tabs={
          [
            {
              value: 0,
              label: 'Details',
              content:
                 <UserDetailsForm user={props.user}
                   profilepage={props.profilepage}
                   form={props.form}
                   enableInputsForSelf={props.enableInputsForSelf}
                   handleChange={props.handleChange}
                 />
            },
            {
              value: 1,
              label: 'Groups',
              content: props.showGroupForm ? 
                <GroupDetailsForm user={props.user}
                  rosters={props.rosters}
                  availableRosterOptions={props.availableRosterOptions}
                  form={props.form}
                  handleChange={props.handleChange}
                /> 
                :
                <GroupDetailsDisplay
                  user={props.user}
                  rosters={props.rosters}
                />
            },
            {
              value: 2,
              label: 'Access',
              content: <AccessDetails user={props.user} rosters={props.rosters.data} showAdminLink={props.showAdminLink} />
            }
          ]
        }
        forceRender={true} />
    </>
  );
};

export default UserDetailsTabs;
