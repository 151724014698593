import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import Input from "patient-ping-remedy/packages/input";

const Form = styled('form')`
  margin-top: 2rem;
`;
const PdmpDiv = styled('div')`
    display: flex;
`;
const StyledInputPdmp = styled(Input)`
  font-size: 1.6rem;
  height: 4rem;
  margin-top: 0;
  display: flex;
  background-color: ${colors.gray2};
  margin-right: -40px;
  align-items: flex-end;
`;

const StyledPdmpRow = styled('div')`
  height: 4rem;
  display: flex;
  margin-top: 2.5rem;
  background-color: ${colors.gray1};
`;
const StyledLabel= styled('label')`
    margin-top: 1px;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    height: 1px;
    width: 100px;
    line-height: 1.8rem;
    display: flex;
    position: absolute;
`;
export { Form,PdmpDiv,StyledPdmpRow,StyledInputPdmp,StyledLabel};
