import styled from '@emotion/styled';

export const FacilityPickerWrapper = styled.div`
  #facility-picker {
    margin-top: 0;

    & > div:first-of-type {
      height: 40px;
    }
  }
`;
