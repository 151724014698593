import {Agency} from "../../../../../../api/dto/justice-involved";
import React from "react";
import {
  EventDetailsSection,
  EventDetailsSectionDetails,
  EventDetailsSectionHeader
} from "../../../../../common/timeline/Timeline.styles";
import Helpers from "../../../../../../helpers/helpers";
import Icon, {ICONS} from "patient-ping-remedy/packages/icon";
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import Spacer from "patient-ping-remedy/packages/spacer";
import DisplayHelpers from "../../../../../../helpers/display_helpers";

type Props = {
  detailsHeaderText: string;
  active: boolean;
  agency : Nullable<Agency>;
}

const JusticeAgencyAddress = ({agency, active, detailsHeaderText}: Props) => {
  const { address1, address2, city, stateOrProvince, postalCode} = agency?.address || {};
  const address1and2 = address1 ? (address2 ? `${address1} ${address2}` : address1) : '';
  const addressParts = Helpers.joinWithComma(address1and2, city, stateOrProvince, postalCode);
  const isAddressValid = !Helpers.isEmpty(addressParts);
  const showDetailsSection = Helpers.isDefined(agency?.name) || Helpers.isDefined(agency?.phoneNumber) || isAddressValid;

  return (
    <EventDetailsSection>
      {showDetailsSection && (
        <EventDetailsSectionDetails>
          <EventDetailsSectionHeader>
            { detailsHeaderText }
          </EventDetailsSectionHeader>
          {agency?.name && <div>{agency?.name}{isAddressValid && <span>,</span>}</div>}
          {isAddressValid && <div> {addressParts}.</div>}
          {agency?.phoneNumber &&
          <div>
            <Icon
              iconClass={ICONS.phone}
              color={active ? colors.primary1 : colors.gray4}
            />
            <Spacer itemWidth={sizes.xxSmall}/>
            {agency?.phoneNumber && DisplayHelpers.formatPhoneNumber(agency.phoneNumber)}
          </div>
          }
        </EventDetailsSectionDetails>
      )}
    </EventDetailsSection>
  );
};

export default JusticeAgencyAddress;
