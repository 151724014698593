import {colors, fontFamily, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';
import Icon from "patient-ping-remedy/packages/icon";

const Button = styled('button')`
  color: white;
  font-family: ${fontFamily.default};
  font-size: ${fontSize.hint};
  font-weight: bold;
  background-color: initial;
  background-image: none;
  border: 0;
  cursor: pointer;
`;

const IconWrapper = styled.div<{ active : boolean}>`
    margin: auto;
    width: 56px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.active ? colors.purple1 : 'initial'};
    border-radius: 100px;

    &:hover {
        background-color: ${props => props.active ? colors.white : colors.tertiary2};
        border-radius: 100px;
    }
`;

const StyledIcon = styled(Icon)`
  font-size: 24px;
`;

const StyledSemiBoldTypography = styled.div`
  font-family: "Libre Franklin", sans-serif;
  font-size: 12px;
  color: ${colors.white};
  font-weight: 800;
`;

const NavItemWrapper = styled.div`
    margin-bottom: 12px;
`;

export { Button, IconWrapper, StyledIcon, StyledSemiBoldTypography, NavItemWrapper};
