import Popover from "patient-ping-remedy/packages/popover";
import React, {useState} from "react";
import Avatar from "./Avatar";
import {Roster, User} from "../../api/dto/dto";
import {
  SmallSemiBoldTypography,
  SmallTypography
} from "patient-ping-remedy/packages/typography";
import {PopoverContainer, UserDetailsWrapper, Wrapper} from "./AvatarDetails.styles";

type Props = {
  user?: User | null;
  roster?: Roster | null;
}
const AvatarDetails = (props: Props) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const content = (
    <Wrapper>
      <Avatar user={props.user}/>
      <UserDetailsWrapper>
        <SmallSemiBoldTypography>{ props.user?.first_name} {props.user?.last_name}</SmallSemiBoldTypography>
        {props.roster && <SmallTypography>{props.roster.name}</SmallTypography>}
        <SmallTypography>{props.user?.email}</SmallTypography>
      </UserDetailsWrapper>
    </Wrapper>
  );

  return (
    <PopoverContainer>
      <Popover
        position={"right"}
        toggle={() => setDetailsOpen(!detailsOpen)}
        isOpen={detailsOpen}
        content={content}>
        <Avatar user={props.user}/>
      </Popover>
    </PopoverContainer>
  );
};

export default AvatarDetails;
