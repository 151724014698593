import { create } from 'zustand';
import { Roster } from '../api/dto/dto';
import {devtools} from "zustand/middleware";

export type RosterState = {
    currentRoster: Roster | null;
    setCurrentRoster: Function;
}

export const useRosterStore = create<RosterState>()(
  devtools(set => ({
    currentRoster: null,
    setCurrentRoster: (val : Roster) => set({currentRoster: val })
  }),
  { name: 'rosterStore', enabled: ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')},
  )
);
