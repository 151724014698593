import {IntakeDetails} from "../api/dto/crisis";
import DisplayHelpers from "./display_helpers";
import Helpers from "./helpers";
import {
  CrisisStatuses
} from "../components/pages/clients/profile_page/summary_tab/timeline_components/CrisisCard.constants";
import {MasterReferralResponse} from "../api/dto/referral";
import ReferralHelpers from "./referral_helpers";
import {ADTItem} from "../api/dto/client-profile";
import {GroupTypes} from "../components/pages/clients/profile_page/summary_tab/timeline_components/ADTCard.constants";
import {JusticeInvolvedItem} from "../api/dto/justice-involved";
import {SDOHItem} from '../api/dto/sdoh';
import {
  SDOHStatus,
  SDOHStatuses
} from '../components/pages/clients/profile_page/summary_tab/timeline_components/SDOHCard.constants';

export function mapAdtItem(event: ADTItem) {
  const startDate = new Date(event.events.at(0)!.eventDate);
  const endDate = event.isOpen ? new Date() : new Date(event.events.at(-1)!.eventDate);
  const eventType = GroupTypes[event.admittingFacility?.type ?? 'Unknown'];

  const classNames = ['encounter'];
  if (eventType.displayName === 'Hospital') {
    classNames.push('encounter-hospital');
  } else if(eventType.displayName === 'Post Acute') {
    classNames.push('encounter-pac');
  }

  if (!event.isOpen) {
    classNames.push('inactive');
  }

  return {
    // make sure this ID matches the dom element id in the vertical timeline
    id: `encounter-${event.encounterId}`,
    content: '',
    start: startDate,
    end: endDate,
    className: classNames.join(' '),
    title: `<b>${event.admittingFacility?.name}</b>`
      + `<br/><br/><b>Start Date: </b><span>${startDate.toLocaleDateString()}
        ${event.isManual ? '' : ` at ${startDate.toLocaleTimeString()}`}</span>`
      + `<br/><br/><b>End Date: </b>`
      + `<span>${event.isOpen
        ? 'Ongoing'
        : `${endDate.toLocaleDateString()}${event.isManual ? '' : ` at ${endDate.toLocaleTimeString()}`}`}</span>`
      + `<br/><br/><b>LOS: </b><span>${Helpers.calculateTimeDifference(startDate, endDate)} day(s)</span>`
    ,
  };
}

export function mapJusticeInvolvedEvent(event: JusticeInvolvedItem) {
  const startDate = new Date(event.arrestDateTime || event.bookingDateTime!);
  const isOpen = !event.released;
  const endDate = isOpen ? new Date() : new Date(event.releaseDateTime!);
  const bookingDate = new Date(event.bookingDateTime!);

  const classNames = ['justice-involved'];

  if (!isOpen) {
    classNames.push('inactive');
  }

  return {
    // make sure this ID matches the dom element id in the vertical timeline
    id: `justice-involved-${event.id}`,
    content: '',
    start: startDate,
    end: endDate,
    className: classNames.join(' '),
    title: `<b> ${event.arrestingAgency?.name}</b>`
      + `<br/><br/><b>Arrest Date: </b>
        <span>${startDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(event.arrestDateTime!)}</span>`
      + `<br/><br/><b>${event.holdingFacility?.name}</b>`
      + `<br/><br/><b>Booking Date: </b>
        <span>${bookingDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(event.bookingDateTime!)}</span>`
      + (!isOpen ? `<br/><br/><b>Release Date: </b>
        <span>${endDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(event.releaseDateTime!)}</span>` : '')
  };
}

export function mapSdohItem(event: SDOHItem) {
  const sortedEvents = event.events.sort((a, b) =>
    new Date(b.eventDateTime).getTime() - new Date(a.eventDateTime).getTime()
  );

  const firstEvent = sortedEvents.at(-1)!;
  const latestEvent = sortedEvents.at(0)!;

  const latestEventStatus = SDOHStatus[latestEvent.derivedStatus];
  const isOpen = [SDOHStatuses.NEW_REFERRAL, SDOHStatuses.PENDING].includes(latestEvent.derivedStatus);

  const startDate = DisplayHelpers.getDateWithTimezone(firstEvent.eventDateTime);
  const endDate = isOpen ? new Date() : DisplayHelpers.getDateWithTimezone(latestEvent.eventDateTime);

  const classNames = ['sdoh', 'referral'];

  if (!isOpen) {
    classNames.push('inactive');
  }

  return {
    // make sure this ID matches the dom element id in the vertical timeline
    id: `sdoh-${event.referralId}`,
    content: '',
    start: startDate,
    end: endDate,
    className: classNames.join(' '),
    title: `<b>${latestEventStatus.displayName}</b>`
      + `<br/><br/><b>From: </b><span>${event.roster.name}</span>`
      + `<br/><br/><b>To: </b><span>${event.program.name}</span>`
      + `<br/><br/><b>Start Date: </b><span>${startDate.toLocaleDateString()} at ${startDate.toLocaleTimeString()}</span>`
    ,
  };
}

export const mapCrisisEvent = (event: IntakeDetails) => {
  let startDate = new Date(event.call_start);
  let isOpen = event.status === CrisisStatuses.OPEN;
  let endDate = isOpen ? new Date() : calculateEndDate();
  const organizationName = event.organization.name || `${event.created_by.first_name} ${event.created_by.last_name}`;

  function calculateEndDate(){
    if(event.duration_total_seconds){
      return new Date(startDate.getTime() + event.duration_total_seconds * 1000);
    }

    // To not break the timeline, we need to set an end date for the event.
    return startDate;
  }

  let endDateDisplay = 'Ongoing';
  let durationDisplayText = 'Ongoing';
  let classNames = ['crisis'];
  let showDuration = isOpen ? true : event.status !== CrisisStatuses.INTAKE_CANCELLED;

  if (!isOpen) {
    classNames.push('inactive');
    endDateDisplay = event.status === CrisisStatuses.INTAKE_CANCELLED || event.status === CrisisStatuses.CANCELLED ?
      'Crisis intake Canceled' :
      `${endDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(endDate.toString())}`;

    if(event.duration_total_seconds){
      let daysHrsMinsSecs = Helpers.calculateSecondsToDaysHrsMinsSecs(event.duration_total_seconds);
      durationDisplayText = `${daysHrsMinsSecs.daysDisplay} ${daysHrsMinsSecs.hoursDisplay} ${daysHrsMinsSecs.minutesDisplay}`;
    }else{
      showDuration = false;
    }
  }

  return {
    // make sure this ID matches the dom element id in the vertical timeline
    id: `crisis-${event.uuid}`,
    content: "",
    start: startDate,
    end: endDate,
    className: classNames.join(' '),
    title: `<b> ${DisplayHelpers.humanize(organizationName)}</b>`
      + `<br/><br/><b>Start Date: </b>
      <span>${startDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(event.call_start)}</span>`
      + `<br/><br/><b>End Date: </b><span>${endDateDisplay}</span>`
      + (showDuration ? `<br/><br/><b>Duration: </b><span>${durationDisplayText}</span>` : '')
    ,
  };
};

export const mapReferralEvent = (event: MasterReferralResponse) => {
  const sortedReferrals = ReferralHelpers.getSortedReferrals(event.referrals);
  const declinedReferrals = ReferralHelpers.getDeclinedReferrals(event.referrals);
  const closedReferrals = ReferralHelpers.getClosedReferrals(event.referrals);
  const acceptedReferral = ReferralHelpers.getAcceptedReferral(event.referrals);
  const inactiveReferrals = ReferralHelpers.getInactiveReferrals(sortedReferrals, acceptedReferral);
  const activeReferrals = ReferralHelpers.getActiveReferrals(sortedReferrals, acceptedReferral);

  const referralStatus = ReferralHelpers
    .getOverallEncounterStatus(sortedReferrals, declinedReferrals, closedReferrals, acceptedReferral);

  let isOpen = ReferralHelpers.isEncounterActive(referralStatus);
  let startDate = new Date(sortedReferrals.at(-1)!.created_at);
  let endDate = isOpen ? new Date() : startDate;
  let sendToService = getSendToService();

  function getSendToService(){
    if(acceptedReferral){
      return acceptedReferral.sent_to_service.organization.name;
    }

    if(activeReferrals.length > 0){
      return activeReferrals
        .filter(referral => Helpers.isDefined(referral))
        .map(referral => referral.sent_to_service.organization.name)
        .join(", ");
    }

    return inactiveReferrals
      .filter(referral => Helpers.isDefined(referral))
      .map(referral => referral.sent_to_service.organization.name)
      .join(", ");
  }

  let classNames = ['referral'];

  if (!isOpen) {
    classNames.push('inactive');
  }

  return {
    // make sure this ID matches the dom element id in the vertical timeline
    id: `referral-${event.master_referral_id}`,
    content: "",
    start: startDate,
    end: endDate,
    className: classNames.join(' '),
    title: `<b>${referralStatus.displayName}</b>`
      +`<br/><br/><b>From: </b><span>${sortedReferrals.at(0)!.sent_from_service.organization.name}</span>`
      +`<br/><br/><b>To: </b><span>${sendToService}</span>`
      +`<br/><br/><b>Start Date: </b>`
      +`<span>${startDate.toLocaleDateString()} at ${DisplayHelpers.formatTime(startDate.toString())}</span>`
    ,
  };
};

