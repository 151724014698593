import React, {useContext} from "react";
import SlideInPane from "patient-ping-remedy/packages/slide-in-pane";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {Roster} from "../../../../api/dto/dto";
import TabsComponent from "../../../common/TabsComponent";
import {useQuery} from "@tanstack/react-query";
import GroupPermissionTable from "./GroupPermissionTable";
import GroupDetailsHeader from "./GroupDetailsHeader";
import RolePermissionMappingContainer from "./RolePermissionMappingContainer";
import Spacer from "patient-ping-remedy/packages/spacer";
import {StyledGroupData} from "./GroupDetailsDrawer.styles";

type Props = {
  roster: Roster;
  isOpen: boolean;
  onClose: Function;
}

export const dataRoleInfo =
  (<StyledGroupData>A user’s <b>data role</b> determines which data the user has legal
    permission to view and create within the platform. This table outlines
    the specific data access provided by each data role. </StyledGroupData>);

export const featureRoleInfo =
  (<StyledGroupData>A user’s <b>feature role</b> determines which functionalities
    the user can view and/or interact with in the platform.
    This table outlines the specific feature access provided
    by each feature role. </StyledGroupData>);

export const dataTypeInfo =
  (<StyledGroupData>This list outlines the <b>data types</b> this group can access,
    according to its contractual relationship with Bamboo Health.
    The data role assigned to each user will determine
    the specific subset of these data types the user is legally permitted
    to view and create within the platform.</StyledGroupData>);

export const featureTypeInfo =
  (<StyledGroupData>This list outlines the <b>feature types</b> this group can access,
    according to its contractual relationship with Bamboo Health.
    The feature role assigned to each user will determine
    the specific subset of these functionalities
    the user can view and/or interact with in the platform.</StyledGroupData>);

const GroupDetailsDrawer = (props : Props) => {
  const {carecoApi} = useContext(CarecoApiContext);

  const {data : rosterPermission} = useQuery({
    queryKey: ["rosterPermission", {id: props.roster.id}],
    queryFn: () => carecoApi?.getRosterDetails(props.roster.id.toString()),
    enabled: !!carecoApi
  });

  return (
    <SlideInPane
      onClose={props.onClose}
      isOpen={props.isOpen}
      isOverlayOpen={props.isOpen}
      showNav={false}
      headerText={"Group Details"}
      width={"600px"}
    >
      <GroupDetailsHeader roster={props.roster} />

      <Spacer itemHeight={'10px'} />

      <TabsComponent tabs={[
        {
          value: 0,
          label: "Contract",
          content: <GroupPermissionTable rosterPermissions={rosterPermission}/>
        },
        {
          value: 1,
          label: "Access",
          content: <RolePermissionMappingContainer/>
        },
      ]}/>
    </SlideInPane>
  );
};

export default GroupDetailsDrawer;
