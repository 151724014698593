import React from "react";
import CarecoApi from "../api/careco-api";
import EncounterApi from "../api/encounter-api";

export interface AppState {
  carecoApi?: CarecoApi | null
  encounterApi?: EncounterApi | null
  updateState: (newState: Partial<AppState>) => void
}

const defaultState: AppState = {
  carecoApi: null,
  encounterApi: null,
  updateState: (newState?: Partial<AppState>) => {},
};

export const CarecoApiContext = React.createContext<AppState>(defaultState);
