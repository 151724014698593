import React from 'react';
import Table from "../../../common/Table";
import {DataPermission, DataRole} from "../../../../api/dto/dto";
import DisplayHelpers from "../../../../helpers/display_helpers";
import {NoAccess} from "../../../common/user_details/AccessDetails.styles";
import {dataRoleInfo} from "./GroupDetailsDrawer";
import RosterHelpers, {dataSourceValues} from "../../../../helpers/roster_helpers";
import InfoIconTooltip from "../../../common/InfoIconTooltip";

type Props = {
  dataRoles: DataRole[],
}

function getDataPermission(data_role_id: number, data_source: string, data_permissions: DataPermission[]): JSX.Element {
  const permission = data_permissions.find((dataPermission: DataPermission) => {
    return dataPermission.data_role_id === data_role_id && dataPermission.data_source === data_source;
  });
  return getDisplayPermission(permission);
}

function getDisplayPermission(permission: DataPermission | undefined) {
  if(permission?.level){
    let permissionLevel = DisplayHelpers.humanize(permission.level);
    return <span>{ permissionLevel === 'Sso' ? 'SSO' : permissionLevel }</span>;
  } else {
    return <NoAccess>No</NoAccess>;
  }
}

const DataRolePermissionMappingTable = (props: Props) => {
  const dataPermissions = RosterHelpers.getAllDataPermissions(props.dataRoles);

  const dataRoleHeading = (
    <>
      <span>Data Role </span>
      <InfoIconTooltip
        html={dataRoleInfo}
        data-testid="data-role-tooltip"
        position={"right"}
      />
    </>
  );

  const dataRoleHeadings = [dataRoleHeading, ...props.dataRoles.map(dataRole => dataRole.name)];

  const generatePermissions = (dataSourceIndex: number) => {
    return [
      dataSourceValues[dataSourceIndex].label,
      ...props.dataRoles.map(
        dataRole => getDataPermission(Number(dataRole.id) ,dataSourceValues[dataSourceIndex].value, dataPermissions)
      )
    ];
  };

  return (
    <>
      <Table
        data={ dataSourceValues.map((_, index) => generatePermissions(index)) }
        header={dataRoleHeadings}
      />
    </>
  );
};

export default DataRolePermissionMappingTable;
