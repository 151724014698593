import React, {useContext, useState} from "react";
import Switch from "patient-ping-remedy/packages/switch";
import {useMutation} from "@tanstack/react-query";
import {useAuthStore} from "../../../store/auth_store";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {queryClient} from "../../../App";
import {AxiosError} from "axios";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {useRosterStore} from "../../../store/roster_store";
import {useAlertStore} from "../../../store/alert_store";
import {User} from "../../../api/dto/dto";
import {GENERICERROR} from "../clients/manual_encounter/constants/BannerMessages";
import Helpers from "../../../helpers/helpers";

type UpdateOnlyShowUnreadNotifications = {
  userId: string;
  onlyShowUnread: boolean;
}

type Props = {
  currentFetchedUser: User
}

function NotificationOnlyShowUnreadSwitch(props: Props) {
  const [ disableShowUnreadSwitch, setDisableShowUnreadSwitch ] = useState<boolean>(false);
  const { addAlert } = useAlertStore();
  const { carecoApi } = useContext(CarecoApiContext);
  const { setCurrentUser } = useAuthStore();
  const { currentRoster } = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;
  const {currentFetchedUser} = props;

  const updateUserOnlyShowUnreadNotificationsMutation = useMutation({
    mutationFn: async (updateUserVariables: UpdateOnlyShowUnreadNotifications) => {
      return carecoApi?.putUserOnlyShowUnreadNotifications(
        updateUserVariables.userId, updateUserVariables.onlyShowUnread);
    },
    onSuccess: (response) => {
      let newCurrentUser = response?.data;
      setDisableShowUnreadSwitch(false);
      setCurrentUser(newCurrentUser);
      queryClient.invalidateQueries({queryKey: ['currentUser']}).then(r => console.log(r));
      queryClient.invalidateQueries(
        {queryKey: ['notifications', currentRosterId, newCurrentUser?.only_show_unread_notifications]})
        .then(r => console.log(r));
    },
    onError: (error:AxiosError) => {
      setDisableShowUnreadSwitch(false);
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({content: `Failed to update show unread notification setting. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  async function updateUnreadOnly() {
    if(currentFetchedUser) {
      setDisableShowUnreadSwitch(true);
      updateUserOnlyShowUnreadNotificationsMutation.mutate({
        userId: currentFetchedUser.id,
        onlyShowUnread: currentFetchedUser.only_show_unread_notifications
      });
    }
  }

  return (
    <>
      { currentFetchedUser && (
        <Switch
          label={'Only show unread'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{
            e.preventDefault();
            currentFetchedUser.only_show_unread_notifications = !currentFetchedUser.only_show_unread_notifications;
            updateUnreadOnly();
          }}
          checked={currentFetchedUser.only_show_unread_notifications}
          disabled={disableShowUnreadSwitch}
        />
      )}
    </>
  );
}

export default NotificationOnlyShowUnreadSwitch;
