import {PropsWithChildren} from 'react';
import {colors} from 'patient-ping-remedy/packages/theme';
import {MenuItemStandardSemiBoldTypography, MenuItemStandardTypography} from './MenuItemTypography.styles';

type Props = PropsWithChildren & {
  active?: boolean;
}

// chopsticks: https://zeroheight.com/5bcb53d99/p/177dd9-menu-item
export default function MenuItemTypography(props: Props) {
  const Typography = props.active ? MenuItemStandardSemiBoldTypography : MenuItemStandardTypography;

  return (
    <Typography color={colors.primary1}>{props.children}</Typography>
  );
}
