import styled from '@emotion/styled';
import Modal from "patient-ping-remedy/packages/modal";
import Spacer from "patient-ping-remedy/packages/spacer";
import {colors} from "patient-ping-remedy/packages/theme";

export const FormUploadModalStyle = styled(Modal)`
  width: 35%;
  font-size: 16px;
  border-radius: 8px;
`;

export const Form = styled('form')`
  width: 100%;
`;

export const ScoreDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

export const StyledSpacer = styled(Spacer)`
  height: 30px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray3};
`;

export const StyledHintTypography = styled.div`
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.01rem;
  color: ${colors.gray4};
`;
