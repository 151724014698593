import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const StyledClientDemographicHeader = styled('div')`
    position: -webkit-sticky; /* for browser compatibility */
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

  & > .client-demographic-header {
    background-color: ${colors.purple1};
    padding: 8px 0 8px 0;
    margin-bottom: 0;
  }
`;

export {StyledClientDemographicHeader};
