import React from 'react';
import pluralize from 'pluralize';

import SafePureComponent from "./SafePureComponent";
import {ADMITTED, MEDICAL_LEAVE_OF_ABSENCE, PRESENTED, RESUMED_SERVICES, TRANSFERRED} from "../../../constants/PingStatuses";

class PingVisitDurationText extends SafePureComponent {
  safeRender() {
    return (
      <span className="ping-visit-duration-text">
        {this._getPreDurationText()}
        {this._getVisitDuration()}
        {this._getPostDurationText()}
      </span>
    );
  }

  _getVisitDuration() {
    const duration = this.props.pingEncounter?.visitDuration ? this.props.pingEncounter?.visitDuration : 0;
    const durationText = duration > 0 ? pluralize('day', duration, true) : 'less than 1 day';
    if (this.props.current) {
      return (
        <span className="duration" data-testid="ping-visit-duration">
          {durationText}
        </span>
      );
    }
    return (
      <span className="duration" data-testid="ping-visit-duration">
        ({durationText})
      </span>
    );
  }

  _getPostDurationText() {
    if (this.props.current) {
      if (
        [PRESENTED, ADMITTED, TRANSFERRED, MEDICAL_LEAVE_OF_ABSENCE, RESUMED_SERVICES].indexOf(
          this.props.ping?.status,
        ) === -1
      ) {
        return <span> of stay</span>;
      }
    }
    return null;
  }

  _getPreDurationText() {
    if (this.props.current) {
      if (
        [PRESENTED, ADMITTED, TRANSFERRED, MEDICAL_LEAVE_OF_ABSENCE, RESUMED_SERVICES].indexOf(this.props.ping?.status) >
        -1
      ) {
        return <span>and has been there for </span>;
      }
      return <span>after </span>;
    }
    return null;
  }
}

PingVisitDurationText.defaultProps = {
  current: false,
};

export default PingVisitDurationText;
