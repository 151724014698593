import React from 'react';
import {ICONS} from 'patient-ping-remedy/packages/icon';
import {Roster} from '../../../../../../api/dto/dto';
import {SDOHProgram, SDOHSender} from '../../../../../../api/dto/sdoh';

type SDOHStatusType = {
  displayName: string;
  iconClass: ICONS;
  displayMessage: (program: SDOHProgram, sender: SDOHSender, roster: Roster) => React.JSX.Element;
}

type SDOHStatusMap = {
  [key: string]: SDOHStatusType;
}

export const SDOHStatuses = {
  NEW_REFERRAL: 'NEW_REFERRAL',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const SDOHStatus: SDOHStatusMap = {
  [SDOHStatuses.NEW_REFERRAL]: {
    displayName: 'New referral',
    iconClass: ICONS['user-tag'],
    displayMessage: (_, sender, roster) => <>
      <span className="event-text-bold">Referred </span>
      by {sender.firstName} {sender.lastName} at {roster.name}
      <span className="pipe-separator">New referral</span>
    </>,
  },
  [SDOHStatuses.PENDING]: {
    displayName: 'Referral pending',
    iconClass: ICONS['user-plus'],
    displayMessage: (program) => <>
      <span className="event-text-bold">Pending </span>
      at {program.name}
      <span className="pipe-separator">Referral pending</span>
    </>,
  },
  [SDOHStatuses.ACCEPTED]: {
    displayName: 'Referral accepted',
    iconClass: ICONS['handshake'],
    displayMessage: (program) => <>
      <span className="event-text-bold">Accepted </span>
      by {program.name}
      <span className="pipe-separator">Referral accepted</span>
    </>,
  },
  [SDOHStatuses.DECLINED]: {
    displayName: 'Referral declined',
    iconClass: ICONS['handshake-slash'],
    displayMessage: (program) => <>
      <span className="event-text-bold">Declined </span>
      by {program.name}
      <span className="pipe-separator">Referral declined</span>
    </>,
  },
};
