import styled from '@emotion/styled';
import {colors} from 'patient-ping-remedy/packages/theme';

export const StyledMenuItem = styled.li`
`;

type ButtonProps = {
  disabled?: boolean;
}

export const Button = styled.button<ButtonProps>(
  {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    background: 'none',
    border: 'none',
    width: '100%',
    gap: '1rem',
    padding: '0.5rem 2rem',
    minHeight: '4rem'
  },
  (props) => ({
    ...(props.disabled ? {
      cursor: 'not-allowed',
      background: colors.gray1,
      '& > *': {
        color: `${colors.gray4} !important`,
      },
    } : {
      cursor: 'pointer',
      '&:hover': {
        background: colors.purple1,
        'input[type="checkbox"] + div': {
          borderColor: colors.green5,
        },
      },
    }),
  }),
);

export const MenuItemSpacer = styled.div`
  flex-grow: 1;
`;
