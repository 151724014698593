import {sizes} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const StyledOptionContainer = styled('div')`
  display: flex;
  margin-bottom: ${sizes.small};
  margin-top: ${sizes.medium};  
`;

export {StyledOptionContainer};
