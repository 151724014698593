import {useAuth0} from "@auth0/auth0-react";
import Helpers from "../../../helpers/helpers";
import Switch from "patient-ping-remedy/packages/switch";
import DisplayHelpers from "../../../helpers/display_helpers";
import Input from "patient-ping-remedy/packages/input";
import {css} from "@emotion/css";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import {User} from "../../../api/dto/dto";
import {useAuthStore} from "../../../store/auth_store";
import { Link } from "react-router-dom";
import DropdownList from "../../pings/dropdown_list/DropdownList";
import {SSOHeader} from "../../pages/profile/ProfilePage.styles";
import {colors} from "patient-ping-remedy/packages/theme";
import {state_codes} from "../../../helpers/state_codes";
import {StyledInputPdmp, StyledPdmpRow, StyledLabel, PdmpDiv} from "./UserDetailsForm.styles";
import {pdmp_role} from "../../../helpers/PDMP_role";
import React from "react";
import InfoIconTooltip from "../InfoIconTooltip";

type GenericObject = { [key: string]: any };

type Props = {
  user: User;
  form: GenericObject;
  profilepage?:Boolean;
  enableInputsForSelf: Boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | null, name: string, value: any) => void;
};

const UserDetailsForm = (props: Props) => {
  const { user } = useAuth0();
  const { currentUser } = useAuthStore();
  const disabled = props.enableInputsForSelf ? PermissionsHelpers.isBamboo(props.user) :
    currentUser?.id === props.user?.id ||
    PermissionsHelpers.hasAdminRole(props.user) ||
    !PermissionsHelpers.canForUserFeatureRole(
      currentUser!,
      Helpers.getAppFeatureRoleDetails().levels.MANAGE,
      Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS
    );
  const required = !PermissionsHelpers.hasAdminRole(props.user) ? 'required' : '';
  return (
    <>
      {!Helpers.isEmptyObject(props.form) &&
      (
        <>
          {
            !props.enableInputsForSelf && user?.sub === props.user?.id &&
            <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
              <Link to={'/profile'}>Update your details in your User Profile here.</Link>
            </Banner>
          }

          <Input
            id={`user-details-first-name-${props.user?.id}`}
            labelText={'First Name'}
            required={required}
            disabled={disabled}
            value={props.form.first_name.value || ''}
            onChange={(e) => props.handleChange(e, 'first_name', e.target.value)}
            error={!props.form.first_name.valid.valid && !disabled}
            errorMessage={props.form.first_name.valid.message}
          />

          <Input
            id={`user-details-last-name-${props.user?.id}`}
            labelText={'Last Name'}
            required={required}
            disabled={disabled}
            value={props.form.last_name.value || ''}
            onChange={(e) => props.handleChange(e, 'last_name', e.target.value)}
            error={!props.form.last_name.valid.valid && !disabled}
            errorMessage={props.form.last_name.valid.message}
          />

          <Input
            id={`user-details-emails-${props.user?.id}`}
            labelText={'Email'}
            required={required}
            disabled={disabled}
            value={props.form.email.value || ''}
            onChange={(e) => props.handleChange(e, 'email', e.target.value)}
            error={!props.form.email.valid.valid && !disabled}
            errorMessage={props.form.email.valid.message}
          />
          <Input
            id={`user-details-phone-${props.user?.id}`}
            labelText={'Phone'}
            value={DisplayHelpers.formatPhoneNumber(props.form.phone.value)}
            required={required}
            disabled={disabled}
            placeholder="(___) ___-____"
            onChange={(e) => props.handleChange(e, 'phone', e.target.value)}
            error={!props.form.phone.valid.valid && !disabled}
            errorMessage={props.form.phone.valid.message}
          />
          {PermissionsHelpers.canForUserDataRole(props.user,
            Helpers.getAppDataRoleDetails().levels.READ,
            Helpers.getAppDataRoleDetails().permissions.PDMP) &&
            !props.profilepage && !PermissionsHelpers.isBamboo(props.user) &&
              (currentUser?.id !== props.user?.id) &&
              (
                <>
                  <SSOHeader>
                    PDMP Connection
                    <InfoIconTooltip
                      text={'Required for users with PDMP access.'}
                      style={{marginTop: '0.7rem', marginLeft: '0.2rem' }}
                    />
                  </SSOHeader>
                  <PdmpDiv>
                    <DropdownList
                      data-testid={`user-details-pdmp_role-${props.user?.id}`}
                      items={pdmp_role}
                      className={css({width: '100%'})}
                      disabled={PermissionsHelpers.isSuperAdmin(props.user)}
                      isClearable={true}
                      value={{label:pdmp_role.find(item=>item.value===props.form?.pdmp_role.value)?.label||
                        props.form?.pdmp_role.value,
                      value:props.form?.pdmp_role.value}}
                      error={!props.form?.pdmp_role.valid.valid && !disabled}
                      errorMessage={''}
                      handleChange={(e) =>  props.handleChange(null, 'pdmp_role', e?.value)}
                      id={`user-details-pdmp_role-${props.user?.id}`}
                      label={'PDMP role'}
                    />
                  </PdmpDiv>
                </>
              )
          }
          {PermissionsHelpers.canForUserDataRole(props.user,
            Helpers.getAppDataRoleDetails().levels.READ,
            Helpers.getAppDataRoleDetails().permissions.PDMP) &&
              props.profilepage && !PermissionsHelpers.isBamboo(props.user) &&
            (
              <>
                <SSOHeader>
                  PDMP Connection
                  <InfoIconTooltip
                    text={'PDMP role and DEA, NPI, or State license number is required.'}
                    style={{marginTop: '0.7rem', marginLeft: '0.2rem'}}
                  />
                </SSOHeader>

                <PdmpDiv>
                  <StyledLabel>PDMP role</StyledLabel>
                  <StyledPdmpRow className={css({width:'50%'})}>
                    <StyledInputPdmp
                      id={`user-details-pdmp_role_profile-${props.user?.id}`}
                      className={css({width: '100%'})}
                      disabled={true}
                      value={props.form.pdmp_role.value || ''}
                      error={!props.form.pdmp_role.valid.valid && !disabled}
                      errorMessage={props.form.pdmp_role.valid.message}
                    />

                    <InfoIconTooltip
                      text={'Please contact your admin to update your PDMP role.'}
                      size={'2x'}
                      hoverColor={colors.gray4}
                      style={{marginTop: "1rem"}}
                    />

                  </StyledPdmpRow>
                  <Input
                    id={`user-details-dea_number-${props.user?.id}`}
                    className={css({width: '50%' ,marginLeft:'20px'})}
                    labelText={'DEA number'}
                    value={props.form.dea_number.value || ''}
                    onChange={(e) => props.handleChange(e, 'dea_number', e.target.value)}
                    error={!props.form.dea_number.valid.valid && !disabled}
                    errorMessage={props.form.dea_number.valid.message}
                  />
                </PdmpDiv>
                <PdmpDiv>
                  <Input
                    id={`user-details-npi_number-${props.user?.id}`}
                    className={css({width: '50%', marginRight: '20px'})}
                    labelText={'NPI number'}
                    value={props.form.npi_number.value || ''}
                    onChange={(e) => props.handleChange(e, 'npi_number', e.target.value)}
                    error={!props.form.npi_number.valid.valid && !disabled}
                    errorMessage={props.form.npi_number.valid.message}
                  />
                  <Input
                    id={`user-details-sln_value-${props.user?.id}`}
                    className={css({width: '50%'})}
                    labelText={'State license number'}
                    value={props.form?.sln?.value?.value || ''}
                    onChange={(e) => props.handleChange(e, 'sln', {
                      value: e.target.value,
                      type: props.form?.sln?.value?.type,
                      jurisdiction: props.form?.sln?.value?.jurisdiction
                    })}
                    error={!props.form?.sln?.valid?.valid  && !disabled && props.form?.sln?.value?.value===''}
                    errorMessage={'Please provide state license number.'}
                  />
                </PdmpDiv>
                <PdmpDiv>
                  <Input
                    id={`user-details-sln_type-${props.user?.id}`}
                    className={css({width: '50%' , marginRight: '20px'})}
                    labelText={'State license type'}
                    value={props.form?.sln?.value?.type || ''}
                    onChange={(e) => props.handleChange(e, 'sln', {
                      value: props.form?.sln?.value?.value,
                      type: e.target.value,
                      jurisdiction: props.form?.sln?.value?.jurisdiction
                    })}
                    error={!props.form?.sln?.valid?.valid && !disabled && props.form?.sln?.value?.type===''}
                    errorMessage={'Required for state license number.'}
                  />
                  <DropdownList
                    data-testid={`user-details-sln_jurisdiction-${props.user?.id}`}
                    className={css({width: '50%'})}
                    items={state_codes}
                    isClearable={true}
                    value={{label :props.form?.sln?.value?.jurisdiction, value:props.form?.sln?.value?.jurisdiction}}
                    error={!props.form?.sln?.valid?.valid && !disabled &&
                        (props.form?.sln?.value?.jurisdiction===undefined||props.form?.sln?.value?.jurisdiction==='')}
                    errorMessage={'Required for state license number.'}
                    handleChange={(e) =>  props.handleChange(null, 'sln', {
                      value: props.form?.sln?.value?.value,
                      type: props.form?.sln?.value?.type,
                      jurisdiction: e?.value
                    })}
                    id={`user-details-sln_jurisdiction-${props.user?.id}`}
                    label={'State license jurisdiction'}
                  />
                </PdmpDiv>

              </>
            )
          }

          {
            !props.enableInputsForSelf &&
            <Switch
              className={css({marginTop: '20px'})}
              id={`user-details-enabled-${props.user?.id}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e, 'enabled', e.target.checked)}
              label={'Enabled'}
              disabled={disabled}
              checked={props.form.enabled.value}/>
          }
        </>
      )
      }
    </>

  );
};

export default UserDetailsForm;
