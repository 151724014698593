import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import {EncounterType} from "./client-profile";

export enum GroupType {
  SNF = 'SNF',
  HHA = 'HHA',
  LTAC = 'LTAC',
  REHAB_HOS = 'REHAB_HOS',
  HOSPICE = 'HOSPICE',
  ALF = 'ALF',
  HL7_BASED_PROGRAM = 'HL7_BASED_PROGRAM',
  CCRC = 'CCRC',
  ADH = 'ADH',
  URGENT_CARE = 'URGENT_CARE',
  PALLIATIVE = 'PALLIATIVE',
  CRISIS = 'CRISIS',
  HOS = 'HOS',
  OWNING_ORG = 'OWNING_ORG',
  PRIVATE_PROGRAM = 'PRIVATE_PROGRAM',
}

export type AppSettings = {
  attestationExpirationSeconds: number;
  clientProfileArtificialDelay: number;
  prefetchClientProfiles: boolean;
  logClientProfileData: boolean;
  userExpirationSeconds: number;
  passwordExpirationSeconds: number;
}

export type Attestation = {
  document_version: string;
  text: string;
}

export type LoginResponse = {
  attestation: Attestation;
  phone_verified: boolean;
}

export type BackendVersions = {
  carecoWebapp: BackendVersion;
  carecoEvents: BackendVersion;
}
export type BackendVersion = {
  GIT_BRANCH: string;
  GIT_COMMIT: string;
}

export interface AuthoritiesResponse {
  role: string,
  privileges: string[]
}

export interface RosterResponse {
  data: Rosters,
  dataRoleOptions: Value[],
  featureRoleOptions: Value[],
  rosterOptions: Value[],
  organizationOptions: Value[]
}

export interface Rosters {
  data: Array<Roster>,
  count: number
  data_roles: Array<DataRole>,
  feature_roles: Array<FeatureRole>,
}

export interface Roster {
  id: number,
  name: string,
  npi: string,
  type: string,
  typeDisplayName: string,
  active: boolean,
  authorized: boolean,
  admittingFacility: boolean,
  automated: boolean,
  address: Address,
  organization: {
    id: string,
    name: string
  }
  uuid?: string,
  isPart2Facility?: boolean,
} 

export interface Address {
  address1: string,
  address2?: string,
  city: string,
  state: string,
  zip: string,
  county: string,
  timeZone: string
}

export interface Clients {
  page: number,
  pageSize: number,
  searchTotal: number,
  rosterTotal: number,
  clients: Array<Client>
}

export interface Client {
  patientId: string,
  firstName: string,
  middleName: string,
  lastName: string,
  dateOfBirth: string,
  gender?: string,
  userAssignments: ClientUserAssignment[],
  lastEventDate: string,
  lastEventType: EncounterType,
}

export interface ClientUserAssignment {
  user: User,
}

export interface Organization {
  createdAt: string,
  id: string,
  name: string,
  active: boolean,
  groups: OrganizationGroups[]
}

export interface DataPermission {
  id: number,
  data_role_id: number,
  data_source: string,
  level: string,
}

export interface DataRole {
  id: string,
  name: string,
  permissions: DataPermission[]
}

export interface FeaturePermission {
  id: number,
  feature_role_id: number,
  feature: string,
  level: string,
}

export interface FeatureRole {
  id: string,
  name: string,
  permissions: FeaturePermission[]
}

export interface Role {
  id: string,
  name: string,
}

export interface UserRoster {
  roster: Roster, // not the full roster
  data_role: DataRole,
  feature_role: FeatureRole
}

export interface User {
  id: string,
  username: string,
  rosters: UserRoster[],
  organizations: string[],
  role: string,
  email: string,
  phone: string,
  image: string;
  first_name: string,
  last_name: string,
  created_at: string,
  updated_at: string,
  last_login: string,
  status: string,
  enabled: boolean,
  dea_number:string,
  npi_number:string,
  sln_value:string,
  sln_type:string,
  sln_jurisdiction:string,
  pdmp_role:string
  only_show_unread_notifications: boolean,
  only_assigned_client_notifications: boolean,
}

export interface RosterSelection {
  roster_id: number,
  data_role_id: number | null, // these are null for SUPER_ADMIN
  feature_role_id: number | null // these are null for SUPER_ADMIN
}

export interface UserRequest {
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
  rosters: RosterSelection[],
  enabled: boolean,
  pdmp_role?:string
}

export interface UserProfileRequest {
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
  dea_number:string,
  npi_number:string,
  sln_value:string,
  sln_type:string,
  sln_jurisdiction:string,
  pdmp_role:string
  only_assigned_client_notifications: boolean,
}

export interface OrganizationGroups {
  active: boolean,
  groupName: string,
}

export interface RosterPermissions {
  adtEnabled: boolean,
  obReferralsEnabled: boolean,
  obCrisisEnabled: boolean,
  findHelpEnabled: boolean,
  pdmpEnabled: boolean,
  jiEnabled: boolean,
  roundtripEnabled: boolean,
  clientListEnabled: boolean,
  clientProfileEnabled: boolean,
  formsEnabled: boolean,
  userPermissionsEnabled: boolean,
  userReportingEnabled: boolean,
  stateReportingEnabled: boolean,
  logEnabled: boolean,
  notificationsEnabled: boolean,
}

export interface ClientGroup {
  firstName: string,
  lastName: string,
  admittingFacilityId?: number,
}

export interface WalkMeData {
  createdAt: number,
  createdAtUnix: number,
  email: string,
  firstLogin: number,
  firstLoginUnix: number,
  firstName: string,
  lastName: string,
  name: string,
  groupIds: string,
  groupNames: string,
  groupStates: string,
  groupTypes: string,
  dataRoles: string,
  featureRoles: string,
  isAdmittingFacility: string,
  isAutomated: string,
  lastLogin: number,
  lastLoginUnix: number,
  orgId: string,
  orgName: string,
  websessions: number,
  orgCreated?: number,
  type?: string,
}
