import React, {ChangeEvent, useState} from 'react';
import Input from "patient-ping-remedy/packages/input";

type Props = {
  id?: string;
  required?: "" | "required" | "optional" | undefined;
  className?: string;
  labelText?: string;
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  validate: (val: string) => string;
  placeholder?: string;
}

const ValidatedInput = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(e);
    setErrorMessage(props.validate(e.target.value));
  }

  return (
    <Input
      id={props.id}
      data-testid={props.id}
      className={props.className}
      labelText={props.labelText}
      value={props.value}
      required={props.required}
      onChange={onChange}
      error={!!errorMessage}
      errorMessage={errorMessage}
      placeholder={props.placeholder}
    />
  );
};

export default ValidatedInput;
