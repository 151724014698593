import { useEffect, useState } from 'react';

/**
 * Custom react hook for debouncing a value change
 *
 * @param value
 * @param delay - time in milliseconds to wait
 */
export function useDebounce<T>(value: T, delay = 300): T {
  const [debounceValue, setDebounceValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => clearTimeout(handler); // clear timer on unmount
  }, [value, delay]);
  return debounceValue;
}
