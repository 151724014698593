import React, {useContext} from 'react';
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import DataRolePermissionMappingTable from "./DataRolePermissionMappingTable";
import FeatureRolePermissionMappingTable from "./FeatureRolePermissionMappingTable";
import Spacer from "patient-ping-remedy/packages/spacer";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {css} from "@emotion/css";
import DisplayHelpers from "../../../../helpers/display_helpers";

const RolePermissionMappingContainer = () => {
  const { carecoApi } = useContext(CarecoApiContext);

  const { data: rosters} = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  return (
    <>
      <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
        {DisplayHelpers.contactAccountManagerMessage()}
      </Banner>
      {rosters?.data?.data_roles &&
        <DataRolePermissionMappingTable
          dataRoles={rosters.data.data_roles}
        />
      }

      <Spacer itemHeight={'30px'} />

      {rosters?.data?.feature_roles &&
        <FeatureRolePermissionMappingTable
          featureRoles={rosters.data.feature_roles}
        />
      }
    </>

  );
};

export default RolePermissionMappingContainer;
