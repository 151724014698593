import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { injectGlobal } from '@emotion/css';

import {colors, fontFamily} from "patient-ping-remedy/packages/theme";
import App, {queryClient} from "./App";
import Auth0ProviderWithRedirect from "./Auth0ProviderWithRedirect";
import {CarecoApiContextProvider} from "./app-context/careco-api-context-provider";
import {QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

injectGlobal`
  body {
    margin: 0;
    background: ${colors.ash};
    font-family: ${fontFamily.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%; // 62.5% of 16px = 10px
  }

  html, body, #root {
    height: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  .tippy-popper {
    max-width: unset;
  }
`;

root.render(
  <Auth0ProviderWithRedirect
    cacheLocation="localstorage"
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/login`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user profile role:admin role:user",
    }}
  >
    <QueryClientProvider client={queryClient}>
      <CarecoApiContextProvider>
        <App />
      </CarecoApiContextProvider>
    </QueryClientProvider>
  </Auth0ProviderWithRedirect>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
