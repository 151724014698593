import React, {useCallback, useState} from 'react';
import {ValidationResponse} from "../helpers/validate";

type GenericObject = { [key: string]: any }

const createFormFieldConfig = (
  key: string,
  value: any,
  valid: ValidationResponse,
  validateFun: Function[] | null,
  required: boolean) => {
  return {
    [key]: {
      value: value,
      valid: valid,
      validateFun: validateFun,
      required: required
    }
  };
};

const useForm = (callback: Function) => {
  const [initialFormValues, setInitialFormValues] = useState<GenericObject>({});
  const [form , setForm] = useState<GenericObject>({});
  const [submitted, setSubmitted] = useState(false);

  const isValid = useCallback(() => {
    return Object.values(form).every((value: any) => value.valid.valid);
  }, [form]);

  const isFormChanged = useCallback(() => {
    for (const [key, value] of Object.entries(form)) {
      if(value.value !== initialFormValues[key].value) {
        return true;
      }
    }

    return false;
  }, [form, initialFormValues]);

  const setInitialForm = useCallback((values: GenericObject) => {
    let initialFormValues: GenericObject = {};
    for (const [key, value] of Object.entries(values)) {
      initialFormValues[key] = { value: value.value };
    }

    setForm(values);
    setInitialFormValues(initialFormValues);
    setSubmitted(false);
  }, []);

  const isInputFieldValid = useCallback((validateFun: Function[], value: any, name: string, required: boolean) => {
    if(validateFun.length > 0) {
      for (const rule of validateFun) {
        let valid = rule(value, name, required);
        if (!valid.valid) {
          return { valid: false, message: valid.message };
        }
      }
    }

    return { valid: true, message: '' };
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent | null, name: string, value: any) => {
    event?.persist(); // dropdowns dont send events

    let formValue = form[name];

    formValue.value = value;

    if(formValue.validateFun) {
      formValue.valid = isInputFieldValid(formValue.validateFun, value, name, formValue.required);
    } else {
      formValue.valid = {valid: true, message: ''};
    }

    setForm({ ...form, [name]: formValue });
  }, [form, isInputFieldValid]);

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    callback();
  }, [callback]);

  const resetForm = () => {
    setForm({});
    setInitialFormValues({});
    setSubmitted(false);
  };

  return { resetForm, isValid, submitted, setSubmitted, isFormChanged, form, setInitialForm, handleChange, handleSubmit };
};

export {createFormFieldConfig, useForm};
