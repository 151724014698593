import React from 'react';
import styled from '@emotion/styled';
import Banner, {BannerTypes} from 'patient-ping-remedy/packages/banner';
import {sizes} from 'patient-ping-remedy/packages/theme';
import SimpleTable from 'patient-ping-remedy/packages/simple-table';
import Spacer from 'patient-ping-remedy/packages/spacer';
import {getLatestEventDate} from './encounterDataUtils';
import pluralize from "pluralize";
import Helpers from "../../../../../../helpers/helpers";

const StyledBanner = styled(Banner)`
  margin: -${sizes.medium} -${sizes.large} ${sizes.small};
`;

const StyledContainer = styled('div')`
  max-width: 60rem;
`;

const StyledTypography = styled('div')`
  font-size: 1.5rem;
`;

const EncounterResolveConflict = ({ encountersToResolve }) => {
  const { total, encounters } = encountersToResolve;

  const data = encounters.map((encounter) => {
    let encounterFullDuration = Helpers.calculateTimeDifference(
      new Date(encounter.events[0].eventDate),
      new Date(encounter.events.at(-1).eventDate));

    return {
      admittingFacility: (
        <span>
          {encounter.admittingFacility.name}
        </span>
      ),
      date: getLatestEventDate(encounter).toLocaleDateString(),
      duration: encounterFullDuration ? pluralize('day', encounterFullDuration, true) : 'less than 1 day',
    };
  });

  const headers = ['Encounter', 'Encounter Start Date', 'Duration'];

  return (
    <StyledContainer>
      <StyledBanner type={BannerTypes.WARNING}>
        After edits are saved, a new match will occur, which may cause a change in the client's visit history from
        other facilities.
      </StyledBanner>
      <StyledTypography>
        Editing these <b>client record</b> details will update
        <b> {pluralize('encounter record', total, true)}</b> from your facility.
        <br /> Please ensure these details belong with these client records.
      </StyledTypography>
      <Spacer itemHeight={sizes.small} itemDisplay="block" />
      <SimpleTable data={data} header={headers} />
      {total - 8 > 0 && <StyledTypography>and {total - 8} more</StyledTypography>}
    </StyledContainer>
  );
};

export default EncounterResolveConflict;
