import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';
import {css} from "@emotion/react";

const Container = styled.div((props: { singleColumn: boolean}) => (
  css`
    display: grid;
    grid-template-areas:
    "left-column right-column";
    grid-template-rows: 1fr;
    grid-template-columns: ${props.singleColumn ? '3fr 1fr' : '5fr 1fr'};
    grid-row-gap: 0;
    grid-column-gap: 40px;
    width: 100%;

    @media (max-width: 1050px) {
        grid-template-areas:
        "right-column"
        "left-column";
        grid-template-rows: min-content 1fr;
        grid-template-columns: 1fr;
    }
`));

const LeftColumn = styled.div`
    grid-area: left-column;
`;

const LeftColumnContent = styled.div`
    border: 1px solid ${colors.gray2};
    border-radius: 8px;
    padding: 20px;
    background-color: ${colors.white};
`;

const RightColumn = styled.div`
    grid-area: right-column;
`;

const RightColumnContent = styled.div`
    padding: 0 0 20px 0;
`;


const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4.8rem 0 3rem 0;
`;

export { Container, LeftColumn, LeftColumnContent, RightColumn, RightColumnContent, HeaderWrapper };
