import {useState} from "react";
import AdmitClientModal from "./encounter_client_search/AdmitClientModal";
import {useRosterStore} from "../../../../store/roster_store";
import Helpers from "../../../../helpers/helpers";
import CreateEditClientModal from "./encounter_admit/pings/CreateEditClientModal";
import {EncounterClient} from "../../../../api/dto/encounter";
import ReconcileHookModal from "./encounter_reconcile/pings/ReconcileHookModal";

interface CreateAdmissionProps {
  hideModal: () => void,
}
export const ADMIT_PATIENT = 'EDIT_PATIENT';
export const SEARCH_PATIENT = 'SEARCH_PATIENT';
export const RECONCILE_PATIENT = 'RECONCILE_PATIENT';

const CreateAdmission = (props: CreateAdmissionProps) => {
  const {currentRoster} = useRosterStore();
  const [selectedPatient, setSelectedPatient] = useState<EncounterClient | undefined>(undefined);
  const [step, setStep] = useState(SEARCH_PATIENT);

  function handleHideChooseModal() {
    props.hideModal();
    setSelectedPatient(undefined);
    setStep(SEARCH_PATIENT);
  }

  function handleSelectPatient(patient: EncounterClient | undefined) {
    setSelectedPatient(patient);
    if(patient?.currentlyAdmitted){
      setStep(RECONCILE_PATIENT);
    }else if(Helpers.isDefined(patient)){
      setStep(ADMIT_PATIENT);
    }
  }

  function renderModal(){
    switch (step) {
    case ADMIT_PATIENT:
      return (
        <CreateEditClientModal
          hideModal={handleHideChooseModal}
          admittingGroup={currentRoster}
          patient={selectedPatient}
        />
      );
    case RECONCILE_PATIENT:
      return (
        <ReconcileHookModal
          patientDetail={selectedPatient}
          hideModal={handleHideChooseModal}
          group={currentRoster}
          setStep={() => setStep(ADMIT_PATIENT)}
        />
      );
    case SEARCH_PATIENT:
    default:
      return (
        <AdmitClientModal
          rosterId={currentRoster?.id}
          hideModal={handleHideChooseModal}
          setSelectedPatient={handleSelectPatient}
        />);
    }
  }

  return renderModal();
};

export default CreateAdmission;
