import {PropsOf} from '@emotion/react';
import {colors} from 'patient-ping-remedy/packages/theme';
import Icon from 'patient-ping-remedy/packages/icon';
import {menuItemIconStyle} from './MenuItemIcon.styles';

// chopsticks: https://zeroheight.com/5bcb53d99/p/177dd9-menu-item
export default function MenuItemIcon(props: PropsOf<typeof Icon>) {
  return (
    <Icon
      color={colors.tertiary2}
      style={menuItemIconStyle}
      weight="far"
      {...props}
    />
  );
}
