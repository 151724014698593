import {sizes} from "patient-ping-remedy/packages/theme";
import styled from "@emotion/styled";

const EncounterInfoFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledEvent = styled.div`
  margin-top: ${sizes.xSmall};
`;

const StyledEncounterInfoAndPrograms = styled.div`
    font-size: 1.3rem;
`;

export {EncounterInfoFlexRow, StyledEvent, StyledEncounterInfoAndPrograms};
