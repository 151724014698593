import {Organization} from "../../../../api/dto/dto";
import SlideInPane from "patient-ping-remedy/packages/slide-in-pane";
import React, {useContext} from "react";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import TabsComponent from "../../../common/TabsComponent";
import OrganizationGroups from "./OrganizationGroups";
import OrganizationRoles from "./OrganizationRoles";
import {
  OrganizationItem,
  OrganizationLabel,
  OrganizationDetailRow,
  StyledOrganizationData,
  StyledOrganizationDetailHeader
} from "./OrganizationDetails.styles";
import StatusIndicator from "../user_details/StatusIndicator";
import DisplayHelpers from "../../../../helpers/display_helpers";

type Props = {
    organization: Organization | undefined;
    close: Function;
    isOpen: boolean
}

const OrganizationDetailsDrawer = (props : Props) => {
  const {carecoApi} = useContext(CarecoApiContext);

  const {data : rosterRoles} = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const groups = props.organization?.groups?.map(group => {
    return {
      groupName : group.groupName,
      status : group.active ? "Yes" : "No",
    };
  }) || [];

  return (
    <>
      <SlideInPane
        onClose={props.close}
        isOpen={props.isOpen}
        isOverlayOpen={props.isOpen}
        showNav={false}
        headerChildren={props.organization && <StatusIndicator status={props.organization.active ? 'Active' : 'Inactive'} />}
        headerText={"Organization Details"}
      >
        <StyledOrganizationDetailHeader>
          <OrganizationDetailRow>
            <OrganizationItem>
              <OrganizationLabel>Organization</OrganizationLabel>
              <StyledOrganizationData>{props.organization?.name}</StyledOrganizationData>
            </OrganizationItem>
            <OrganizationItem>
              <OrganizationLabel>ID</OrganizationLabel>
              <StyledOrganizationData>{props.organization?.id}</StyledOrganizationData>
            </OrganizationItem>
          </OrganizationDetailRow>
          <OrganizationItem>
            <OrganizationLabel>Date created</OrganizationLabel>
            <StyledOrganizationData>{DisplayHelpers.formatDate(props.organization?.createdAt!)}</StyledOrganizationData>
          </OrganizationItem>
        </StyledOrganizationDetailHeader>
        <TabsComponent tabs={[
          {
            value: 0,
            label: "Roles",
            content: <OrganizationRoles rosterRoles={rosterRoles}/>
          },
          {
            value: 1,
            label: "Groups",
            content: <OrganizationGroups groups={groups} />
          },
        ]}/>
      </SlideInPane>
    </>
  );
};

export default OrganizationDetailsDrawer;
