import styled from '@emotion/styled';
import Tabs, {Tab} from "patient-ping-remedy/packages/tabs";
import {colors} from "patient-ping-remedy/packages/theme";
import {css} from "@emotion/react";

const StyledTabDiv = styled.div((props: {display: string}) => ({
  display: props.display,
}));

const StyledTabs = styled(Tabs)`
  padding-top: 0;
  margin-bottom: 2rem;
  border: none;
`;

const StyledTabWrapper = styled.div`
  padding: 0 0 0 10px;
`;

const StyledTab = styled(Tab)((props: {active: boolean}) => (
  css`
  cursor: pointer;
  font-family: 'Libre Franklin', sans-serif;
  text-transform: none;
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 1px;
  color: ${colors.primary1};
  background-color: transparent;
  border-radius: 0;
  border-bottom: ${props.active ? `5px solid ${colors.primary1}` : 'none'};

    &:hover {
      color: ${colors.primary1};
      background-color: transparent;
      border-bottom: 5px solid ${colors.primary1};
    }
`));

export { StyledTabDiv, StyledTabs, StyledTabWrapper, StyledTab };
