import React from 'react';
import {SDOHOffice} from '../../../../../../api/dto/sdoh';
import DisplayHelpers from '../../../../../../helpers/display_helpers';

type Props = {
  office: SDOHOffice;
}

export default function SDOHOfficeAddress(props: Props) {
  return (
    <>
      <div>{props.office.address.address1}</div>
      {props.office.address.address2 && (
        <div>{props.office.address.address2}</div>
      )}
      {props.office.address.address3 && (
        <div>{props.office.address.address3}</div>
      )}
      <div>
        {props.office.address.city + ', '}
        {props.office.address.stateOrProvince + ' '}
        {props.office.address.postalCode}
      </div>
      <div>{DisplayHelpers.formatPhoneNumber(props.office.phoneNumber!)}</div>
    </>
  );
}
