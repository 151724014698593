import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

export const CheckBoxRowStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 2rem;
`;

export const CheckBoxItemStyle = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

export const HeadingStyle = styled.div`
    color: ${colors.gray4};
    font-weight: bold;
    font-size: 1.5em;
`;
