import React from 'react';
import {StyledContainer} from "./MutatedText.styles";

const MutedText = ({ children, onClick }) => {
  return (
    <StyledContainer onClick={onClick} className="muted-text" data-testid="muted-text">
      {children}
    </StyledContainer>
  );
};

export default MutedText;
