import {Auth0Provider, AppState, Auth0ProviderOptions} from '@auth0/auth0-react';
import React from "react";
import {useAuthStore} from "./store/auth_store";

const Auth0ProviderWithRedirect = (props : Auth0ProviderOptions) => {
  const {setReturnToRoute} = useAuthStore();

  const onRedirectCallback = (appState?: AppState) => {
    setReturnToRoute(appState?.returnTo==='/' ? '/clients' : appState?.returnTo);
  };

  return (
    <Auth0Provider
      cacheLocation={props.cacheLocation}
      domain={props.domain}
      clientId={props.clientId}
      authorizationParams={props.authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirect;
