import {ICONS} from 'patient-ping-remedy/packages/icon';
import {colors} from 'patient-ping-remedy/packages/theme';
import {Container, IconContainer, StyledMessage} from './LOSIndicator.styles';
import EventStatus from './EventStatus';
import {ReactElement} from "react";

type Props = {
  message: string;
  iconMessage: string;
  iconClass?: ICONS;
  iconSvg?: ReactElement,
  active: boolean;
}

export default function LOSIndicator(props: Props) {
  return (
    <Container>
      <StyledMessage>
        {props.message}
      </StyledMessage>
      <IconContainer>
        <EventStatus
          iconMessage={props.iconMessage}
          iconClass={props.iconClass}
          iconSvg={props.iconSvg}
          color={props.active ? colors.primary1 : colors.gray4}
        />
      </IconContainer>
    </Container>
  );
}
