export const FACILITY_TYPE_NAMES = {
  SNF: 'Skilled Nursing Facility',
  HOS: 'Hospital',
  HHA: 'Home Health Agency',
  CCRC: 'Continuing Care Retirement Community',
  LTAC: 'Long-Term Acute Care',
  REHAB_HOS: 'Rehab Hospital',
  HOSPICE: 'Hospice',
  ALF: 'Assisted Living',
  SNF_ADMIN: 'Deprecated',
  URGENT_CARE: 'Urgent Care',
  ADH: 'Adult Day Health',
  PALLIATIVE: 'Palliative',
};

export const FACILITY_TYPE_KEYS = Object.keys(FACILITY_TYPE_NAMES).reduce(
  (keys, key) => ({
    ...keys,
    [key]: key,
  }),
  {},
);

export const POST_ACUTE_CARE_FACILITY_TYPE_KEYS = [
  FACILITY_TYPE_KEYS.SNF,
  FACILITY_TYPE_KEYS.HHA,
  FACILITY_TYPE_KEYS.HOSPICE,
  FACILITY_TYPE_KEYS.LTAC,
  FACILITY_TYPE_KEYS.REHAB_HOS,
  FACILITY_TYPE_KEYS.ALF,
  FACILITY_TYPE_KEYS.CCRC,
  FACILITY_TYPE_KEYS.ADH,
];

export const HOSPITAL = FACILITY_TYPE_KEYS.HOS;
export const { SNF } = FACILITY_TYPE_KEYS;
export const { SNF_ADMIN } = FACILITY_TYPE_KEYS;
export const { URGENT_CARE } = FACILITY_TYPE_KEYS;

export const FacilityTypesObj = {
  [FACILITY_TYPE_NAMES.SNF]: FACILITY_TYPE_KEYS.SNF,
  [FACILITY_TYPE_NAMES.HOS]: FACILITY_TYPE_KEYS.HOS,
  [FACILITY_TYPE_NAMES.HHA]: FACILITY_TYPE_KEYS.HHA,
  [FACILITY_TYPE_NAMES.LTAC]: FACILITY_TYPE_KEYS.LTAC,
  [FACILITY_TYPE_NAMES.REHAB_HOS]: FACILITY_TYPE_KEYS.REHAB_HOS,
  [FACILITY_TYPE_NAMES.HOSPICE]: FACILITY_TYPE_KEYS.HOSPICE,
  [FACILITY_TYPE_NAMES.ALF]: FACILITY_TYPE_KEYS.ALF,
  [FACILITY_TYPE_NAMES.CCRC]: FACILITY_TYPE_KEYS.CCRC,
  ALL: undefined,
};

export const ADMITTING_FACILITY_TYPES_SET = ([
  FACILITY_TYPE_KEYS.SNF,
  FACILITY_TYPE_KEYS.SNF_ADMIN,
  FACILITY_TYPE_KEYS.HOS,
  FACILITY_TYPE_KEYS.HHA,
  FACILITY_TYPE_KEYS.LTAC,
  FACILITY_TYPE_KEYS.REHAB_HOS,
  FACILITY_TYPE_KEYS.HOSPICE,
  FACILITY_TYPE_KEYS.ALF,
  FACILITY_TYPE_KEYS.CCRC,
]);
