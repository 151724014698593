import {EventIcon} from "./Timeline.styles";
import Icon, {  ICONS } from "patient-ping-remedy/packages/icon";
import {css} from "@emotion/css";
import {colors} from "patient-ping-remedy/packages/theme";
import React from "react";

type Props = {
  active: boolean;
  iconClass?: ICONS;
  iconSvg?: string;
  iconAlt?: string;
}

const MainEventIcon = (props: Props) => {
  return (
    <EventIcon active={props.active}>
      { props.iconClass &&
        <Icon
          className={css({marginTop: '-2px'})}
          iconClass={props.iconClass}
          color={colors.white}
        />
      }

      {
        props.iconSvg &&
        <img src={props.iconSvg} alt={props.iconAlt} />
      }
    </EventIcon>
  );
};

export default MainEventIcon;
