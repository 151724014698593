import React from 'react';
import MutedText from "./MutedText";

const UNAVAILABLE = 'Unavailable';

const InformationUnavailable = ({ skipFormatting = false, onClick }) => {
  if (skipFormatting) {
    return <span onClick={onClick}>{UNAVAILABLE}</span>;
  }

  return <MutedText onClick={onClick}>{UNAVAILABLE}</MutedText>;
};

export default InformationUnavailable;
