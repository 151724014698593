import styled from '@emotion/styled';

export const AddNewUserButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  height: 0;
  align-items: center;
  gap: 20px;
    
`;
export const AddNewUserButton = styled.div`
  top: -71px;
`;
