import React, { useState } from "react";
import { AppState, CarecoApiContext } from "./careco-api-context";

interface Props {
  children: React.ReactNode;
}

// The main context provider
export const CarecoApiContextProvider: React.FunctionComponent<Props> = (
  props: Props
): React.ReactElement => {

  const [state, setState] = useState({});
  const updateState = (newState: Partial<AppState>) => {
    setState({ ...state, ...newState });
  };

  return (
    <CarecoApiContext.Provider value={{ ...state, updateState }}>
      {props.children}
    </CarecoApiContext.Provider>
  );
};
