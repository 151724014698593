import {colors, sizes} from "patient-ping-remedy/packages/theme";
import {LabelFormFieldTypography} from "patient-ping-remedy/packages/typography";
import styled from '@emotion/styled';

const StyledDisclaimerText = styled('div')`
  color: ${colors.yellow5};
  max-width: 60rem;
  font-size: 1.5rem;
`;

const StyledLabelFormFieldTypography = styled(LabelFormFieldTypography)`
  margin-bottom: ${sizes.xSmall};
  display: block;
`;

export { StyledDisclaimerText, StyledLabelFormFieldTypography };
