import {ICONS} from "patient-ping-remedy/packages/icon";
import {ADTEvent} from "../../../../../../api/dto/client-profile";
import React from "react";
import {
  ADMITTED, CLOSED, DECEASED, DISCHARGED, ENDED_SERVICES,
  MEDICAL_LEAVE_OF_ABSENCE, NO_CURRENT_STATUS,
  PAYOR_CHANGED, PRESENTED, RESUMED_SERVICES, TRANSFERRED, VISIT
} from "../../../manual_encounter/constants/PingStatuses";

export type ADTStatus = {
  displayName: string;
  iconClass: ICONS;
  displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => React.JSX.Element;
  display: boolean;
}

export type StatusMap = {
  [key: string]: ADTStatus
}

// these statuses map directly to statuses returned from pings
export const Status : StatusMap = {
  // unknown if pings does not return a status
  'Unknown': {
    displayName: 'Unknown',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">Unknown</span> at {facilityName}</>;
    },
    display: false
  },
  [ADMITTED]: {
    displayName: 'Admitted',
    iconClass: ICONS.bed,
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> to {facilityName}
        {event.setting && <span className="pipe-separator">{event.setting}</span>}</>;
    },
    display: true
  },
  [DECEASED]: {
    displayName: 'Deceased',
    iconClass: ICONS.tombstone,
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: true
  },
  [DISCHARGED]: {
    displayName: 'Discharged',
    iconClass: ICONS["walking"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> from {facilityName}
        {dischargeDispo && <span>{" to " + dischargeDispo}</span>}
      </>;
    },
    display: true
  },
  [PAYOR_CHANGED]: {
    displayName: 'Payor Change',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> to {event.payor?.insuranceCompanyName}</>;
    },
    display: true // at some point product wanted to hide this event
  },
  [PRESENTED]: {
    displayName: 'Presented',
    iconClass: ICONS["stretcher"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> to {facilityName}
        {event.setting && <span className="pipe-separator">{event.setting}</span>}</>;
    },
    display: true
  },
  [TRANSFERRED]: {
    displayName: 'Transferred',
    iconClass: ICONS.ambulance,
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> to {facilityName}
        {event.setting && <span className="pipe-separator">{event.setting}</span>}</>;
    },
    display: true
  },
  [MEDICAL_LEAVE_OF_ABSENCE]: {
    displayName: 'Medical LOA',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: false  // not in v1 timeline
  },
  [RESUMED_SERVICES]: {
    displayName: 'Resumed Services',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: false  // not in v1 timeline
  },
  [ENDED_SERVICES]: {
    displayName: 'Ended Services',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: false  // not in v1 timeline
  },
  [VISIT]: {
    displayName: 'Visit',
    iconClass: ICONS["clinic-medical"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: false  // not in v1 timeline
  },
  [NO_CURRENT_STATUS]: {
    displayName: 'No Current Status',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: false  // not in v1 timeline
  },
  [CLOSED]: {
    displayName: 'No Current Status',
    iconClass: ICONS["trash-alt"],
    displayMessage: (event: ADTEvent, facilityName: string, dischargeDispo: string) => {
      return <><span className="event-text-bold">{event.status}</span> at {facilityName}</>;
    },
    display: true  // not in v1 timeline
  },
};

export type GroupType = {
  displayName: string;
  iconClass: ICONS;
}

export type GroupMap = {
  [key: string]: GroupType
}

// these statuses map directly to admittingFacility types returned from pings
export const GroupTypes : GroupMap = {
  // unknown if pings does not return a status
  'Unknown': {
    displayName: 'Unknown',
    iconClass: ICONS["trash-alt"],
  },
  'HOS': {
    displayName: 'Hospital',
    iconClass: ICONS["hospital"]
  },
  'IPSYCH': {
    displayName: 'Hospital',
    iconClass: ICONS["hospital"]
  },
  'REHAB_HOS': {
    displayName: 'Hospital',
    iconClass: ICONS["hospital"]
  },
  'RTC': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'SUD': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'OBH': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'SNF': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'HHA': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'LTAC': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'HOSPICE': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'ALF': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'CCRC': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'ADH': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'URGENT_CARE': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  },
  'PALLIATIVE': {
    displayName: 'Post Acute',
    iconClass: ICONS["clinic-medical"]
  }
};
