import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  background: ${colors.white};
  border-bottom: ${colors.gray2} 1px solid;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px 36px;
  z-index: 2;
`;

const LeftRegion = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
`;

const RightRegion = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-left: auto;
  height: 56px;
`;

export {Wrapper, LeftRegion, RightRegion};
