import React from 'react';
import DisplayHelpers from "../../../../../../../helpers/display_helpers";
import MutedText from "../common/MutedText";
import InformationUnavailable from "../common/InformationUnavailable";
import {DobAndPayorFlexRow, PatientCardField, StyledInsuranceName} from "./DobAndPayerRow.styles";
import TruncatedText from "../common/TruncatedText";

const DateOfBirth = ({ dateOfBirth }) => {
  return (
    <PatientCardField className="dob">
      <MutedText>DOB: </MutedText>
      <MutedText data-testid="patient-dob">
        {DisplayHelpers.formatDateFromDateOnlyString(dateOfBirth) || <InformationUnavailable />}
      </MutedText>
    </PatientCardField>
  );
};

const Billing = ({ currentPayor }) => {
  const { insuranceCompanyName = '' } = currentPayor || {};

  return (
    <PatientCardField className="insurance-name">
      <MutedText>{'Currently Billing: '}</MutedText>

      {insuranceCompanyName && insuranceCompanyName !== 'None Provided' ? (
        <StyledInsuranceName data-testid="patient-billing">
          <TruncatedText text={insuranceCompanyName} />
        </StyledInsuranceName>
      ) : (
        <MutedText>None Provided</MutedText>
      ) }
    </PatientCardField>
  );
};

const DobAndPayerRow = ({ patient: { dateOfBirth, currentPayor } }) => {
  return (
    <DobAndPayorFlexRow>
      <DateOfBirth dateOfBirth={dateOfBirth} />
      <Billing currentPayor={currentPayor} />
    </DobAndPayorFlexRow>
  );
};

export default DobAndPayerRow;
