import styled from '@emotion/styled';
import {colors} from 'patient-ping-remedy/packages/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`;

export const IconContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.gray3};
  padding: 5px 10px;
  border-radius: 50px;
`;

export const StyledMessage = styled.div`
  font-size: 16px;
  color: ${colors.primary1};
  margin-right: 10px;
`;
