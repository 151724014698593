import React, {useContext, useEffect, useState} from 'react';
import Modal from 'patient-ping-remedy/packages/modal';
import Tabs, { Tab } from 'patient-ping-remedy/packages/tabs';
import Icon, {ICONS} from 'patient-ping-remedy/packages/icon';
import { colors } from 'patient-ping-remedy/packages/theme';
import { HeadingTypography, StandardTypography, SmallTypography } from 'patient-ping-remedy/packages/typography';
import Spinner from 'patient-ping-remedy/packages/spinner';
import {Info, PatientInfo, printDocStyle, StyledPrintIcon, Loading, StyledContent} from './DischargeInfoModal.styles';
import {DischargeInfo, DischargeEvent, Patient} from "../../../../../../api/dto/client-profile";
import {CarecoApiContext} from "../../../../../../app-context/careco-api-context";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import ToolTip from "patient-ping-remedy/packages/tool-tip";
import {StyleType} from "patient-ping-remedy/packages/button";

const GENERAL_TAB = 0;
const DISCHARGE_INSTRUCTIONS_TAB: number = 1;
const DISCHARGE_MEDICATIONS_TAB: number = 2;
const UPCOMING_ENCOUNTERS_TAB: number = 3;

export const DischargeInfoTabs = {
  GENERAL_TAB,
  DISCHARGE_INSTRUCTIONS_TAB,
  DISCHARGE_MEDICATIONS_TAB,
  UPCOMING_ENCOUNTERS_TAB,
};

type Props = {
  hideModal: () => void;
  dischargeInfo: DischargeInfo;
  patient: Patient;
  event: DischargeEvent;
  selectedRoster: number;
};

export const DischargeInfoModal = ({
  patient,
  dischargeInfo,
  event,
  hideModal,
  selectedRoster
}: Props) => {
  const [content, setContent] = useState<string>('');
  const [inFlight, setInFlight] = useState(true);
  const [error, setError] = useState(false);
  const [currentTab, setCurrentTab] = useState(GENERAL_TAB);
  const { carecoApi } = useContext(CarecoApiContext);
  const [isLoadingPrintData, setIsLoadingPrintData] = useState(false);

  const patientFullName = DisplayHelpers.formatName(
    patient.firstName,
    patient.middleName,
    patient.lastName,
  );
  const patientInfo = `${DisplayHelpers.formatDateOfBirthWithAge(patient.dateOfBirth)},`
    + ` ${DisplayHelpers.genderFromEnum(patient.gender)}`;
  const patientEncounter = ` ${event.status} from ${event.facility.name} on ${DisplayHelpers.formatDateTime(event.eventDate)}`;

  useEffect(function fetchData() {
    setInFlight(true);
    setError(false);

    carecoApi?.getDischargeSummary(
      patient.patientId,
      dischargeInfo.encounterId,
      selectedRoster,
      'HTML')
      .then((response: string) => {
        setInFlight(false);
        setContent(response);
      })
      .catch((e) => {
        setInFlight(false);
        setError(true);
        console.error(e);
      });
  }, []);

  const handleTabChange = (tab: number) => {
    setCurrentTab(tab);
  };

  const promptDischargeSummaryPrint = (printData: string) => {
    const WindowObject = window.open('', 'PrintWindow');
    WindowObject!.document.writeln(`
      <html>
        <head>
          <style>${printDocStyle}</style>
        </head>
        <div>${patientFullName}</div>
        <div>${patientInfo}</div>
        <div>${patientEncounter}</div>
        ${printData}
      </html>
    `);
    WindowObject!.document.close();
    WindowObject!.focus();
    WindowObject!.print();
    WindowObject!.close();
  };

  const handleXmlIconClick = () => {
    setIsLoadingPrintData(true);
    carecoApi?.getDischargeSummary(
      patient.patientId,
      dischargeInfo.encounterId,
      selectedRoster,
      'XML'
    ).then((response) => {
      setIsLoadingPrintData(false);
      const blob = new Blob([response], { type: 'text/xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${patientFullName} - Discharge Summary.xml`;
      a.click();
    }).catch((error) => {
      setIsLoadingPrintData(false);
      console.error(error);
    });
  };

  const handlePrintIconClick = () => {
    setIsLoadingPrintData(true);
    carecoApi?.getDischargeSummary(
      patient.patientId,
      dischargeInfo.encounterId,
      selectedRoster,
      'HTML'
    ).then((response) => {
      setIsLoadingPrintData(false);
      promptDischargeSummaryPrint(response);
    }).catch((error) => {
      setIsLoadingPrintData(false);
      console.error(error);
    });
  };

  return (
    <Modal isOpen={true} hideModal={hideModal} headerText="Discharge Information">
      {inFlight && (
        <Loading>
          <Spinner iconSize="3x" />
        </Loading>
      )}
      {(!inFlight && !error && (
        <div>
          <Info>
            <Icon iconClass="notes-medical" weight="far" iconSize="4x" color={colors.green4} />
            <div>
              <PatientInfo>
                <HeadingTypography>
                  {DisplayHelpers.formatName(
                    patient.firstName,
                    patient.middleName,
                    patient.lastName,
                  )}
                </HeadingTypography>
                <StandardTypography>
                  {` ${DisplayHelpers.formatDateOfBirthWithAge(patient.dateOfBirth)}, 
                  ${DisplayHelpers.genderFromEnum(patient.gender)}`}
                </StandardTypography>
              </PatientInfo>
              <ToolTip text={'Download: "Click here to download the full discharge summary XML document"'}>
                <StyledPrintIcon styleType={StyleType.TERTIARY} onClick={handleXmlIconClick}>
                  <Icon iconClass={ICONS.download}/>
                </StyledPrintIcon>
              </ToolTip>

              {isLoadingPrintData ? (
                <Spinner iconSize="xs"/>
              ) : (
                <ToolTip text={'Print: "Click here to print or download the full discharge summary PDF"'}>
                  <StyledPrintIcon styleType={StyleType.TERTIARY} onClick={handlePrintIconClick}>
                    <Icon iconClass={ICONS.print}/>
                  </StyledPrintIcon>
                </ToolTip>
              )}

              <div>
                <SmallTypography>Encounter </SmallTypography>
                <StandardTypography>
                  {` ${event.status} from ${event.facility.name} on ${DisplayHelpers.formatDateTime(event.eventDate)}`}
                </StandardTypography>
              </div>
            </div>
          </Info>

          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab>General</Tab>
            <Tab disabled={!dischargeInfo.hasDischargeInstructions}>Discharge Instructions</Tab>
            <Tab disabled={!dischargeInfo.hasDischargeMedication}>Discharge Medication</Tab>
            <Tab disabled={!dischargeInfo.hasUpcomingEncounters}>Upcoming Encounters</Tab>
          </Tabs>

          <StyledContent currentTab={currentTab} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      )) || <StyledContent currentTab={currentTab} dangerouslySetInnerHTML={{ __html: content }} />}

      {error && (
        <div>
          <StandardTypography>
            There was an error loading the discharge summary. Please try again later.
          </StandardTypography>
        </div>
      )}
    </Modal>
  );
};

export default DischargeInfoModal;
