import styled from '@emotion/styled';
import {colors} from 'patient-ping-remedy/packages/theme';

const avatarWrapperStyle = {
  borderRadius: '50%',
  overflow: 'hidden',
};

export const avatarStyle = {
  height: '36px',
  width: '36px',
  minWidth: '36px',
};

export const AvatarWrapper = styled.div({
  ...avatarWrapperStyle,
  ...avatarStyle,
});

export const AvatarCircle = styled.div({
  ...avatarWrapperStyle,
  ...avatarStyle,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: colors.gray4,
  '& > svg': {
    fontSize: '16px',
  },
});
