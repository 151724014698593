import Modal from "patient-ping-remedy/packages/modal";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {css} from "@emotion/css";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import React from "react";
import {ModalTextDiv} from "./ConfirmModal.styles";

type Props = {
  show: boolean,
  leaveFunction: Function,
  showFunction: Function
}

const ConfirmModal = (props: Props) => {
  const handleCancelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.showFunction(false);
  };

  const handleLeaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.leaveFunction();
  };

  return (
    <Modal
      id={"confirm-modal"}
      headerText={"Are you sure you want to leave?"}
      isOpen={props.show}
      buttons={[
        <Button key={'cancelButton'} onClick={handleCancelClick} styleType={StyleType.TERTIARY}>Cancel</Button>,
        <Button key={'leaveButton'} onClick={handleLeaveClick}>Leave</Button>
      ]}
      className={css({width: '30%'})}
    >
      <ModalTextDiv>
        <SmallTypography>
          It looks like you’re in the middle of making updates and you haven’t saved all of your content.
        </SmallTypography>
      </ModalTextDiv>
      <ModalTextDiv>
        <SmallTypography>
          Save before you go!
        </SmallTypography>
      </ModalTextDiv>
    </Modal>
  );
};

export default ConfirmModal;
