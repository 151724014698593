import {colors} from 'patient-ping-remedy/packages/theme';
import Icon from 'patient-ping-remedy/packages/icon';
import {PropsOf} from '@emotion/react';
import {menuItemTrailingIconStyle} from './MenuItemTrailingIcon.styles';

type Props = PropsOf<typeof Icon> & {
  iconClass: 'info-circle' | 'external-link';
}

// chopsticks: https://zeroheight.com/5bcb53d99/p/177dd9-menu-item
export default function MenuItemTrailingIcon(props: Props) {
  return (
    <Icon
      color={colors.gray4}
      style={menuItemTrailingIconStyle}
      weight="far"
      {...props}
    />
  );
}
