import {useContext, useEffect} from "react";
import {useAuthStore} from "../store/auth_store";
import {CarecoApiContext} from "../app-context/careco-api-context";

const useWalkMe = () => {
  const { currentUser} = useAuthStore();
  const { carecoApi } = useContext(CarecoApiContext);

  useEffect(() => {
    if(currentUser && carecoApi) {
      carecoApi.getWalkMeData(currentUser.id)
        .then((carecoWalkMeData) => {
          window.PatientPingThirdPartyData = carecoWalkMeData;
        });
    }
  }, [currentUser, carecoApi]);
};

export default useWalkMe;
