import {AxiosError} from "axios";
import {Address, EncounterType, PatientPhoneNumber} from "../api/dto/client-profile";
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import Helpers from "./helpers";

const DisplayHelpers = {
  displayHonorific(honorific: string | undefined) {
    if(!honorific) return '';
    if(['MD'].includes(honorific)) {
      return 'Dr.';
    } else {
      return honorific;
    }
  },
  formatDateTime(date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString(
      'en-US', {
        hour: '2-digit',
        minute: '2-digit'
      }
    );
  },
  formatDateTimeShort(date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })+ ' at ' + dateObj.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  },
  formatTime(date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleTimeString(
      'en-US', {
        hour: '2-digit',
        minute: '2-digit'
      }
    );
  },
  formatDate(date: string | null) {
    if (!date) return '';
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  },
  formatTimeUTC(date: string) {
    return this.formatTime(this.getDateWithTimezone(date).toString());
  },
  formatDateUTC(date: string) {
    return this.formatDate(this.getDateWithTimezone(date).toString());
  },
  formatDateFromDateOnlyString(date: string | null) {
    if (!date) return '';

    const dateObj = new Date(date + 'T00:00:00'); // needed to make javascript parse as local time instead of UTC
    return dateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  },
  formatTimeSince(unit: string, value: number): string {
    return `${value} ${unit}${value === 1 ? '' : 's'} ago`;
  },
  timeSince(date: Date, isDrawer:boolean = false): string {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
      return this.formatTimeSince("second", Math.round(secondsPast));
    }
    if (secondsPast < 3600) {
      const unit = isDrawer ? "min" : "minute";
      return this.formatTimeSince(unit, Math.floor(secondsPast / 60));
    }
    if (secondsPast < 86400) {
      return this.formatTimeSince("hour", Math.floor(secondsPast / 3600));
    }
    if (secondsPast < 2592000) {
      return this.formatTimeSince("day", Math.floor(secondsPast / 86400));
    }
    if (secondsPast < 31536000) {
      return this.formatTimeSince("month", Math.floor(secondsPast / 2592000));
    }
    return this.formatTimeSince("year", Math.floor(secondsPast / 31536000));
  },
  getDateMonthDay(date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit'
    });
  },
  getDateWithTimezone(date: string) {
    const dateObj = new Date(date);

    const timeInSeconds = dateObj.getTime() / 1000;
    const timezoneOffsetInSeconds = dateObj.getTimezoneOffset() * 60;

    return new Date((timeInSeconds - timezoneOffsetInSeconds) * 1000);
  },
  formatDateOfBirthWithAge(dateOfBirthString: string): string {
    const dob = new Date(dateOfBirthString);

    const now = Date.now();
    const diff = new Date(now.valueOf() - dob.valueOf());
    const age = Math.abs(diff.getUTCFullYear() - 1970);

    const formattedDate = this.formatDateFromDateOnlyString(dateOfBirthString);

    return `${formattedDate} (${age} yo)`;
  },
  formatName(firstName: string, middleName: Nullable<string>, lastName: string) {
    let formattedName = "";

    if (firstName) {
      formattedName += this.capitalizeFirstLetter(firstName);
    }

    if (middleName) {
      formattedName += " " + this.capitalizeFirstLetter(middleName);
    }

    if (lastName) {
      formattedName += " " + this.capitalizeFirstLetter(lastName);
    }

    return formattedName.trim();
  },
  capitalizeFirstLetter(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  },
  removeUnnecessaryWhitespace(value: string | null) {
    return value?.replace(/\s\s+/g, ' ').trim() ?? '';
  },
  formatPhoneNumber(phoneNumber: string) {
    // this display helper should be used for form inputs
    // phone data we control or can verify vailidity
    // (ie user phone numbers, facility phone numbers)
    if (!phoneNumber) return '';

    const phone = phoneNumber.replace(/\D/g, '').substring(0, 10);
    const areaCode = phone.substring(0, 3);
    const middle = phone.substring(3, 6);
    const last = phone.substring(6, 10);

    let formattedPhone = '';
    if (phone.length > 6) {
      formattedPhone = `(${areaCode}) ${middle}-${last}`;
    } else if (phone.length > 3) {
      formattedPhone = `(${areaCode}) ${middle}`;
    } else if (phone.length > 0) {
      formattedPhone = `${areaCode}`;
    }

    return formattedPhone;
  },
  formatPatientPhoneNumber(patientPhoneNumber: PatientPhoneNumber | undefined) {
    // patient phone numbers have to be handled separately due to not
    // being able to control data effectively
    // this method should ONLY be used for patient phone numbers
    const phoneNumber = patientPhoneNumber?.phone;
    if (!phoneNumber) return '';

    const scrubbedPhone = parsePhoneNumberFromString(phoneNumber, 'US');
    if (scrubbedPhone && scrubbedPhone.isPossible()) {
      var formattedPhone = scrubbedPhone.formatNational();

      if (patientPhoneNumber.type === 'mobile') {
        formattedPhone += ' (cell)';
      }

      return formattedPhone;
    }
    return phoneNumber;
  },
  cleanGroupTypeKey(groupType: string): string | null {
    if (groupType === 'OWNING_ORG') {
      return null;
    }
    return groupType.replace(/_/g, ' ');
  },
  formatAddress(address: Address | null | undefined): string {
    if (!address) {
      return '';
    }

    const { address1, city, state, zip } = address;
    const parts = [
      address1,
      city,
      state && zip ? `${state} ${zip}` : state || zip
    ];
    return Helpers.joinWithComma(...parts);
  },
  formatSSN(ssn: string) {
    if (!ssn) {
      return '';
    }

    const cleaned = ssn.replace(/\D/g,'');

    if (cleaned.length === 9) {
      return `xxx-xx-${cleaned.slice(5)}`;
    }

    if (cleaned.length === 4) {
      return `xxx-xx-${cleaned}`;
    }

    return 'could not format: ' + ssn;
  },
  genderFromEnum(genderEnum: string) {
    switch (genderEnum) {
    case "M": {
      return "Male";
    }
    case "F": {
      return "Female";
    }
    case "U": {
      return "Unknown";
    }
    case "O": {
      return "Other";
    }
    case "X": {
      return "Non-binary";
    }
    default: {
      return "Unknown";
    }
    }
  },
  humanize(str: string) {
    if(!str) return '';

    return str.trim().split(/\s+/).map(function(str) {
      return str.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
    }).join(' ').toLowerCase().replace(/^./, function(m) {
      return m.toUpperCase();
    });
  },
  displayErrorMessage(error: Error | null) {
    if (!error) return '';

    if(error instanceof AxiosError) {
      if(error.response?.status === 403) {
        return 'You do not have permission to view this resource. ' +
          'If you believe you are seeing this message in error please contact your account manager.';
      }
    }

    return error.message;
  },
  contactAccountManagerMessage() {
    return 'To change your platform functionality, contact your account manager.';
  },
  contactAdminMessage() {
    return 'To change your platform functionality, contact your account admin.';
  },
  contactGroupAdminMessage() {
    return 'To update your group permissions, contact your admin.';
  },
  contactAdminAccessMessage() {
    return 'To change your platform functionality, contact your admin.';
  },
  readableEncounterType(type: EncounterType) {
    switch(type) {
    case EncounterType.ADT:
      return 'ADT';
    case EncounterType.OPENBEDS_REFERRAL:
    case EncounterType.FINDHELP_SDOH:
      return 'Referral';
    case EncounterType.JUSTICE_INVOLVED:
      return 'Justice Involved';
    case EncounterType.OPENBEDS_CRISIS:
      return 'Crisis';
    }
  }
};

export default DisplayHelpers;
