import {useEffect, useState} from "react";

// hide scrollbars in the report because we will always extend the iframe to fit the report
// without hiding it could cause the scrollbars to flicker for a moment
// while iframe size is changed
const addedStyles  = `
  ::-webkit-scrollbar { 
      display: none /* Firefox */
  }
  
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }  
`;

type Options = {
  html: string;
  observerElementId: string;
}

const useIframeResizer = ({ html, observerElementId }: Options) => {
  const [customHtml, setCustomHtml] = useState<string | null>(null);

  // this script will listen for changes in the report DOM
  // and send the height of the content to the parent window
  const addedScript = `
  const sendHeight = function() {
    let height = document.getElementById('${observerElementId}').scrollHeight;
    window.parent.postMessage({'height': height}, '*');
  };
  
  let observerElement = document.getElementById('${observerElementId}');
  let observerConfig = { attributes: true, subtree: true, childList: true };
  let observerCallback = function(mutationsList, observer) {
    sendHeight();
  };
  
  let observer = new MutationObserver(observerCallback);
  observer.observe(observerElement, observerConfig);
  document.addEventListener('DOMContentLoaded', function() {
    document.addEventListener('unload', function() {
      observer.disconnect();
    });
  });
`;

  const handleIframeHeightChange = (event: MessageEvent) => {
    if(event.data['height']) {
      const iframe = document.querySelector('iframe');
      if(iframe) {
        let newHeight = event.data['height'] + 50; // add extra room for padding to prevent scrollbars
        iframe.style.height = `${newHeight}px`;
      }
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    const style = document.createElement('style');

    style.innerHTML = addedStyles;
    script.innerHTML = addedScript;

    const dom = new DOMParser().parseFromString(html, 'text/html');
    dom.documentElement.querySelector('body')?.append(script);
    dom.documentElement.querySelector('head')?.append(style);

    setCustomHtml(dom.documentElement.outerHTML);
    window.addEventListener('message', handleIframeHeightChange);

    return () => {
      window.removeEventListener('message', handleIframeHeightChange);
    };
  }, [html]);

  return { customHtml };
};

export default useIframeResizer;
