import React from 'react';
import SafePureComponent from "./SafePureComponent";

class PingStateText extends SafePureComponent {
  safeRender() {
    const {
      ping: { state },
    } = this.props;
    return (
      <span data-testid="ping-status-text">
        {state}
      </span>
    );
  }
}

export default PingStateText;
