import styled from '@emotion/styled';
import {colors, fontSize, sizes} from 'patient-ping-remedy/packages/theme';

type Props = {
  negated: boolean;
  disabled?: boolean;
};

export const StyledChip = styled('button')<Props>`
  display: inline-block;
  font-size: ${fontSize.small};
  border-radius: ${sizes.xSmall};
  padding: 0.3rem ${sizes.xSmall};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 0 10px 0 0;
  border: 1px solid ${(props) => (props.negated ? colors.gray2 : colors.green4)};
  background-color: ${(props) => (props.negated ? colors.gray2 : colors.green1)};
  color: ${(props) => (props.negated ? colors.charcoal : colors.green5)};
`;
