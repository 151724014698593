import {ReactNode, useState} from "react";
import ExpandCollapseArrow from "patient-ping-remedy/packages/expand-collapse-arrow";
import { ExpandMoreButtonStyled } from "./ExpandMoreButton.styles";
import {colors} from "patient-ping-remedy/packages/theme";
import {Container} from "./ExpandMoreButton.styles";

type Props = {
  buttonText: string | ((isOpen: boolean) => string);
  expandItems: ReactNode[];
  active: boolean;
  useExpandHideText: boolean;
  renderBelow?: boolean;
  inline?: boolean;
  disabled?: boolean;
}

const ExpandMoreButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {props.inline &&
        !isOpen && '...'
      }

      {!props.renderBelow &&
        isOpen && props.expandItems
      }

      <Container inline={props.inline}>
        <ExpandMoreButtonStyled
          active={props.active}
          inline={props.inline}
          disabled={props.disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {!props.inline && !props.disabled && (
            <ExpandCollapseArrow
              isOpen={props.disabled ? true : isOpen}
              iconSize={'1x'}
              iconColor={props.active ? colors.primary1 : colors.gray4}
            />
          )}

          {props.useExpandHideText && (
            <>
              {isOpen ? 'Hide ' : 'Expand '}
              {typeof props.buttonText === 'string' ? props.buttonText : props.buttonText(isOpen)}
            </>
          )}

          {!props.useExpandHideText && (
            typeof props.buttonText === 'string' ? props.buttonText : props.buttonText(isOpen)
          )}
        </ExpandMoreButtonStyled>
      </Container>

      {props.renderBelow &&
        isOpen && props.expandItems
      }
    </>
  );
};

ExpandMoreButton.defaultProps = {
  disbaled: false
};

export default ExpandMoreButton;
