import React, {useCallback, useContext, useEffect, useState} from 'react';
import Icon from "patient-ping-remedy/packages/icon";
import {GroupType, Roster} from "../../../api/dto/dto";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {colors} from "patient-ping-remedy/packages/theme";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import DisplayHelpers from 'helpers/display_helpers';
import SortableTable, {TableColumn, TableData} from "../../common/SortableTable";
import RosterHelpers from "../../../helpers/roster_helpers";
import GroupDetailsDrawer from "./group_details/GroupDetailsDrawer";

const GroupsTab = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [resetTableFilters, setResetTableFilters] = useState<boolean>(false);
  const [currentRoster, setCurrentRoster] = useState<Roster | null>(null);
  const { carecoApi } = useContext(CarecoApiContext);

  const { data: rosters, isLoading, error } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const mapSubmissionToType = (type: string) => {
    const DynamicRosterSubmission = "Dynamic (Event Based) Roster";
    const StaticRosterSubmission = "Static Roster";

    switch (type) {
    case GroupType.SNF:
    case GroupType.HHA:
    case GroupType.LTAC:
    case GroupType.REHAB_HOS:
    case GroupType.HOSPICE:
    case GroupType.ALF:
    case GroupType.HL7_BASED_PROGRAM:
    case GroupType.CCRC:
    case GroupType.ADH:
    case GroupType.URGENT_CARE:
    case GroupType.PALLIATIVE:
    case GroupType.CRISIS:
    case GroupType.HOS:
      return DynamicRosterSubmission;
    case GroupType.OWNING_ORG:
    case GroupType.PRIVATE_PROGRAM:
      return StaticRosterSubmission;
    default:
      return DynamicRosterSubmission;
    }

  };

  const dropdownOptions = useCallback(() => {
    return RosterHelpers.getGroupFiltersDropdownOptions(rosters);
  }, [rosters]);

  const mapRosterTableData = useCallback((roster: Roster) : TableData => {
    return {
      id: {
        tableHeader: 'ID',
        key: 'id',
        type: 'number',
        value: roster.id,
        displayValue: roster.id,
        columnWidth: '10%'
      },
      group: {
        tableHeader: 'Group',
        key: 'group',
        type: 'string',
        value: roster.name,
        displayValue: (
          <Button
            onClick={() => {
              setCurrentRoster(roster);
            }}
            styleType={StyleType.TERTIARY}>
            { roster.name }
          </Button>
        )
      },
      type: {
        tableHeader: 'Type',
        key: 'type',
        type: 'string',
        value: roster.type,
        displayValue: roster.typeDisplayName
      },
      submission: {
        tableHeader: 'Submission',
        key: 'submission',
        type: 'string',
        value: mapSubmissionToType(roster.type),
        displayValue: mapSubmissionToType(roster.type)
      },
      address: {
        tableHeader: 'Address',
        key: 'address',
        type: 'string',
        value: DisplayHelpers.formatAddress(roster.address),
        displayValue: DisplayHelpers.formatAddress(roster.address)
      },
      organization: {
        tableHeader: 'Organization',
        key: 'organization',
        type: 'string',
        value: roster.organization.name,
        displayValue: roster.organization.name
      },
      authorized: {
        tableHeader: 'Authorized',
        key: 'authorized',
        type: 'string',
        value: roster.authorized ? "Yes" : "No",
        displayValue: roster.authorized ? "Yes" : "No",
      },
      status: {
        tableHeader: 'Active',
        key: 'active',
        type: 'string',
        value: roster.active ? "Yes" : "No",
        displayValue: roster.active ? "Yes" : "No",
      },
      actions: {
        tableHeader: '',
        key: 'actions',
        type: 'custom',
        value: '',
        displayValue: (
          <Button
            onClick={handleClick}
            style={{ background: 'none', padding: '0', margin: '0'}}>
            <Icon iconClass={'ellipsis-h'} color={colors.gray4} />
          </Button>
        )
      }
    };
  }, []);

  useEffect(() => {
    if(rosters) {
      setTableData(
        rosters.data.data.map((roster: Roster) => {
          return mapRosterTableData(roster);
        })
      );
    }
  }, [rosters, mapRosterTableData]);

  function handleClick() {
    console.log('clicked');
  }

  return (
    <>
      <SortableTable
        id={'groups-table'}
        data={tableData}
        loading={isLoading}
        loadError={error}
        header={Object.values(tableData[0] ?? [])?.map((value: TableColumn) => value.tableHeader)}
        searchPlaceholder={'Search by group name'}
        searchColumns={['group']}
        resetTableFilters={resetTableFilters}
        setResetTableFilters={setResetTableFilters}
        sortableColumns={tableData[0] ?
          [
            tableData[0]['group'],
            tableData[0]['organization'],
            tableData[0]['id']
          ]
          : []}
        filterOptions={[
          {
            type: 'multiselect-search',
            label: 'Organizations',
            dataType: 'string',
            options: dropdownOptions().organizationOptions,
            key: 'organization'
          }
        ]}
      />
      {
        !!currentRoster &&
        <GroupDetailsDrawer
          roster={currentRoster}
          isOpen={!!currentRoster}
          onClose={ () => setCurrentRoster(null)}
        />
      }
    </>
  );
};

export default React.memo(GroupsTab);
