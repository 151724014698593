import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {colors} from 'patient-ping-remedy/packages/theme';
import {SmallSemiBoldTypography, StandardTypography} from 'patient-ping-remedy/packages/typography';

export const globalStyles = css`
  body {
    background: ${colors.white};
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

export const Image = styled('img')`
  width: 100%;
  max-width: 600px;
  user-select: none;
`;

export const Heading = styled(SmallSemiBoldTypography)`
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const Content = styled(StandardTypography)`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  gap: 1.6rem;
`;

export const List = styled('ul')`
  margin: 0;
`;

export const Footer = styled('footer')`
  font-size: 1rem;
  margin-top: 3.2rem;
  color: ${colors.gray4};
`;

export const ErrorCenter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorCenterContent = styled('div')`
  margin-right: 10px;
`;
