import React from 'react';
import DeploymentFooter from "../../DeploymentFooter";
import Wrapper from '../../common/pages/Wrapper';
import Header from '../../common/pages/Header';
import ScanRosterTool from "./ScanRosterTool";

const AppSettingsPage = () => {
  return (
    <Wrapper>
      <Header>
        Tools
      </Header>

      <ScanRosterTool />

      <DeploymentFooter />
    </Wrapper>
  );
};

export default AppSettingsPage;
