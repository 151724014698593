import {useEffect, useState} from 'react';
import {QueryKey, useQueryClient} from '@tanstack/react-query';
import {cloneDeep} from 'lodash';
import {router} from '../router';
import {useRosterAwareState} from './useRosterAwareState';
import {useRosterStore} from '../store/roster_store';
import Helpers from '../helpers/helpers';

type Options<T> = {
  queryKey(rosterId: number): QueryKey;
  params: T;
}

export function useFilterQuery<T extends object>({ queryKey, params }: Options<T>) {
  const { currentRoster } = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;
  const queryClient = useQueryClient();

  const [currentRosterParams] = useRosterAwareState(cloneDeep(params));
  const [rosterIdsToInvalidateQueries, setRosterIdsToInvalidateQueries]
    = useState<number[]>([]);

  const previousPathname = router.state.location.pathname;

  useEffect(() => {
    return () => {
      if (previousPathname === router.state.location.pathname) {
        return;
      }

      setTimeout(() => {
        for (const rosterId of rosterIdsToInvalidateQueries) {
          rosterIdRemoveInvalidateQuery(rosterId);

          queryClient.setQueryData(queryKey(rosterId), null);
          queryClient.invalidateQueries({ queryKey: queryKey(rosterId) })
            .then(r => console.log(r));
        }
      });
    };
  }, [router.state.location.pathname, rosterIdsToInvalidateQueries]);

  const rosterIdInvalidateQuery = (rosterId: number) => {
    setRosterIdsToInvalidateQueries([...rosterIdsToInvalidateQueries, rosterId]);
  };

  const rosterIdRemoveInvalidateQuery = (rosterId: number) => {
    setRosterIdsToInvalidateQueries(
      [...rosterIdsToInvalidateQueries.filter((id) => id !== rosterId)]
    );
  };

  const queryFnCallback = (forceInvalidate?: boolean) => {
    const anyFilterIsActive = Object.values(currentRosterParams)
      .some((param) => !Helpers.isEmptyUnknown(param));

    forceInvalidate || anyFilterIsActive
      ? rosterIdInvalidateQuery(currentRosterId)
      : rosterIdRemoveInvalidateQuery(currentRosterId);

    return anyFilterIsActive;
  };

  return {
    params: currentRosterParams,
    queryFnCallback,
  };
}
