import {ICONS} from "patient-ping-remedy/packages/icon";
import React from "react";
import {CreatedBy, CrisisOrganization, Dispatch} from "../../../../../../api/dto/crisis";

export type CrisisLocationType = {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export type CrisisStatusType = {
  displayName: string;
  iconClass: ICONS;
  displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
  ) => React.JSX.Element;
}

export type CrisisStatusMap = {
  [key: string]: CrisisStatusType
}

export const CrisisStatuses = {
  REQUESTED: 'REQUESTED',
  ACCEPTED: 'ACCEPTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  ARRIVED: 'ARRIVED',
  DEPARTED: 'DEPARTED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
  COMPLETED: 'COMPLETED',
  TRANSFER: 'TRANSFER',
  UNKNOWN: 'UNKNOWN',
  OPEN: 'OPEN',
  INTAKE_OPEN: 'OPEN',
  INTAKE_COMPLETED: 'INTAKE_COMPLETED',
  INTAKE_CANCELLED: 'INTAKE_CANCELLED',
  INTAKE_TRANSFERRED: 'INTAKE_TRANSFERRED',
  CALL_STARTED: 'CALL_STARTED',
  CALL_ENDED: 'CALL_ENDED',
};

// these statuses map directly to statuses returned from crisis api
// the intake and call statuses are additional statuses that are not returned from the api
// used for careco only
export const CrisisStatus : CrisisStatusMap = {
  //catch all in case we get a status we don't know about
  UNKNOWN: {
    displayName: 'Unknown',
    iconClass: ICONS["trash-alt"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <><
      span className="event-text-bold">Unknown</span></>,
  },

  // Start mcu_status options
  REQUESTED: {
    displayName: 'Mobile dispatch requested',
    iconClass: ICONS["comment"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch requested </span>
      by {createdBy?.first_name} {createdBy?.last_name + ' '}
      {intakeOrganization?.name ? `at ${intakeOrganization?.name}` : ''}
      <span className="pipe-separator">Mobile dispatch requested</span>
    </>,
  },
  ACCEPTED: {
    displayName: 'Mobile dispatch accepted',
    iconClass: ICONS["comment-check"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch accepted </span>
      by {event?.responders?.at(0)?.user?.fname} {event?.responders?.at(0)?.user?.lname + ' '}
      <span className="pipe-separator">Mobile dispatch accepted</span>
    </>,
  },
  ACKNOWLEDGED: {
    displayName: 'Mobile dispatch acknowledged',
    iconClass: ICONS["comment-plus"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch acknowledged </span>
      by {event?.responders?.at(0)?.user?.fname} {event?.responders?.at(0)?.user?.lname + ' '}
      <span className="pipe-separator">Mobile dispatch acknowledged</span>
    </>,
  },
  ARRIVED: {
    displayName: 'Mobile crisis team arrived',
    iconClass: ICONS["car-garage"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch arrived </span>on scene
      <span className="pipe-separator">Mobile dispatch arrived</span>
    </>,
  },
  DEPARTED: {
    displayName: 'Mobile dispatch departed',
    iconClass: ICONS["car"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch departed </span>
      from crisis location
      <span className="pipe-separator">Mobile dispatch departed</span>
    </>,
  },
  DECLINED: {
    displayName: 'Mobile dispatch declined',
    iconClass: ICONS["comment-minus"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch declined </span>
      by {event?.responders?.at(0)?.user?.fname} {event?.responders?.at(0)?.user?.lname + ' '}
      <span className="pipe-separator">Mobile dispatch declined</span>
    </>,
  },
  CANCELLED: {
    displayName: 'Mobile dispatch canceled',
    iconClass: ICONS["comment-slash"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Dispatch canceled </span>
      {intakeOrganization?.name ? `by ${intakeOrganization?.name}` : ''}
      <span className="pipe-separator">Mobile dispatch canceled</span>
    </>,
  },
  CLOSED: {
    displayName: 'Mobile dispatch closed',
    iconClass: ICONS["trash-alt"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Closed </span>dispatch
      <span className="pipe-separator">Mobile dispatch closed</span>
    </>,
  },
  COMPLETED: {
    displayName: 'Mobile dispatch completed',
    iconClass: ICONS["car"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Completed </span>visit
      <span className="pipe-separator">Mobile dispatch completed</span>
    </>,
  },

  // start intake and call options
  INTAKE_TRANSFER: {
    displayName: 'Intake transferred',
    iconClass: ICONS["trash-alt"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Intake transferred </span>
      <span className="pipe-separator">Crisis intake transferred</span>
    </>,
  },
  INTAKE_COMPLETED: {
    displayName: 'Intake completed',
    iconClass: ICONS["notes-medical"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Intake completed </span>
      <span className="pipe-separator">Crisis intake completed</span>
    </>,
  },
  INTAKE_CANCELLED: {
    displayName: 'Crisis intake canceled',
    iconClass: ICONS["ban"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">
        Crisis intake canceled
        {intakeOrganization?.name ? `by ${intakeOrganization?.name}` : ''}
      </span>
      <span className="pipe-separator">Crisis intake canceled</span>
    </>,
  },
  CALL_STARTED: {
    displayName: 'Call started',
    iconClass: ICONS["phone"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Call started </span>
      by {createdBy?.first_name} {createdBy?.last_name + ' '}
      {intakeOrganization?.name ? `at ${intakeOrganization?.name}` : ''}
      <span className="pipe-separator">Crisis call started</span>
    </>,
  },
  CALL_ENDED: {
    displayName: 'Call ended',
    iconClass: ICONS["phone-slash"],
    displayMessage: (
      event: Dispatch,
      createdBy: CreatedBy,
      intakeOrganization: CrisisOrganization
    ) => <>
      <span className="event-text-bold">Call ended </span>
      by {createdBy?.first_name} {createdBy?.last_name + ' '}
      {intakeOrganization?.name ? `at ${intakeOrganization?.name}` : ''}
      <span className="pipe-separator">Crisis call ended</span>
    </>,
  }
};
