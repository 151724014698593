import {
  EventContainerEventsList,
  EventContainerEventsSession,
  EventContainerEventsStatus,
  EventContainerEventsTime,
  EventContainerEventsWrapper
} from "../../../../../common/timeline/Timeline.styles";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import React from "react";
import ExpandMoreButton from "../../../../../common/timeline/ExpandMoreButton";
import {CrisisStatus} from "./CrisisCard.constants";
import {CreatedBy, CrisisOrganization, Dispatch} from "../../../../../../api/dto/crisis";

type Props = {
  dispatches: Dispatch[],
  createdBy: CreatedBy,
  intakeOrganization: CrisisOrganization,
  active: boolean
}

const CrisisTimeline = (props: Props) => {
  return (
    <EventContainerEventsWrapper>
      <EventContainerEventsSession>
        {
          props.dispatches.slice(0, 3).map((dispatch, index) => {
            let crisisStatus = CrisisStatus[dispatch.mcu_status];

            return (
              <EventContainerEventsList data-testid={`dispatch-${dispatch.id}`} active={props.active} key={index}>
                <EventContainerEventsTime>
                  {DisplayHelpers.formatDate(dispatch.datetime)}
                  <span className="pipe-separator">{DisplayHelpers.formatTime(dispatch.datetime)}</span>
                </EventContainerEventsTime>

                <EventContainerEventsStatus>
                  { crisisStatus.displayMessage(dispatch, props.createdBy, props.intakeOrganization) }
                </EventContainerEventsStatus>
              </EventContainerEventsList>
            );
          })
        }

        {
          props.dispatches.length > 3 &&
          <ExpandMoreButton
            useExpandHideText={true}
            active={props.active}
            buttonText={`${props.dispatches.length - 3} more`}
            expandItems={props.dispatches.slice(3).map((dispatch, index) => {
              let crisisStatus = CrisisStatus[dispatch.mcu_status];

              return (
                <EventContainerEventsList active={props.active} key={index}>
                  <EventContainerEventsTime>
                    {DisplayHelpers.formatDate(dispatch.datetime)}
                    <span className="pipe-separator">{DisplayHelpers.formatTime(dispatch.datetime)}</span>
                  </EventContainerEventsTime>

                  <EventContainerEventsStatus>
                    { crisisStatus.displayMessage(dispatch, props.createdBy, props.intakeOrganization) }
                  </EventContainerEventsStatus>
                </EventContainerEventsList>
              );})
            }
          />
        }
      </EventContainerEventsSession>
    </EventContainerEventsWrapper>
  );
};

export default CrisisTimeline;
