import {fontSize} from "patient-ping-remedy/packages/theme";
import React from "react";
import {ClientProfileResponse, JobStatus, EncounterType} from "../../../../api/dto/client-profile";
import styled from "@emotion/styled";
import TableAccordion from "patient-ping-remedy/packages/table-accordion";
import {DisplaySmallTypography} from "patient-ping-remedy/packages/typography";
import {PatientResponse} from "../../../../api/dto/pdmp";
import {AxiosError} from "axios";

type Props = {
  clientProfile: ClientProfileResponse | undefined;
  pdmpPatientResponse: PatientResponse | Error | undefined;
}

// just leaving styles in the file for now since this file will eventually
// be deleted
const StyledTableAccordion = styled(TableAccordion)`
  background: transparent;
`;

const StyledPreBlock = styled.pre`
  font-size: ${fontSize.small};
  display: block;
  background-color: rgb(229 231 235);
  padding: 5px 10px;
  max-height: 400px;
  overflow-y: scroll;
`;

const RawJson = (props: Props) => {
  const adts = props.clientProfile?.encounters?.filter((encounter) => encounter.type === EncounterType.ADT);
  const referrals = props.clientProfile?.encounters?.filter((encounter) => encounter.type === EncounterType.OPENBEDS_REFERRAL);
  const crisis = props.clientProfile?.encounters?.filter((encounter) => encounter.type === EncounterType.OPENBEDS_CRISIS);
  const sdoh = props.clientProfile?.encounters?.filter((encounter) => encounter.type === EncounterType.FINDHELP_SDOH);
  const ji = props.clientProfile?.encounters?.filter((encounter) => encounter.type === EncounterType.JUSTICE_INVOLVED);

  return (
    <>
      <StyledTableAccordion headerItems={[<DisplaySmallTypography>Demographics</DisplaySmallTypography>]}
        isOpen={true}>
        <StyledPreBlock>
          {JSON.stringify(props.clientProfile?.patientProfileResponse?.patient, null, 2)}
        </StyledPreBlock>
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>Full Client Profile</DisplaySmallTypography>]}
        isOpen={true}>
        <StyledPreBlock>
          {JSON.stringify(props.clientProfile?.patientProfileResponse, null, 2)}
        </StyledPreBlock>
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>
        ADTs ({props.clientProfile?.adtJobResult?.status === JobStatus.Complete
          ? adts?.length
          : props.clientProfile?.adtJobResult?.status})
      </DisplaySmallTypography>]}
      isOpen={true}>
        {adts?.map((adt) => (
          <StyledPreBlock key={adt.content.encounterId}>
            {JSON.stringify(
              adt,
              null,
              2)}
          </StyledPreBlock>
        ))
        }
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>
        Referrals ({props.clientProfile?.openBedsReferralJobResult?.status === JobStatus.Complete
          ? referrals?.length
          : props.clientProfile?.openBedsReferralJobResult?.status})
      </DisplaySmallTypography>]}
      isOpen={true}>
        { referrals?.map((referral) => (
          <StyledPreBlock key={referral.content.uuid}>
            {JSON.stringify(
              referral,
              null,
              2)}
          </StyledPreBlock>
        ))
        }
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>
        Crisis ({props.clientProfile?.openBedsCrisisJobResult?.status === JobStatus.Complete
          ? crisis?.length
          : props.clientProfile?.openBedsCrisisJobResult?.status})
      </DisplaySmallTypography>]}
      isOpen={true}>
        { crisis?.map((crisis) => (
          <StyledPreBlock key={crisis.content.uuid}>
            {JSON.stringify(
              crisis,
              null,
              2)}
          </StyledPreBlock>
        ))
        }
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>
        SDOH ({props.clientProfile?.findHelpSdohJobResult?.status === JobStatus.Complete
          ? sdoh?.length : props.clientProfile?.findHelpSdohJobResult?.status})
      </DisplaySmallTypography>]}
      isOpen={true}>
        { sdoh?.map((sdoh) => (
          <StyledPreBlock key={sdoh.content.referralId}>
            {JSON.stringify(
              sdoh,
              null,
              2)}
          </StyledPreBlock>
        ))
        }
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>
        Justice Involved ({props.clientProfile?.justiceInvolvedJobResult?.status === JobStatus.Complete
          ? ji?.length : props.clientProfile?.justiceInvolvedJobResult?.status})
      </DisplaySmallTypography>]}
      isOpen={true}>
        { ji?.map((ji) => (
          <StyledPreBlock key={ji.content.id}>
            {JSON.stringify(
              ji,
              null,
              2)}
          </StyledPreBlock>
        ))
        }
      </StyledTableAccordion>

      <StyledTableAccordion headerItems={[<DisplaySmallTypography>PDMP Client Response</DisplaySmallTypography>]}
        isOpen={true}>
        <StyledPreBlock>
          {JSON.stringify(
            props.pdmpPatientResponse instanceof AxiosError ?
              props.pdmpPatientResponse.message + ": " + props.pdmpPatientResponse.response?.data :
              props.pdmpPatientResponse,
            null,
            2
          )}
        </StyledPreBlock>
      </StyledTableAccordion>
    </>
  );
};

export default RawJson;
