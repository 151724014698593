import React, {useMemo} from "react";
import {ICONS} from "patient-ping-remedy/packages/icon";
import JusticeTimeline from "./JusticeTimeline";
import { StyledHandcuffIcon,} from "./JusticeInvolvedCard.styles";
import JusticeAgencyAddress from "./JusticeAgencyAddress";
import Helpers from "../../../../../../helpers/helpers";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import {
  EventContainer,
  EventContainerEvents,
  EventCurrentStatus,
  EventDetails, EventLowerSection1, EventLowerSection1Content, EventSectionHeader,
  EventType,
  TimelineContentWrapper,
  TimelineDate,
  TimelineEventWrapper
} from "../../../../../common/timeline/Timeline.styles";
import MainEventIcon from "../../../../../common/timeline/MainEventIcon";
import LOSIndicator from "../../../../../common/timeline/LOSIndicator";
import justiceInvolvedIcon from "../../../../../../assets/icons/Custom-Icon-JI.svg";
import {JusticeInvolvedItem} from "../../../../../../api/dto/justice-involved";

type Props = {
  id: string;
  event: JusticeInvolvedItem,
}

const JusticeInvolvedCard = ({id, event} : Props) => {
  const [recentDateTime, setRecentDateTime] = React.useState<Date>(new Date());

  const lastEvent = useMemo(() => {
    if(event.released && Helpers.isDefined(event.releaseDateTime)) {
      setRecentDateTime(new Date(event.releaseDateTime!));
      return  "Released";
    }else if(Helpers.isDefined(event.bookingDateTime)) {
      setRecentDateTime(new Date(event.bookingDateTime!));
      return "Booked";
    }else if(Helpers.isDefined(event.arrestDateTime)) {
      setRecentDateTime(new Date(event.arrestDateTime!));
      return "Arrested";
    }
  },[event]);

  const active = useMemo(() =>
    !event.released || !Helpers.isDefined(event.releaseDateTime)
  , [event]);

  const eventYear = recentDateTime.getFullYear();
  const eventDate = DisplayHelpers.getDateMonthDay(recentDateTime.toString());

  const chargeDescription = useMemo(() => {
    return event.charges
      .filter(charge => Helpers.isDefined(charge.cleanDescription))
      .map(charge => DisplayHelpers.humanize(charge.cleanDescription))
      .join(", ");
  },[event.charges]);

  return (
    <div id={id}>
      <TimelineEventWrapper>
        <TimelineDate>
          <span className="timeline__event__date-year">{eventYear}</span>
          <span className="timeline__event__date-date">{eventDate}</span>
        </TimelineDate>
        <TimelineContentWrapper>
          <EventContainer active={active}>
            <MainEventIcon active={active} iconAlt={'justice-involved-icon'} iconSvg={justiceInvolvedIcon} />
            <EventDetails>
              <EventType>Justice Involved</EventType>
              <JusticeAgencyAddress
                detailsHeaderText={'Source'}
                agency={event.sourceFacility}
                active={active}
              />
              <JusticeAgencyAddress
                detailsHeaderText={'Arresting Agency'}
                agency={event.arrestingAgency}
                active={active}
              />
              <JusticeAgencyAddress
                detailsHeaderText={'Holding Facility'}
                agency={event.holdingFacility}
                active={active}
              />
            </EventDetails>
            <EventContainerEvents active={active}>
              <EventCurrentStatus>
                {lastEvent === "Released" ? (
                  <LOSIndicator
                    message={""}
                    iconMessage={lastEvent}
                    iconClass={ICONS.walking}
                    active={active}
                  />) : (
                  <LOSIndicator
                    message={""}
                    iconMessage={lastEvent!}
                    active={active}
                    iconSvg={<StyledHandcuffIcon />}
                  />
                )}
              </EventCurrentStatus>
              <JusticeTimeline events={event} active={active}/>
              {Helpers.isDefined(chargeDescription) && !Helpers.isEmpty(chargeDescription) &&
                <EventLowerSection1>
                  <EventSectionHeader>Charge description:</EventSectionHeader>
                  <EventLowerSection1Content>
                    {chargeDescription}
                  </EventLowerSection1Content>
                </EventLowerSection1>
              }
            </EventContainerEvents>
          </EventContainer>
        </TimelineContentWrapper>
      </TimelineEventWrapper>
    </div>
  );
};

export default JusticeInvolvedCard;
