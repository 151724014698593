import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const ClientNameLink = styled('span')`
  cursor: pointer;

  & > span {
    color: ${colors.blue4};
  }
`;

export { ClientNameLink };
