import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import {LabelTypography} from "patient-ping-remedy/packages/typography";

interface StyledDemographicHeaderProps {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  whiteSpace?: string;
}

const StyledDemographicHeaderComponent = styled(LabelTypography)<StyledDemographicHeaderProps>`
  font-size: ${(props) => props.fontSize || "1.4rem"};
  font-weight: ${(props) => props.fontSize || 400};
  color: ${(props) => props.color || colors.charcoal};
  line-height: ${(props) => props.lineHeight || "1.8rem"};
  white-space: ${(props) => props.whiteSpace || "normal"};
`;

export default StyledDemographicHeaderComponent;
