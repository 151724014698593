import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {useRosterStore} from "../../../../store/roster_store";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import CarecoApi from "../../../../api/careco-api";
import {ClientEncounter, ClientProfileResponse, JobStatus} from "../../../../api/dto/client-profile";
import TableAccordion from "patient-ping-remedy/packages/table-accordion";
import ClientDemographicHeader from "./ClientDemographicHeader";
import ClientDemographicContent from "./ClientDemographicContent";
import Wrapper from "../../../common/pages/Wrapper";
import {StyledClientDemographicHeader} from "./ClientProfilePage.styles";
import TabsComponent from "../../../common/TabsComponent";
import ClientSummaryTab from "./summary_tab/ClientSummaryTab";
import RawJson from "./RawJson";
import RosterHelpers from "../../../../helpers/roster_helpers";
import FormsTab from "./forms_tab/FormsTab";
import PermissionsHelpers from "../../../../helpers/permissions_helpers";
import Helpers from "../../../../helpers/helpers";
import {useAuthStore} from "../../../../store/auth_store";
import PdmpTab from "./pmp_tab/PdmpTab";
import {useAlertStore} from "../../../../store/alert_store";
import {PATIENT_NOT_FOUND} from "../manual_encounter/constants/BannerMessages";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {router} from "../../../../router";

const ClientProfilePage = () => {
  const [searchParams] = useSearchParams();
  const {carecoApi} = useContext(CarecoApiContext);
  const {currentRoster, setCurrentRoster} = useRosterStore();
  const {addAlert} = useAlertStore();
  const queryClient = useQueryClient();
  const { currentUser } = useAuthStore();
  const [inView, setInView]= useState(false);
  const tabsRef = useRef();
  const [horizontalInView, setHorizontalInView] = useState(false);

  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const [clientProfile, setClientProfile] = useState<ClientProfileResponse>();
  const [isDemographicOpen, setIsDemographicOpen] = useState(true);

  const completeEvents = useRef<ClientEncounter[]>([]);
  const incompleteEvents = useRef<ClientEncounter[]>([]);
  const [pageNum, setPageNum] = useState(0);

  const patientId = searchParams.get('p');
  const rosterId = searchParams.get('r');

  const { data: rosters } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const { data: pdmpPatientResponse, isLoading: pdmpDataLoading, error: pdmpError } = useQuery({
    queryKey: ['pdmp', 'patientResponse', patientId],
    queryFn: () => carecoApi?.getPatientRequest(
        currentRoster!.id,
        patientId!
    ),
    enabled: !!carecoApi &&
        !!currentRoster &&
        !!patientId &&
        PermissionsHelpers.canForUserDataRoleRoster(
            currentUser!,
            currentRoster!,
            Helpers.getAppDataRoleDetails().levels.READ,
            Helpers.getAppDataRoleDetails().permissions.PDMP
        )
  });

  const forceRefresh = async () => {
    await pollApi(carecoApi!, patientId!, currentRoster?.id!, 0, true);
  };

  const tabs = [
    {
      value: 0,
      label: 'Summary',
      content: <ClientSummaryTab
        patient={clientProfile?.patientProfileResponse!}
        pdmp={{
          pdmpPatientResponse: pdmpPatientResponse,
          error: pdmpError,
          isLoading: pdmpDataLoading,
          tabsRef: tabsRef,
          pdmpTabIndex: 2
        }}
        encounters={completeEvents.current.concat(incompleteEvents.current)}
        isLoading={loading}
        isPolling={polling}
        setInView={setInView}
        horizontalInView={horizontalInView}
        setHorizontalInView={setHorizontalInView}
        forceRefresh={forceRefresh}
        openDemographics={() => setIsDemographicOpen(false)}
      />
    },
    {
      value: 1,
      label: 'Forms',
      content: <FormsTab patientId={patientId!}/>,
      hidden: !PermissionsHelpers.canForUserFeatureRoleRoster(
        currentUser!,
        currentRoster!,
        Helpers.getAppFeatureRoleDetails().levels.VIEW,
        Helpers.getAppFeatureRoleDetails().permissions.FORMS)
    },
    {
      value: 2,
      label: 'PMP',
      content: <PdmpTab patient={clientProfile?.patientProfileResponse!}/>,
      hidden: !PermissionsHelpers.canForUserDataRoleRoster(
        currentUser!,
        currentRoster!,
        Helpers.getAppDataRoleDetails().levels.READ,
        Helpers.getAppDataRoleDetails().permissions.PDMP)
    },
    {
      value: 3,
      label: 'Raw JSON',
      content: <RawJson clientProfile={clientProfile} pdmpPatientResponse={pdmpError ? pdmpError : pdmpPatientResponse} />,
      hidden: !['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')
    }
  ];

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ['pdmp'],
        exact: false
      });
    };
  }, []);

  useEffect(() => {
    const roster = RosterHelpers.getRosterById(rosters, rosterId);
    if(roster) setCurrentRoster(roster);
  }, [rosters, setCurrentRoster, rosterId]);

  useEffect(() => {
    if (polling) {
      return;
    }

    if ((inView || horizontalInView) && pageNum > 0 && pageNum < 7) {
      fetchNextPage();
    } else {
      setLoading(false);
      setHorizontalInView(false);
    }
  }, [inView, horizontalInView, polling]);

  const fetchNextPage = async () => {
    pollApi(carecoApi!, patientId!, currentRoster?.id!, pageNum); //
  };

  const pollApi = async (
    carecoApi : CarecoApi,
    patientId: string,
    rosterId: number,
    pageNum: number,
    forceRefresh: boolean = false
  ) => {
    let clientProfileResponse : ClientProfileResponse;

    setLoading(true);
    setPolling(true);

    if(forceRefresh) {
      completeEvents.current = [];
      incompleteEvents.current = [];
      await carecoApi.getClientProfile(patientId, rosterId, true, pageNum, forceRefresh);
    }

    do {
      clientProfileResponse = await carecoApi.getClientProfile(patientId, rosterId, true, pageNum, false);

      if (!clientProfile) {
        setClientProfile(clientProfileResponse);
      }

      if (clientProfileResponse.encounters) {
        // When we're polling for events beyond the first "page" (i.e. when the user scrolls down), we keep the returned
        // events separate from any previous pages we had loaded. This is necessary because the events on the new page
        // may be reordered when each data source comes back with events.
        incompleteEvents.current = clientProfileResponse.encounters;
      }

      await new Promise(r => setTimeout(r, process.env.REACT_APP_CLIENT_PROFILE_POLL_DELAY));
    } while ([JobStatus.InProgress, JobStatus.Retrying].includes(clientProfileResponse.status));

    if (clientProfileResponse.patientProfileJobResult?.status === JobStatus.Failed
      && clientProfileResponse.patientProfileJobResult?.apiResultStatusCode === 404) {
      router.navigate('/clients').then(() => {
        addAlert({content: PATIENT_NOT_FOUND, type: ToastType.INFO});
        return;
      });
    }

    if (clientProfileResponse.encounters) {
      let completeEventsTemp = completeEvents.current.concat(clientProfileResponse.encounters);
      completeEvents.current = completeEventsTemp;
      setClientProfile({
        ...clientProfileResponse,
        encounters: completeEventsTemp
      });
    }

    incompleteEvents.current = [];
    setHorizontalInView(false);
    setPolling(false);
    setPageNum(pageNum + 1);
  };

  useEffect(() => {
    if (!(carecoApi && patientId && currentRoster)) {
      return;
    }

    pollApi(carecoApi, patientId, currentRoster.id, pageNum);
  }, [carecoApi, currentRoster, patientId]);

  return (
    <>
      <StyledClientDemographicHeader id={'client-demographics'}>
        <TableAccordion
          className="client-demographic-header"
          isOpen={isDemographicOpen}
          headerItems={
            ClientDemographicHeader(
              {ClientProfileResponse: clientProfile}
            )
          }
          handleClick={() => setIsDemographicOpen(prevState => !prevState)}
        >
          <ClientDemographicContent ClientProfileResponse={clientProfile} />
        </TableAccordion>
      </StyledClientDemographicHeader>

      {
        clientProfile &&
        <Wrapper>
          <TabsComponent ref={tabsRef} tabs={tabs} forceRender={false}/>
        </Wrapper>
      }
    </>
  );
};

export default ClientProfilePage;
