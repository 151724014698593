import React from 'react';
import SafePureComponent from "./SafePureComponent";
import {formatDate} from "../utils/dateFormatters";

class PingDateText extends SafePureComponent {
  safeRender() {
    const dateFormat = this.props.facilityType === 'HOS' ? 'M/D/YY [at] h:mm A' : 'M/D/YY';
    return (
      <span className="ping-date-text" data-testid="ping-date-text">
        on {formatDate(this.props.date, dateFormat)}
      </span>
    );
  }
}

export default PingDateText;
