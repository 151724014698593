import React, {useContext} from "react";
import {
  DisplaySmallTypography,
  StandardTypography
} from "patient-ping-remedy/packages/typography";
import {
  NotificationPageLink,
  NotificationPopupHeader,
  NotificationPopupRightHeader
} from "./NotificationPopover.styles";
import Icon from "patient-ping-remedy/packages/icon";
import {css} from "@emotion/css";
import {colors} from "patient-ping-remedy/packages/theme";
import Spacer from "patient-ping-remedy/packages/spacer";
import {useQuery} from "@tanstack/react-query";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {useRosterStore} from "../../../../store/roster_store";
import NotificationPopoverContent from "./NotificationPopoverContent";
import {useAuthStore} from "../../../../store/auth_store";
import {router} from "../../../../router";
import NotificationOnlyShowUnreadSwitch from "../NotificationOnlyShowUnreadSwitch";

type Props = {
  isOpen: boolean;
}

const NotificationPopover = (props: Props) => {
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentUser} = useAuthStore();
  const { currentRoster } = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;

  const {
    data: currentFetchedUser,
  } = useQuery({
    queryKey: ['currentUser', currentUser?.id],
    queryFn: () => {
      return carecoApi?.getUser(currentUser?.id!);
    },
    enabled: !!currentUser?.id && !!carecoApi
  });

  return (
    <>
      {!currentRoster && <StandardTypography> No group selected </StandardTypography>}
      { currentRoster && currentFetchedUser &&
          <>
            <NotificationPopupHeader>
              <DisplaySmallTypography>Notifications</DisplaySmallTypography>
              <NotificationPopupRightHeader>
                <NotificationOnlyShowUnreadSwitch currentFetchedUser={currentFetchedUser} />
                <Spacer itemWidth={'1rem'} />
                <NotificationPageLink onClick={() => router.navigate('/notifications')}>
                  <Icon
                    iconClass={'tasks'}
                    className={css({fontSize: '20px'})}
                    color={colors.charcoal}
                  />
                </NotificationPageLink>

              </NotificationPopupRightHeader>
            </NotificationPopupHeader>
            <Spacer itemHeight={'10px'} />

            <NotificationPopoverContent
              currentRosterId={currentRosterId}
              onlyShowUnreadNotifications={currentFetchedUser.only_show_unread_notifications}
            />
          </>
      }
    </>
  );
};

export default NotificationPopover;
