import styled from '@emotion/styled';
import {colors, fontSize} from 'patient-ping-remedy/packages/theme';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';

export const iconStyle = {
  height: '24px',
  width: '24px',
  zIndex: 1,
};

type StyledButtonProps = {
  disabled?: boolean;
  overflowBackground?: boolean;
  propsStyleType?: StyleType;
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  {
    position: 'relative',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  },
  (props) => ({
    ...(props.disabled ? {
      cursor: 'not-allowed',
    } : {
      cursor: 'pointer',
      '&:hover': {
        background: props.propsStyleType === StyleType.TERTIARY ? colors.gray1 : colors.purple1,
      },
    }),
    ...(props.overflowBackground ? {
      padding: '8px',
      margin: '-8px',
    } : {
      padding: 0,
      margin: 0,
    }),
  }),
);

export const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  padding: 0 4px;
  border-radius: 100px;
  font-size: ${fontSize.hint};
  color: ${colors.white};
  background: ${colors.red5};
  z-index: 2;
`;
