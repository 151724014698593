import React from 'react';
import {StandardTypography} from "patient-ping-remedy/packages/typography";
import {ClientNameLink} from "./ClientNameLinkComponent.styles";
import {useRosterStore} from "../../../store/roster_store";
import {useAuthStore} from "../../../store/auth_store";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import Helpers from "../../../helpers/helpers";
import DisplayHelpers from "../../../helpers/display_helpers";
import {Client} from "../../../api/dto/client-profile";

type ClientNameLinkProps = {
  client: Client;
  testId?: string;
  handleClick: Function;
};

function ClientNameLinkComponent(clientNameLinkProps: ClientNameLinkProps) {
  const { client, testId, handleClick } = clientNameLinkProps;
  const { currentRoster } = useRosterStore();
  const { currentUser } = useAuthStore();

  const clientName = DisplayHelpers.formatName(
    client.firstName || '',
    client.middleName || '',
    client.lastName || '',
  );

  const clientNameLink = PermissionsHelpers.canForUserFeatureRoleRoster(
    currentUser!,
    currentRoster!,
    Helpers.getAppFeatureRoleDetails().levels.VIEW,
    Helpers.getAppFeatureRoleDetails().permissions.CLIENT_PROFILE
  ) ? (
      <ClientNameLink
        data-testid={testId}
        onClick={() => handleClick()}
      >
        <StandardTypography>{clientName}</StandardTypography>
      </ClientNameLink>
    ) : (
      clientName
    );

  return <>{clientNameLink}</>;
}

export default ClientNameLinkComponent;
