import styled from '@emotion/styled';
import {colors} from 'patient-ping-remedy/packages/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem 0;
`;

export const AssignedSection = styled.div`
  border-bottom: 1px solid ${colors.gray2};
  padding: 0 2rem 1rem 2rem;
  margin-bottom: 1rem;
`;
