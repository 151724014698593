import {ClientProfile} from "../../../../../../api/dto/client-profile";
import React, {useMemo} from "react";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import LOSIndicator from "../../../../../common/timeline/LOSIndicator";
import {
  EventContainer,
  EventContainerEvents,
  EventCurrentStatus,
  EventDetails,
  EventType, ExpandEventDetailsSectionDetails,
  TimelineContentWrapper,
  TimelineDate,
  TimelineEventWrapper
} from "../../../../../common/timeline/Timeline.styles";
import Helpers from "../../../../../../helpers/helpers";
import DetailsSection from "../../../../../common/timeline/DetailsSection";
import MainEventIcon from "../../../../../common/timeline/MainEventIcon";
import referralIcon from "../../../../../../assets/icons/Custom-Icon-Referral.svg";
import ExpandMoreButton from "../../../../../common/timeline/ExpandMoreButton";
import {ReferralDetails} from "../../../../../../api/dto/referral";
import ReferralTimeline from "./ReferralTimeline";
import {ReferralStatus} from "./ReferralCard.constants";
import ReferralHelpers from "helpers/referral_helpers";
import EventStatus from "../../../../../common/timeline/EventStatus";
import Part2Disclaimer from "../../../../../common/Part2Disclaimer";
import {StyleType} from "patient-ping-remedy/packages/button";

type Props = {
  id: string;
  referrals: ReferralDetails[],
  isPart2?: boolean,
  patient: ClientProfile,
}

const ReferralCard = (props: Props) => {
  const sortedReferrals = useMemo(() => {
    return ReferralHelpers.getSortedReferrals(props.referrals);
  }, [props.referrals]);

  const eventYear = useMemo(() => new Date(sortedReferrals.at(-1)!.created_at).getFullYear(),[sortedReferrals]);
  const eventDate = useMemo(() => DisplayHelpers.getDateMonthDay(sortedReferrals.at(-1)!.created_at), [sortedReferrals]);

  const acceptedReferral = useMemo(() => {
    return ReferralHelpers.getAcceptedReferral(sortedReferrals);
  }, [sortedReferrals]);

  const activeReferrals = useMemo(() => {
    return ReferralHelpers.getActiveReferrals(sortedReferrals, acceptedReferral);
  }, [sortedReferrals]);

  const inactiveReferrals = useMemo(() => {
    return ReferralHelpers.getInactiveReferrals(sortedReferrals, acceptedReferral);
  }, [sortedReferrals]);

  const declinedReferrals = useMemo(() => {
    return ReferralHelpers.getDeclinedReferrals(inactiveReferrals);
  }, [inactiveReferrals]);

  const closedReferrals = useMemo(() => {
    return ReferralHelpers.getClosedReferrals(inactiveReferrals);
  }, [inactiveReferrals]);

  const overallCardStatus = useMemo(() => {
    return ReferralHelpers.getOverallEncounterStatus(sortedReferrals, declinedReferrals, closedReferrals, acceptedReferral);
  }, [sortedReferrals, acceptedReferral, declinedReferrals, closedReferrals]);

  const isEventActive = useMemo(() => {
    return ReferralHelpers.isEncounterActive(overallCardStatus);
  }, [overallCardStatus]);

  const lengthOfReferral = useMemo(() => {
    if(isEventActive) {
      return 'Ongoing: ';
    }

    let startDate = new Date(sortedReferrals.at(-1)!.created_at);
    let endDate = new Date(sortedReferrals.at(0)!.updated_at);

    let days = Helpers.calculateTimeDifference(startDate, endDate);
    return `Time ${days} ${days > 1 ? 'days' : 'day'}: `;
  }, [props.referrals]);

  const receivingContactStatus = useMemo(() => {
    if(closedReferrals.length === sortedReferrals.length) {
      return ReferralStatus.Closed;
    }

    if(declinedReferrals.length === sortedReferrals.length) {
      return ReferralStatus.Declined;
    }

    if(acceptedReferral) {
      return ReferralStatus.Accepted;
    }

    return ReferralStatus.Opened;
  }, [acceptedReferral, closedReferrals, sortedReferrals]);

  const acceptedReceivingContactContent = useMemo(() => {
    if(receivingContactStatus === ReferralStatus.Accepted && acceptedReferral) {
      return (
        <div key={`referral-${acceptedReferral.id}`}>
          <div>{acceptedReferral.sent_to_service.organization.name}</div>
          <div>{acceptedReferral.sent_to_service.address?.street}</div>
          <div>
            {acceptedReferral.sent_to_service.address?.city + ', '}
            {acceptedReferral.sent_to_service.address?.state + ' '}
            {acceptedReferral.sent_to_service.address?.zip}
          </div>
          <div>{acceptedReferral.sent_to_service?.contact_info?.phone}</div>
        </div>
      );
    }
  }, [receivingContactStatus, acceptedReferral]);

  const receivingContactReferrals = useMemo(() => {
    // include all referrals in receiving contact section if the overall status is closed or declined
    if(overallCardStatus === ReferralStatus.Closed || overallCardStatus === ReferralStatus.Declined) {
      return sortedReferrals;
    }

    return activeReferrals;
  }, [overallCardStatus, sortedReferrals, activeReferrals]);

  return (
    <div id={props.id}>
      <TimelineEventWrapper>
        <TimelineDate>
          <span className="timeline__event__date-year">{eventYear}</span>
          <span className="timeline__event__date-date">{eventDate}</span>
        </TimelineDate>

        <TimelineContentWrapper>
          <EventContainer active={isEventActive}>
            <MainEventIcon active={isEventActive} iconAlt={'referral-icon'} iconSvg={referralIcon}/>
            <EventDetails>
              <EventType> Referral </EventType>
              <DetailsSection detailsHeaderText={'Receiving contact'}
                hasDetails={true}>
                <EventStatus
                  iconMessage={receivingContactStatus.displayName}
                  iconClass={receivingContactStatus.iconClass}
                />
                {
                  acceptedReceivingContactContent ?
                    acceptedReceivingContactContent :
                    receivingContactReferrals &&
                      receivingContactReferrals.map((referral: ReferralDetails) => (
                        <div key={`referral-${referral.id}`}>
                          <ExpandMoreButton buttonText={referral.sent_to_service.organization.name}
                            expandItems={[
                              <ExpandEventDetailsSectionDetails>
                                <div>{referral.sent_to_service.address?.street}</div>
                                <div>
                                  {referral.sent_to_service.address?.city + ', '}
                                  {referral.sent_to_service.address?.state+ ' '}
                                  {referral.sent_to_service.address?.zip}
                                </div>
                                <div>{referral.sent_to_service?.contact_info?.phone}</div>
                              </ExpandEventDetailsSectionDetails>
                            ]}
                            active={isEventActive}
                            useExpandHideText={false}
                            renderBelow={true}
                          />
                        </div>
                      ))
                }
              </DetailsSection>
              {
                receivingContactStatus !== ReferralStatus.Closed &&
                receivingContactStatus !== ReferralStatus.Declined &&
                inactiveReferrals &&
                inactiveReferrals.length > 0 &&
                <DetailsSection detailsHeaderText={'Referral not accepted'}
                  hasDetails={Helpers.isDefined(inactiveReferrals) && inactiveReferrals.length > 0}
                  lowerSection={true}
                >
                  <>
                    {
                      inactiveReferrals.map((referral: ReferralDetails) => (
                        <div key={`inactive-referral-${referral.id}`}>
                          <ExpandMoreButton buttonText={referral.sent_to_service.organization.name}
                            expandItems={[
                              <ExpandEventDetailsSectionDetails>
                                <EventStatus
                                  iconMessage={ReferralStatus[referral.accepted_status ?? referral.status].displayName}
                                  iconClass={ReferralStatus[referral.accepted_status ?? referral.status].iconClass}
                                />
                                <div>{referral.sent_to_service.address?.street}</div>
                                <div>
                                  {referral.sent_to_service.address?.city + ', '}
                                  {referral.sent_to_service.address?.state + ' '}
                                  {referral.sent_to_service.address?.zip}
                                  <div>{referral.sent_to_service?.contact_info?.phone}</div>
                                </div>
                              </ExpandEventDetailsSectionDetails>
                            ]}
                            active={isEventActive}
                            useExpandHideText={false}
                            renderBelow={true}
                          />
                        </div>
                      ))
                    }
                  </>
                </DetailsSection>
              }
            </EventDetails>

            <EventContainerEvents active={isEventActive}>
              <EventCurrentStatus>
                <LOSIndicator
                  message={lengthOfReferral}
                  iconClass={overallCardStatus.iconClass}
                  active={isEventActive}
                  iconMessage={overallCardStatus.displayName}
                />
              </EventCurrentStatus>

              <ReferralTimeline
                active={isEventActive}
                acceptedReferral={acceptedReferral}
                referrals={sortedReferrals}
                overallCardStatus={overallCardStatus}
              />
            </EventContainerEvents>
            { props.isPart2 && <Part2Disclaimer styleType={isEventActive ? StyleType.PRIMARY : StyleType.TERTIARY} /> }
          </EventContainer>
        </TimelineContentWrapper>
      </TimelineEventWrapper>
    </div>
  );
};

export default ReferralCard;
