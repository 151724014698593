import {useState} from 'react';
import {useRosterStore} from '../store/roster_store';

type Dispatch<T> = (value: T, rosterId?: number) => void;

export function useRosterAwareState<T>(value: T): [T, Dispatch<T>] {
  const { currentRoster } = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;

  const [state, setState] = useState<Record<number, T>>({});
  const currentState = state[currentRosterId] ?? value;

  const dispatchState = (value: T, rosterId: number = currentRosterId) => {
    setState({ ...state, [rosterId]: value });
  };

  return [
    currentState,
    dispatchState,
  ];
}
