import NotificationPopover from "../pages/notifications/./popover/NotificationPopover";
import Popover from "patient-ping-remedy/packages/popover";
import {css} from "@emotion/css";
import {useState} from "react";
import NotificationBellButton from "../pages/notifications/popover/NotificationBellButton";

export default function AlertsWidget() {
  const [showPopover, setShowPopover] = useState(false);

  const content = (
    <div
      className={css({width: '550px', padding: '10px'})}
    >
      <NotificationPopover isOpen={showPopover}/>
    </div>
  );

  return (
    <>
      <div>
        <Popover
          toggle={() => setShowPopover(!showPopover)}
          isOpen={showPopover}
          position={"bottom"}
          triggerTestId={"notification-popover"}
          useContext={true}
          content={content}
        >
          <NotificationBellButton />
        </Popover>
      </div>
    </>
  );
}
