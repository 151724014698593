import {SmallTypography} from "patient-ping-remedy/packages/typography";
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const StyledSmallTypography = styled(SmallTypography)`
  display: block;
  margin-bottom: ${sizes.small};
  & > span {
    color: ${colors.red5};
  }
`;

const StyledSearchRow = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  & > button {
    margin-top: 2.6rem;
  }  
`;

export {StyledSmallTypography, StyledSearchRow};
