export const ADMITTED = 'Admitted';
export const PRESENTED = 'Presented';
export const VISIT = 'Visit';
export const DISCHARGED = 'Discharged';
export const DECEASED = 'Deceased';
export const TRANSFERRED = 'Transferred';
export const CLOSED = 'Closed';
export const PAYOR_CHANGED = 'Payor Change';
export const MEDICAL_LEAVE_OF_ABSENCE = 'Medical LOA';
export const RESUMED_SERVICES = 'Resumed Services';
export const ENDED_SERVICES = 'Ended Services';
export const STARTED_HHA_SERVICES = 'Started HHA Services';
export const ENDED_HHA_SERVICES = 'Ended HHA Services';
export const MENTIONS_COVID_19 = 'MENTIONS_COVID_19';
export const NO_CURRENT_STATUS = 'No Current Status';

export const PingTags = {
  [ADMITTED]: 'ADMIT',
  [DISCHARGED]: 'DISCHARGE',
  [DECEASED]: 'DECEASED',
  [CLOSED]: 'CLOSED',
  [TRANSFERRED]: 'TRANSFER',
  [PRESENTED]: 'PRESENTED',
  [VISIT]: 'VISIT',
  [PAYOR_CHANGED]: 'PAYOR_CHANGE',
  [MEDICAL_LEAVE_OF_ABSENCE]: 'MEDICAL_LEAVE_OF_ABSENCE',
  [RESUMED_SERVICES]: 'RESUMED_SERVICES',
  [ENDED_SERVICES]: 'ENDED_SERVICES',
  [STARTED_HHA_SERVICES]: 'STARTED_HHA_SERVICES',
  [ENDED_HHA_SERVICES]: 'ENDED_HHA_SERVICES',
};

export const PingStatuses ={
  ADMITTED,
  PRESENTED,
  VISIT,
  DISCHARGED,
  DECEASED,
  TRANSFERRED,
  CLOSED,
  PAYOR_CHANGED,
  MEDICAL_LEAVE_OF_ABSENCE,
  RESUMED_SERVICES,
  ENDED_SERVICES,
  STARTED_HHA_SERVICES,
  ENDED_HHA_SERVICES,
};
