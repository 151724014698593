import {PropsWithChildren, DetailedHTMLProps, HTMLAttributes} from 'react';
import {Button, StyledMenuItem} from './MenuItem.styles';

type Props = PropsWithChildren & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  disabled?: boolean;
}

// chopsticks: https://zeroheight.com/5bcb53d99/p/177dd9-menu-item
export default function MenuItem(props: Props) {
  return (
    <StyledMenuItem>
      <Button
        role="menuitem"
        onClick={!props.disabled ? props.onClick : undefined}
        {...props}
      >
        {props.children}
      </Button>
    </StyledMenuItem>
  );
}
