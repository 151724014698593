import React from "react";
import SlideInPane from "patient-ping-remedy/packages/slide-in-pane";
import FormPDFViewer from "./FormPDFViewer";
import Loading from "../../../../common/Loading";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {SlidePaneFormProps} from "./FormsTab";

type Props = {
  isOpen: boolean;
  close: Function;
  form: SlidePaneFormProps | undefined;
  error: Error | null;
}

const FormDetailsDrawer = (props: Props) => {
  const isLoading = !props.error && props.isOpen && !props.form;

  return (
    <>
      <SlideInPane
        onClose={props.close}
        isOpen={props.isOpen}
        isOverlayOpen={props.isOpen}
        showNav={false}
        headerText={props.form?.form.form_upload?.name ?? ''}
        buttons={[]}
        width={'80%'}
      >
        {isLoading && <Loading />}
        {props.error && <Banner type={BannerTypes.PATIENT_SAFETY}>Error loading file.</Banner>}
        {
          props.form?.form.form_upload && <FormPDFViewer id={`${props.form.form.id}-pdf-viewer`} url={props.form.formPdfUrl!} />
        }
        {
          props.form && !props.form.form.form_upload &&
          <p>NOT IMPLEMENTED YET</p>
        }
      </SlideInPane>
    </>
  );
};

export default FormDetailsDrawer;
