import {useAuthStore} from '../store/auth_store';
import {Client} from '../api/dto/dto';

export function useClientUserAssignments(client: Client) {
  const { currentUser} = useAuthStore();

  const userAssignmentsWithoutSelf = client.userAssignments
    .filter((userAssignment) => userAssignment.user.id !== currentUser?.id);

  const assignedToSelf = userAssignmentsWithoutSelf.length !== client.userAssignments.length;

  return {
    userAssignmentsWithoutSelf,
    assignedToSelf,
  };
}
