import React, {Fragment, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form/dist/index.ie11';

import Input from 'patient-ping-remedy/packages/input';
import DropdownList from '../../../../../pings/dropdown_list/DropdownList';
import DatePicker from 'patient-ping-remedy/packages/date-picker';
import {sizes} from 'patient-ping-remedy/packages/theme';
import Spacer from 'patient-ping-remedy/packages/spacer';
import Genders from "../../constants/Genders";
import {StyledGenderContainer, StyledRow, StyledSSNInput} from "../../encounter_client_search/PatientInformationForm.styles";

const { MALE, FEMALE, NON_BINARY, OTHER } = Genders;
const genderOptions = [MALE, FEMALE, NON_BINARY, OTHER].map((gender) => ({ value: gender, label: gender }));

const requiredField = 'Required field';
const invalidValue = 'Invalid value';

const PatientDemographics = ({
  disabled = false,
  showAll = true,
  isWithinModal = true,
  focusOnLoad = true,
  featureName = 'PatientAdmit',
}) => {
  const firstNameRef = useRef();
  const { register, control, errors, watch, formState } = useFormContext();
  const { dirtyFields } = formState;

  useEffect(() => {
    if (focusOnLoad) {
      firstNameRef.current.focus();
    }
  }, []);

  const { ssn, zip } = watch(['ssn', 'zip']);

  const isSSNDisabled = (disabled && ssn && !dirtyFields.ssn);
  const isZipDisabled = (disabled && zip && !dirtyFields.zip);

  return (
    <Fragment>
      <StyledRow>
        <Input
          name="firstName"
          labelText="First Name"
          id="firstName"
          error={errors?.firstName}
          errorMessage={requiredField}
          required="required"
          isDisabled={disabled}
          ref={(input) => {
            register(input, {
              required: showAll ? requiredField : false,
              maxLength: 50,
              minLength: showAll ? 2 : 0,
              pattern: /^[A-Za-z]/,
            });
            firstNameRef.current = input;
          }}
        />
        <Spacer itemWidth={sizes.large} />
        <Input
          name="lastName"
          labelText="Last Name"
          id="lastName"
          error={errors?.lastName}
          errorMessage={requiredField}
          required="required"
          isDisabled={disabled}
          ref={register({
            required: showAll ? requiredField : false,
            maxLength: 50,
            minLength: showAll ? 2 : 0,
            pattern: /^[A-Za-z]/,
          })}
        />
      </StyledRow>

      {showAll && (
        <Fragment>
          <StyledRow>
            <StyledGenderContainer>
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, onBlur }) => (
                  <DropdownList
                    handleChange={({ value: newValue }) => onChange(newValue)}
                    onBlur={onBlur}
                    value={genderOptions.find((g) => g.value === value)}
                    id="gender"
                    items={genderOptions}
                    label="Gender"
                    disabled={disabled}
                    isWithinModal={isWithinModal}
                    required
                    error={errors?.gender}
                    errorMessage={requiredField}
                  />
                )}
              />
            </StyledGenderContainer>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  maxDate={new Date()}
                  externalError={errors?.dob}
                  externalErrorText={requiredField}
                  id={`dob${featureName}`}
                  onBlur={onBlur}
                  labelText="Date of Birth"
                  required="required"
                />
              )}
            />
          </StyledRow>
          <StyledRow>
            <Input
              name="zip"
              id="zip"
              type="text"
              labelText="Zip Code"
              required="optional"
              isDisabled={isZipDisabled}
              error={errors.zip}
              errorMessage={errors.zip && invalidValue}
              maxLength="5"
              ref={register({
                required: false,
                pattern: /[0-9]{5}/,
              })}
            />
            <div>
              {/* UE-3050 Only validate if field is enabled */}
              <StyledSSNInput
                name="ssn"
                id="ssn"
                type="text"
                labelText="SSN - Last Four"
                required="optional"
                isDisabled={isSSNDisabled}
                error={errors?.ssn}
                errorMessage={errors.ssn && invalidValue}
                placeholder="####"
                maxLength="4"
                ref={register(
                  isSSNDisabled
                    ? { required: false }
                    : {
                      required: false,
                      pattern: /(?!0000)[0-9]{4}$/,
                      maxLength: 4,
                    },
                )}
              />
            </div>
          </StyledRow>
        </Fragment>
      )}
    </Fragment>
  );
};

PatientDemographics.propTypes = {
  disabled: PropTypes.bool,
  showAll: PropTypes.bool,
  isWithinModal: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  featureName: PropTypes.string,
};

export default PatientDemographics;
