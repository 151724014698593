import {colors} from "patient-ping-remedy/packages/theme";
import Icon from "patient-ping-remedy/packages/icon";
import React from "react";
import {Wrapper} from "./NotificationViewedIcon.styles";
import ToolTip from "patient-ping-remedy/packages/tool-tip";

type Props = {
  onClick: (e: any) => void;
  viewed: boolean;
};

function NotificationViewedIcon (props: Props) {
  const hoverText = props.viewed ? 'Mark as unread' : 'Mark as read';

  return (
    <Wrapper
      onClick={props.onClick}
      data-testid='mark-notification-as-viewed'
    >
      <ToolTip
        html={hoverText}
        testId={'notification-viewed-icon-tooltip'}
      >
        <Icon
          iconClass={'circle'}
          weight={
            props.viewed ? 'far' : 'fas'
          }
          color={colors.tertiary2}
          iconSize={'1x'}
        />
      </ToolTip>
    </Wrapper>
  );
}

export default NotificationViewedIcon;
