import {
  ADMITTED,
  DECEASED,
  DISCHARGED,
  ENDED_SERVICES,
  MEDICAL_LEAVE_OF_ABSENCE,
  PAYOR_CHANGED,
  RESUMED_SERVICES,
  TRANSFERRED,
} from '../../../constants/PingStatuses';

export const allStatuses = [
  ADMITTED,
  TRANSFERRED,
  MEDICAL_LEAVE_OF_ABSENCE,
  PAYOR_CHANGED,
  DISCHARGED,
  DECEASED,
  RESUMED_SERVICES,
  ENDED_SERVICES,
];

export const getStatusConfig = (patient) => {
  return {
    [ADMITTED]: {
      id: ADMITTED,
      label: 'Admitted',
      //always hidden. included in config only for currently admitted patients so the user can undo changes
      hideForCurrentStatus: allStatuses,
      formControls: { showSetting: false, showInsurance: false },
    },
    [TRANSFERRED]: {
      id: TRANSFERRED,
      label: 'Transferred',
      // hideForCurrentStatus: [DISCHARGED, DECEASED, MEDICAL_LEAVE_OF_ABSENCE, ENDED_SERVICES],
      hideForCurrentStatus: [DISCHARGED, DECEASED],
      formControls: {
        label: 'Transfer Date',
        showSetting: true,
        showInsurance: patient.currentPing?.manual,
      },
    },
    [MEDICAL_LEAVE_OF_ABSENCE]: {
      id: MEDICAL_LEAVE_OF_ABSENCE,
      label: 'Medical Leave Of Absence',
      // hideForCurrentStatus: [DISCHARGED, DECEASED, MEDICAL_LEAVE_OF_ABSENCE, ENDED_SERVICES],
      // always hidden until after V1
      hideForCurrentStatus: allStatuses,
      formControls: {
        label: 'Date Medical Leave of Absence Began',
        showSetting: false,
        showInsurance: true,
      },
    },
    [DISCHARGED]: {
      id: DISCHARGED,
      label: 'Discharged',
      // hideForCurrentStatus: [DISCHARGED, DECEASED, MEDICAL_LEAVE_OF_ABSENCE, ENDED_SERVICES],
      hideForCurrentStatus: [DISCHARGED, DECEASED],
      formControls: { showSetting: false, showInsurance: false },
    },
    [PAYOR_CHANGED]: {
      id: PAYOR_CHANGED,
      label: 'Payor Changed',
      // hideForCurrentStatus: [PAYOR_CHANGED, DISCHARGED, DECEASED, ENDED_SERVICES],
      hideForCurrentStatus: [PAYOR_CHANGED, DISCHARGED, DECEASED],
      formControls: { label: 'Payor Change Date', showSetting: false, showInsurance: true },
    },
    [DECEASED]: {
      id: DECEASED,
      label: 'Deceased',
      // hideForCurrentStatus: [DECEASED, MEDICAL_LEAVE_OF_ABSENCE, ENDED_SERVICES],
      hideForCurrentStatus: [DECEASED],
      formControls: { label: 'Deceased Date', showSetting: false, showInsurance: false },
    },
    [RESUMED_SERVICES]: {
      id: RESUMED_SERVICES,
      label: 'Resumed Services',
      // hideForCurrentStatus: allStatuses.filter((option) => option !== MEDICAL_LEAVE_OF_ABSENCE),
      // always hidden until after V1
      hideForCurrentStatus: allStatuses,
      formControls: { label: 'Admitted Date', showSetting: true, showInsurance: true },
    },
    [ENDED_SERVICES]: {
      id: ENDED_SERVICES,
      label: 'Ended Services',
      // hideForCurrentStatus: allStatuses.filter((option) => option !== MEDICAL_LEAVE_OF_ABSENCE),
      // always hidden until after V1
      hideForCurrentStatus: allStatuses,
      formControls: { label: 'Date Services Ended', showSetting: false, showInsurance: false },
    },
  };
};

export const formatSubmitInsurancePlans = (
  startingInsurancePlans,
  updatedInsurancePlans,
  selectedInsurancePlan,
  isCreating = false,
) => {
  const canceledInsurances = [];
  const newInsurances = updatedInsurancePlans.map((plan) => {
    const { id, code } = plan.insuranceProvider;
    const isSelected = id === selectedInsurancePlan.id;
    const UUID = id.toString().includes('NEW') ? null : id;
    const updateCompanyName = code === 'OTHER' ? plan.otherTranslatedName : plan.insuranceProvider.insuranceCompanyName;
    return {
      ...plan.insuranceProvider,
      value: plan.insuranceValue,
      id: UUID,
      selected: isSelected,
      insuranceCompanyName: updateCompanyName,
    };
  });

  if (!isCreating) {
    startingInsurancePlans.forEach((existingPlan) => {
      const existingPlanIsAvailable = newInsurances.find(
        (editedValues) => editedValues.id === existingPlan.insuranceProvider.id,
      );
      if (!existingPlanIsAvailable) {
        canceledInsurances.push({ ...existingPlan.insuranceProvider, canceled: true });
      }
    });
  }
  return [...canceledInsurances, ...newInsurances];
};

export const getInsurancePlanOptions = (patient) => {
  const { currentPing, currentPayor } = patient;
  const isPcc = currentPing?.manual === false;
  let currentInsurancePlan = { id: 'NEW' };
  let insurancePlans = patient.insurancePlans || [];
  //pcc is not sending insurancePlans array, so set the currentPayor as their only insurancePlan
  if (isPcc && !insurancePlans.length) {
    insurancePlans = [{ ...currentPayor }];
  }
  const unsortedPatientInsurancePlans = insurancePlans.length
    ? insurancePlans
      .filter((plan) => !plan.canceled)
      .map((plan) => {
        if (plan.insuranceCompanyName === currentPayor?.insuranceCompanyName) {
          currentInsurancePlan = plan;
        }
        if (plan.code === 'OTHER') {
          const otherTranslatedName = plan.insuranceCompanyName || '';
          const updatedPlan = { ...plan, insuranceCompanyName: 'Other' };
          return { insuranceProvider: updatedPlan, insuranceValue: updatedPlan.value || '', otherTranslatedName };
        }
        return { insuranceProvider: plan, insuranceValue: plan.value || '' };
      })
    : [{ insuranceProvider: currentInsurancePlan, insuranceValue: '', otherTranslatedName: '' }];

  const patientInsurancePlans = unsortedPatientInsurancePlans.sort((plan) => {
    return plan.insuranceProvider.id === currentInsurancePlan.id ? -1 : 1;
  });

  //if there are insurance plans but the current plan still is 'NEW' we
  //didn't get  match in the above loop and there are data issues with currentPayor and insurancePlans
  //this will ensure we have a default value selected for insurances and guarding against bug in UE-3653
  if (insurancePlans.length && currentInsurancePlan.id === 'NEW') {
    const [firstAvailablePlan] = insurancePlans.filter((plan) => !plan.canceled);
    currentInsurancePlan = firstAvailablePlan;
  }

  return { currentInsurancePlan, patientInsurancePlans };
};
