import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const SSOHeader = styled('h2')`
  font-size: ${fontSize.standard};
  margin-bottom: 3rem;
  color: ${colors.primary1};
`;

const TopRegion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
  margin-top: 1rem;
`;

const BorderedContent = styled.div`
  padding: 20px;
  border: 0.1rem solid ${colors.gray3};
  width: 50%;
  border-radius: 8px;
  background-color: ${colors.white};
`;


export { TopRegion, BorderedContent, Content,SSOHeader};
