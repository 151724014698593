import React from "react";

import SlideInPane from "patient-ping-remedy/packages/slide-in-pane";

import {
  CRISIS_EVENT,
  FIND_HELP_EVENT,
  HOSPITAL_EVENT,
  JI_EVENT,
  NotificationEventType,
  NotificationInfoMap,
  NotificationSettings,
  POST_ACUTE_CARE_EVENT,
  REFERRAL_EVENT
} from "../../../../api/dto/notification";
import NotificationSettingDetails from "./NotificationSettingDetails";

function getNotificationInfo(notificationEvent: NotificationEventType, notificationSettings: NotificationSettings) {
  switch (notificationEvent) {
  case REFERRAL_EVENT:
    return notificationSettings.referralSetting;
  case CRISIS_EVENT:
    return notificationSettings.crisisSetting;
  case HOSPITAL_EVENT:
    return notificationSettings.hospitalSetting;
  case JI_EVENT:
    return notificationSettings.jiSetting;
  case POST_ACUTE_CARE_EVENT:
    return notificationSettings.pacSetting;
  case FIND_HELP_EVENT:
    return notificationSettings.findHelpSetting;
  }
}

type Props = {
  notificationEvent: NotificationEventType,
  isOpen: boolean,
  onClose: Function,
  notificationSettings: NotificationSettings
}

const NotificationSettingDrawer = (props : Props) => {
  return (
    <SlideInPane
      onClose={props.onClose}
      isOpen={props.isOpen}
      isOverlayOpen={props.isOpen}
      showNav={false}
      headerText={NotificationInfoMap[props.notificationEvent as NotificationEventType].drawerHeaderText}
      width={"600px"}
    >
      <NotificationSettingDetails
        eventSetting={getNotificationInfo(props.notificationEvent as NotificationEventType, props.notificationSettings)}
        eventFormInfo={NotificationInfoMap[props.notificationEvent as NotificationEventType].eventFormInfo}
        notificationSchedule={props.notificationSettings.notificationSchedule}
        handleClose={props.onClose}
      />
    </SlideInPane>
  );
};

export default NotificationSettingDrawer;
