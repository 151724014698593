import React, {ReactNode} from 'react';
import {ClientProfileResponse} from "../../../../api/dto/client-profile";
import StyledDemographicHeaderComponent from "./ClientDemographicHeader.styles";
import DisplayHelpers from "../../../../helpers/display_helpers";
import {colors} from "patient-ping-remedy/packages/theme";

type ModalProps = {
  ClientProfileResponse: ClientProfileResponse | undefined
}

const buildHeaderNode = (label:string, value: string | undefined) => {
  return(
    <>
      <StyledDemographicHeaderComponent color={colors.gray5} whiteSpace={"nowrap"}>
        {label}
      </StyledDemographicHeaderComponent>
      <br/>
      <StyledDemographicHeaderComponent>
        {value || <br/>}
      </StyledDemographicHeaderComponent>
    </>
  );
};

const ClientDemographicHeader = ({ClientProfileResponse}: ModalProps): ReactNode[] => {
  let headerNodes: ReactNode[] = [];
  let patient = ClientProfileResponse?.patientProfileResponse?.patient;

  if (!patient) {
    return headerNodes;
  }

  let fullName = DisplayHelpers.formatName(patient.firstName, patient.middleName, patient.lastName);
  headerNodes.push(
    <StyledDemographicHeaderComponent
      fontSize={"2rem"}
      lineHeight={"2.4rem"}
      whiteSpace={"nowrap"}
    >
      {fullName}
    </StyledDemographicHeaderComponent>
  );

  headerNodes.push(buildHeaderNode('Date of birth', DisplayHelpers.formatDateOfBirthWithAge(patient.dateOfBirth)));
  headerNodes.push(buildHeaderNode('Gender', DisplayHelpers.genderFromEnum(patient.gender)));
  headerNodes.push(buildHeaderNode('Social security number', DisplayHelpers.formatSSN(patient.ssn)));
  headerNodes.push(buildHeaderNode('Phone',
    DisplayHelpers.formatPatientPhoneNumber(ClientProfileResponse?.patientProfileResponse?.patientPhoneNumbers[0])));
  headerNodes.push(buildHeaderNode('Address', DisplayHelpers.formatAddress(patient.address)));
  headerNodes.push(buildHeaderNode('Insurance',
    ClientProfileResponse?.patientProfileResponse?.patientRosterInsurances[0]?.insurances[0]));

  return headerNodes;
};

export default ClientDemographicHeader;
