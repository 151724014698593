import {create } from 'zustand';
import {devtools} from "zustand/middleware";
import {User} from "../api/dto/dto";

export type AuthState = {
  attestationCheck: boolean | null;
  setAttestationCheck: Function;
  phoneVerificationCheck: boolean | null;
  setPhoneVerificationCheck: Function;
  currentUser: User | null;
  setCurrentUser: Function;
  returnToRoute: string | null;
  setReturnToRoute: Function;
  resetState: Function;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    (set) => ({
      attestationCheck: null,
      setAttestationCheck: (val: boolean) => set({attestationCheck: val }),
      phoneVerificationCheck: null,
      setPhoneVerificationCheck: (val: boolean) => set({phoneVerificationCheck: val }),
      currentUser: null,
      setCurrentUser: (val: User) => set({currentUser: val }),
      returnToRoute: null,
      setReturnToRoute: (val: string) => set({returnToRoute: val }),
      resetState: () => set({attestationCheck: null, phoneVerificationCheck: null, currentUser: null}),
    }),
    { name: 'authStore', enabled: ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')},
  ),
);
