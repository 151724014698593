import {NotificationDisplayText, NotificationDto} from "../../../api/dto/notification";
import {NotificationIcons} from "./NotificationIcon";
import {
  SmallTypography, StandardSemiBoldTypography,
  StandardTypography
} from "patient-ping-remedy/packages/typography";
import React from "react";
import DisplayHelpers from "../../../helpers/display_helpers";
import Spacer from "patient-ping-remedy/packages/spacer";
import {router} from "../../../router";
import {useRosterStore} from "../../../store/roster_store";
import {useAuthStore} from "../../../store/auth_store";
import {Wrapper, LeftWrapper, IconWithBackground} from "./NotificationItem.styles";
import {colors} from "patient-ping-remedy/packages/theme";
import NotificationViewedIcon from "./popover/NotificationViewedIcon";
import {useNotifications} from "../../../hooks/useNotifications";
import ClientNameLinkComponent from "../clients/ClientNameLinkComponent";

type Props = {
  notification: NotificationDto;
}

function NotificationItem(props : Props) {
  let { notification } = props;
  const { currentRoster } = useRosterStore();
  const { currentUser } = useAuthStore();

  const { setNotificationViewed } = useNotifications({
    currentRosterId: currentRoster?.id || 0,
    onlyShowUnreadNotifications: currentUser?.only_show_unread_notifications || false,
    enabled: !!currentRoster,
  });

  const clientResponse = notification.clientResponse;
  const eventAndCategoryText = NotificationDisplayText[notification.eventType][notification.eventSubtype];
  const timePassed = <SmallTypography>
    {DisplayHelpers.timeSince(new Date(notification.eventDate))}
  </SmallTypography>;

  return (
    <Wrapper>
      <LeftWrapper>
        <IconWithBackground>
          {NotificationIcons[notification.eventType]}
        </IconWithBackground>
        <StandardSemiBoldTypography color={colors.primary1}>
          {eventAndCategoryText} <Spacer itemWidth={'5px'}/>
        </StandardSemiBoldTypography>
        <StandardTypography>
          | <Spacer itemWidth={'5px'}/>
          <span>
            <ClientNameLinkComponent
              client={clientResponse}
              testId={`client-link-for-notification-${notification.id}`}
              handleClick={() => {
                router.navigate('/client_profile?p=' + clientResponse.patientId + '&r=' + currentRoster?.id);
                if (!notification.viewed) {
                  setNotificationViewed(notification.id, true);
                }
              }}
            />
          </span> had a {eventAndCategoryText.toLowerCase()} event at {notification.groupName}
          <Spacer itemWidth={'10px'}/> {timePassed}
        </StandardTypography>
      </LeftWrapper>
      <NotificationViewedIcon
        onClick={() => setNotificationViewed(notification.id, !notification.viewed)}
        viewed={notification.viewed}
      />
    </Wrapper>
  );
}

export default NotificationItem;
