import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const NotificationUserWrapper = styled.div`
  transform: scaleY(0.85);
  transform-origin: top left;
  
  span {
    height: 0.1px;
    margin-bottom: 0;
  }

  & > div label {
   font-size: 1.3rem;
   line-height: 1.8rem;
   letter-spacing: 0.01rem;
   font-weight: 400;
   color: ${colors.gray5};
  }
`;

export { NotificationUserWrapper};
