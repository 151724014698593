import styled from '@emotion/styled';

const NotificationPopupHeader = styled('div')`
  margin-top: 20px;
  margin-bottom: 10px; 
  display: flex;
  justify-content: space-between;
`;

const NotificationPopupRightHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationPageLink = styled('div')`
  padding: 5px;
  cursor: pointer;
`;

export {NotificationPopupHeader, NotificationPopupRightHeader, NotificationPageLink};
