import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form/dist/index.ie11';

import RadioButton from 'patient-ping-remedy/packages/radio-button';
import Modal from "patient-ping-remedy/packages/modal";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {moment} from "vis-timeline/standalone";

import {useMutation} from "@tanstack/react-query";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {useAlertStore} from "../../../../../../store/alert_store";
import {CarecoApiContext} from "../../../../../../app-context/careco-api-context";
import {GENERICERROR, SUCCESS_DISCHARGE} from "../../constants/BannerMessages";
import {ADMIT_PATIENT} from "../../CreateAdmission";
import {getDefaultValues} from "../../encounter_admit/pings/CreateEditClientModal";
import PatientCard from "./patient/PatientCard";
import DischargeHookForm from "../../encounter_update_status/pings/components/DischargeHookForm";
import {StyledSameEncounter, StyledSameRadioButton, StyledSmallTypography} from "./ReconcileHookModal.styles";
import Helpers from "../../../../../../helpers/helpers";

const ReconcileHookModal = ({ patientDetail, hideModal, group, setStep }) => {
  const [patient, setPatient] = useState(patientDetail);
  const { addAlert } = useAlertStore();
  const { carecoApi, encounterApi } = useContext(CarecoApiContext);

  const dischargePatient = useMutation({
    mutationFn: (payload) => encounterApi.createDischargeStatus(payload, {patientId: patient.publicPatientId}, group.id),
    onSuccess: () => {
      addAlert({content: SUCCESS_DISCHARGE, type: ToastType.SUCCESS});
      setStep(ADMIT_PATIENT);
    },
    onError: (error) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to discharge client. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  const calculateVisitDuration = useCallback((eventDate) => {
    const today = new Date();
    const admitDate = new Date(eventDate);
    return Helpers.calculateTimeDifference(admitDate, today);
  }, []);

  useEffect(() => {
    encounterApi?.getEncounters(patientDetail.publicPatientId, group.id).then((patientEncounter) => {
      if (patientEncounter) {
        const currentEncounter = patientEncounter.find(encounter => encounter.isOpen);

        if (currentEncounter) {
          setPatient(prevState => ({
            ...prevState,
            id: prevState.patientId,
            currentPing: {
              ...currentEncounter.events.at(-1),
              editable: group.admittingFacility && !group.automated,
              manual: !group.automated,
              state: currentEncounter.events.at(-1).status,
              facility: { ...group }
            },
            currentPingEncounter: {
              ...currentEncounter,
              visitDuration: calculateVisitDuration(currentEncounter.events.at(-1).eventDate),
              id: currentEncounter.encounterId
            },
          }));
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }, [carecoApi]);

  const defaultValues = useMemo(() => getDefaultValues(patient, group), [patient, group]);
  const hookFormMethods = useForm({ defaultValues, mode: 'onTouched' });

  useEffect(() => {
    return () => hookFormMethods.unregister('reconcilePatient');
  }, [hookFormMethods]);

  const watchEncounterSelection = hookFormMethods.watch('isSameEncounter', 'true');
  const showDischarge = watchEncounterSelection === 'false';

  const reconcilePatient = async ({
    isSameEncounter,
    dischargeDate,
    dispositionType,
    facility,
    secondaryFacility,
    locationDescription,
  }) => {
    if (isSameEncounter === 'true') {
      hideModal();
    } else {
      const options = {
        date: moment(dischargeDate),
        disposition:{
          dispositionType: {
            id: dispositionType.id,
            itemCode: dispositionType.code
          },
          location: {
            facility: facility ? { facilityName: facility.label, id: facility.id } : null,
            secondaryFacility: secondaryFacility ? { facilityName: secondaryFacility.label, id: secondaryFacility.id } : null,
            locationDescription: locationDescription?.label || '',
          }
        },
      };

      const payload = { encounterId: patient.currentPingEncounter.id, options };
      dischargePatient.mutate(payload);
      hookFormMethods.reset({ ...defaultValues, date: new Date(), setting: '', admittedFrom: { label: '', id: '' } });
    }
  };

  const isDisabled = dischargePatient.isPending || !hookFormMethods.formState.isValid;

  const modalButtons = [
    <Button
      key="submit"
      styleType={StyleType.PRIMARY}
      disabled={isDisabled}
      onClick={hookFormMethods.handleSubmit(reconcilePatient)}
    >
      {watchEncounterSelection === 'true' ? 'Done' : 'Update to add new encounter'}
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={hideModal}>
      Cancel
    </Button>,
  ];

  return (
    <Modal buttons={modalButtons} headerText="Reconcile Open Encounter">
      <StyledSmallTypography>
        All fields marked with <span>*</span> are required.
      </StyledSmallTypography>
      <StyledSmallTypography>
        We currently have an active admission to your facility for the client you selected. Is this the same admission
        or a new admission?
      </StyledSmallTypography>
      <StyledSameEncounter>
        <StyledSameRadioButton
          name="isSameEncounter"
          value="true"
          id="reconcileSame"
          label="Same"
          defaultChecked={true}
          ref={hookFormMethods.register}
        />
        <PatientCard patient={patient}/>
      </StyledSameEncounter>
      <RadioButton
        name="isSameEncounter"
        value="false"
        id="reconcileNew"
        label="New"
        defaultChecked={false}
        ref={hookFormMethods.register}
      />
      {showDischarge &&
        <FormProvider {...hookFormMethods} >
          <DischargeHookForm patient={patient} isReconcile={true}/>
        </FormProvider>}
    </Modal>
  );
};

export default ReconcileHookModal;
