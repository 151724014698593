import {MixpanelEvent, MixpanelEventType, ViaType} from "../api/dto/mixpanel";

export const getMixPanelEventProperties = (eventName : MixpanelEventType, returnToRoute: Nullable<string> = null) => {
  const currentApplicationLocationAndVia = getCurrentApplicationLocationAndVia(returnToRoute);

  const mixPanelEvent : MixpanelEvent = {
    eventName: eventName,
    viewPortWidth: window.innerWidth,
    viewPortHeight: window.innerHeight,
    $os: getOperatingSystem(),
    device: getDeviceType(),
    currentApplicationLocation: currentApplicationLocationAndVia.currentLocation,
    pageUrl: window.location.href,
    via: currentApplicationLocationAndVia.via
  };

  return mixPanelEvent;
};

export const getCurrentApplicationLocationAndVia = (returnToRoute: Nullable<string>) => {
  const pathName = window.location.pathname;
  const href = window.location.href;
  switch (pathName) {
  case "/login":
    return {
      currentLocation: "Login",
      via: returnToRoute?.includes("via=email") ? ViaType.EMAIL : null
    };
  case "/clients":
    return {
      currentLocation: "Clients List",
      via: href.includes("via=email") ? ViaType.EMAIL : ViaType.CLIENT_LIST
    };
  case "/client_profile":
    return {
      currentLocation: "Client Profile",
      via: href.includes("via=email") ? ViaType.EMAIL : ViaType.CLIENT_PROFILE
    };
  case "/admin":
    return {
      currentLocation: "Admin",
      via: href.includes("via=email") ? ViaType.EMAIL : ViaType.ADMIN
    };
  case "/notifications":
    return {
      currentLocation: "Notifications",
      via: href.includes("via=email") ? ViaType.EMAIL : ViaType.NOTIFICATIONS
    };
  case "/profile":
    return {
      currentLocation: "User Profile",
      via: href.includes("via=email") ? ViaType.EMAIL : ViaType.USER_PROFILE
    };
  default:
    return {
      currentLocation: "Clients List",
      via: ViaType.CLIENT_LIST
    };
  }
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/iPhone/i.test(userAgent)) return "iPhone";
  if(/iPad/.test(userAgent)) return "iPad";
  if (/Android/i.test(userAgent) && !/Mobile/i.test(userAgent)) return "Tablet";
  if (/Android/i.test(userAgent)) return "Android";
  if (/Windows/i.test(userAgent) ||
    /Linux/i.test(userAgent) ||
    /Mac/i.test(userAgent)) return "Desktop";

  return "Desktop";
};

export const getOperatingSystem = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Android")) return "Android";
  if (userAgent.includes("like Mac")) return "iOS";
  if (userAgent.includes("Win")) return "Windows";
  if (userAgent.includes("Mac")) return "macOS";
  if (userAgent.includes("X11") || userAgent.includes("Linux")) return "Linux";

  return "Unknown OS";
};
