import React from 'react';
import InformationUnavailable from "./InformationUnavailable";

const EncounterDiagnosis = ({ encounter, shouldTruncate = false, isHospital = false }) => {
  if (!encounter.diagnosis.synonym) {
    return <InformationUnavailable skipFormatting={!isHospital} />;
  }
  const { fullCode = '', synonym = '' } = encounter.diagnosis || {};
  //rising risk does not have the full code available at beta - if this gets updated, this logic can be removed
  const diagnosis = `${synonym} ${fullCode ? `(${fullCode})` : ''}`;
  const shouldTruncateDiagnosis = shouldTruncate && diagnosis.length >= 100;
  const truncatedDiagnoses = shouldTruncate ? `${diagnosis.substr(0, 100)}...` : diagnosis;
  return (
    <span data-testid="ping-diagnosis" title={shouldTruncateDiagnosis ? diagnosis : ''}>
      {shouldTruncateDiagnosis ? truncatedDiagnoses : diagnosis}
    </span>
  );
};

export default EncounterDiagnosis;
