import {NotificationEventType} from "../../../api/dto/notification";
import React from "react";

import { ReactComponent as CrisisIcon } from "../../../assets/icons/Custom-Icon-Crisis.svg";
import { ReactComponent as ReferralIcon } from "../../../assets/icons/Custom-Icon-Referral.svg";
import { ReactComponent as HospitalIcon } from "../../../assets/icons/hospital.svg";
import { ReactComponent as JusticeInvolvedIcon } from "../../../assets/icons/Custom-Icon-JI.svg";
import { ReactComponent as ClinicalIcon } from "../../../assets/icons/clinic.svg";

type NotificationIconInfoType = { [K in NotificationEventType]: React.ReactElement };

export const NotificationIcons: NotificationIconInfoType = {
  CRISIS: <CrisisIcon/>,
  REFERRAL: <ReferralIcon/>,
  HOSPITAL: <HospitalIcon style={{height: 20, width: 20}}/>,
  JI: <JusticeInvolvedIcon style={{height: 20, width: 20}}/>,
  FIND_HELP: <ReferralIcon/>,
  POST_ACUTE_CARE: <ClinicalIcon style={{height: 20, width: 20}}/>
};
