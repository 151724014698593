import React, {useState} from "react";
import {Heading, IconWrapper, Label, StyledIcon} from "./ApplicationMenu.styles";
import {colors} from "patient-ping-remedy/packages/theme";
import Popover from "patient-ping-remedy/packages/popover";
import PermissionsHelpers from "../../helpers/permissions_helpers";
import {useAuthStore} from "../../store/auth_store";
import {useOpenBeds} from "../../hooks/useOpenBeds";
import Helpers from "../../helpers/helpers";
import ToolTip from "patient-ping-remedy/packages/tool-tip";
import {ICONS} from "patient-ping-remedy/packages/icon";
import DisplayHelpers from "../../helpers/display_helpers";
import Menu from "../common/menu/Menu";
import MenuItem from "../common/menu/MenuItem";
import MenuItemIcon from "../common/menu/MenuItemIcon";
import MenuItemTypography from "../common/menu/MenuItemTypography";
import {MenuItemSpacer} from "../common/menu/MenuItem.styles";
import MenuItemTrailingIcon from "../common/menu/MenuItemTrailingIcon";
import {SSOApplication, SSOLaunchMethod} from "../../api/dto/mixpanel";

const ApplicationMenu = () => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { currentUser } = useAuthStore();
  const { openOpenBeds } = useOpenBeds();

  const crisisEnabled = currentUser ? PermissionsHelpers.canForUserDataRole(
    currentUser,
    Helpers.getAppDataRoleDetails().levels.SSO,
    Helpers.getAppDataRoleDetails().permissions.OB_CRISIS
  ) : false;

  const referralsEnabled = currentUser ? PermissionsHelpers.canForUserDataRole(
    currentUser,
    Helpers.getAppDataRoleDetails().levels.SSO,
    Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS
  ) : false;

  const PopoverContent = () => {
    return <>
      <Heading>
        <Label>
          YOUR APPS
        </Label>
      </Heading>
      <Menu width="32rem" marginBottom>
        <MenuItem
          onClick={() => openOpenBeds(SSOApplication.OPENBEDS_REFERRALS, SSOLaunchMethod.APPLICATION_MENU)}
          disabled={!referralsEnabled}
        >
          <MenuItemIcon iconClass={'procedures'} color={colors.primary2} />
          <MenuItemTypography>Clinical Referrals</MenuItemTypography>
          <MenuItemSpacer />
          {referralsEnabled ? (
            <MenuItemTrailingIcon iconClass={ICONS["external-link"]} />
          ) : (
            <ToolTip
              text={DisplayHelpers.contactAdminMessage()}
              position="bottom-start"
              testId="referrals-disabled-tooltip"
            >
              <MenuItemTrailingIcon iconClass={ICONS["info-circle"]} />
            </ToolTip>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => openOpenBeds(SSOApplication.OPENBEDS_CRISIS, SSOLaunchMethod.APPLICATION_MENU)}
          disabled={!crisisEnabled}
        >
          <MenuItemIcon iconClass={'ambulance'} color={colors.primary2} />
          <MenuItemTypography>Crisis</MenuItemTypography>
          <MenuItemSpacer />
          {crisisEnabled ? (
            <MenuItemTrailingIcon iconClass={ICONS["external-link"]} />
          ) : (
            <ToolTip
              text={DisplayHelpers.contactAdminMessage()}
              position="bottom-start"
              testId="crisis-disabled-tooltip"
            >
              <MenuItemTrailingIcon iconClass={ICONS["info-circle"]} />
            </ToolTip>
          )}
        </MenuItem>
      </Menu>
    </>;
  };

  return (
    <>
      <Popover
        content={<PopoverContent/>}
        position="bottom"
        toggle={() => setPopoverOpen(!popoverOpen)}
        isOpen={popoverOpen}
        triggerTestId="nav-rail-popover-trigger"
      >
        <IconWrapper active={popoverOpen}>
          <StyledIcon
            iconClass={'th'}
            color={popoverOpen ? colors.primary1 : colors.white}
            iconSize={'4x'}
          />
        </IconWrapper>
      </Popover>
    </>
  );
};

export default ApplicationMenu;
