import {NotificationDto} from "../api/dto/notification";
import {devtools} from "zustand/middleware";
import {create} from "zustand";


export type NotificationState = {
    notifications: NotificationDto[];
    setNotifications: Function;
    updateNotificationViewed: Function;
    removeNotification: Function;
    totalUnreadNotifications: number;
    setTotalUnreadNotifications: Function;
}

export const useNotificationStore = create<NotificationState>()(
  devtools(
    (set) => ({
      notifications: [],
      setNotifications: (val: NotificationDto[]) => set({notifications: val }),
      updateNotificationViewed: (id: number, viewed: boolean) => {
        set((state) => {
          let notifications = state.notifications.map((notification) => {
            if (notification.id === id) {
              notification.viewed = viewed;
            }
            return notification;
          });
          return {notifications: notifications};
        });
      },
      removeNotification: (id: number) => {
        set((state) => {
          let notifications = state.notifications.filter((notification) => notification.id !== id);
          return {notifications: notifications};
        });
      },
      totalUnreadNotifications: 0,
      setTotalUnreadNotifications: (val: number) => set({totalUnreadNotifications: val}),
    }),
    { name: 'notificationStore', enabled: ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')},
  ),
);
