import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const UserRosterContainer = styled.div`
 font-size: ${fontSize.standard};
`;

const Label = styled.div`
  color: ${colors.gray5};
  margin-bottom: 1rem;
`;

const GroupDiv = styled.div`
`;

const RolesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const DataRoleDiv = styled.div`
  width: 50%;
`;

const FeatureRoleDiv = styled.div`
`;

const Divider = styled('div')`
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid ${colors.gray2};
`;

export { 
  Label,
  UserRosterContainer,
  GroupDiv,
  RolesWrapper,
  DataRoleDiv,
  FeatureRoleDiv,
  Divider
};
