import React from 'react';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import {router} from '../../../router';
import ErrorPage from './ErrorPage';
import {ErrorCenter} from './ErrorPage.styles';

export default function FailedAttestation() {
  return (
    <ErrorPage title="Error! You have not agreed to the attestation.">
      <span>Please re-login and agree to the attestation.</span>
      <ErrorCenter>
        <Button onClick={() => router.navigate('/')} styleType={StyleType.SECONDARY}>
          Return to Login
        </Button>
      </ErrorCenter>
    </ErrorPage>
  );
}
