import ToolTip from "patient-ping-remedy/packages/tool-tip";
import {StyledTooltipIcon} from "./InfoIconTooltip.styles";
import {colors} from "patient-ping-remedy/packages/theme";
import React, {ReactNode} from "react";
import {Size} from "patient-ping-remedy/packages/icon/lib/Icon";

// This is a common component used for small info icons that provide info
// that is not of much importance; it will change color on hover
// if you have a tooltip with important information (i.e. part 2 disclaimer)
// please use another component

type Props = {
  html?: ReactNode;
  style?: React.CSSProperties;
  text?: string;
  size?: Size;
  color?: string;
  hoverColor?: string;
  position?: "right" | "top" | "top-end" | "top-start"
    | "bottom" | "bottom-end" | "bottom-start" | "right-end" | "left" | undefined;
}

const InfoIconTooltip = (props: Props) => {

  return (
    <ToolTip
      data-testid="info-icon-tooltip"
      position={props.position ?? "right"}
      html={props.html}
      text={props.text}>
      <StyledTooltipIcon
        data-testid="info-icon"
        hoverColor={props.hoverColor ?? colors.primary1}
        style={props.style}
        iconClass={'info-circle'}
        iconSize={props.size ?? '1x'}
        color={props.color ?? colors.gray4}
        weight={'far'}
      />
    </ToolTip>
  );
};

export default InfoIconTooltip;
