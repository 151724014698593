import styled from '@emotion/styled';
import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import {SmallTypography} from "patient-ping-remedy/packages/typography";

const ContentContainer = styled.div`
  display: flex;
  font-size: ${fontSize.small};
`;

const PhoneHistoryTableContainer = styled.div`
  width: 45%;
  margin-right: 96px;
`;

const InsuranceHistoryTableContainer = styled.div`
  width: 45%;
`;

const HistoryTableLabel = styled(SmallTypography)`
  display: flex;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight:400;
  color: ${colors.gray5};
  text-align: left;
  margin-bottom: 0.8rem;
`;

const HistoryTable = styled.table`
  border-collapse: separate;

  tr th:not(:first-child) {
    padding: 0 0.8rem;
  }

  tr td:not(:first-child) {
    padding: 0.4rem 1.6rem 0.4rem 0.8rem;
  }

  th {
    border-bottom: 1px solid ${colors.gray2};
  }

  tbody tr td  {
    font-weight: 400;
    font-size: 1.6rem;
    align-content: start;
    text-align: left;
    line-height: 2rem;
    color: ${colors.charcoal};
    padding: 0.4rem 1.6rem 0.4rem 0;
  }

  thead tr th  {
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
    line-height: 2rem;
    color: ${colors.charcoal};
  }
`;

export {
  ContentContainer,
  PhoneHistoryTableContainer,
  InsuranceHistoryTableContainer,
  HistoryTableLabel,
  HistoryTable,
};
