import React, {Fragment, useContext, useLayoutEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import DatePicker from 'patient-ping-remedy/packages/date-picker';
import DropdownList from '../../../../../../pings/dropdown_list/DropdownList';
import InsurancePlanHookForm from './InsurancePlansHookForm';
import {ADMITTED, DISCHARGED, PingStatuses} from '../../../constants/PingStatuses';
import DischargeHookForm from './DischargeHookForm';
import {CarecoApiContext} from "../../../../../../../app-context/careco-api-context";
import {REQUIRED_FIELD} from "../../../constants/common";
import {StyledRow} from "./StatusInfoHookForm.styles";
import AdmitHookForm from "../../../encounter_admit/pings/AdmitHookForm";

const StatusInfoHookForm = ({
  label = '',
  showSetting = false,
  showInsurance = false,
  defaultSelectedPlan = null,
  patient,
  status,
  isSnf,
  isPcc,
  isEditing,
}) => {
  const [settingOptions, setSettingOptions] = useState([]);
  const [inflight, setInflight] = useState(true);
  const { carecoApi } = useContext(CarecoApiContext);

  useLayoutEffect(
    function getSettingOptions() {
      if(carecoApi) {
        if (!settingOptions.length && showSetting) {
          setInflight(true);
          carecoApi.readSettings(patient.creatorGroupId).then((options) => {
            setSettingOptions(options);
            setInflight(false);
          });
        } else if (inflight) {
          setInflight(false);
        }
      }
    },
    [status, carecoApi],
  );

  const { control, errors } = useFormContext();

  if (inflight) {
    return null;
  }

  if (status === DISCHARGED) {
    return <DischargeHookForm patient={patient} showInstructions={false} />;
  }

  if (status === ADMITTED) {
    return <AdmitHookForm
      patient={patient}
      isSnf={isSnf}
      isPcc={isPcc}
      isEditing={isEditing}
      groupId={patient.creatorGroupId}
    />;
  }

  return (
    <Fragment>
      <StyledRow>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              maxDate={new Date()}
              minDate={new Date(patient.currentPing.eventDate)}
              value={value}
              externalError={errors.date}
              externalErrorText={REQUIRED_FIELD}
              id="admitDate"
              labelText={label}
              required="required"
              disabled={isPcc && isEditing}
            />
          )}
        />
        {showSetting && (
          <Controller
            name="setting"
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <DropdownList
                handleChange={(event) => {
                  onChange(event.value);
                }}
                onBlur={onBlur}
                value={settingOptions.find((option) => option.value === value)}
                id="setting"
                items={status === PingStatuses.TRANSFERRED && !isEditing
                  ? settingOptions.filter((option) => option.value !== patient.currentPing.setting)
                  : settingOptions}
                label="Setting"
                isWithinModal
                required
                error={errors.setting}
                errorMessage={REQUIRED_FIELD}
              />
            )}
          />
        )}
      </StyledRow>
      {showInsurance && <InsurancePlanHookForm defaultSelectedPlan={defaultSelectedPlan} />}
    </Fragment>
  );
};
export default StatusInfoHookForm;
