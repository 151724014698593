import {PropsWithChildren, HTMLAttributes} from 'react';
import {StyledMenu} from './Menu.styles';

type Props = PropsWithChildren & HTMLAttributes<HTMLUListElement> & {
  width?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
}

// chopsticks: https://zeroheight.com/5bcb53d99/p/177dd9-menu-item
export default function Menu(props: Props) {
  return (
    <StyledMenu role="menu" {...props}>{props.children}</StyledMenu>
  );
}
