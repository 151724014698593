import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  flex-shrink: 0;
  background-color: ${colors.primary1};
  height: 100%;
  z-index: 3;

  > div:first-of-type {
      margin-top: 8px;
      margin-bottom: 48px;
  };
`;

export { Wrapper };
