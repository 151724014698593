import {create } from 'zustand';
import {devtools} from "zustand/middleware";
import {ToastType} from "patient-ping-remedy/packages/toast";

interface Alert {
  content: string;
  type: ToastType;
}

export type AlertState = {
  alerts: Alert[];
  setAlerts: Function;
  addAlert: Function;
}

export const useAlertStore = create<AlertState>()(
  devtools(
    (set) => ({
      alerts: [],
      setAlerts: (val: Alert[]) => set({alerts: val }),
      addAlert: (alert: Alert) => set((state) => ({alerts: [...state.alerts, alert]})),
    }),
    { name: 'alertStore', enabled: ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')},
  ),
);
