import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {StyledTab, StyledTabDiv, StyledTabs, StyledTabWrapper} from "./TabsComponent.styles";

export interface TabProps {
  value: number;
  label: string;
  hidden?: boolean;
  content: any; // want to be able to render a component string etc
}

type Props = {
  tabs: TabProps[];
  forceRender?: boolean;
  setCurrentTab?: (tabIndex: number) => void;
};

const TabsComponent = forwardRef((props: Props, ref) => {
  const [currentTab, setCurrentTab] = React.useState(props.tabs[0].value);
  const currentTabContent = props.tabs.find(tab => tab.value === currentTab)?.content;

  useImperativeHandle(ref, () => {
    return {
      changeTab(tabIndex: number) {
        setCurrentTab(tabIndex);
      },
    };
  }, []);

  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab(currentTab);
    }
  }, [currentTab]);

  return (
    <>
      <StyledTabs value={currentTab} onChange={setCurrentTab}>
        { props.tabs.map((tab, index) => {
          if(tab.hidden) return <></>;

          return (
            <StyledTabWrapper key={index}>
              <StyledTab active={currentTab === tab.value}>
                {tab.label}
              </StyledTab>
            </StyledTabWrapper>
          );
        })}
      </StyledTabs>

      { props.forceRender && props.tabs.map((tab, index) => (
        <StyledTabDiv id={tab.label} key={index} display={tab.value === currentTab ? 'block' : 'none'}>
          {tab.content}
        </StyledTabDiv>
      ))}

      { !props.forceRender && currentTabContent }
    </>
  );
});

export default React.memo(TabsComponent);
