import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';
import {DisplaySmallTypography} from "patient-ping-remedy/packages/typography";

const Wrapper = styled.div`
  display: flex;
  padding: 5px 20px;
  margin-bottom: 10px;
`;

const NotificationGroup = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

const TodayCategoryHeader = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const TimeCategoryHeader = styled(DisplaySmallTypography)`
  color: ${colors.gray4};
  font-size: ${fontSize.heading};
`;

export { Wrapper, TodayCategoryHeader, NotificationGroup, TimeCategoryHeader};
