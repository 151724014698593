import Icon from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";
import React, {useContext, useState} from "react";
import {StyledIconWrapper} from "./EncounterActions.styles";
import ContextMenu from "patient-ping-remedy/packages/context-menu";
import {useRosterStore} from "../../../../store/roster_store";
import {ADTItem, ClientProfile, InsurancePlan} from "../../../../api/dto/client-profile";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import PatientUpdateStatus from "./encounter_update_status/pings/components/PatientUpdateStatus";
import {useAlertStore} from "../../../../store/alert_store";
import {
  formatDeceasedOptions,
  formatDischargeOptions,
  formatPatientOptions,
  formatPayorChangeOptions,
  formatTransferOptions,
} from "./encounter_update_status/pings/utils/format_encounters";
import PatientEncounterDeleteModal from "./encounter_delete/PatientEncounterDeleteModal";
import EncounterEdit from "./encounter_edit/pings/EncounterEdit";
import {GroupType} from "../../../../api/dto/dto";

type Props = {
  patient: ClientProfile,
  event: ADTItem,
  canEdit?: boolean,
  forceRefresh: Function,
}

export type PingPatient = {
  patientId: string,
  firstName?: string,
  lastName?: string,
  dateOfBirth?: string,
  admittingFacilityId?: number,
  creatorGroupId?: number,
  creatorGroupName?:  string,
  gender?: string,
  address?: {
    address1?: string,
    address2?: string,
    city?: string,
    state?: string,
    zip: string
  },
  ssn?: string,
  id?: string,
  currentPing: {
    eventId: number,
    eventDate?: string,
    payor?: {
      insuranceCompanyName?: string,
      value?: string
    },
    setting?: string,
    status?: string,
    manual?: boolean,
    editable?: boolean
  },
  currentPingEncounter?: ADTItem,
  insurancePlans?: InsurancePlan[],
  currentPayor?: InsurancePlan,
}

export type UpdatePatientJson = {
  data: {
    insurancePlans?: InsurancePlan[];
    setting?: string
    dob?: string;
  },
  patientId: string,
  encounterId?: string,
};

export type UpdateStatusJson = {
  encounterId: string,
  patientId: string,
  options: {
    status: { [key: string]: any },
    date?: string,
    setting?: string,
    insurancePlans?: InsurancePlan[],
    selectedInsurancePlan?: InsurancePlan,
  }
}

enum EncounterActionTypes {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  EMPTY = ''
}

const EncounterActions = (props: Props) => {
  const { currentRoster } = useRosterStore();
  const { encounterApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const [actionType, setActionType] = useState<EncounterActionTypes>(EncounterActionTypes.EMPTY);

  const pingPatient = {
    ...props.patient.patient,
    id: props.event.admittingFacilityPublicPatientId,
    currentPing: {
      ...props.event.events.at(-1),
      eventId: props.event.events.at(-1)!.eventId,
      manual: !currentRoster!.automated,
      editable: currentRoster!.admittingFacility && !currentRoster!.automated,
    },
    currentPingEncounter: props.event,
    insurancePlans: props.patient.currentInsurancePlans,
    currentPayor: props.patient.currentInsurancePlans?.filter(plan => plan.selected)[0],
  };

  const getPatientEncounters = (patientId: string, groupId: number) => {
    return encounterApi!.getEncounters(patientId, groupId);
  };

  const updatePatient = (update: UpdatePatientJson) => {
    let json = formatPatientOptions(update);

    // Need to send these until https://hbuco.atlassian.net/browse/PNGDAT-1769 is complete
    if(!json.data.gender) json.data.gender = props.patient.patient.gender;
    if(!json.data.firstName) json.data.firstName = props.patient.patient.firstName;
    if(!json.data.lastName) json.data.lastName = props.patient.patient.lastName;
    if(!json.data.dateOfBirth) json.data.dateOfBirth = props.patient.patient.dateOfBirth;

    return encounterApi!.updatePatient(json.data, json.patientId, currentRoster!.id);
  };

  const createDeceased = (update: UpdateStatusJson, patient: PingPatient) => {
    let json = formatDeceasedOptions(update);
    return encounterApi!.createDeceasedStatus(json, patient, currentRoster!.id);
  };

  const createDischarge = (update: UpdateStatusJson, patient: PingPatient) => {
    // NOTE cannot discharge to secondary facility of HOME until https://hbuco.atlassian.net/browse/PNGDAT-1768
    let json = formatDischargeOptions(update);
    return encounterApi!.createDischargeStatus(json, patient, currentRoster!.id);
  };

  const createTransfer = (update: UpdateStatusJson, patient: PingPatient) => {
    let json = formatTransferOptions(update);
    return encounterApi!.createTransferStatus(json, patient, currentRoster!.id);
  };

  const createPayorChange = (update: UpdateStatusJson, patient: PingPatient) => {
    let json = formatPayorChangeOptions(update);
    return encounterApi!.createPayorChangeStatus(json, patient, currentRoster!.id);
  };

  let menuItems = [
    {
      label: 'Update status',
      action: () => setActionType(EncounterActionTypes.UPDATE),
    },
    {
      label: 'Delete Event',
      action: () => setActionType(EncounterActionTypes.DELETE),
    }
  ];

  if(props.canEdit) {
    menuItems.unshift({
      label: 'Edit',
      action: () => setActionType(EncounterActionTypes.EDIT),
    });
  }

  return (
    <>
      <ContextMenu
        menuItems={menuItems}
      >
        <StyledIconWrapper>
          <Icon iconClass={'ellipsis-h'} color={colors.gray5}></Icon>
        </StyledIconWrapper>
      </ContextMenu>

      { actionType === EncounterActionTypes.UPDATE &&
        <PatientUpdateStatus
          hideModal={() => setActionType(EncounterActionTypes.EMPTY)}
          addAlert={addAlert}
          forceRefresh={props.forceRefresh}
          createAutomatedEncounterTransfer={() => console.log('not implemented')}
          createDeceased={createDeceased}
          createDischarge={createDischarge}
          createEndServices={() => console.log('not implemented')}
          createMedicalLeaveOfAbsence={() => console.log('not implemented')}
          createPayorChange={createPayorChange}
          createResumeServices={() => console.log('not implemented')}
          createTransfer={createTransfer}
          updatePatient={updatePatient}
          admittingGroup={currentRoster!}
          patient={pingPatient}
        />
      }

      { actionType === EncounterActionTypes.EDIT &&
        <EncounterEdit
          hideModal={() => setActionType(EncounterActionTypes.EMPTY)}
          forceRefresh={props.forceRefresh}
          isSnf={currentRoster!.type === GroupType.SNF}
          admittingGroup={currentRoster!}
          patient={pingPatient}
          updatePatient={updatePatient}
          getPatientEncounters={getPatientEncounters}
        />
      }

      {actionType === EncounterActionTypes.DELETE &&
        <PatientEncounterDeleteModal
          hideModal={() => setActionType(EncounterActionTypes.EMPTY)}
          lastEvent={props.event.events.at(-1)}
          encounterId={props.event.encounterId || ''}
          patientId={props.patient.patient.patientId}
          rosterId={currentRoster!.id}
          forceRefresh={props.forceRefresh}
        />
      }
    </>
  );
};

export default EncounterActions;
