import React from 'react';
import Table from "../../../common/Table";
import {FeaturePermission, FeatureRole} from "../../../../api/dto/dto";
import DisplayHelpers from "../../../../helpers/display_helpers";
import {NoAccess} from "../../../common/user_details/AccessDetails.styles";
import {featureRoleInfo} from "./GroupDetailsDrawer";
import RosterHelpers, {featureValues} from "../../../../helpers/roster_helpers";
import InfoIconTooltip from "../../../common/InfoIconTooltip";

type Props = {
  featureRoles: FeatureRole[],
}

function getFeaturePermission(feature_role_id: number, feature: string, feature_permissions: FeaturePermission[]): JSX.Element {
  const permission = feature_permissions.find((featurePermission: FeaturePermission) => {
    return featurePermission.feature_role_id === feature_role_id && featurePermission.feature === feature;
  });
  return getDisplayPermission(permission);
}

function getDisplayPermission(permission: FeaturePermission | undefined) {
  if(permission?.level){
    return <span>{ DisplayHelpers.humanize(permission?.level) }</span>;
  } else {
    return <NoAccess>No</NoAccess>;
  }
}

const FeatureRolePermissionMappingTable = (props: Props) => {
  const featurePermissions = RosterHelpers.getAllFeaturePermissions(props.featureRoles);

  const featureRoleHeading = (
    <>
      <span>Feature Role </span>
      <InfoIconTooltip
        html={featureRoleInfo}
        data-testId="feature-role-tooltip"
        position={"right"}
      />
    </>
  );

  const featureRoleHeadings = [featureRoleHeading, ...props.featureRoles.map(featureRole => featureRole.name)];

  const generatePermissions = (featureSourceIndex: number) => {
    return [
      featureValues[featureSourceIndex].label,
      ...props.featureRoles.map(
        featureRole => getFeaturePermission(
          Number(featureRole.id) ,
          featureValues[featureSourceIndex].value,
          featurePermissions
        )
      )
    ];
  };

  return (
    <>
      <Table
        data={ featureValues.map((_, index) => generatePermissions(index)) }
        header={featureRoleHeadings}
      />
    </>
  );
};

export default FeatureRolePermissionMappingTable;
