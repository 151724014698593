import styled from '@emotion/styled';

export const EventStatusWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const ExpandMoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
