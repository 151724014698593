import React from 'react';
import NavButton from "./NavButton";
import {useAuthStore} from "../../store/auth_store";
import {Wrapper} from "./NavRail.styles";
import Helpers from "../../helpers/helpers";
import PermissionsHelpers from "../../helpers/permissions_helpers";
import ApplicationMenu from "./ApplicationMenu";

const NavRail = () => {
  const { currentUser } = useAuthStore((state) => ({ currentUser: state.currentUser }));

  if(!currentUser) return null;

  return (
    <Wrapper>
      <ApplicationMenu />
      <NavButton
        title={'Clients'}
        iconClass={'user'}
        route={'/clients'}
      />
      {
        (PermissionsHelpers.canForUserFeatureRole(currentUser,
          Helpers.getAppFeatureRoleDetails().levels.VIEW,
          Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS
        ) &&
        <NavButton
          title={'Admin'}
          route={'/admin'}
          iconClass={'cogs'}
        />
        )
      }
      {
        currentUser?.role?.toUpperCase().includes(Helpers.getAppRoles().BAMBOO_ADMIN) &&
        <>
          <NavButton
            title={'Settings'}
            iconClass={'cog'}
            route={'/app_settings'}
          />
          <NavButton
            title={'Tools'}
            iconClass={'tools'}
            route={'/tools'}
          />
        </>
      }
    </Wrapper>
  );
};

export default React.memo(NavRail);
