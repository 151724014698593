import { useAuth0} from "@auth0/auth0-react";
import { useAuthStore} from "../store/auth_store";

const useHandleLogout = () => {
  const { logout } = useAuth0();
  const { resetState } = useAuthStore();

  return () => {
    window.history.pushState(null, '', '/');
    window.history.replaceState(null, '', '/');

    sessionStorage.removeItem('access_check');
    resetState();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
};

export default useHandleLogout;
