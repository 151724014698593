import React from 'react';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import ErrorPage from './ErrorPage';
import {ErrorCenter, ErrorCenterContent} from './ErrorPage.styles';
import {useAuthStore} from "../../../store/auth_store";
import Auth0Api from "../../../api/auth0-api";

export default function PasswordExpired() {
  const { currentUser } = useAuthStore();
  
  let currentUserEmail: Nullable<string> = null;
    
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const emailFromParams = params.get('email');
    
  if(currentUser && currentUser.email) {
    currentUserEmail = currentUser.email;
  } else if (emailFromParams) {
    currentUserEmail = emailFromParams;
  }

  return (
    <ErrorPage title="Oops! Your password has expired.">
      <span>
          Check your inbox. We've sent an email to the address associated with your account.
          Follow the instructions in it and all will be well.
      </span>
      <ErrorCenter>
        {
          currentUserEmail &&
          <>
            <ErrorCenterContent>
              <span>
                Didn’t receive an email?
              </span>
            </ErrorCenterContent>
            <Button
              onClick={() => Auth0Api.sendPasswordResetEmail(currentUserEmail!)}
              styleType={StyleType.SECONDARY}
            >
                Send it again
            </Button>
          </>
        }
      </ErrorCenter>
    </ErrorPage>
  );
}
