import styled from '@emotion/styled';

type StyledMenuProps = {
  width?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export const StyledMenu = styled.ul<StyledMenuProps>((props) => ({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  margin: 0,
  ...(props.width && {
    width: props.width,
  }),
  ...(props.marginTop && {
    paddingTop: '2rem',
  }),
  ...(props.marginBottom && {
    paddingBottom: '2rem',
  }),
}));
