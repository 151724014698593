import React, {useEffect, useState} from 'react';
import {colors} from 'patient-ping-remedy/packages/theme';
import {ICONS} from 'patient-ping-remedy/packages/icon';
import {StandardTypography} from 'patient-ping-remedy/packages/typography';
import Checkbox from 'patient-ping-remedy/packages/checkbox';
import ToolTip from 'patient-ping-remedy/packages/tool-tip';
import {useAuthStore} from '../../../store/auth_store';
import {useRosterStore} from '../../../store/roster_store';
import {Client, User} from '../../../api/dto/dto';
import {useClientUserAssignments} from '../../../hooks/useClientUserAssignments';
import PermissionsHelpers from '../../../helpers/permissions_helpers';
import Helpers from '../../../helpers/helpers';
import Avatar from '../../common/Avatar';
import Menu from '../../common/menu/Menu';
import MenuItem from '../../common/menu/MenuItem';
import MenuItemTypography from '../../common/menu/MenuItemTypography';
import {MenuItemSpacer} from '../../common/menu/MenuItem.styles';
import MenuItemTrailingIcon from '../../common/menu/MenuItemTrailingIcon';
import {Wrapper, AssignedSection} from './AssignedToClientList.styles';
import DisplayHelpers from '../../../helpers/display_helpers';

export type AssignUserParams = {
  client: Client;
  user: User;
  delete: boolean;
}

type Props = {
  client: Client;
  assignUser?(params: AssignUserParams): Promise<unknown>;
}

export default function AssignedToClientList(props: Props) {
  const { currentUser} = useAuthStore();
  const { currentRoster } = useRosterStore();

  const canActOnClientListForRoster = PermissionsHelpers.canForUserFeatureRoleRoster(
    currentUser!,
    currentRoster!,
    Helpers.getAppFeatureRoleDetails().levels.ACT,
    Helpers.getAppFeatureRoleDetails().permissions.CLIENT_LIST,
  );

  const [disabledAssignUser, setDisabledAssignUser] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setDisabledAssignUser({
      ...disabledAssignUser,
      ...(currentUser && { [currentUser.id]: !canActOnClientListForRoster })
    });
  }, [currentUser, canActOnClientListForRoster]);

  const { userAssignmentsWithoutSelf, assignedToSelf} = useClientUserAssignments(props.client);

  const handleAssignUser = async (user: User, deleteUserAssignment: boolean) => {
    if (disabledAssignUser[user.id]) {
      return;
    }

    setDisabledAssignUser({ ...disabledAssignUser, [user.id]: true });

    try {
      await props.assignUser?.({ client: props.client, user, delete: deleteUserAssignment });
    } catch {
      // This catch is required so that useMutation.onError is properly invoked
      // error handling should also be done there (e.g. ClientsTable)
    } finally {
      setDisabledAssignUser({ ...disabledAssignUser, [user.id]: false });
    }
  };

  return (
    <Wrapper>
      {userAssignmentsWithoutSelf.length > 0 && (
        <AssignedSection>
          <StandardTypography color={colors.gray4}>{props.client.userAssignments.length} assigned</StandardTypography>
        </AssignedSection>
      )}
      <Menu>
        {currentUser && (
          <MenuItem
            data-testid="assign-to-self"
            disabled={disabledAssignUser[currentUser.id]}
            onClick={() => handleAssignUser(currentUser, assignedToSelf)}
          >
            <Checkbox value={assignedToSelf} disabled={disabledAssignUser[currentUser.id]} />
            <Avatar user={currentUser} />
            {assignedToSelf
              ? (
                <MenuItemTypography active>
                  {currentUser.first_name} {currentUser.last_name}{' '}
                  <MenuItemTypography>(assign to me)</MenuItemTypography>
                </MenuItemTypography>
              )
              : <MenuItemTypography>Assign to me</MenuItemTypography>}
            {!canActOnClientListForRoster && (
              <>
                <MenuItemSpacer />
                <ToolTip text={DisplayHelpers.contactAdminMessage()}>
                  <MenuItemTrailingIcon iconClass={ICONS['info-circle']} />
                </ToolTip>
              </>
            )}
          </MenuItem>
        )}
        {userAssignmentsWithoutSelf.map((userAssignment, index) => (
          <MenuItem key={index} disabled>
            <Checkbox value={true} disabled />
            <Avatar user={userAssignment.user} />
            <MenuItemTypography>
              {userAssignment.user.first_name} {userAssignment.user.last_name}
            </MenuItemTypography>
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
}
