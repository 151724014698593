import styled from '@emotion/styled';
import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import {DisplaySmallTypography, SmallTypography} from "patient-ping-remedy/packages/typography";

const NoNotificationsWrapper = styled.div`
  padding: 30px;
  font-style: italic;
`;

const NotificationGroup = styled.div`
  text-align: left;
`;

const TodayCategoryHeader = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const TimeCategoryHeader = styled(DisplaySmallTypography)`
  color: ${colors.gray4};
  font-size: ${fontSize.hint};
  text-align: left;
`;

const MarkAllAsReadLink = styled(SmallTypography)`
  color: ${colors.blue4};
  cursor: pointer;
`;

export {NoNotificationsWrapper, TodayCategoryHeader, NotificationGroup, TimeCategoryHeader, MarkAllAsReadLink};
