import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const Wrapper = styled.div`
  display: flex;
  padding: 10px 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWithBackground = styled.div`
  display: flex;
  background: ${colors.red5};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ClientNameLink = styled('span')`
  cursor: pointer;
`;

export { Wrapper, LeftWrapper, IconWithBackground, ClientNameLink };
