import useHandleLogout from "../hooks/useHandleLogout";
import {useEffect} from "react";

const Logout = () => {
  const handleLogout = useHandleLogout();

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
};

export default Logout;
