import React, {useCallback, useMemo} from 'react';
import {colors} from "patient-ping-remedy/packages/theme";
import Icon from "patient-ping-remedy/packages/icon";
import Popover from "patient-ping-remedy/packages/popover";
import {css} from "@emotion/css";
import DropdownMultiGroupSelect from "patient-ping-remedy/packages/dropdown-multi-group";
import {LabelSettingTypography} from "patient-ping-remedy/packages/typography";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import {CheckBoxItemStyle, CheckBoxRowStyle, HeadingStyle} from "./FilterComponent.styles";
import {UseQueryResult} from "@tanstack/react-query";

export interface nonGroupedValues {
  id: string;
  label: string;
  value: string;
  quickFilter?: boolean;
}

interface BaseFilterOption {
  type: 'multiselect-search' | 'multi-select-checkbox';
  label: string;
  options: nonGroupedValues[];
  key: string;
  hasQuickFilter?: boolean;
}

export interface DefaultFilterOption extends BaseFilterOption {
  dataType: 'string' | 'array';
}

interface AjaxFilterOption extends BaseFilterOption {
  dataType: 'ajax';
  query: FilterQuery;
}

export type FilterOption = DefaultFilterOption | AjaxFilterOption;

export type FilterQuery = {
  result: UseQueryResult;
  params: Record<string, unknown>;
}

export type FilterArgs = {
  filterOptions: FilterOption[];
  currentFilters: object;
}

type Props = {
  id?: string;
  filterFunction: Function;
  filterArgs: FilterArgs;
}

const FilterComponent = (props: Props) => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const filtersApplied = useMemo(() => {
    return Object.values(props.filterArgs.currentFilters)?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.length;
    }, 0);
  }, [props.filterArgs.currentFilters]);

  const getFilterLabel = () => {
    if(filtersApplied === 0) return 'Filter';
    return `${filtersApplied} ${filtersApplied > 1 ? 'filters' : 'filter'} applied`;
  };

  const handleChange = (e: nonGroupedValues[], key: string) => {
    props.filterFunction(e, key);
    setFiltersOpen(false);
  };

  const PopoverContent = useCallback(() => {
    return (
      <div className={css({ width: '350px', padding: '20px', overflow: 'visible' })}>
        {props.filterArgs?.filterOptions.map((option, index) => {
          if(option.type === 'multiselect-search') {
            return (
              <DropdownMultiGroupSelect
                id={`${option.label}-${index}`}
                className={css({fontSize: '1.6rem'})}
                key={index}
                handleChange={(e) => handleChange(e!, option.key)}
                isRequiredField={false}
                label={option.label}
                options={option.options}
                value={props.filterArgs.currentFilters[option.key as keyof typeof props.filterArgs.currentFilters] || []}
              />
            );
          } else if (option.type === 'multi-select-checkbox') {
            return (
              <CheckBoxRowStyle key={index}>
                <HeadingStyle>{option.label}</HeadingStyle>
                <>
                  {option.options.map(event => (
                    <CheckBoxItemStyle key={index}>
                      <Checkbox
                        key={event.id}
                        value={(props.filterArgs.currentFilters[option.key as keyof typeof
                          props.filterArgs.currentFilters] as string[]).includes(event.id)}
                        onChange={(checked) => props.filterFunction(event.id, checked)}
                        label={event.label}
                        id={event.id}
                      />
                    </CheckBoxItemStyle>
                  ))}
                </>
              </CheckBoxRowStyle>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }, [props, handleChange]);

  return (
    <>
      <Popover
        onHide={() => setFiltersOpen(false)}
        onShow={() => setFiltersOpen(true)}
        position="bottom"
        toggle={() => setFiltersOpen(!filtersOpen)}
        isOpen={filtersOpen}
        triggerTestId="popover-trigger"
        content={PopoverContent()}>
        <Icon iconClass={'filter'} color={colors.purple3} iconSize={'1x'} className={css({fontSize: '1.4rem'})} />
        <LabelSettingTypography className={css({marginLeft: '5px'})}>
          { getFilterLabel() }
        </LabelSettingTypography>
      </Popover>
    </>
  );
};

export default React.memo(FilterComponent);
