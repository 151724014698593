import React from "react";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {css} from "@emotion/css";
import {RosterResponse, User} from "../../../api/dto/dto";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import RosterSelection from "components/pages/admin/RosterSelection";
import {useAuthStore} from "../../../store/auth_store";
import DisplayHelpers from "../../../helpers/display_helpers";

type GenericObject = { [key: string]: any }

type Props = {
  user: User;
  rosters: RosterResponse | undefined;
  availableRosterOptions: Value[];
  form: GenericObject;
  handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
};

const GroupDetailsForm = (props: Props) => {
  const { currentUser } = useAuthStore();
  return (
    <>
      {
        props.form.rosters?.value?.length  === 0 && !PermissionsHelpers.isBamboo(props.user) &&
        <Banner type={BannerTypes.WARNING} className={css({marginBottom: '30px'})}>
          This user has no group selected. Please add at least one.
        </Banner>
      }

      {
        PermissionsHelpers.isBamboo(props.user) &&
        <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
          This user is a Bamboo Admin. They have access to all groups.
        </Banner>
      }

      {
        PermissionsHelpers.isSuperAdmin(props.user) &&
        <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
          This user is a Super Admin. To update their group permissions, contact your account manager.
        </Banner>
      }

      {
        currentUser?.id === props.user?.id &&
        !PermissionsHelpers.isSuperAdmin(props.user) &&
        !PermissionsHelpers.isBamboo(props.user) &&
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            {DisplayHelpers.contactGroupAdminMessage()}
          </Banner>
      }

      {
        !PermissionsHelpers.isBamboo(props.user) && props.rosters &&
        <RosterSelection
          user={props.user}
          rosters={props.rosters}
          availableRosterOptions={props.availableRosterOptions}
          rosterOptions={props.rosters?.rosterOptions ?? []}
          dataRoleOptions={props.rosters?.dataRoleOptions ?? []}
          featureRoleOptions={props.rosters?.featureRoleOptions ?? []}
          formRosters={props.form.rosters}
          handleChange={props.handleChange}
        />
      }
    </>
  );
};

export default GroupDetailsForm;
