import React, {useState} from 'react';
import Button, { StyleType } from 'patient-ping-remedy/packages/button/lib';
import DischargeInfoModal from './DischargeInfoModal';
import {DischargeEvent, DischargeInfo, Patient} from "../../../../../../api/dto/client-profile";
import {css} from "@emotion/css";
import {
  DateOfReceiptContainer,
  DischargeInfoButtonContainer,
  Layout,
  StyledIcon
} from "./EncounterDischargeInfo.styles";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import ToolTip from "patient-ping-remedy/packages/tool-tip";
import {ICONS} from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";
import DisplayHelpers from "../../../../../../helpers/display_helpers";

type Props = {
  event: DischargeEvent;
  dischargeInfo: DischargeInfo;
  patient: Patient;
  selectedRoster: number;
};

const EncounterDischargeInfo = ({ patient, dischargeInfo, event, selectedRoster }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Layout>
      <DischargeInfoButtonContainer>
        <Button
          className={css({ marginTop: '10px'})}
          testId="discharge-info"
          styleType={StyleType.SECONDARY}
          onClick={() => setShowModal(true)}
        >
          Discharge information
        </Button>
        <DateOfReceiptContainer>
          <SmallTypography>Date of receipt: {DisplayHelpers.formatDate(dischargeInfo.receivedDate)}</SmallTypography>
          <ToolTip text="This date shows when the discharge summary was made available to you and Bamboo Health">
            <StyledIcon iconClass={ICONS['info-circle']} color={colors.green4} iconSize="1x" weight="far" />
          </ToolTip>
        </DateOfReceiptContainer>
      </DischargeInfoButtonContainer>

      {showModal && (
        <DischargeInfoModal
          dischargeInfo={dischargeInfo}
          patient={patient}
          event={event}
          selectedRoster={selectedRoster}
          hideModal={() => setShowModal(false)}
        />
      )}
    </Layout>
  );
};

export default EncounterDischargeInfo;
