import Modal from "patient-ping-remedy/packages/modal";
import {BannerTypes} from "patient-ping-remedy/packages/banner";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useCallback, useContext, useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import {ADTEvent} from "../../../../../api/dto/client-profile";
import {SmallSemiBoldTypography, SmallTypography, StandardTypography} from "patient-ping-remedy/packages/typography";
import {
  ADMITTED,
  DECEASED,
  DISCHARGED,
  ENDED_HHA_SERVICES,
  ENDED_SERVICES,
  MEDICAL_LEAVE_OF_ABSENCE,
  PAYOR_CHANGED,
  RESUMED_SERVICES,
  TRANSFERRED
} from "../constants/PingStatuses";
import {useAlertStore} from "../../../../../store/alert_store";
import {ENCOUNTER_DELETED, GENERICERROR, PATIENT_REMOVED} from "../constants/BannerMessages";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {css} from "@emotion/css";
import DisplayHelpers from "../../../../../helpers/display_helpers";
import Spacer from "patient-ping-remedy/packages/spacer";
import {router} from "../../../../../router";
import {EncounterDeleteResponse} from "../../../../../api/dto/encounter";
import {AxiosError, AxiosResponse} from "axios";
import Helpers from "../../../../../helpers/helpers";

type Props = {
  hideModal: () => void,
  lastEvent?: ADTEvent,
  encounterId: string,
  patientId: string,
  rosterId: number,
  forceRefresh: Function
}

const banner = {
  type: BannerTypes.INFORMATIONAL,
  text: `This will delete the client's most recent event only. 
  This will not delete the whole encounter. Continue to delete each previous event as needed.`,
};

const PatientEncounterDeleteModal = (props: Props) => {
  const {addAlert} = useAlertStore();
  const {encounterApi} = useContext(CarecoApiContext);

  const {mutate, isPending} = useMutation({
    mutationFn: (deleteFunction: Function) => deleteFunction(),
    onSuccess: () => {
      props.hideModal();
      props.forceRefresh();
      addAlert({ content: ENCOUNTER_DELETED, type: ToastType.SUCCESS });
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  const handleDeleteEncounter = (patientId: string, rosterId: number, encounterId: string) => {
    encounterApi?.deleteEncounter(patientId, rosterId, encounterId)
      .then((response: AxiosResponse) => {
        let encounterDeleteResponse: EncounterDeleteResponse = response.data;
        if (encounterDeleteResponse.patientRemoved) {
          router.navigate('/clients')
            .then(() => {
              addAlert({content: PATIENT_REMOVED, type: ToastType.INFO});
              addAlert({content: ENCOUNTER_DELETED, type: ToastType.SUCCESS});
            });
        } else {
          props.hideModal();
          props.forceRefresh();
          addAlert({content: ENCOUNTER_DELETED, type: ToastType.SUCCESS});
        }
      })
      .catch((error: AxiosError) => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error(error);
      });
  };

  const handleKeyPress = useCallback((e : KeyboardEvent) => {
    if (isPending) {
      return;
    }
    if (e.key === 'Escape') {
      props.hideModal();
    }
    if (e.key === 'Enter') {
      handleConfirm();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  function handleConfirm() {
    switch (props.lastEvent?.status) {
    // case STARTED_HHA_SERVICES:
    case ADMITTED:
      handleDeleteEncounter(props.patientId, props.rosterId, props.encounterId);
      break;
    case TRANSFERRED:
      mutate(() =>
        encounterApi?.deleteTransfer(props.patientId, props.rosterId, props.encounterId, props.lastEvent!.eventId!));
      break;
    case ENDED_SERVICES:
    case RESUMED_SERVICES:
    case MEDICAL_LEAVE_OF_ABSENCE:
      mutate(() =>
        encounterApi?.deleteEvent(props.patientId, props.rosterId, props.encounterId, props.lastEvent!.eventId!));
      break;
    case DISCHARGED:
    case ENDED_HHA_SERVICES:
      mutate(() => encounterApi?.deleteDischarge(props.patientId, props.rosterId, props.encounterId));
      break;
    case DECEASED:
      mutate(() => encounterApi?.deleteDeceased(props.patientId, props.rosterId, props.encounterId));
      break;
    case PAYOR_CHANGED:
      mutate(() =>
        encounterApi?.deletePayorChange(props.patientId, props.rosterId, props.encounterId, props.lastEvent!.eventId!));
      break;
    default:
      // we should never get here
      console.error(`User attempted to delete event with status: ${props.lastEvent?.status}`,);
      props.hideModal();
    }
  }

  const modalButtons = [
    <Button key="submit" styleType={StyleType.PRIMARY} onClick={handleConfirm} disabled={isPending}>
      Delete
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={props.hideModal}>
      Cancel
    </Button>,
  ];

  return (
    <Modal
      className={css({maxWidth: "60rem"})}
      headerText="Delete Client Event"
      banner={banner}
      buttons={modalButtons}
    >
      <StandardTypography>Are you sure you want to delete this client event?</StandardTypography>
      <Spacer itemHeight={'1rem'} itemDisplay={'block'}/>
      <SmallSemiBoldTypography>Status:</SmallSemiBoldTypography>
      <SmallTypography> {props.lastEvent?.status}</SmallTypography>
      {
        props.lastEvent?.setting &&
        <>
          <Spacer itemDisplay={'block'}/>
          <SmallSemiBoldTypography>Setting:</SmallSemiBoldTypography>
          <SmallTypography> {props.lastEvent?.setting}</SmallTypography>
        </>
      }

      {
        props.lastEvent?.eventDate &&
        <>
          <Spacer itemDisplay={'block'}/>
          <SmallSemiBoldTypography>Event Date:</SmallSemiBoldTypography>
          <SmallTypography> {DisplayHelpers.formatDate(props.lastEvent?.eventDate)}</SmallTypography>
        </>
      }
    </Modal>
  );
};

export default PatientEncounterDeleteModal;
