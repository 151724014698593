import React from 'react';
import ContextMenu from "patient-ping-remedy/packages/context-menu";
import {useAuth0} from "@auth0/auth0-react";
import Avatar from "../common/Avatar";
import {router} from "../../router";
import {useAuthStore} from "../../store/auth_store";
import useHandleLogout from "../../hooks/useHandleLogout";

type Props = {
  className?: string;
}

const AvatarWidget = (props: Props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const handleLogout = useHandleLogout();
  const { currentUser } = useAuthStore();

  const handleProfile = () => {
    router.navigate('/profile');
  };

  let menuItems = [];

  if (isAuthenticated) {
    menuItems.push(
      {
        label: 'Manage Profile',
        action: handleProfile
      },
      {
        label: 'Logout',
        action: handleLogout
      }
    );
  } else {
    menuItems.push({
      label: 'Login',
      action: loginWithRedirect
    });
  }

  return (
    <div className={props.className}>
      <ContextMenu
        menuItems={menuItems}
      >
        <Avatar user={currentUser} />
      </ContextMenu>
    </div>
  );
};

export default React.memo(AvatarWidget);
