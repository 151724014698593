import {sizes} from "patient-ping-remedy/packages/theme/lib";
import styled from "@emotion/styled";
import Icon from "patient-ping-remedy/packages/icon";

export const Layout = styled.div`
  display: flex;
  align-items: baseline;
  > div:nth-of-type(1) {
    flex: 0 0 8rem;
  }
  > button {
    margin-right: ${sizes.xxSmall};
  }
`;

export const DischargeInfoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateOfReceiptContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: ${sizes.xxSmall} 0;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${sizes.xxSmall};
`;
