import styled from '@emotion/styled';

const Wrapper = styled.div`
    display: grid;
    grid-template-areas:
    "left-aside header"
    "left-aside main";
    grid-template-rows: 60px 1fr;
    grid-template-columns: 80px 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
    min-height: 100%;
    width: 100%;
`;

const Header = styled.div`
    grid-area: header;
`;

const Main = styled.div`
    grid-area: main;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const LeftAside = styled.div`
    grid-area: left-aside;
`;

export { Wrapper, Header, LeftAside, Main };
