export enum MixpanelEventType {
    BIH_USER_LOGGED_IN = "BIH - User Logged in",
    BIH_ADMIN_PAGE_VIEWED = "BIH - Admin Page Viewed",
    BIH_SSO_LAUNCHED = "BIH - SSO Launched",
}

export enum ViaType {
    EMAIL = "Email",
    NOTIFICATIONS = "Notifications",
    CLIENT_PROFILE = "Client Profile",
    CLIENT_LIST = "Client List",
    ADMIN = "Admin",
    USER_PROFILE = "User Profile",
}

export enum AdminPageTab {
    USERS = "Users",
    GROUPS = "Groups",
    ORGANIZATIONS = "Organizations",
}

export enum SSOLaunchMethod {
    CREATE_BUTTON = "Create Button",
    APPLICATION_MENU = "Application Menu",
}

export enum SSOApplication{
    OPENBEDS_REFERRALS = "OpenBeds Referrals",
    OPENBEDS_CRISIS = "OpenBeds Crisis",
    FINDHELP = "FindHelp",
    ROUND_TRIP = "RoundTrip",
}

export interface MixpanelEvent{
    eventName: MixpanelEventType;
    viewPortWidth: number;
    viewPortHeight: number;
    $os: string;
    device: string;
    currentApplicationLocation: string;
    pageUrl: string;
    via: Nullable<ViaType>;
}

export interface MixPanelAdminPageEvent extends MixpanelEvent{
    tabViewed: AdminPageTab;
    adminAccess: string
}

export interface MixPanelSSOLaunchEvent extends MixpanelEvent{
    applicationName: SSOApplication,
    launchMethod: SSOLaunchMethod;
}
