export function toTitleCase(text) {
  return text
    ? text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
    : text;
}

export function getPatientFullName({ firstName, middleName, lastName }) {
  const nameArray = [firstName, middleName, lastName];
  return nameArray
    .filter((name) => name)
    .map((name) => toTitleCase(name))
    .join(' ');
}

export const breakpoints = [500, 1000, 1500];
export const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
