import styled from '@emotion/styled';

const Form = styled('div')`
    display: flex;
    flex-direction: column;
`;

const NameDiv = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export { Form, NameDiv };
