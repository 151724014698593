import ADTCard from "./timeline_components/ADTCard";
import Timeline from "../../../../common/timeline/Timeline";
import {ADTItem, ClientEncounter, ClientProfile, EncounterType} from "../../../../../api/dto/client-profile";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import React from "react";
import Loading from "../../../../common/Loading";
import CrisisCard from "./timeline_components/CrisisCard";
import {IntakeDetails} from "../../../../../api/dto/crisis";
import ReferralCard from "./timeline_components/ReferralCard";
import {ReferralDetails} from "../../../../../api/dto/referral";
import JusticeInvolvedCard from "./timeline_components/JusticeInvolvedCard";
import {JusticeInvolvedItem} from "../../../../../api/dto/justice-involved";
import SDOHCard from "./timeline_components/SDOHCard";
import {SDOHItem} from "../../../../../api/dto/sdoh";
import {useInView} from "react-intersection-observer";

type Props = {
  encounters: ClientEncounter[];
  patient: ClientProfile;
  isLoading: boolean;
  isPolling: boolean;
  forceRefresh: Function;
  openDemographics: Function;
}

const ClientSummaryTimeline = (props: Props) => {
  const { ref, inView } = useInView();

  return (
    <>
      {(!inView && (props.isLoading || props.isPolling)) && <Loading iconSize={'2x'}/>}
      {
        !props.isLoading && props.encounters.length === 0 &&
        <SmallTypography>Client does not have any reported encounters.</SmallTypography>
      }
      <Timeline>
        {
          props.encounters.map((encounter, index) => {
            if (encounter.type === EncounterType.ADT) {
              return (
                <ADTCard
                  id={`encounter-${encounter.content.encounterId}`}
                  patient={props.patient}
                  event={encounter.content as ADTItem}
                  isPart2={encounter.isPart2}
                  key={index}
                  latestEncounter={props.patient?.currentPingEncounter?.encounterId === encounter.content.encounterId}
                  forceRefresh={props.forceRefresh}
                  openDemographics={props.openDemographics}
                />
              );
            } else if (encounter.type === EncounterType.OPENBEDS_REFERRAL) {
              return <ReferralCard
                key={index}
                id={`referral-${encounter.content.master_referral_id}`}
                referrals={encounter.content.referrals as ReferralDetails[]}
                isPart2={encounter.isPart2}
                patient={props.patient}/>;
            } else if (encounter.type === EncounterType.OPENBEDS_CRISIS) {
              return (
                <CrisisCard
                  id={`crisis-${encounter.content.uuid}`}
                  patient={props.patient}
                  event={encounter.content as IntakeDetails}
                  isPart2={encounter.isPart2}
                  key={index}
                />
              );
            } else if (encounter.type === EncounterType.FINDHELP_SDOH) {
              return (
                <SDOHCard
                  key={index}
                  id={`sdoh-${encounter.content.referralId}`}
                  isPart2={encounter.isPart2}
                  event={encounter.content as SDOHItem}
                />
              );
            } else if(encounter.type === EncounterType.JUSTICE_INVOLVED) {
              return (
                <JusticeInvolvedCard
                  id={`justice-involved-${encounter.content.id}`}
                  event={encounter.content as JusticeInvolvedItem}
                  key={index}
                />
              );
            }
            else {
              return null;
            }
          })
        }
      </Timeline>
      <div ref={ref}>
        {props.isLoading && <Loading iconSize={'4x'}/>}
      </div>
    </>
  );
};

export default ClientSummaryTimeline;
