import styled from '@emotion/styled';

export const StyledRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 28rem;
  }
`;
