import Icon from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";
import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const StyledTooltipIcon = styled(Icon)((props: {hoverColor: string}) => (
  css`
    :hover {
      color: ${props.hoverColor ? props.hoverColor : colors.primary1};
    }
`));
