import React, { PureComponent } from 'react';
import { css } from '@emotion/core';
import {HOSPITAL, SNF, SNF_ADMIN, URGENT_CARE} from "../../../constants/FacilityTypes";
import {
  ADMITTED,
  CLOSED, DECEASED,
  DISCHARGED,
  ENDED_SERVICES,
  MEDICAL_LEAVE_OF_ABSENCE, RESUMED_SERVICES,
  TRANSFERRED
} from "../../../constants/PingStatuses";

const noBoldHere = css`
  strong {
    font-weight: normal;
  }
`;

export default class PingLocationText extends PureComponent {
  static defaultProps = {
    omitType: false,
    current: false,
  };

  getSetting() {
    const {
      ping: { status, facility, setting },
    } = this.props;
    if (status === MEDICAL_LEAVE_OF_ABSENCE || status === ENDED_SERVICES) {
      return '';
    }
    if (facility && facility.type === HOSPITAL && setting) {
      return setting.toLowerCase();
    }

    if (facility && facility.type === URGENT_CARE && setting) {
      return setting.toLowerCase();
    }

    if (
      facility &&
      (facility.type === SNF || facility.type === SNF_ADMIN) &&
      setting &&
      setting.toLowerCase() !== 'unknown'
    ) {
      return setting.toLowerCase();
    }
    return '';
  }

  getPostEventStatusText() {
    const {
      ping: { status },
      current,
    } = this.props;
    switch (status) {
    case ADMITTED:
      return current ? 'at' : 'to';
    case TRANSFERRED:
    case CLOSED:
      return 'to';
    case DISCHARGED:
      return 'from';
    case DECEASED:
      return 'at';
    case MEDICAL_LEAVE_OF_ABSENCE:
      return 'at';
    case RESUMED_SERVICES:
      return 'at';
    case ENDED_SERVICES:
      return 'at';
    default:
      return 'at';
    }
  }

  getGroupTypeText() {
    const {
      omitType,
      ping: {
        facility: { type },
      },
    } = this.props;
    const facilityType = type.replace(/_/g, ' ');
    return !(omitType || type === URGENT_CARE || type === HOSPITAL || type === SNF) ? `(${facilityType})` : '';
  }

  render() {
    const {
      ping: {
        facility: { name },
      },
    } = this.props;
    const setting = this.getSetting();
    const postEventText = this.getPostEventStatusText();
    const groupTypeText = this.getGroupTypeText();
    const groupTypeAndSettingText = groupTypeText ? `${groupTypeText} ${setting}` : `${setting}`;
    return (
      <span css={noBoldHere} data-testid="ping-location-text">
        {`${postEventText} ${name} ${groupTypeAndSettingText}`}
      </span>
    );
  }
}
