import React from 'react';
import {router} from '../../router';
import {ReactComponent as DTRN360} from '../../assets/DTRN360.svg';
import {ReactComponent as PoweredBy} from '../../assets/powered-by.svg';
import {Button} from './Logo.styles';

export default function Logo() {
  return (
    <Button onClick={() => router.navigate('/clients')}>
      <DTRN360 aria-label="DTRN360" />
      <PoweredBy aria-label="Powered by Bamboo Health" />
    </Button>
  );
}
