// Similar Pings formatting file:
// https://github.com/PatientPing/ping/blob/master/janus/app/src/js/encounteredit/actions/encounters.js
import Helpers from "../../../../../../../helpers/helpers";

export const formatPatientOptions = ({ patientId, data }) => {
  const dataVar = data;

  // pings uses dob but unrestricted endpoint expects dateOfBirth
  // so account for both here
  if ('dob' in dataVar) {
    dataVar.dob = new Date(dataVar.dob).toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  if ('dateOfBirth' in dataVar) {
    dataVar.dateOfBirth = new Date(dataVar.dateOfBirth).toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  // also pass nulls instead of empty strings (different from pings)
  if ('ssn' in dataVar) {
    if (Helpers.isEmpty(dataVar.ssn)) {
      dataVar.ssn = null;
    }
  }

  if ('address' in dataVar) {
    if ('zip' in dataVar.address) {
      if (Helpers.isEmpty(dataVar.address.zip)) {
        dataVar.address.zip = null;
      }
    }
  }

  return {
    patientId,
    data: dataVar,
  };
};

export const formatEncounterOptions = ({ options, groupId = null, patientId = null }) => ({
  admit: {
    ...options,
    date: new Date(options.date),
    selectedInsurancePlan: {
      id: options.selectedInsurancePlan.id,
    }
  },
  publicPatientId: patientId,
  creatorGroupId: Number(groupId),
});

export const formatDischargeOptions = ({ encounterId, options }) => {
  const location = {};
  if (options.locationDescription) {
    location.locationDescription = options.locationDescription;
  }
  if (options.facility.facilityName) {
    location.facility = options.facility;
  }
  if (options.secondaryFacility.facilityName) {
    location.secondaryFacility = options.secondaryFacility;
  }
  return {
    encounterId: encounterId,
    options: {
      date: new Date(options.date),
      disposition: {
        dispositionType: {
          id: options.dispositionType.id,
          itemCode: options.dispositionType.code,
        },
        location,
      },
    },
  };
};

export const formatTransferOptions = ({ encounterId, options }) => ({
  encounterId: encounterId,
  options: {
    setting: options.setting,
    date: new Date(options.date),
    selectedInsurancePlan: {
      id: options.selectedInsurancePlan.id,
    }
  },
});

export const formatDeceasedOptions = ({ encounterId, options }) => ({
  encounterId: encounterId,
  options: {
    date: new Date(options.date),
  },
});

export const formatPayorChangeOptions = ({ encounterId, options }) => ({
  encounterId: encounterId,
  options: {
    date: new Date(options.date),
    payor: {
      id: options.payor.id,
    }
  },
});

// export const formatMedicalLeaveOfAbsenceOptions = ({ encounterId, options }) => ({
//   encounterId: encounterId,
//   options: {
//     eventDate: new Date(options.date),
//     payor: options.selectedInsurancePlan,
//   },
// });

// export const formatResumeServicesOptions = ({ encounterId, options }) => ({
//   encounterId: encounterId,
//   options: {
//     eventDate: new Date(options.date),
//     setting: options.setting,
//     payor: options.selectedInsurancePlan,
//   },
// });
//
// export const formatEndServicesOptions = ({ encounterId, options }) => ({
//   encounterId: encounterId,
//   options: {
//     eventDate: new Date(options.date),
//   },
// });

// export const formatAutomatedEncounterTransferOptions = ({ patientId, encounterId, options }) => ({
//   patientId,
//   encounterId: getEncounterId(encounterId),
//   options: {
//     setting: options.setting,
//     date: formatServerDate(options.date),
//   },
// });

// export const formatUpdateAutomatedEventSettingOptions = ({ patientId, encounterId, eventId, options }) => ({
//   options: { ...options },
//   patientId,
//   encounterId: encounterId,
//   eventId,
// });
