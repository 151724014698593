import React from 'react';
import {css} from '@emotion/core';

import {StandardSemiBoldTypography} from 'patient-ping-remedy/packages/typography';
import {PingStatuses} from "../../../constants/PingStatuses";
import PingStateText from "../common/PingStateText";
import PingLocationText from "../common/PingLocationText";
import PingDispositionText from "../common/PingDispositionText";
import PingDateText from "../common/PingDateText";
import PingVisitDurationText from "../common/PingVisitDurationText";
import EncounterDiagnosis from "../common/EncounterDiagnosis";
import {EncounterInfoFlexRow, StyledEncounterInfoAndPrograms, StyledEvent} from "./EncounterInfoAndPrograms.styles";

export default class EncounterInfoAndPrograms extends React.Component {

  render() {
    const { patient } = this.props;

    return (
      <EncounterInfoFlexRow>
        <StyledEncounterInfoAndPrograms className="patient-card-content">
          {!!patient.currentPing && !!patient.currentPingEncounter ? (
            <StyledEvent className="event-section" data-testid="event-section">
              {patient.currentPing.state === PingStatuses.CLOSED ? (
                <div className={css({"font-size": "rem"})}>
                  ENCOUNTER CLOSED - update from{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    {patient.currentPingEncounter.admittingFacility
                      ? patient.currentPingEncounter.admittingFacility.name
                      : ' facility '}
                  </span>{' '}
                  never provided.
                </div>
              ) : (
                <div>
                  <StandardSemiBoldTypography>
                    <PingStateText ping={patient.currentPing} />{' '}
                  </StandardSemiBoldTypography>
                  <PingLocationText ping={patient.currentPing} />{' '}
                  <PingDispositionText ping={patient.currentPing} pingEncounter={patient.currentPingEncounter} />{' '}
                  <PingDateText
                    date={patient.currentPing.eventDate}
                    setting={patient.currentPing.setting}
                    facilityType={patient.currentPing.facility?.type}
                  />{' '}
                  <PingVisitDurationText
                    pingEncounter={patient.currentPingEncounter}
                    ping={patient.currentPing}
                    current={true}
                  />
                  {patient.currentPingEncounter?.diagnosis?.synonym && (
                    <span>
                      {' '}
                      for{' '}
                      <b>
                        <EncounterDiagnosis encounter={patient.currentPingEncounter} />
                      </b>
                    </span>
                  )}
                  .
                </div>
              )}
            </StyledEvent>
          ) : (
            <div className="event-section-empty-state">
              No client encounters yet.
            </div>
          )}
        </StyledEncounterInfoAndPrograms>
      </EncounterInfoFlexRow>
    );
  }
}
