import React, {useMemo } from "react";
import {RosterPermissions} from "../../../../api/dto/dto";
import Spacer from "patient-ping-remedy/packages/spacer";
import Table from "../../../common/Table";
import {NoAccess} from "../../../common/user_details/AccessDetails.styles";
import {featureTypeInfo, dataTypeInfo} from "./GroupDetailsDrawer";
import {dataSourceValues, featureValues} from "../../../../helpers/roster_helpers";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import InfoIconTooltip from "../../../common/InfoIconTooltip";

type Props = {
  rosterPermissions?: RosterPermissions | undefined
}

function yesOrNo(enabled: boolean) {
  if (enabled) {
    return <span>{ 'Yes' }</span>;
  } else {
    return <NoAccess>No</NoAccess>;
  }
}

function rolePurchased(roleName: string, rosterPermission: RosterPermissions | undefined) {
  if (!rosterPermission) {
    return yesOrNo(false);
  }

  switch(roleName) {
  //data roles
  case "ADT": {
    return yesOrNo(rosterPermission.adtEnabled);
  }
  case "OpenBeds referrals": {
    return yesOrNo(rosterPermission.obReferralsEnabled);
  }
  case "OpenBeds crisis": {
    return yesOrNo(rosterPermission.obCrisisEnabled);
  }
  case "FindHelp referrals": {
    return yesOrNo(rosterPermission.findHelpEnabled);
  }
  case "PDMP": {
    return yesOrNo(rosterPermission.pdmpEnabled);
  }
  case "Justice involved": {
    return yesOrNo(rosterPermission.jiEnabled);
  }
  case "Roundtrip": {
    return yesOrNo(rosterPermission.roundtripEnabled);
  }

  // feature roles
  case "Client list": {
    return yesOrNo(rosterPermission.clientListEnabled);
  }
  case "Client profile": {
    return yesOrNo(rosterPermission.clientProfileEnabled);
  }
  case "Forms": {
    return yesOrNo(rosterPermission.formsEnabled);
  }
  case "User management": {
    return yesOrNo(rosterPermission.userPermissionsEnabled);
  }
  case "Notifications": {
    return yesOrNo(rosterPermission.notificationsEnabled);
  }
  default: {
    return yesOrNo(false);
  }
  }
}

const GroupPermissionTable = (props: Props) => {
  const dataRoleNames = useMemo(() =>
    dataSourceValues, []);
  const featureRoleNames = useMemo(() =>
    featureValues, []);

  let dataRoleData = dataRoleNames.map((dataRoleName: Value) => {
    return [dataRoleName.label, rolePurchased(dataRoleName.label, props.rosterPermissions)];
  });

  let featureRoleData = featureRoleNames.map((featureRoleName: Value) => {
    return [featureRoleName.label, rolePurchased(featureRoleName.label, props.rosterPermissions)];
  });

  const dataRoleHeading = (
    <>
      <span>Data Type </span>
      <InfoIconTooltip
        html={dataTypeInfo}
        data-testId="data-role-tooltip"
        position={"right"}
      />
    </>
  );

  const featureRoleHeading = (
    <>
      <span>Feature Type </span>
      <InfoIconTooltip
        html={featureTypeInfo}
        data-testId="feature-role-tooltip"
        position={"right"}
      />
    </>
  );

  return (
    <>
      <Table data={dataRoleData} header={[dataRoleHeading, 'Purchased']} />
      <Spacer itemHeight={'30px'} />
      <Table data={featureRoleData} header={[featureRoleHeading, 'Purchased']} />
    </>
  );
};

export default GroupPermissionTable;
