// THIS IS BASICALLY COPY-PASTED FROM PatientModal.js with exclusion
// of non-editing code and moving the update functions into this component vs as props
// https://github.com/PatientPing/ping/blob/master/janus/app/src/js/encounteredit/components/PatientModal.js
import React, {useContext, useEffect, useMemo, useState} from "react";
import Spacer from "patient-ping-remedy/packages/spacer";
import {sizes} from "patient-ping-remedy/packages/theme";
import {PingPatient} from "../../EncounterActions";
import {ADMITTED, DECEASED, DISCHARGED, PingStatuses, TRANSFERRED} from "../../constants/PingStatuses";
import {
  formatSubmitInsurancePlans,
  getInsurancePlanOptions,
  getStatusConfig
} from "../../encounter_update_status/pings/utils/utils";
import PatientDemographics from "../../encounter_admit/pings/PatientDemographicsHookForm";
import InsurancePlanHookForm from "../../encounter_update_status/pings/components/InsurancePlansHookForm";
import StatusInfoHookForm from "../../encounter_update_status/pings/components/StatusInfoHookForm";
import {Roster} from "../../../../../../api/dto/dto";
import {FormProvider, useForm} from "react-hook-form/dist/index.ie11";
import Modal from "patient-ping-remedy/packages/modal";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {ToastType} from "patient-ping-remedy/packages/toast";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import {ENCOUNTER_UPDATED, GENERICERROR, PATIENT_UPDATED,} from "../../constants/BannerMessages";
import {StyledSmallTypography} from "../../encounter_client_search/AdmitClientModal.styles";
import {AxiosError, AxiosResponse} from "axios";
import {ADTEvent, ADTItem, ClientProfile, InsurancePlan} from "../../../../../../api/dto/client-profile";
import { getGenderAbbreviation } from "../../constants/Genders";
import EncounterResolveConflict from "./EncounterResolveConflict";
import {payorChangeLaterThanActiveEvent} from "./encounterDataUtils";
import {CarecoApiContext} from "../../../../../../app-context/careco-api-context";
import {useAlertStore} from "../../../../../../store/alert_store";
import {
  formatDeceasedOptions, formatDischargeOptions,
  formatEncounterOptions, formatTransferOptions
} from "../../encounter_update_status/pings/utils/format_encounters";
import {UpdatePayorChangeJson} from "../../../../../../api/dto/encounter";
import Helpers from "../../../../../../helpers/helpers";

type Props = {
  patient: PingPatient;
  hideModal: () => void;
  admittingGroup: Roster;
  isSnf: boolean;
  forceRefresh: Function;
  updatePatient: Function;
  getPatientEncounters: (patientId: string, groupId: number) => Promise<ADTItem[]>;
}

type GenericObject = {
  [key: string]: any;
}

export type PatientUpdates = {
  dateOfBirth: string,
  firstName: string,
  gender: string | undefined,
  insurancePlans: InsurancePlan[],
  lastName: string,
  ssn: string | undefined,
  address: {
    zip: string | undefined
  }
}

export type FormData = {
  dob: string,
  firstName: string,
  gender: string | undefined,
  insurancePlans: InsurancePlan[],
  selectedInsurancePlan: InsurancePlan,
  lastName: string,
  ssn: string | undefined,
  zip: string | undefined,

  // different data will come in for each event
  // but all will have the above data
  [key: string]: any
}

export type ActionUpdate = {
  data: FormData,
  encounterId: string,
  options: GenericObject,
  patientId: string,
  eventId: number,
  selectedInsurancePlan: InsurancePlan,
}

type EncountersToResolve = {
  data: FormData | GenericObject,
  updates?: PatientUpdates,
  encounterId?: string,
  response: {
    total: number,
    encounters: ADTItem[]
  },
  hasBeenSubmitted?: boolean
}

type PayorChangeUpdateParams = {
  patient: ClientProfile,
  selectedInsurancePlan: InsurancePlan,
  updatePayorChange: Function,
  deletePayorChange: Function,
  addAlert: Function,
  hideModal: Function,
  forceRefresh: Function,
  setInflight: Function
}

export type PayorChangeUpdate = {
  encounterId: string,
  payorChangeId: number,
  patientId: string,
}

const EDIT_PATIENT = 'EDIT_PATIENT';
const RESOLVE_CONFLICT = 'RESOLVE_CONFLICT';

const getDefaultValues = (patient: PingPatient, admittingGroup: Roster) => {
  const { currentInsurancePlan, patientInsurancePlans } = getInsurancePlanOptions(patient);

  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    gender: DisplayHelpers.genderFromEnum(patient.gender ?? ''),
    dob: new Date(patient.dateOfBirth + 'T00:00:00'), // need this for the 1-day problem with no time zone dates
    zip: patient.address?.zip,
    ssn: patient.ssn,
    date: patient?.currentPing?.eventDate ? new Date(patient.currentPing.eventDate) : new Date(),
    setting: patient.currentPing?.setting || '',
    admittedFrom: { label: patient.currentPingEncounter?.admittedFrom || '', id: '' },
    insurancePlans: patientInsurancePlans,
    selectedInsurancePlan: currentInsurancePlan,
    groupId: admittingGroup.id,
    chosenPatient: null,
  };
};

const payorChangeUpdate = ({
  patient,
  selectedInsurancePlan,
  updatePayorChange,
  deletePayorChange,
  addAlert,
  hideModal,
  forceRefresh,
  setInflight,
} : PayorChangeUpdateParams) => {
  // active event is the currentPing returned by pings (this does NOT include payor changes)
  const payorChange = payorChangeLaterThanActiveEvent({ patient } );
  const prevPayorChangeExists = payorChange.length > 0;

  const { insuranceCompanyName, value } =
  patient.currentPingEncounter?.events.find((ping: ADTEvent) => {
    return ping.eventId.toString() === patient.currentPing?.eventId.toString();
  })?.payor || {};

  const insuranceChanged =
    insuranceCompanyName !== selectedInsurancePlan.insuranceCompanyName || value !== selectedInsurancePlan.value;

  if (prevPayorChangeExists && insuranceChanged) {
    // if there is a payor change event after the active event and the insurance has changed
    // we will update the payor change
    let payorChangeJson : UpdatePayorChangeJson = {
      date: new Date(),
      payor: selectedInsurancePlan
    };

    updatePayorChange({
      encounterId: patient.currentPingEncounter?.encounterId,
      eventId: payorChange.at(-1).eventId,
      patientId: patient.patient.patientId,
      options: payorChangeJson,
      skipPatientFetch: true,
    })
      .then(() => {
        forceRefresh();
        hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
      });
  } else if (prevPayorChangeExists) {
    // if there are previous payor changes, but we are changing the insurance back to the insurance of
    // the original event, we will delete the latest payor change after the active event
    // since we are reverting back to the original insurance
    // not this logic is not the greatest, pings will be reworking this logic and when that is complete
    // we will also need to adjust
    // TODO https://hbuco.atlassian.net/browse/PNGDAT-2012
    deletePayorChange({
      encounterId: patient.currentPingEncounter?.encounterId,
      payorChangeId: payorChange.at(-1).eventId,
      patientId: patient.patient.patientId,
    })
      .then(() => {
        forceRefresh();
        hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        addAlert({content: GENERICERROR, type: ToastType.ERROR});
        setInflight(false);
      });
  }
};

const defaultEncounterResolution = { data: {}, response: { total: 0, encounters: [] }, hasBeenSubmitted: false };

const EncounterEdit = (props: Props) => {
  const [step, setStep] = useState(EDIT_PATIENT);
  const patientValues = props.patient;
  const [inflight, setInflight] = useState(false);
  const [encountersToResolve, setEncountersToResolve] = useState<EncountersToResolve>(defaultEncounterResolution);
  const teamName = props.admittingGroup?.name || '';

  const { encounterApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();

  const updateAdmit = (update: ActionUpdate) => {
    const json = formatEncounterOptions({ options: update.options }).admit;
    return encounterApi!.updateAdmit(json, update.patientId, update.encounterId, props.admittingGroup.id);
  };

  const updatePayorChange = (update: ActionUpdate) => {
    return encounterApi!.updatePayorChange(
      update.options as UpdatePayorChangeJson,
      update.patientId,
      update.encounterId,
      props.admittingGroup.id,
      update.eventId
    );
  };

  const updateDeceased = (update: ActionUpdate) => {
    let json = formatDeceasedOptions(update);
    return encounterApi!.updateDeceased(json.options, update.patientId, update.encounterId, props.admittingGroup.id);
  };

  const updateDischarge = (update: ActionUpdate) => {
    let json = formatDischargeOptions(update);
    return encounterApi!.updateDischarge(json.options, update.patientId, update.encounterId, props.admittingGroup.id);
  };

  const updateTransfer = (update: ActionUpdate) => {
    let json = formatTransferOptions(update);
    return encounterApi!.updateTransfer(
      json.options,
      update.patientId,
      update.encounterId,
      props.admittingGroup.id,
      update.eventId);
  };

  const deletePayorChange = (update: PayorChangeUpdate) => {
    return encounterApi!.deletePayorChange(update.patientId, props.admittingGroup.id, update.encounterId, update.payorChangeId);
  };

  const defaultValues = useMemo(() => {
    return getDefaultValues(patientValues, props.admittingGroup);
  }, [patientValues, props.admittingGroup]);

  const hookFormMethods = useForm({ defaultValues, mode: 'onChange' });

  const {formState: { dirtyFields, errors }, reset} = hookFormMethods;

  useEffect(
    function scrollToError() {
      const errorsArray = Object.values(errors);
      if (errorsArray.length) {
        let errorName = errorsArray[0]?.ref?.name;

        //Datepickers add additional string to the id supplied to the component
        if (errorName === 'date') {
          errorName = 'remedyDatePickeradmitDate';
        } else if (errorName === 'dob') {
          errorName = 'remedyDatePickerdobPatientAdmit';
        }

        const errorNode = document.querySelector(`#${errorName}`);
        errorNode?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [errors],
  );

  const handleAdmit = (update: ActionUpdate) => {
    const admitUpdate = {
      ...update,
      options: {
        ...update.options,
        admittedFromFacilityId: update.data?.admittedFrom?.id,
        admittedFromFacilityName: update.data.admittedFrom?.label,
      },
    };
    updateAdmit(admitUpdate)
      .then(() => {
        props.forceRefresh();
        props.hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        addAlert({content: GENERICERROR, type: ToastType.ERROR});
        setInflight(false);
      })
    ;
  };

  const handleDischarge = (update: ActionUpdate) => {
    const defaultInputKeyValue = { id: '', label: '' };

    const {
      locationDescription = defaultInputKeyValue,
      facility = defaultInputKeyValue,
      secondaryFacility = defaultInputKeyValue,
      dischargeDate,
      dispositionType,
    } = update.data;

    const dischargeUpdate = {
      ...update,
      options: {
        ...update.options,
        dispositionType,
        date: dischargeDate,
        facility: { ...facility, facilityName: facility.label },
        secondaryFacility: { ...secondaryFacility, facilityName: secondaryFacility.label ?? null },
        locationDescription: locationDescription.label ?? null,
      },
    };

    updateDischarge(dischargeUpdate)
      .then(() => {
        props.forceRefresh();
        props.hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((error: AxiosError) => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error(error);
        setInflight(false);
      });
  };

  const handleTransfer = (update: ActionUpdate) => {
    updateTransfer(update)
      .then(() => {
        props.forceRefresh();
        props.hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        setInflight(false);
      });
  };

  const handleDeceased = (update: ActionUpdate) => {
    updateDeceased(update)
      .then(() => {
        props.forceRefresh();
        props.hideModal();
        addAlert({content: ENCOUNTER_UPDATED, type: ToastType.SUCCESS});
        addAlert({content: PATIENT_UPDATED, type: ToastType.SUCCESS});
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        setInflight(false);
      });
  };

  const editActions : GenericObject = {
    [ADMITTED]: (update: ActionUpdate) => handleAdmit(update),
    [TRANSFERRED]: (update: ActionUpdate) => handleTransfer(update),
    // [MEDICAL_LEAVE_OF_ABSENCE]: (update) => updateMedicalLeaveOfAbsence({ ...update, patient }),
    // [RESUMED_SERVICES]: (update) => updateResumeServices(update),
    // [ENDED_SERVICES]: (update) => updateEndServices(update, patient),
    [DISCHARGED]: (update: ActionUpdate) => handleDischarge(update),
    [DECEASED]: (update: ActionUpdate) => handleDeceased(update),
  };

  const editEncounter = (updates: PatientUpdates, data: FormData) => {
    const { currentPingEncounter, id, currentPing } = props.patient;
    const encounterId = currentPingEncounter?.encounterId || '';
    const eventId = currentPing?.eventId!;

    // gender on this page comes in as "Male" not "M"
    updates.gender = getGenderAbbreviation(updates.gender);
    data.gender = getGenderAbbreviation(data.gender);

    props.updatePatient({ patientId: id, encounterId: encounterId, data: updates })
      .then((response: AxiosResponse) => {
        const updatedPatient = response.data;
        const updatedSelectedPlan = updatedPatient.currentInsurancePlans
          .find((plan: InsurancePlan) => plan.selected);

        const options = {
          date: data.date,
          setting: data.setting,
          selectedInsurancePlan: updatedSelectedPlan,
        };

        const actionUpdate : ActionUpdate = {
          data,
          encounterId,
          options,
          patientId: id!,
          eventId: eventId,
          selectedInsurancePlan: updatedSelectedPlan,
        };

        payorChangeUpdate({
          patient: {
            ...updatedPatient,
            // bug where currentPingEncounter returned from pings is not including all events
            // TODO https://hbuco.atlassian.net/browse/PNGDAT-2012
            currentPingEncounter: props.patient.currentPingEncounter,
          },
          selectedInsurancePlan: updatedSelectedPlan,
          updatePayorChange,
          deletePayorChange,
          addAlert,
          hideModal: props.hideModal,
          forceRefresh: props.forceRefresh,
          setInflight,
        });

        const editStatus = editActions[currentPing?.status as keyof typeof editActions];
        if (editStatus) {
          editStatus(actionUpdate);
        }
      })
      .catch((e: AxiosError) => {
        console.error('error', e);
        const traceId = e.response?.headers['x-trace-id'];
        addAlert({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        setInflight(false);
      });
  };

  const confirmConflict = () => {
    setInflight(true);
    editEncounter(encountersToResolve.updates!, encountersToResolve.data as FormData);
  };

  const cancelResolveConflict = () => {
    // @ts-ignore result is from a non ts file so typing incorrect
    reset({ ...encountersToResolve.data });
    setEncountersToResolve({ ...defaultEncounterResolution, hasBeenSubmitted: true });
    setStep(EDIT_PATIENT);
  };

  const submitSaveEdit = (data: FormData) => {
    setInflight(true);
    const { insurancePlans, selectedInsurancePlan, firstName, lastName, gender, dob, zip, ssn } =
      data;

    const encounterId = props.patient.currentPingEncounter?.encounterId || '';

    const formattedInsurancePlans : InsurancePlan[] = formatSubmitInsurancePlans(
      defaultValues.insurancePlans,
      insurancePlans,
      selectedInsurancePlan,
      false
    );

    const updates : PatientUpdates = {
      firstName,
      lastName,
      gender,
      dateOfBirth: dob,
      address: { zip },
      insurancePlans: formattedInsurancePlans,
      ssn,
    };

    props.getPatientEncounters(
      props.patient.patientId,
      props.admittingGroup.id
    ).then((response) => {
      if (response.length > 1) {
        // @ts-ignore result is from a non ts file so typing incorrect
        defaultValues.selectedInsurancePlan = data.selectedInsurancePlan;
        setEncountersToResolve({ response: { total: response.length, encounters: response}, data, updates, encounterId });
        setStep(RESOLVE_CONFLICT);
        setInflight(false);
      } else {
        editEncounter(updates, data);
      }
    });
    return null;
  };

  const renderEditCreatePatient = () => {
    const { editable = false, status = '' } = patientValues?.currentPing || {};
    const formStatus = !status ? PingStatuses.ADMITTED : status;
    const statusConfig: GenericObject = getStatusConfig(patientValues);

    const { label = '', showSetting = false } = statusConfig[formStatus].formControls;

    return (
      <>
        <PatientDemographics disabled={false} />
        <InsurancePlanHookForm
          disabled={false}
          defaultSelectedPlan={defaultValues.selectedInsurancePlan}
          isEditing={true}
        />
        <Spacer itemHeight={sizes.medium} />
        {(editable) && (
          <StatusInfoHookForm
            status={formStatus}
            label={label}
            showSetting={showSetting}
            patient={patientValues}
            isSnf={props.isSnf}
            isPcc={false}
            isEditing={true}
          />
        )}
      </>
    );
  };

  const renderBody = () => {
    switch (step) {
    case EDIT_PATIENT:
      return renderEditCreatePatient();
    case RESOLVE_CONFLICT:
      return <EncounterResolveConflict encountersToResolve={encountersToResolve.response} />;
    default:
      return renderEditCreatePatient();
    }
  };

  const modalControls = {
    [EDIT_PATIENT]: {
      headerText: `Edit - ${teamName}`,
      primaryButton: {
        action: submitSaveEdit,
        text: 'Save',
        disabled: inflight || (encountersToResolve.hasBeenSubmitted ? false : Object.keys(dirtyFields).length === 0),
      },
    },
    [RESOLVE_CONFLICT]: {
      headerText: `Edit - ${teamName}`,
      primaryButton: {
        action: confirmConflict,
        text: 'Confirm',
        disabled: inflight
      },
    },
  };

  // @ts-ignore
  const { headerText, primaryButton } = modalControls[step];

  const modalButtons = [
    <Button
      key="submit"
      styleType={StyleType.PRIMARY}
      disabled={inflight || primaryButton.disabled}
      onClick={hookFormMethods.handleSubmit(primaryButton.action)}
    >
      {primaryButton.text}
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={props.hideModal}>
      Cancel
    </Button>,
  ];

  const secondaryButtons =
    step === RESOLVE_CONFLICT
      ? [
        <Button key="back" styleType={StyleType.TERTIARY} onClick={cancelResolveConflict}>
          Back
        </Button>,
      ]
      : [];

  return (
    <>
      <Modal headerText={headerText} buttons={modalButtons} secondaryButtons={secondaryButtons}>
        {step !== RESOLVE_CONFLICT && (
          <StyledSmallTypography>
            All fields marked with <span>*</span> are required.
          </StyledSmallTypography>
        )}
        <FormProvider {...hookFormMethods}>{renderBody()}</FormProvider>
      </Modal>
    </>
  );
};

export default EncounterEdit;
