import React from 'react';

export const REQUIRED_FIELD = 'Required field';

export const KEY_CODES = {
  ESCAPE: 27,
  ENTER: 13,
};

export const POSITIONING = {
  NAV_BAR_BOTTOM_PADDING: '1.5rem',
  NAV_BAR_HEIGHT: 50,
};

export const BAMBOO_HEALTH_NAME = 'Bamboo Health';
export const BAMBOO_HEALTH_SUPPORT_EMAIL = 'support@bamboohealth.com';
export const BAMBOO_HEALTH_ADDRESS = 'Bamboo Health, 100 High Street, Boston, MA 02110';

export const supportCenterHref = 'https://bamboohealth.zendesk.com/hc/en-us';
export const supportCenterLink = (
  <a target="_blank" rel="noopener noreferrer" href={supportCenterHref}>
    Bamboo Health Support Center
  </a>
);
