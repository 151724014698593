import styled from '@emotion/styled';
import Input from "patient-ping-remedy/packages/input";

const StyledRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 28rem;
  }
`;

const StyledGenderContainer = styled('div')`
  max-width: 20rem;
`;

const StyledSSNInput = styled(Input)`
  & > input {
    max-width: 7rem;
  }
`;


export {StyledRow, StyledSSNInput, StyledGenderContainer};
