import styled from '@emotion/styled';

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 34px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  & > svg {
    height: 100%;
    width: auto;
  }
`;
