import {HTMLAttributes, ReactElement} from 'react';
import {colors} from 'patient-ping-remedy/packages/theme';
import Icon, {ICONS} from 'patient-ping-remedy/packages/icon';
import {Container, StyledMessage} from './EventStatus.styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  iconMessage: string;
  iconClass?: ICONS;
  iconSvg?: ReactElement;
  color?: string;
}

export default function EventStatus(props: Props) {
  const {
    iconMessage,
    iconClass,
    color = colors.charcoal,
    ...containerProps
  } = props;

  return (
    <Container {...containerProps}>
      {props.iconClass && (
        <Icon
          color={color}
          style={{ height: '16px', width: '16px' }}
          iconClass={iconClass!}
        />
      )}
      {props.iconSvg && props.iconSvg}
      <StyledMessage color={color}>
        {iconMessage}
      </StyledMessage>
    </Container>
  );
}
