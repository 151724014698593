import React from "react";
import Logo from "../common/Logo";
import AvatarWidget from "./AvatarWidget";
import FacilityPicker from "./FacilityPicker";
import HelpWidget from "./HelpWidget";
import AlertsWidget from "./AlertsWidget";
import CreateButton from "./CreateButton";
import {useAuth0} from "@auth0/auth0-react";
import {LeftRegion, RightRegion, Wrapper} from "./GlobalNavBar.styles";

type Props = {
  showFacilityPicker: boolean;
}

function GlobalNavBar(props: Props) {
  const { isAuthenticated} = useAuth0();

  return (
    <Wrapper>
      <LeftRegion>
        <Logo />
        {isAuthenticated && props.showFacilityPicker && <CreateButton />}
      </LeftRegion>
      <RightRegion>
        {isAuthenticated && (
          <>
            <AlertsWidget />
            <HelpWidget />
            { props.showFacilityPicker && <FacilityPicker /> }
          </>
        )}
        <AvatarWidget />
      </RightRegion>
    </Wrapper>
  );
}

export default React.memo(GlobalNavBar);
