import {colors} from 'patient-ping-remedy/packages/theme';

export const menuItemIconStyle = {
  height: '2.4rem',
  width: '2.4rem',
};

export const customMenuItemIconStyle = {
  ...menuItemIconStyle,
  color: colors.tertiary2,
};
