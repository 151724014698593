import {useState} from 'react';
import Button from "patient-ping-remedy/packages/button";
import Popover from "patient-ping-remedy/packages/popover";
import CreateButtonContent from "./CreateButtonContent";
import Icon from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";

const CreateButton = () => {
  const [showPopover, setShowPopover] = useState(false);

  const handleHidePopover = () => {
    setShowPopover(false);
  };

  return (
    <>
      <Popover
        toggle={() => setShowPopover(!showPopover)}
        isOpen={showPopover}
        content={<CreateButtonContent hidePopover={handleHidePopover} />}
        position={"bottom-start"}
        triggerTestId={"create-admission-popover"}
        useContext={true}
      >
        <Button onClick={() => setShowPopover(!showPopover)} style={{ height: '40px' }}>
          <Icon iconClass={'plus'} color={colors.white} style={{ marginRight: '10px' }} />
          Create
        </Button>
      </Popover>
    </>
  );
};

export default CreateButton;
