import React from 'react';

import {StandardTypography} from 'patient-ping-remedy/packages/typography';
import {formatAge} from "../utils/dateFormatters";
import {getPatientFullName} from "../utils/stringUtils";
import {flexContainer, marginRightAuto, NameHeading} from "./PatientCardHeader.styles";

export default class PatientCardHeader extends React.Component {

  renderAgeGender = () => {
    const { patient } = this.props;
    return (
      <StandardTypography data-testid="patient-age-gender">
        {[formatAge(patient.dateOfBirth), patient.gender].filter(Boolean).join(', ')}
      </StandardTypography>
    );
  };

  render() {
    const { patient, isSmallScreen } = this.props;
    return (
      <div css={flexContainer}>
        <div className="name" css={marginRightAuto}>
          <NameHeading data-testid="patient-name">
            {' '}
            {getPatientFullName(patient)}
            {patient.nameSuffix ? `, ${patient.nameSuffix}` : ''}{' '}
          </NameHeading>
          {isSmallScreen && this.renderAgeGender()}
        </div>
      </div>
    );
  }
}
