import {RosterResponse} from "../../../../api/dto/dto";
import React, { useMemo } from "react";
import Table from "../../../common/Table";
import InfoIconTooltip from "../../../common/InfoIconTooltip";

interface OrganizationRolesProps {
  rosterRoles?: RosterResponse | undefined
}

const dataRoleInfo =
    (<p>A user’s <b>data role</b> determines which data the user has legal
permission to view and create within the platform. To view 
more information regarding what data is included in these roles,
visit the group detail drawer. Note: these roles may be limited
contractually based on your group's relationship with 
Bamboo Health.</p>);

const featureRoleInfo =
    (<p>A user’s <b>feature role</b> determines which functionalities
the user can view and/or interact with in the platform. 
To view more information regarding what functionalities 
are included in these roles, visit the group detail drawer. 
Note: these roles may be limited contractually based on 
  your group's relationship with Bamboo Health.</p>);

const OrganizationRoles = ({rosterRoles}: OrganizationRolesProps) => {
  const dataRoleSize = rosterRoles?.dataRoleOptions?.length || 0;
  const featureRoleSize = rosterRoles?.featureRoleOptions?.length || 0;

  const dataRoleMemorized = useMemo(() => {
    return rosterRoles?.dataRoleOptions?.map((dataRole, index) => {
      return {
        dataRole: dataRole.label,
        featureRole: index < featureRoleSize ? rosterRoles?.featureRoleOptions[index].label : ""
      };
    }) || [];
  }, [rosterRoles?.dataRoleOptions, rosterRoles?.featureRoleOptions, featureRoleSize]);

  const featureRoleMemorized = useMemo(() => {
    return rosterRoles?.featureRoleOptions?.map((featureRole, index) => {
      return {
        dataRole: index < dataRoleSize ? rosterRoles.dataRoleOptions[index].label : "",
        featureRole: featureRole.label
      };
    }) || [];
  }, [rosterRoles?.dataRoleOptions, rosterRoles?.featureRoleOptions, dataRoleSize]);

  let content = dataRoleSize >= featureRoleSize ? dataRoleMemorized : featureRoleMemorized;

  const dataRoleHeading = (
    <>
      <span>Data Role </span>
      <InfoIconTooltip
        html={dataRoleInfo}
        data-testId="data-role-tooltip"
        position={"right"}
      />
    </>
  );

  const featureRoleHeading = (
    <>
      <span>Feature Role </span>
      <InfoIconTooltip
        html={featureRoleInfo}
        data-testId="feature-role-tooltip"
        position={"right"}
      />
    </>
  );

  const headings = [dataRoleHeading, featureRoleHeading];

  return (
    <Table data={content} header={headings} />
  );
};

export default OrganizationRoles;
