import React, {useMemo} from "react";
import {ClientProfileResponse} from "../../../../api/dto/client-profile";
import {
  ContentContainer, PhoneHistoryTableContainer, InsuranceHistoryTableContainer,
  HistoryTableLabel, HistoryTable
} from "./ClientDemographicContent.styles";
import DisplayHelpers from "../../../../helpers/display_helpers";
import Spacer from "patient-ping-remedy/packages/spacer";
import {css} from "@emotion/css";

type ModalProps = {
  ClientProfileResponse: ClientProfileResponse | undefined
}

type InsuranceHistory = {
  date: string;
  insurances: string[];
  reportedBy: string;

  // Currently the order of encounters is not sorted by encounter date in encounter insurances
  //TODO Fix:  https://hbuco.atlassian.net/browse/PNGDAT-2081
  recentEventDate: string;
}

function mergeInsurances(clientProfileResponse: ClientProfileResponse | undefined) : InsuranceHistory[]{
  const patientRosterInsurances = clientProfileResponse?.patientProfileResponse?.patientRosterInsurances || [];
  const patientEncounterInsurances = clientProfileResponse?.patientProfileResponse?.patientEncounterInsurances || [];
  const insurances: InsuranceHistory[] = [];

  patientRosterInsurances.forEach((rosterInsurance) => {
    insurances.push({
      date: DisplayHelpers.formatDateFromDateOnlyString(rosterInsurance.uploadDate),
      insurances: Array.from(new Set(rosterInsurance.insurances)),
      reportedBy: rosterInsurance.groupName,
      recentEventDate: rosterInsurance.uploadDate
    });
  });

  patientEncounterInsurances.forEach((encounterInsurance) => {
    let encounterDate = "";
    if(encounterInsurance.encounterStartDate){
      encounterDate = DisplayHelpers.formatDateFromDateOnlyString(encounterInsurance.encounterStartDate);
    }
    if(encounterInsurance.encounterEndDate){
      if(encounterInsurance.encounterStartDate) {
        encounterDate = encounterDate + " - ";
      }
      encounterDate = encounterDate +  DisplayHelpers.formatDateFromDateOnlyString(encounterInsurance.encounterEndDate);
    }

    insurances.push({
      date: encounterDate,
      insurances: Array.from(new Set(encounterInsurance.insurances.map(insurance => insurance.insuranceCompanyName))),
      reportedBy: encounterInsurance.groupName,
      recentEventDate: encounterInsurance.encounterEndDate || encounterInsurance.encounterStartDate
    });
  });

  return insurances
    .filter((insurance) => insurance.date !== "" && insurance.insurances.length > 0)
    .sort((a, b) => {
      return new Date(b.recentEventDate) > new Date(a.recentEventDate) ? 1 : -1;
    });
}

const ClientDemographicContent = ({ClientProfileResponse}: ModalProps) => {
  const phoneNumbers = ClientProfileResponse?.patientProfileResponse?.patientPhoneNumbers || [];
  const insurances = useMemo(() => mergeInsurances(ClientProfileResponse), [ClientProfileResponse]);

  return (
    <ContentContainer>
      <PhoneHistoryTableContainer>
        <HistoryTableLabel>
          Phone number last 12 months <Spacer itemWidth={"10px"}/>
        </HistoryTableLabel>
        <HistoryTable>
          <thead>
            <tr>
              <th>Date</th>
              <th>Phone</th>
              <th>Reported by</th>
            </tr>
          </thead>
          <tbody>
            {phoneNumbers.map((phoneNumber, index) => (
              <tr key={index}>
                <td>{DisplayHelpers.formatDateFromDateOnlyString(phoneNumber.updatedDate)}</td>
                <td>{DisplayHelpers.formatPatientPhoneNumber(phoneNumber)}</td>
                <td>
                  {phoneNumber.groupName}
                  {DisplayHelpers.cleanGroupTypeKey(phoneNumber.groupType) ? `(${phoneNumber.groupType})` : ''}
                  {phoneNumber.currentOrganization && ' (Created at Your Organization)'}
                </td>
              </tr>
            ))}
          </tbody>
        </HistoryTable>
      </PhoneHistoryTableContainer>

      <InsuranceHistoryTableContainer>
        <HistoryTableLabel>
          Insurance last 12 months <Spacer itemWidth={"10px"}/>
        </HistoryTableLabel>
        <HistoryTable>
          <thead>
            <tr>
              <th>Date</th>
              <th>Insurance</th>
              <th>Reported by</th>
            </tr>
          </thead>
          <tbody>
            {insurances.map((insurance, index) => (
              <tr key={index}>
                <td>{insurance.date}</td>
                <td>
                  {insurance.insurances.map((ins, insIndex) => (
                    <div className={css({paddingBottom: "0.4rem"})} key={insIndex}>{ins}</div>
                  ))}
                </td>
                <td>{insurance.reportedBy}</td>
              </tr>
            ))}
          </tbody>
        </HistoryTable>
      </InsuranceHistoryTableContainer>

    </ContentContainer>
  );
};

export default ClientDemographicContent;
