import styled from '@emotion/styled';

export const Wrapper = styled.div`
  cursor: pointer;
  transform: scale(1.6);
  float: right;
  padding-right: 10px;
  padding-left: 5px;
`;

