import {ClientProfile} from "../../../../../../api/dto/client-profile";
import React, {useMemo} from "react";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import LOSIndicator from "../../../../../common/timeline/LOSIndicator";
import {
  EventContainer,
  EventContainerEvents,
  EventCurrentStatus,
  EventDetails,
  EventLowerSection1,
  EventLowerSection1Content,
  EventSectionHeader,
  EventType, ExpandEventDetailsSectionDetails,
  TimelineContentWrapper,
  TimelineDate,
  TimelineEventWrapper
} from "../../../../../common/timeline/Timeline.styles";
import Helpers from "../../../../../../helpers/helpers";
import DetailsSection from "../../../../../common/timeline/DetailsSection";
import MainEventIcon from "../../../../../common/timeline/MainEventIcon";
import crisisIcon from "../../../../../../assets/icons/Custom-Icon-Crisis.svg";
import CrisisTimeline from "./CrisisTimeline";
import ExpandMoreButton from "../../../../../common/timeline/ExpandMoreButton";
import {CrisisStatus} from "./CrisisCard.constants";
import {Dispatch, IntakeDetails} from "../../../../../../api/dto/crisis";
import CrisisHelpers from "helpers/crisis_helpers";
import display_helpers from "../../../../../../helpers/display_helpers";
import EventStatus from "../../../../../common/timeline/EventStatus";
import Part2Disclaimer from "../../../../../common/Part2Disclaimer";
import {StyleType} from "patient-ping-remedy/packages/button";

type Props = {
  id: string;
  event: IntakeDetails,
  isPart2?: boolean,
  patient: ClientProfile,
}

const CrisisCard = (props: Props) => {
  const eventYear = useMemo(() => new Date(props.event.call_start).getFullYear(),[props.event]);
  const eventDate = useMemo(() => DisplayHelpers.getDateMonthDay(props.event.call_start), [props.event]);

  const crisisLocation = useMemo(() => {
    if(props.event.current_street) {
      return {
        street: props.event.current_street ?? '',
        city: props.event.current_city ?? '',
        state: props.event.current_state ?? '',
        zip: props.event.current_zip ?? ''
      };
    }

    return {
      street: props.event.patient.permanent_street ?? '',
      city: props.event.patient.permanent_city ?? '',
      state: props.event.patient.permanent_state ?? '',
      zip: props.event.patient.permanent_zip ?? ''
    };
  }, [props.event]);

  const crisisLocationDisplay = useMemo(() => {
    return (
      <>
        <div>{crisisLocation?.street ?? ''}</div>
        <div>
          {crisisLocation?.city + ', '}
          {crisisLocation?.state + ' '}
          {crisisLocation?.zip}
        </div>
      </>
    );
  }, [crisisLocation]);

  const sortedDispatches = useMemo(() => {
    return CrisisHelpers.getSortedDispatches(props.event.dispatches);
  }, [props.event]);

  const acceptedDispatch = useMemo(() => {
    return CrisisHelpers.getAcceptedDispatch(sortedDispatches);
  }, [sortedDispatches]);

  const activeDispatchEvents = useMemo(() => {
    return CrisisHelpers.getActiveDispatches(sortedDispatches);
  }, [sortedDispatches]);

  const inactiveDispatchEvents = useMemo(() => {
    return CrisisHelpers.getInactiveDispatches(sortedDispatches);
  }, [sortedDispatches]);

  const acknowledgedDispatchEvents = useMemo(() => {
    return CrisisHelpers.getAcknowledgedDispatch(sortedDispatches);
  }, [sortedDispatches]);

  const dispatchAndIntakeEvents= useMemo(() : Dispatch[] => {
    return CrisisHelpers.getDispatchAndIntakeEvents(
      props.event,
      sortedDispatches
    );
  }, [props.event, acceptedDispatch, sortedDispatches]);

  const overallCardStatus = useMemo(() => {
    return CrisisHelpers.getOverallEncounterStatus(
      dispatchAndIntakeEvents,
      acknowledgedDispatchEvents,
      acceptedDispatch
    );
  }, [dispatchAndIntakeEvents, acceptedDispatch, acknowledgedDispatchEvents]);

  const isEventActive = useMemo(() => {
    return CrisisHelpers.isEncounterActive(overallCardStatus);
  }, [overallCardStatus]);

  const lengthOfCallMessage = useMemo(() => {
    if(isEventActive) {
      return 'Ongoing: ';
    }

    if(props.event.duration_total_seconds) {
      let daysHrsMinsSecs = Helpers.calculateSecondsToDaysHrsMinsSecs(props.event.duration_total_seconds);
      if(daysHrsMinsSecs.daysDisplay) {
        return `Time ${daysHrsMinsSecs.daysDisplay}: `;
      } else if (daysHrsMinsSecs.hoursDisplay || daysHrsMinsSecs.minutesDisplay) {
        return `Time ${daysHrsMinsSecs.hoursDisplay} ${daysHrsMinsSecs.minutesDisplay}: `;
      }
    }

    return '';
  }, [props.event, isEventActive]);

  const receivingContactStatus = useMemo(() => {
    return CrisisHelpers.getReceivingContactStatus(acceptedDispatch, activeDispatchEvents);
  }, [acceptedDispatch, activeDispatchEvents]);


  return (
    <div id={props.id}>
      <TimelineEventWrapper>
        <TimelineDate>
          <span className="timeline__event__date-year">{ eventYear }</span>
          <span className="timeline__event__date-date">{ eventDate }</span>
        </TimelineDate>

        <TimelineContentWrapper>
          <EventContainer active={isEventActive}>
            <MainEventIcon active={isEventActive} iconAlt={'crisis-icon'} iconSvg={crisisIcon} />
            <EventDetails>
              <EventType> Crisis </EventType>
              <DetailsSection detailsHeaderText={'Crisis location'} hasDetails={Helpers.isDefined(crisisLocationDisplay)}>
                { crisisLocationDisplay }
              </DetailsSection>

              <DetailsSection detailsHeaderText={'Caller'} hasDetails={!!props.event.caller_first_name}>
                <>
                  <div>
                    {
                      props.event.caller_relationship && props.event.caller_relationship.length > 0 ?
                        display_helpers.formatName(props.event.caller_first_name, null, props.event.caller_last_name)
                          + ` (${props.event.caller_relationship})`:
                        display_helpers.formatName(props.event.caller_first_name, null, props.event.caller_last_name)
                    }
                  </div>
                  <div>{props.event.caller_phone_number}</div>
                </>
              </DetailsSection>

              {
                Helpers.isDefined(activeDispatchEvents) && activeDispatchEvents.length > 0 &&
                  <DetailsSection detailsHeaderText={'Receiving contact'}
                    hasDetails={activeDispatchEvents[0].responders.length > 0
                    }>
                    <div>
                      <EventStatus
                        iconMessage={receivingContactStatus.displayName}
                        iconClass={receivingContactStatus.iconClass}
                      />
                    </div>
                    {
                      activeDispatchEvents.map((dispatch) => (
                        <div key={`active-dispatch-${dispatch.id}`}>
                          {dispatch.responders.map((responder, index) => (
                            <ExpandMoreButton
                              key={`responder-${index}`}
                              useExpandHideText={false}
                              renderBelow={true}
                              buttonText={`${responder.user.fname} ${responder.user.lname}`}
                              expandItems={[
                                <ExpandEventDetailsSectionDetails>
                                  <div>{responder.user.mobile}</div>
                                </ExpandEventDetailsSectionDetails>
                              ]}
                              active={isEventActive}
                              disabled={!Helpers.isDefined(responder.user.mobile)}
                            />
                          ))}
                        </div>
                      ))
                    }
                  </DetailsSection>
              }

              {
                Helpers.isDefined(inactiveDispatchEvents) && inactiveDispatchEvents.length > 0 &&
                  <DetailsSection detailsHeaderText={'Dispatch not accepted'}
                    hasDetails={inactiveDispatchEvents[0].responders.length > 0}
                    lowerSection={true}
                  >
                    {
                      inactiveDispatchEvents.map((dispatch) => (
                        <div key={`inactive-dispatch-${dispatch.id}`}>
                          { dispatch.responders.map((responder, index) => (
                            <ExpandMoreButton
                              key={`responder-${index}`}
                              useExpandHideText={false}
                              renderBelow={true}
                              buttonText={`${responder.user.fname} ${responder.user.lname}`}
                              expandItems={[
                                <ExpandEventDetailsSectionDetails>
                                  <div>
                                    <EventStatus
                                      iconMessage={CrisisStatus[dispatch.mcu_status].displayName}
                                      iconClass={CrisisStatus[dispatch.mcu_status].iconClass}
                                    />
                                  </div>
                                  <div>{responder.user.mobile}</div>
                                </ExpandEventDetailsSectionDetails>
                              ]}
                              active={isEventActive}
                            />
                          ))}
                        </div>
                      ))
                    }
                  </DetailsSection>
              }
            </EventDetails>

            <EventContainerEvents active={isEventActive}>
              <EventCurrentStatus>
                <LOSIndicator
                  message={lengthOfCallMessage}
                  iconClass={overallCardStatus.iconClass}
                  active={isEventActive}
                  iconMessage={overallCardStatus.displayName}
                />
              </EventCurrentStatus>

              <CrisisTimeline
                active={isEventActive}
                createdBy={props.event.created_by}
                intakeOrganization={props.event.organization}
                dispatches={dispatchAndIntakeEvents}
              />

              {
                ((props.event.primary_presentation && props.event.primary_presentation.length > 0) ||
                    props.event.final_disposition) &&
                  <EventLowerSection1>
                    {
                      props.event.primary_presentation && props.event.primary_presentation.length > 0 &&
                        <>
                          <EventSectionHeader>Reason for crisis:</EventSectionHeader>
                          <EventLowerSection1Content>
                            {
                              props.event.primary_presentation.slice(0,3).map((presentation, index) => (
                                <div className="details" key={`visible-presentation-${index}`}>
                                  {DisplayHelpers.humanize(presentation)}
                                </div>
                              ))
                            }

                            {props.event.primary_presentation.length > 3 &&
                                <ExpandMoreButton
                                  useExpandHideText={true}
                                  active={isEventActive}
                                  buttonText={`${props.event.primary_presentation.length - 3} more`}
                                  expandItems={props.event.primary_presentation.slice(3).map((presentation, index) => (
                                    <div className="details" key={`hidden-presentation-${index}`}>
                                      {presentation}
                                    </div>
                                  ))
                                  }
                                />
                            }
                          </EventLowerSection1Content>
                        </>
                    }
                    {
                      props.event.final_disposition &&
                        <>
                          <EventSectionHeader>Final Disposition:</EventSectionHeader>
                          <EventLowerSection1Content>
                            <div className="details">
                              {props.event.final_disposition}
                            </div>
                          </EventLowerSection1Content>
                        </>
                    }
                  </EventLowerSection1>
              }
            </EventContainerEvents>
            { props.isPart2 && <Part2Disclaimer styleType={isEventActive ? StyleType.PRIMARY : StyleType.TERTIARY} /> }
          </EventContainer>
        </TimelineContentWrapper>
      </TimelineEventWrapper>
    </div>
  );
};

export default CrisisCard;
