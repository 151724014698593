import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const Wrapper = styled.div`
    padding: 0 40px;
`;
const Header = styled('h1')`
  font-size: ${fontSize.displayLarge};
  margin-bottom: 2rem;
  color: ${colors.primary1};
`;

const TopRegion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BorderedContent = styled.div`
  padding: 20px;
  border: 0.1rem solid ${colors.gray3};
  gap: 20px;
  width: 100%;
`;


export { Header, Wrapper, TopRegion, BorderedContent, Content};
