import styled from '@emotion/styled';
import {colors, fontSize} from "patient-ping-remedy/packages/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  transform: scale(1.4);
`;

const IconWithBackground = styled.div`
  display: flex;
  background: ${colors.red5};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
`;

const NotificationElement = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 10px;
  width: 65%;
`;

const NotificationDescription = styled('div')`
  font-size: ${fontSize.standard};;
`;

const ClientNameLink = styled('span')`
  cursor: pointer;
`;

const TimePassedComponent = styled('div')`
  padding-right: 10px;
  padding-top: 10px;
  align-self: baseline;
`;

export { Wrapper, IconWrapper, IconWithBackground, NotificationElement,
  NotificationDescription, TimePassedComponent, ClientNameLink };
