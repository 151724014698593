import React, {Fragment, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form/dist/index.ie11';
import {StyleType} from 'patient-ping-remedy/packages/button';
import Icon from 'patient-ping-remedy/packages/icon';
import {colors, sizes} from 'patient-ping-remedy/packages/theme';
import Spacer from 'patient-ping-remedy/packages/spacer';
import {CarecoApiContext} from "../../../../../../../app-context/careco-api-context";
import {
  StyledButton,
  StyledControlsContainer,
  StyledDisclaimerText,
  StyledDropdownContainer,
  StyledIconClose,
  StyledIconWrapper,
  StyledIdDropdownList,
  StyledIdInput,
  StyledLabelFormFieldTypography,
  StyledOtherNameInput,
  StyledRadioButton,
  StyledRow,
  StyledSmallTypography
} from "./InsurancePlansHookForm.styles";
import {REQUIRED_FIELD} from "../../../constants/common";

const InsurancePlanHookForm = ({ defaultSelectedPlan, disabled = false, isEditing }) => {
  const [insurers, setInsurers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(defaultSelectedPlan);
  const [showUpdateDisclaimer, setShowUpdateDisclaimer] = useState(false);
  const { carecoApi } = useContext(CarecoApiContext);
  const { register, unregister, errors, control, setValue, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'insurancePlans',
  });
  const watchInsurancePlans = useWatch({ control, name: 'insurancePlans' });

  const items = () => {
    const inUse = {};
    watchInsurancePlans.forEach((plan) => {
      inUse[plan.insuranceProvider.insuranceCompanyName] = true;
    });
    return insurers.filter((insurer) => !inUse[insurer.insuranceCompanyName]);
  };

  useLayoutEffect(() => {
    carecoApi?.readInsurers().then((insurances) => {
      setInsurers(insurances);
    });
    return () => {
      unregister('selectedInsurancePlan');
      unregister('insurancePlans');
    };
  }, []);

  //calling setValue instead of storing this value in state was having issues with updates on the uncontrolled components
  //this is a work around to force updates
  useEffect(() => {
    setValue('selectedInsurancePlan', selectedRow);
  }, [selectedRow]);

  const validateExistingRows = () => {
    fields.forEach((field, index) => {
      trigger(`insurancePlans[${index}].insuranceProvider`);
      //there is no insuranceValue field rendered for selfPay, so it can't be validated
      if (field.insuranceProvider?.insuranceCompanyName !== 'Self Pay') {
        trigger(`insurancePlans[${index}].insuranceValue`);
      }
    });
    isEditing && !showUpdateDisclaimer && setShowUpdateDisclaimer(true);
  };

  const handleSelectRow = (insuranceProvider, onChange) => {
    validateExistingRows();
    onChange(insuranceProvider);
    setSelectedRow(insuranceProvider);
  };

  const handleAddRow = () => {
    validateExistingRows();
    const id = `NEW${Math.floor(Math.random() * 1000)}`;
    append(
      {
        insuranceProvider: { id },
        insuranceValue: '',
      },
      false,
    );
  };

  const handleInputChange = () => {
    if (isEditing && !showUpdateDisclaimer) {
      setShowUpdateDisclaimer(true);
    }
  };

  const handleRemoveRow = (index, isSelected) => {
    remove(index);
    if (isSelected) {
      const nextIndex = index ? 0 : 1;
      setSelectedRow(watchInsurancePlans[nextIndex].insuranceProvider);
    }
    handleInputChange();
  };

  const formatCompanySelection = ({ code, insurerId, insuranceCompanyName }, previousValue) => {
    return { ...previousValue, code, insurerId, insuranceCompanyName };
  };

  const renderInsurances = () => {
    return fields.map((plan, index) => {
      const hasProviderError = errors?.insurancePlans?.[index]?.insuranceProvider;
      const hasIdError = errors?.insurancePlans?.[index]?.insuranceValue;
      const hasOtherTranslatedNameError = errors?.insurancePlans?.[index]?.otherTranslatedName;
      const isSelected = plan.insuranceProvider?.id === selectedRow?.id;
      const showPrimaryLabel = isSelected && !hasProviderError && !hasOtherTranslatedNameError;
      const showOtherInsuranceInput = watchInsurancePlans?.[index]?.insuranceProvider?.code === 'OTHER';
      return (
        <StyledRow key={plan.id}>
          <div>
            <StyledControlsContainer className="radioDropdown" showOtherInsuranceInput={showOtherInsuranceInput}>
              <Controller
                name="selectedInsurancePlan"
                defaultValue={selectedRow}
                control={control}
                render={({ onChange }) => (
                  <StyledRadioButton
                    name={`insurance${index}`}
                    id={`insurance${index}`}
                    value={plan.insuranceProvider.id || ''}
                    onChange={() => handleSelectRow(plan.insuranceProvider, onChange)}
                    checked={isSelected}
                    disabled={disabled}
                  />
                )}
              />
              <StyledDropdownContainer>
                <Controller
                  defaultValue={plan.insuranceProvider}
                  name={`insurancePlans[${index}].insuranceProvider`}
                  control={control}
                  rules={{ validate: (value) => disabled || !!value.code }}
                  render={({ onChange, onBlur, value }) => (
                    <StyledIdDropdownList
                      handleChange={(newValue) => {
                        onChange(formatCompanySelection(newValue, plan.insuranceProvider));
                        isEditing && setShowUpdateDisclaimer(true);
                        trigger(`insurancePlans[${index}].insuranceProvider`);
                      }}
                      value={insurers.find((insurer) => insurer.insuranceCompanyName === value.insuranceCompanyName)}
                      id={`insuranceProvider${index}`}
                      items={items()}
                      isWithinModal
                      error={hasProviderError}
                      errorMessage="Required Field"
                      disabled={disabled}
                      onBlur={onBlur}
                    />
                  )}
                />
                {showOtherInsuranceInput && (
                  <StyledOtherNameInput
                    name={`insurancePlans[${index}].otherTranslatedName`}
                    defaultValue={plan.otherTranslatedName || ''}
                    id={`otherTranslatedName${index}`}
                    error={hasOtherTranslatedNameError}
                    errorMessage="Required Field"
                    isDisabled={disabled}
                    onChange={handleInputChange}
                    labelText="Other Name"
                    required="required"
                    ref={register({
                      required: disabled ? false : REQUIRED_FIELD,
                      maxLength: 50,
                      minLength: 1,
                      pattern: /^[A-Za-z0-9]/,
                    })}
                  />
                )}

                {showPrimaryLabel && <StyledSmallTypography>Primary Insurance</StyledSmallTypography>}
              </StyledDropdownContainer>
            </StyledControlsContainer>
          </div>
          <Spacer itemWidth={sizes.small} />
          <StyledControlsContainer className="inputContainer">
            {watchInsurancePlans?.[index]?.insuranceProvider?.insuranceCompanyName !== 'Self Pay' && (
              <StyledIdInput
                name={`insurancePlans[${index}].insuranceValue`}
                defaultValue={plan.insuranceValue || ''}
                id={`insuranceValue${index}`}
                data-testid={`insuranceValue${index}`}
                error={hasIdError}
                errorMessage="Required Field"
                isDisabled={disabled}
                onChange={handleInputChange}
                ref={register({
                  required: disabled ? false : REQUIRED_FIELD,
                  maxLength: 50,
                  minLength: 1,
                  pattern: /^[A-Za-z0-9]/,
                })}
              />
            )}
            {fields.length > 1 && !disabled && (
              <StyledIconClose
                iconClass="times"
                color={colors.cta}
                onClick={() => handleRemoveRow(index, isSelected)}
              />
            )}
          </StyledControlsContainer>
        </StyledRow>
      );
    });
  };

  return (
    <Fragment>
      <StyledRow>
        <StyledLabelFormFieldTypography className="requiredField">Insurance Provider</StyledLabelFormFieldTypography>
        <StyledLabelFormFieldTypography className="requiredField">Insurance Plan ID</StyledLabelFormFieldTypography>
      </StyledRow>
      {renderInsurances()}
      {!disabled && (
        <StyledButton styleType={StyleType.TERTIARY} onClick={handleAddRow}>
          <StyledIconWrapper>
            <Icon color={colors.cta} iconClass="plus" />
          </StyledIconWrapper>
          Add Another Insurance
        </StyledButton>
      )}
      {showUpdateDisclaimer && (
        <StyledDisclaimerText>
          Are you trying to indicate a payor change for this client? If so, please use the "Update Status" flow instead
          by clicking on the edit pencil on the client card. If you move forward with editing the payor details here,
          you will overwrite the payor details you've previously entered.
        </StyledDisclaimerText>
      )}
    </Fragment>
  );
};

export default InsurancePlanHookForm;
