export const  pdmp_role=[
  {
    "label": "Dentist",
    "value": "DENTIST"
  },
  {
    "label": "Medical Resident with prescriptive authority",
    "value": "MEDICAL_RESIDENT_WITH_PRESCRIPTIVE_AUTHORITY"
  },
  {
    "label": "Nurse Practitioner",
    "value": "NURSE_PRACTITIONER"
  },
  {
    "label": "Optometrist with prescriptive authority",
    "value": "OPTOMETRIST_WITH_PRESCRIPTIVE_AUTHORITY"
  },
  {
    "label": "Other Prescriber",
    "value": "OTHER_PRESCRIBER"
  },
  {
    "label": "Physician",
    "value": "PHYSICIAN"
  },
  {
    "label": "Physician Assistant with prescriptive authority",
    "value": "PHYSICIAN_ASSISTANT_WITH_PRESCRIPTIVE_AUTHORITY"
  },
  {
    "label": "Prescriber's Delegate - Licensed",
    "value": "PRESCRIBER_DELEGATE_LICENSED"
  },
  {
    "label": "Prescriber's Delegate - Unlicensed",
    "value": "PRESCRIBER_DELEGATE_UNLICENSED"
  },
  {
    "label": "Pharmacist",
    "value": "PHARMACIST"
  },
  {
    "label": "Pharmacist's Delegate - Licensed",
    "value": "PHARMACIST_DELEGATE_LICENSED"
  },
  {
    "label": "Pharmacist's Delegate - Unlicensed",
    "value": "PHARMACIST_DELEGATE_UNLICENSED"
  },
  {
    "label": "Coroner/Medical Examiner",
    "value": "CORONER_MEDICAL_EXAMINER"
  },
];
