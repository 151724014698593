import React from 'react';
import Icon, {ICONS} from 'patient-ping-remedy/packages/icon';
import {colors} from 'patient-ping-remedy/packages/theme';
import {User} from '../../api/dto/dto';
import {avatarStyle, AvatarWrapper, AvatarCircle} from './Avatar.styles';

type Props = {
  user?: User | null;
  iconClass?: ICONS;
}

const Avatar = (props: Props) => {
  const label: string = props.user ? `${props.user.first_name} ${props.user.last_name}` : '';

  if (props.user?.image) {
    return <AvatarWrapper>
      <img
        alt={label}
        src={props.user.image}
        style={avatarStyle}
      />
    </AvatarWrapper>;
  }

  if (props.iconClass) {
    return <AvatarCircle>
      <Icon
        aria-label={label}
        iconClass={props.iconClass}
        color={colors.white}
      />
    </AvatarCircle>;
  }

  return <AvatarWrapper>
    <Icon
      aria-label={label}
      iconClass={'user-circle'}
      color={colors.gray4}
      style={avatarStyle}
    />
  </AvatarWrapper>;
};

export default Avatar;
