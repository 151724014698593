import {colors, fontSize, sizes} from "patient-ping-remedy/packages/theme";
import {mq} from "../utils/stringUtils";
import styled from "@emotion/styled";

const StyledPatientCard = styled.div`
  margin-bottom: ${sizes.xSmall};
  background: white;
  border: 1px solid ${colors.gray3};
  border-radius: 5px;
  padding: ${sizes.small};
  width: 100%;
  min-height: 95px;
  position: relative;
  transition: border-color 150ms ease, box-shadow 150ms ease;
  cursor: pointer;
  color: ${colors.gray5};
  &:hover {
    .name {
      color: ${colors.tertiary2};
    }
    border-color: ${colors.tertiary2};
  }
  ${mq[0]} {
    font-size: ${fontSize.small};
    padding: ${sizes.xSmall};
  }
`;

export {StyledPatientCard};
