import React from "react";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {css} from "@emotion/css";
import {RosterResponse, User} from "../../../api/dto/dto";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import {
  DataRoleDiv,
  FeatureRoleDiv,
  GroupDiv,
  Label,
  RolesWrapper,
  Divider,
  UserRosterContainer
} from "./GroupDetailsDisplay.styles";
import DisplayHelpers from "../../../helpers/display_helpers";

type Props = {
  user: User;
  rosters: RosterResponse | undefined;
};

const GroupDetailsDisplay = (props: Props) => {
  return (
    <>
      {
        PermissionsHelpers.isBamboo(props.user) &&
        <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
          You are a Bamboo Admin. You have access to all groups.
        </Banner>
      }

      {
        props.user && props?.user.rosters &&
        props?.user.rosters.map((userRoster, index: number) => {
          return (
            <div key={`user-roster-${index}`}>
              <UserRosterContainer>
                {index !== 0 && <Divider></Divider>}
                <GroupDiv>
                  <Label>
                  Group
                  </Label>
                  {userRoster.roster.name}
                </GroupDiv>
                <RolesWrapper>
                  <DataRoleDiv>
                    <Label>
                      Data Role
                    </Label>
                    {DisplayHelpers.humanize(userRoster.data_role.name)}
                  </DataRoleDiv>
                  <FeatureRoleDiv>
                    <Label>
                      Feature Role
                    </Label>
                    {DisplayHelpers.humanize(userRoster.feature_role.name)}
                  </FeatureRoleDiv>
                </RolesWrapper>
              </UserRosterContainer>
            </div>
          );
        })
      }
    </>
  );
};

export default GroupDetailsDisplay;
