import React from 'react';
import {GroupLabel, RowDiv, GroupItem, StyledGroupData, StyledGroupDetailHeader} from "./GroupDetailsHeader.styles";
import {Roster} from "../../../../api/dto/dto";
import RosterHelpers from "../../../../helpers/roster_helpers";

type Props = {
  roster: Roster;
}

const GroupDetailsHeader = (props: Props) => {

  return (
    <>
      <StyledGroupDetailHeader>
        <RowDiv>
          <GroupItem>
            <GroupLabel>Organization</GroupLabel>
            <StyledGroupData>{props.roster.organization.name}</StyledGroupData>
          </GroupItem>
          <GroupItem>
            <GroupLabel>Group</GroupLabel>
            <StyledGroupData>{props.roster.name}</StyledGroupData>
          </GroupItem>
          <GroupItem>
            <GroupLabel>ID</GroupLabel>
            <StyledGroupData>{props.roster.id}</StyledGroupData>
          </GroupItem>
        </RowDiv>
        <RowDiv>
          <GroupItem>
            <GroupLabel>Type</GroupLabel>
            <StyledGroupData>{props.roster.type}</StyledGroupData>
          </GroupItem>
          <GroupItem>
            <GroupLabel>Submission</GroupLabel>
            <StyledGroupData>{ RosterHelpers.groupTypeToSubmission(props.roster.type)}</StyledGroupData>
          </GroupItem>
          <GroupItem>
            <GroupLabel>Timezone</GroupLabel>
            <StyledGroupData>{props.roster.address.timeZone}</StyledGroupData>
          </GroupItem>
        </RowDiv>
        <RowDiv>
          <div>
            <GroupLabel>Address</GroupLabel>
            <StyledGroupData>{ RosterHelpers.formatGroupAddress(props.roster.address) }</StyledGroupData>
          </div>
        </RowDiv>
      </StyledGroupDetailHeader>
    </>

  );
};

export default GroupDetailsHeader;
