import React from 'react';
import {PropsOf} from '@emotion/react';
import {DistributiveOmit} from '@emotion/react/types/helper';
import {colors} from 'patient-ping-remedy/packages/theme';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import Icon, {ICONS} from 'patient-ping-remedy/packages/icon';
import {Badge, iconStyle, StyledButton} from './IconButton.styles';

const buttonStyleTypeToColor = {
  [StyleType.PRIMARY]: colors.primary1,
  [StyleType.SECONDARY]: colors.tertiary2,
  [StyleType.TERTIARY]: colors.gray4,
};

type Props = DistributiveOmit<PropsOf<typeof Button>, 'children'> & {
  iconClass: ICONS;
  badge?: string | number | null;
  overflowBackground?: boolean;
}

// chopsticks: https://zeroheight.com/5bcb53d99/p/203cc4-icon-button
export default function IconButton(props: Props) {
  const { iconClass, badge , ...buttonProps } = props;

  return (
    <StyledButton
      {...buttonProps}
      styleType={StyleType.TERTIARY}
      propsStyleType={props.styleType ?? StyleType.PRIMARY}
    >
      <Icon
        iconClass={iconClass}
        style={iconStyle}
        color={props.disabled
          ? colors.gray3
          : buttonStyleTypeToColor[props.styleType ?? StyleType.PRIMARY]}
        weight="far"
      />
      { badge && <Badge>{badge}</Badge> }
    </StyledButton>
  );
}
