export const SUCCESS_ADMIT = 'Client admitted successfully.';
export const SUCCESS_TRANSFER = 'Client transferred successfully.';
export const SUCCESS_DISCHARGE = 'Client discharged successfully.';
export const SUCCESS_DECEASED = 'Client marked as deceased successfully.';
export const SUCCESS_PAYOR_CHANGE = 'Payor updated successfully.';
export const SUCCESS_MEDICAL_LEAVE_OF_ABSENCE = 'Client placed on medical leave of absence successfully.';
export const SUCCESS_RESUME_SERVICES = 'Client resumed services successfully.';
export const SUCCESS_END_SERVICES = 'Client ended services successfully';
export const SUCCESS_START_HHA_SERVICES = 'Client started HHA services successfully.';
export const SUCCESS_END_HHA_SERVICES = 'Client ended HHA services successfully.';
export const ENCOUNTER_UPDATED = 'Event updated successfully.';
export const ENCOUNTER_DELETED = 'Event deleted successfully.';
export const PATIENT_UPDATED = 'Client updated successfully. Be advised: visit history has been adjusted.';
export const PATIENT_HELD = 'This client record will be held for 72 hours.';
export const PATIENT_REMOVED = 'Client was removed because they have no more encounters at your facility.';
export const PATIENT_NOT_FOUND = 'The client profile you are trying to view is not available.';
export const GENERICERROR = 'Sorry, something went wrong. Please try again and contact Bamboo Health if the problem persists.';
export const USER_ALREADY_EXISTS = 'User already exists.';
