import {Link} from "react-router-dom";
import styled from '@emotion/styled';
import {css} from "@emotion/react";

export const LinkAccount = styled(Link)((props: { justify: string }) => (
  css`
  display: flex;
  margin: 2rem 0 0 0;
  justify-content: ${props.justify};
`));
