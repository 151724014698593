import axios from "axios";

const Auth0Api  = {
  baseUrl: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  connection: process.env.REACT_APP_AUTH0_CONNECTION,
  async sendPasswordResetEmail(email: string) {
    return await axios.post(`${this.baseUrl}/dbconnections/change_password`, {
      client_id: this.clientId,
      email: email,
      connection: this.connection
    });
  },
};

export default Auth0Api;
