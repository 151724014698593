import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

export const StyledIconWrapper = styled.div`
    height: 40px;
    width: 40px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    
    &:hover {
      color: ${colors.gray4};
      border-radius: 50%;
      background-color: ${colors.gray1};
  }
`;
