import React from 'react';
import Icon from "patient-ping-remedy/packages/icon";
import {css} from "@emotion/css";
import {colors} from "patient-ping-remedy/packages/theme";
import {EventInfo, Wrapper, IconWithBackground} from "./NotificationSetting.styles";
import {StandardTypography} from "patient-ping-remedy/packages/typography";
import Spacer from "patient-ping-remedy/packages/spacer";
import {NotificationEventType, NotificationInfoMap} from "../../../api/dto/notification";
import {NotificationIcons} from "../notifications/NotificationIcon";

type GenericObject = { [key: string]: any };

type Props = {
  notificationKey: NotificationEventType;
  enabled: boolean;
  form: GenericObject;
  handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
  selectedType: (newSelectedType: NotificationEventType | null) => void;
}

const NotificationSetting = (props: Props) => {
  const handleClick = () => {
    props.selectedType(props.notificationKey); // Call the state setter
  };

  return (
    <div>
      <Wrapper onClick={handleClick}>
        <EventInfo>
          <IconWithBackground >
            {NotificationIcons[props.notificationKey]}
          </IconWithBackground>

          <Spacer itemWidth={'2rem'}></Spacer>

          <StandardTypography>{NotificationInfoMap[props.notificationKey].profileLabelText}</StandardTypography>
        </EventInfo>
        <div>
          {props.enabled ?
            <Icon iconClass={'bell'} color={colors.tertiary2} className={css({fontSize: '15px'})} />
            : <Icon weight={"far"} iconClass={'bell-slash'} color={colors.gray4} className={css({fontSize: '15px'})} />
          }
        </div>
      </Wrapper>
    </div>
  );
};

export default NotificationSetting;
