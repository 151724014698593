import { colors } from "patient-ping-remedy/packages/theme";
import Badge from "patient-ping-remedy/packages/badge";
import Wrapper from "./StatusIndicator.styles";

type Props = {
    status: string;
}

const StatusIndicator = (props: Props) => {

  const isEnabledOrActivated = (status: string) => {
    return props.status === 'Enabled' ||  props.status === 'Activated' || props.status === 'Active';
  };

  return (
    <>
      <Badge 
        backgroundColor={isEnabledOrActivated(props.status) ? colors.green1 : colors.red1}
        textColor={isEnabledOrActivated(props.status) ? colors.green5 : colors.red5}>
        <Wrapper>
          {props.status}
        </Wrapper>
      </Badge>
    </>
  );
};

export default StatusIndicator;
