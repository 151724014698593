import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const NotificationEnableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const BannerWrapper = styled.div`
  margin-bottom: 10px;
  & > .notification-banner {
    background-color: ${colors.teal1};
  }

  & > .notification-banner span {
    color: ${colors.charcoal};
  }
`;

const CheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

interface CheckboxWrapperProps {
  selected: boolean;
}

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  width: 50%;

  & > label div {
    background-color: ${({selected}) => selected ? colors.primary1 : colors.white};
  }
`;

const GrayedOutCheckbox = styled.div`
  width: 50%;

  & > label {
    color: ${colors.gray3};
  }

  & > label div {
    background-color: ${colors.gray3};
  }
`;

const EventCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
`;

const AlertMethodWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export {SaveButtonWrapper, BannerWrapper, NotificationEnableContainer, CheckBoxRow,
  CheckboxWrapper, GrayedOutCheckbox, EventCategoryWrapper, AlertMethodWrapper};
