import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const ClientNameLink = styled('span')`
  color: ${colors.primary1};
  cursor: pointer;
`;

const EventType = styled('span')`
  color: ${colors.primary1};
`;

const EventDate = styled('span')`
  color: ${colors.gray4};
  font-size: ${fontSize.hint};
`;

export { ClientNameLink, EventType, EventDate };
