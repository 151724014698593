import React, {useMemo, useState} from 'react';
import Modal from 'patient-ping-remedy/packages/modal';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import {Controller, FormProvider, useForm} from 'react-hook-form/dist/index.ie11';
import DropdownList from '../../../../../../pings/dropdown_list/DropdownList';
import {
  ADMITTED,
  DECEASED,
  DISCHARGED,
  ENDED_SERVICES,
  MEDICAL_LEAVE_OF_ABSENCE,
  PAYOR_CHANGED,
  RESUMED_SERVICES,
  TRANSFERRED,
} from '../../../constants/PingStatuses';
import {FACILITY_TYPE_KEYS} from '../../../constants/FacilityTypes';
import StatusInfoHookForm from './StatusInfoHookForm';
import {allStatuses, formatSubmitInsurancePlans, getInsurancePlanOptions, getStatusConfig,} from '../utils/utils';
import {
  StyledLabelFormFieldTypography,
  StyledRow,
  StyledSmallTypography,
  StyledStandardTypography,
} from "./PatientUpdateStatus.styles";
import {REQUIRED_FIELD} from "../../../constants/common";
import {
  GENERICERROR,
  SUCCESS_DECEASED,
  SUCCESS_DISCHARGE,
  SUCCESS_PAYOR_CHANGE,
  SUCCESS_TRANSFER
} from "../../../constants/BannerMessages";
import {ToastType} from "patient-ping-remedy/packages/toast";
import Helpers from "../../../../../../../helpers/helpers";

const isHidden = (hideForCurrentStatus, currentStatus) =>
  hideForCurrentStatus.some((hiddenValue) => hiddenValue === currentStatus);

const getDefaultValues = (patient) => {
  const { currentInsurancePlan, patientInsurancePlans } = getInsurancePlanOptions(patient);
  return {
    insurancePlans: patientInsurancePlans,
    selectedInsurancePlan: currentInsurancePlan,
    date: new Date(),
  };
};

const PatientUpdateStatus = ({
  admittingGroup,
  hideModal,
  addAlert,
  forceRefresh,
  patient,
  createMedicalLeaveOfAbsence,
  createResumeServices,
  createEndServices,
  createDischarge,
  createDeceased,
  updatePatient,
  createAutomatedEncounterTransfer,
  createTransfer,
  createPayorChange,
}) => {
  const [inflight, setInflight] = useState(false);

  const handlePayorChange = (update) =>
    createPayorChange({ ...update, options: { ...update.options, payor: update.options.selectedInsurancePlan } }, patient)
      .then((response) => {
        forceRefresh();
        hideModal();
        addAlert({content: SUCCESS_PAYOR_CHANGE, type: ToastType.SUCCESS});
      })
      .catch((error) => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error(error);
        setInflight(false);
      });

  const handleDischarge = (update) => {
    const { dischargeDate, facility, secondaryFacility, dispositionType, locationDescription } = update.options;
    const formattedOptions = {
      date: dischargeDate,
      facility: { facilityName: facility?.label, id: facility?.id },
      secondaryFacility: { facilityName: secondaryFacility?.label, id: secondaryFacility?.id },
      locationDescription: locationDescription?.id,
      dispositionType,
    };

    createDischarge({ ...update, options: formattedOptions }, patient)
      .then(() => {
        forceRefresh();
        hideModal();
        addAlert({content: SUCCESS_DISCHARGE, type: ToastType.SUCCESS});
      })
      .catch(() => {
        addAlert({content: GENERICERROR, type: ToastType.ERROR});
        setInflight(false);
      });
  };

  const updateActions = {
    [TRANSFERRED]: (update) => {
      createTransfer(update, patient)
        .then(() => {
          forceRefresh();
          hideModal();
          addAlert({content: SUCCESS_TRANSFER, type: ToastType.SUCCESS});
        })
        .catch((error) => {
          const traceId = error.response?.headers['x-trace-id'];
          addAlert({
            content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
            type: ToastType.ERROR
          });
          console.error(error);
          setInflight(false);
        });
    },
    [PAYOR_CHANGED]: (update) => handlePayorChange(update),
    // [MEDICAL_LEAVE_OF_ABSENCE]: (update) => createMedicalLeaveOfAbsence({ ...update, patient }),
    // [RESUMED_SERVICES]: (update) => createResumeServices(update),
    // [ENDED_SERVICES]: (update) => createEndServices(update, patient),
    [DISCHARGED]: (update) => handleDischarge(update),
    [DECEASED]: (update) => {
      createDeceased(update, patient)
        .then(async (response) => {
          await forceRefresh();
          hideModal();
          addAlert({content: SUCCESS_DECEASED, type: ToastType.SUCCESS});
        })
        .catch((error) => {
          const traceId = error.response?.headers['x-trace-id'];
          addAlert({
            content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
            type: ToastType.ERROR
          });
          console.error(error);
          setInflight(false);
        });
    },
  };

  let currentStatus = '';
  if (patient.currentPing) {
    if (admittingGroup.automated) {
      currentStatus = TRANSFERRED; // pcc edits only allow transfer so default to that
    } else if (patient.currentPing.status === TRANSFERRED) {
      currentStatus = ADMITTED;
    } else {
      // these three statuses are not supported in V1 please remove this if check when they are supported
      if(patient.currentPing.status === RESUMED_SERVICES
        || patient.currentPing.status === ENDED_SERVICES
        || patient.currentPing.status === MEDICAL_LEAVE_OF_ABSENCE) {
        currentStatus = ADMITTED;
      } else {
        currentStatus = patient.currentPing.status;
      }
    }
  }

  const defaultValues = getDefaultValues(patient);

  const hookFormMethods = useForm({ defaultValues, mode: 'onChange' });
  const { watch } = hookFormMethods;
  const statusConfig = getStatusConfig(patient);
  const defaultStatusValue = statusConfig[currentStatus] || {
    id: '',
    label: '',
    hideForCurrentStatus: [],
    formControls: {},
    action: null,
  };
  const updatedStatus = watch('status', defaultStatusValue);
  const isEncounterAutomated = patient.currentPing?.editable && !patient.currentPing?.manual;

  const handleUpdate = (data) => {
    setInflight(true);

    const { currentPingEncounter, id } = patient;
    const encounterId = currentPingEncounter ? currentPingEncounter.encounterId : '';
    const options = { ...data, setting: data.setting };

    const patientUpdates = {};
    if (data.insurancePlans) {
      patientUpdates.insurancePlans = formatSubmitInsurancePlans(
        defaultValues.insurancePlans,
        data.insurancePlans,
        data.selectedInsurancePlan,
      );
    }
    if (data.setting) {
      patientUpdates.setting = data.setting;
    }
    if (updatedStatus.id === TRANSFERRED && isEncounterAutomated) {
      return createAutomatedEncounterTransfer({ patientId: id, encounterId, options })
        .then((response) => {
          hideModal();
          addAlert({content: SUCCESS_TRANSFER, type: ToastType.SUCCESS});
        })
        .catch((error) => {
          const traceId = error.response?.headers['x-trace-id'];
          addAlert({
            content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
            type: ToastType.ERROR
          });
          console.error(error);
          setInflight(false);
        });
    }
    if (Object.keys(patientUpdates).length) {
      updatePatient({ patientId: id, data: patientUpdates }).then((response) => {
        const updatedInsurance = response.data.currentInsurancePlans.filter((plan) => plan.selected)[0];
        const newStatus = {
          encounterId,
          patientId: id,
          options: { ...options, selectedInsurancePlan: updatedInsurance },
        };
        updateActions[updatedStatus.id](newStatus);
      }).catch((error) => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error(error);
        setInflight(false);
      });
    } else {
      updateActions[updatedStatus.id]({ encounterId, patientId: id, options });
    }
    return null;
  };

  const statusOptions = useMemo(() => {
    let allowedStatusTypes = [PAYOR_CHANGED, DISCHARGED, DECEASED];

    if (isEncounterAutomated) {
      //PCC users are only allowed to update transfers, which will be their current status and will be concatted below
      allowedStatusTypes = [];
    } else if (admittingGroup.type === FACILITY_TYPE_KEYS.SNF) {
      allowedStatusTypes = allStatuses;
    }

    const filteredStatuses = allowedStatusTypes
      .map((option) => statusConfig[option])
      .filter((option) => !isHidden(option.hideForCurrentStatus, currentStatus));
    const currentStatusOption = statusConfig[currentStatus];
    return [currentStatusOption, ...filteredStatuses];
  }, []);

  const formHasNoChange = defaultStatusValue.id === updatedStatus.id;
  const cannotReoccur = [DISCHARGED, DECEASED, ADMITTED].includes(updatedStatus.id);
  const isDisabled = formHasNoChange && cannotReoccur;

  const modalButtons = [
    <Button
      key="submit"
      styleType={StyleType.PRIMARY}
      disabled={inflight || isDisabled || !hookFormMethods.formState.isValid}
      onClick={hookFormMethods.handleSubmit(handleUpdate)}
    >
      Update
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={hideModal}>
      Cancel
    </Button>,
  ];

  const { label = '', showSetting = false, showInsurance = false } = statusConfig[updatedStatus.id].formControls;

  return (
    <Modal headerText={`Update Client Status - ${admittingGroup.name}`} buttons={modalButtons}>
      <FormProvider {...hookFormMethods}>
        <StyledSmallTypography>
          All fields marked with <span>*</span> are required.
        </StyledSmallTypography>
        <StyledRow>
          <div>
            <StyledLabelFormFieldTypography as="label">Client Name</StyledLabelFormFieldTypography>
            <StyledStandardTypography>
              {`${patient.firstName} ${patient.middleName ? patient.middleName[0].toUpperCase() : ''} ${patient.lastName}`}
            </StyledStandardTypography>
          </div>
          <Controller
            name="status"
            defaultValue={defaultStatusValue}
            control={hookFormMethods.control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <DropdownList
                handleChange={(event) => {
                  onChange(event);
                }}
                onBlur={onBlur}
                value={statusOptions.find((option) => option.id === value.id)}
                id="status"
                items={statusOptions}
                label="Status"
                isWithinModal
                required
                error={hookFormMethods.errors.status}
                errorMessage={REQUIRED_FIELD}
              />
            )}
          />
        </StyledRow>

        {!isDisabled && (
          <StatusInfoHookForm
            status={updatedStatus.id}
            patient={patient}
            label={label}
            showSetting={showSetting}
            showInsurance={showInsurance}
            defaultSelectedPlan={defaultValues.selectedInsurancePlan}
            isSnf={admittingGroup.type === FACILITY_TYPE_KEYS.SNF}
            isPcc={isEncounterAutomated}
          />
        )}
      </FormProvider>
    </Modal>
  );
};

export default PatientUpdateStatus;
