import { colors, sizes } from 'patient-ping-remedy/packages/theme';
import Button from "patient-ping-remedy/packages/button";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {DischargeInfoTabs} from "./DischargeInfoModal";

const contentStyles = css`
  font: 1.5rem 'Libre Franklin', sans-serif;
    
  h1 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 600;
  }
  & > div:not(#general) {
    margin-top: ${sizes.small};
  }
    
  table {
    width: 100%;
    margin-top: ${sizes.small};
    border-collapse: collapse;
    thead th {
      font-size: 1.3rem;
      padding: ${sizes.xSmall};
      background-color: ${colors.gray1};
      font-weight: normal;
      text-align: left;
    }
    td {
      padding: ${sizes.xSmall};
      font-size: 1.5rem;
    }
    tbody tr {
      border-bottom: 1px solid ${colors.gray2};
    }
  }
  img {
    width: 300px;
   }
   p {
    font-size: 1.5rem;
   }
`;

export const printDocStyle = `
        table {
          border: 1px solid grey;
          width: 100%;
          border-collapse: collapse !important;
          border-radius: .3rem;
          padding: .5rem;
          width: 90%;
          font-family: 'Libre Franklin', sans-serif;
        }
        tr {
            border-bottom: 1px solid ${colors.gray2} !important;
        }
        td {
            padding: .5rem;
        }`;

export const StyledContent = styled.div((props: {currentTab: number}) => (
  css`
  max-width: 90rem;

  #general {
      display: ${props.currentTab === DischargeInfoTabs.GENERAL_TAB ? 'block' : 'none'};
  }

  #dischargeInstructions {
    display: ${props.currentTab === DischargeInfoTabs.DISCHARGE_INSTRUCTIONS_TAB ? 'block' : 'none'};
  }

  #dischargeMedications {
    display: ${props.currentTab === DischargeInfoTabs.DISCHARGE_MEDICATIONS_TAB ? 'block' : 'none'};
  }

  #upcomingEncounters {
    display: ${props.currentTab === DischargeInfoTabs.UPCOMING_ENCOUNTERS_TAB ? 'block' : 'none'};
  }

  ${contentStyles}
  `
));

export const Loading = styled.div`
  width: 10rem;
  height: 10rem;
  margin: auto;
`;

export const Info = styled.div`
  display: flex;
  margin: ${sizes.xSmall} 0;
  align-items: center;
    
  svg {
    margin-right: ${sizes.xSmall};
  }
`;

export const PatientInfo = styled.div`
  display: inline-block;
  margin-bottom: ${sizes.xxSmall};
`;

export const StyledPrintIcon = styled(Button)`
    padding: 0;
    margin-left: ${sizes.xSmall};
`;
