const MALE = 'Male';
const FEMALE = 'Female';
const OTHER = 'Other';
const UNKNOWN = 'Unknown';
const NON_BINARY = 'Non-Binary';

export function getGenderAbbreviation(value) {
  switch (value) {
  case MALE:
    return 'M';
  case FEMALE:
    return 'F';
  case OTHER:
    return 'O';
  case NON_BINARY:
    return 'X';
  case UNKNOWN:
    return 'U';
  default:
    return value;
  }
}

const Genders = { MALE, FEMALE, OTHER, UNKNOWN, NON_BINARY };

export default Genders;
