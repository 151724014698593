import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import DropdownList from '../../../../../../pings/dropdown_list/DropdownList';
import DatePicker from 'patient-ping-remedy/packages/date-picker';
import Spacer from 'patient-ping-remedy/packages/spacer';
import { sizes } from 'patient-ping-remedy/packages/theme';
import FacilitySelect from '../forms/FacilitySelect';
import { DISCHARGED } from '../../../constants/PingStatuses';
import {StyledInstructions, StyledLabelFormFieldTypography, StyledRow} from "./DischargeHookForm.styles";
import {CarecoApiContext} from "../../../../../../../app-context/careco-api-context";
import {REQUIRED_FIELD} from "../../../constants/common";
import {useQuery} from "@tanstack/react-query";

const locationDescription = [
  { id: 'patient', label: "Patient's Home" },
  { id: 'caregiver', label: "Caregiver's Home" },
];

const defaultFacilityOptions = {
  hasFacility: false,
  hasFacility2: false,
  hasHome: false,
};

const defaultFacilities = {
  facility: {
    facilityName: '',
    label: '',
    id: 0,
  },
  secondaryFacility: {
    facilityName: '',
    label: '',
    id: 0,
  },
  locationDescription: { label: '', id: 0 },
};

const getOptionsAndValues = (patient) => {
  let values = {
    date: new Date(),
    dispositionType: {
      id: 0,
      dispositionSynonym: '',
      code: '',
    },
    ...defaultFacilities,
    disabled: true,
  };

  const options = {
    dispositionType: [],
    facility: [],
    ...defaultFacilityOptions,
  };

  if (patient.currentPing?.status === DISCHARGED) {
    const { currentPing, currentPingEncounter } = patient;
    values = {
      ...values,
      date: new Date(currentPing.eventDate),
      dispositionType: {
        ...values.dispositionType,
        dispositionSynonym: currentPingEncounter.dischargeDisposition,
      },
    };
    // TODO: from 2016 :( This is incredibly ugly, but the api is returning 1 string with this information and there's no way
    // to really know what the dispositionType is or if something is a facility or a home.
    // I'll need to update api/patients currentPingEncounter.dischargeTo
    let dischargeFacility = currentPingEncounter.dischargeTo ? currentPingEncounter.dischargeTo.split('(') : '';
    if (dischargeFacility.length > 1) {
      values.facility = { id: 0, facilityName: dischargeFacility[0].trim(), label: dischargeFacility[0].trim() };
      values.locationDescription = locationDescription.find(
        (location) => location.label === dischargeFacility[1].replace(')', ''),
      );
      options.hasFacility = true;
      options.hasHome = true;
    } else if (Array.isArray(dischargeFacility)) {
      dischargeFacility = dischargeFacility[0].split(',');
      if (dischargeFacility.length > 1) {
        values.facility = {
          id: 0,
          facilityName: dischargeFacility[0].trim(),
          label: dischargeFacility[0].trim(),
        };
        values.secondaryFacility = {
          id: 0,
          facilityName: dischargeFacility[1].trim(),
          label: dischargeFacility[1].trim(),
        };
        options.hasFacility = true;
        options.hasFacility2 = true;
      } else if (dischargeFacility[0] === 'Patient\'s Home' || dischargeFacility[0] === 'Caregiver\'s Home') {
        values.locationDescription = locationDescription.find((location) => location.label === dischargeFacility[0]);
        options.hasHome = true;
      } else if (dischargeFacility[0].length > 0) {
        values.facility = { id: 0, facilityName: dischargeFacility[0], label: dischargeFacility[0] };
        options.hasFacility = true;
      }
    }
  }
  return { values, options };
};

const validateInputLength = (value) => {
  const trimmedValueLength = value?.label?.trim('')?.length;
  return trimmedValueLength > 0 && trimmedValueLength < 151;
};

const DischargeHookForm = ({ patient, showInstructions = true }) => {
  const { options: defaultOptions, values: defaultValues } = getOptionsAndValues(patient);
  const [options, setOptions] = useState(defaultOptions);
  const [values, setValues] = useState(defaultValues);
  const [inflight, setInflight] = useState(true);
  const { carecoApi } = useContext(CarecoApiContext);

  const {data: dispositionType, isLoading, error} = useQuery({
    queryFn: () => carecoApi?.readDischargeDispositions(),
    queryKey: ['dischargeDispositions'],
    enabled: !!carecoApi,
  });

  function getDispositionOptions() {
    if (isLoading || error || !dispositionType) {
      return options;
    }

    const newOptions = {
      ...options,
      dispositionType,
    };
    if (patient.currentPing?.status === DISCHARGED) {
      const newValues = {
        ...values,
        dispositionType: dispositionType.find(
          (disposition) => disposition.dispositionSynonym === values.dispositionType.dispositionSynonym,
        ),
      };
      setValues(newValues);
    }
    setInflight(false);
    return newOptions;
  }

  const { control, errors, watch } = useFormContext();
  const watchDispositionType = watch('dispositionType');

  useEffect(
    function setSecondaryOptions() {
      const updatedOptions = { ...defaultFacilityOptions };
      switch (watchDispositionType?.type) {
      case 'LU':
        updatedOptions.hasFacility = true;
        break;
      case 'LU_OTHER':
        updatedOptions.hasFacility = true;
        updatedOptions.hasHome = true;
        break;
      case 'OTHER':
        updatedOptions.hasHome = true;
        break;
      case 'LU_ALHH':
        updatedOptions.hasFacility = true;
        updatedOptions.hasFacility2 = true;
        break;
      default:
        break;
      }
      const newOptions = getDispositionOptions();
      setOptions({ ...newOptions, ...updatedOptions });
    },
    [watchDispositionType, inflight, carecoApi, dispositionType],
  );

  if (inflight) {
    return null;
  }

  return (
    <Fragment>
      {showInstructions && (
        <StyledInstructions>Please discharge the client from the previous encounter.</StyledInstructions>
      )}
      <StyledRow>
        <Controller
          defaultValue={values.date}
          name="dischargeDate"
          control={control}
          rules={{ required: true }}
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              maxDate={new Date()}
              minDate={new Date(patient.currentPing.eventDate)}
              value={value}
              externalError={errors.dischargeDate}
              externalErrorText={REQUIRED_FIELD}
              id="dischargeDate"
              labelText="Discharge Date"
              required="required"
            />
          )}
        />
        <Controller
          defaultValue={values.dispositionType}
          name="dispositionType"
          control={control}
          rules={{ validate: (value) => !!value.value }}
          render={({ onChange, onBlur, value }) => (
            <DropdownList
              handleChange={onChange}
              onBlur={onBlur}
              value={options.dispositionType.find((option) => option.value === value.value)}
              id="dispositionType"
              items={options.dispositionType}
              label="Disposition"
              isWithinModal
              required
              error={errors.dispositionType}
              errorMessage={REQUIRED_FIELD}
            />
          )}
        />
      </StyledRow>
      <StyledRow>
        {options.hasFacility && (
          <div>
            <StyledLabelFormFieldTypography className="requiredField">
              {!options.hasFacility2 ? 'Facility Name' : 'Select an assisted living facility'}
            </StyledLabelFormFieldTypography>
            <Controller
              defaultValue={values.facility}
              name="facility"
              control={control}
              rules={{ validate: validateInputLength }}
              shouldUnregister
              render={({ onChange, value, onBlur }) => (
                <FacilitySelect
                  id="facility"
                  onSelect={onChange}
                  onBlur={onBlur}
                  value={value.label}
                  disposition={values.dispositionType.dispositionSynonym}
                  groupId={patient.creatorGroupId}
                  floatOnModal
                  error={!!errors.facility}
                  searchFacilities={carecoApi?.searchFacilities}
                />
              )}
            />
          </div>
        )}
        {options.hasFacility2 && (
          <Fragment>
            <Spacer itemWidth={sizes.small} />
            <div>
              <StyledLabelFormFieldTypography className="requiredField">
                Select a home health agency
              </StyledLabelFormFieldTypography>
              <Controller
                defaultValue={values.secondaryFacility}
                name="secondaryFacility"
                control={control}
                rules={{ validate: validateInputLength }}
                shouldUnregister
                render={({ onChange, value, onBlur }) => (
                  <FacilitySelect
                    onSelect={onChange}
                    onBlur={onBlur}
                    value={value.label}
                    disposition={values.dispositionType.dispositionSynonym}
                    groupId={patient.creatorGroupId}
                    floatOnModal
                    error={!!errors.secondaryFacility}
                    id="secondaryFacility"
                    searchFacilities={carecoApi?.searchFacilities}
                  />
                )}
              />
            </div>
          </Fragment>
        )}

        {options.hasHome && (
          <Fragment>
            <Spacer itemWidth={sizes.small} />
            <Controller
              defaultValue={values.locationDescription}
              name="locationDescription"
              control={control}
              rules={{ validate: (value) => !!value.id }}
              render={({ onChange, onBlur, value }) => (
                <DropdownList
                  handleChange={onChange}
                  onBlur={onBlur}
                  value={locationDescription.find((option) => option.id === value.id)}
                  id="locationDescription"
                  items={locationDescription}
                  label="Whose home"
                  isWithinModal
                  required
                  error={errors.locationDescription}
                  errorMessage={REQUIRED_FIELD}
                />
              )}
            />
          </Fragment>
        )}
      </StyledRow>
    </Fragment>
  );
};

export default DischargeHookForm;
