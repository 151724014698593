import React, {ReactNode} from "react";
import {StyledChip} from "./QuickFilter.styles";

type Props = {
  onClick: (e: any) => void;
  children: ReactNode;
  negated: boolean;
  disabled?: boolean;
};

const QuickFilter = (props: Props) => {
  return (
    <StyledChip
      negated={props.negated}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </StyledChip>
  );
};

export default QuickFilter;
