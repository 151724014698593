import React, { Fragment, useState, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Icon from 'patient-ping-remedy/packages/icon';
import { SmallTypography } from 'patient-ping-remedy/packages/typography';
import { boxShadow, colors, sizes } from 'patient-ping-remedy/packages/theme';
import DelayedTextInput from './DelayedTextInput';
import InputDropdownItem from './InputDropdownItem';
import Spinner from 'patient-ping-remedy/packages/spinner';
import classNames from '../utils/classnames';

const INITIALSTATE = {
  open: false,
  highlightedIndex: null,
};

const InputWithDropdownWrapper = styled('div')`
  & {
    position: relative;
  }
`;

const StyledSpinner = styled(Spinner)`
  top: calc(50% - ${sizes.xxSmall});
  right: 1rem;
  position: absolute;
`;

const InputDropdownMenu = styled('div')`
  & {
    ${({ floatOnModal, menuStyle }) => {
    if (floatOnModal) {
      return `   
      position: fixed;
      top: ${menuStyle.top / 10}rem;
      left: ${menuStyle.left / 10}rem;
      width: 67rem;
      `;
    }
    return `
    width: 100%;
    margin: 0.2rem 0 0;
    `;
  }}
    max-height: 20.8rem;
    float: left;
    list-style: none;
    font-size: 1.4rem;
    text-align: left;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray2};
    box-shadow: ${boxShadow}
    background-clip: padding-box;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 7002;
  }
`;

const coverModalScrollContainer = css`
          position: fixed;
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          z-index: 7001;
`;

const ErrorMessage = styled(SmallTypography)`
  display: block;
  margin: ${sizes.xxSmall} 0;
  color: ${colors.yellow3};
  svg {
    margin-right: ${sizes.xxSmall};
  }
`;

const InputWithDropdown = forwardRef(
  (
    {
      options,
      onSelect,
      search,
      inFlight,
      reset,
      value,
      onChange,
      onBlur,
      placeholder,
      onFocus,
      activePlaceholder,
      disabled,
      onEnterKeyed,
      inputButton,
      onArrowUpKeyed,
      onArrowDownKeyed,
      getOptionLabel,
      clearOnLeave,
      className,
      floatOnModal,
      menuStyle,
      error,
      testId,
      id,
      showDropDown
    },
    ref,
  ) => {
    const [ignoreBlur, setIgnoreBlur] = useState(false);
    const [open, setOpen] = useState(INITIALSTATE.open);
    const [highlightedIndex, setHighlightedIndex] = useState(INITIALSTATE.highlightedIndex);

    const resetState = () => {
      reset();
      setOpen(INITIALSTATE.open);
      setHighlightedIndex(INITIALSTATE.highlightedIndex);
      setIgnoreBlur(false);
    };

    const selectItem = () => {
      const item = options[highlightedIndex];
      if (item) {
        resetState();
        onChange(clearOnLeave ? '' : item.label);
        onSelect(item);
      }
    };

    const handleEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      selectItem();
      onEnterKeyed && onEnterKeyed();
    };

    const handleArrowDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (options.length === 0) {
        return;
      }
      if (highlightedIndex === null) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex(Math.min(highlightedIndex + 1, options.length - 1));
      }
      onArrowDownKeyed && onArrowDownKeyed();
    };

    const handleArrowUp = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (options.length === 0) {
        return;
      }
      if (highlightedIndex !== null) {
        setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
      }
      onArrowUpKeyed && onArrowUpKeyed();
    };

    const handleEscape = (e) => {
      e.preventDefault();
      e.stopPropagation();
      resetState();
    };

    const handleKeyDown = (e) => {
      switch (e.key) {
      case 'ArrowDown':
        handleArrowDown(e);
        break;
      case 'ArrowUp':
        handleArrowUp(e);
        break;
      case 'Enter':
        handleEnter(e);
        break;
      case 'Escape':
        handleEscape(e);
        break;
      case 'Tab':
        if (clearOnLeave) {
          resetState();
        }
        break;
      default:
      }
    };

    const handleInputChange = (e) => {
      onChange(e.target.value);
      if (!e.target.value) {
        onSelect(null);
        reset();
      }
    };

    const handleDelayedChange = (e) => {
      search(e.target.value);
    };

    const handleMouseEnter = () => {
      setIgnoreBlur(true);
    };

    const handleItemMouseEnter = (idx) => {
      setHighlightedIndex(idx);
    };

    const handleMouseLeave = () => {
      setIgnoreBlur(false);
    };

    const handleInputFocus = (e) => {
      if (!!e.nativeEvent.type && e.nativeEvent.type === 'mouseup' && !!onFocus) {
        onFocus(e);
      }
      setOpen(true);
    };

    const handleInputBlur = () => {
      onBlur && onBlur();
      // reset input to empty if leaving the input unless selecting an option
      if (!ignoreBlur) {
        setOpen(false);
        if (clearOnLeave) {
          onChange('');
          reset();
        }
      }
    };

    const getPlaceholder = () => {
      if (open) {
        return activePlaceholder || placeholder;
      }
      return placeholder;
    };

    const renderDropdown = () => {
      if (options.length === 0 || !showDropDown) {
        return null;
      }
      let idx = -1;
      const optionItems = options.map((option) => {
        idx++;
        const j = idx;
        return (
          <InputDropdownItem
            key={j}
            label={getOptionLabel(option)}
            highlighted={highlightedIndex === j}
            onMouseEnter={() => handleItemMouseEnter(j)}
            onClick={() => selectItem(j)}
            onMouseDown={() => setIgnoreBlur(true)}
          />
        );
      });

      const dropdownComponent = (
        <InputDropdownMenu floatOnModal={floatOnModal} menuStyle={menuStyle}>
          {optionItems}
        </InputDropdownMenu>
      );

      return floatOnModal ? createPortal(dropdownComponent, document.body) : dropdownComponent;
    };

    const closeDropdown = () => {
      setOpen(false);
    };

    const coverModalScroll = () => {
      const optionsAvailable = options.length > 0;
      if (!open || !optionsAvailable) {
        return null;
      }
      return createPortal(<div onClick={closeDropdown} css={coverModalScrollContainer} />, document.body);
    };

    return (
      <Fragment>
        <InputWithDropdownWrapper
          id={id}
          ref={ref}
          data-testid={testId}
          className={classNames(className)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={classNames({ 'input-group': !!inputButton })}>
            <DelayedTextInput
              disabled={disabled || false}
              value={value}
              onChange={handleInputChange}
              onDelayedChange={handleDelayedChange}
              delay={100}
              onSelect={handleInputFocus}
              placeholder={getPlaceholder()}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              error={error}
            />
            {!!inputButton && <span className="input-group-btn">{inputButton}</span>}
          </div>
          {inFlight && <StyledSpinner iconSize="1x" />}
          {floatOnModal && coverModalScroll()}
        </InputWithDropdownWrapper>
        {open && renderDropdown()}
        {error && (
          <ErrorMessage>
            <Icon iconClass="exclamation-triangle" color={colors.yellow2} iconSize="lg" weight="fas" />
            Required Field
          </ErrorMessage>
        )}
      </Fragment>
    );
  },
);

InputWithDropdown.defaultProps = {
  reset: () => null,
  getOptionLabel: (option) => option.label,
  clearOnLeave: false,
  testId: 'input-with-dropdown',
};

export default InputWithDropdown;
