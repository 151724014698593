import {ICONS} from 'patient-ping-remedy/packages/icon';
import {useMemo, useState} from 'react';
import Menu from '../common/menu/Menu';
import MenuItem from '../common/menu/MenuItem';
import MenuItemIcon from '../common/menu/MenuItemIcon';
import {customMenuItemIconStyle} from '../common/menu/MenuItemIcon.styles';
import MenuItemTypography from '../common/menu/MenuItemTypography';
import {MenuItemSpacer} from '../common/menu/MenuItem.styles';
import MenuItemTrailingIcon from '../common/menu/MenuItemTrailingIcon';
import CreateAdmission from '../pages/clients/manual_encounter/CreateAdmission';
import CreateReferral from '../pages/clients/referral/CreateReferral';
import {ReactComponent as ReferralIcon} from '../../assets/icons/menu/referral.svg';
import {useAuthStore} from '../../store/auth_store';
import {useRosterStore} from '../../store/roster_store';
import ToolTip from 'patient-ping-remedy/packages/tool-tip';
import Helpers from '../../helpers/helpers';
import PermissionsHelpers from '../../helpers/permissions_helpers';
import {GroupType} from "../../api/dto/dto";

type Props = {
  hidePopover: () => void;
}

const CreateButtonContent = (props: Props) => {
  const {currentUser} = useAuthStore();
  const {currentRoster} = useRosterStore();

  const [showAdmissionModal, setShowAdmissionModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const isAdmissionAvailable = useMemo(() => {
    return currentRoster
    && currentRoster.admittingFacility
    && !currentRoster.automated
    && currentRoster.type !== GroupType.CRISIS;
  }, [currentRoster]);

  const isReferralAvailable = currentUser && currentRoster && (
    PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.SSO,
      Helpers.getAppDataRoleDetails().permissions.FINDHELP,
    ) ||
    PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.SSO,
      Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS,
    ) ||
    PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.SSO,
      Helpers.getAppDataRoleDetails().permissions.OB_CRISIS,
    ) ||
    PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.SSO,
      Helpers.getAppDataRoleDetails().permissions.ROUNDTRIP,
    )
  );

  const handleAdmissionClick = () => {
    if (isAdmissionAvailable) {
      setShowAdmissionModal(true);
      props.hidePopover();
    }
  };

  const handleReferralClick = () => {
    if (isReferralAvailable) {
      setShowReferralModal(true);
      props.hidePopover();
    }
  };

  return (
    <>
      <Menu width="28rem" marginTop marginBottom>
        <MenuItem disabled={!isAdmissionAvailable} onClick={handleAdmissionClick}>
          <MenuItemIcon iconClass={ICONS['user-circle']} />
          <MenuItemTypography>Admission</MenuItemTypography>
          {!isAdmissionAvailable && (
            <>
              <MenuItemSpacer />
              <ToolTip
                text="Please select a group that is a manual admitting facility to enable this option."
                position="bottom-start"
              >
                <MenuItemTrailingIcon iconClass={ICONS['info-circle']} />
              </ToolTip>
            </>
          )}
        </MenuItem>
        <MenuItem disabled={!isReferralAvailable} onClick={handleReferralClick}>
          <ReferralIcon style={customMenuItemIconStyle} />
          <MenuItemTypography>Referral</MenuItemTypography>
          {!isReferralAvailable && (
            <>
              <MenuItemSpacer />
              <ToolTip
                text={currentRoster
                  ? 'Users require OpenBeds, FindHelp, or Roundtrip SSO access to enable this option.'
                  : 'No group selected.'}
                position="bottom-start"
              >
                <MenuItemTrailingIcon iconClass={ICONS['info-circle']} />
              </ToolTip>
            </>
          )}
        </MenuItem>
      </Menu>
      {showAdmissionModal && <CreateAdmission hideModal={() => setShowAdmissionModal(false)} />}
      {showReferralModal && <CreateReferral hideModal={() => setShowReferralModal(false)} />}
    </>
  );
};

export default CreateButtonContent;
