import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
`;

const EventInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWithBackground = styled.div`
  display: flex;
  background: ${colors.primary1};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
`;

export { Wrapper, EventInfo, IconWithBackground };
