import {
  dayOfWeekFormInfo,
  notificationFrequencyInfo,
  NotificationFrequencyType,
  NotificationSchedule
} from "../../../../api/dto/notification";
import React from "react";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import QuickFilter from "../../../common/QuickFilter";
import {
  AlertFrequencyWrapper,
  AlertScheduleWrapper,
  DayButtonWrapper,
  DaysOfWeekWrapper,
  NotificationTimeWrapper
} from "./NotificationScheduleComponent.styles";
import RemedyDatePicker from "patient-ping-remedy/packages/date-picker";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import Button, {StyleType} from "patient-ping-remedy/packages/button";

type GenericObject = { [key: string]: any };

type Props = {
  notificationSchedule: NotificationSchedule;
  handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
  form: GenericObject;
}

export const NotificationScheduleComponent = (props: Props) => {
  const externalAlertDisabled = !props.form['email']?.value && !props.form['sms']?.value;
  const alertScheduleDisabled = externalAlertDisabled || !props.form.custom_schedule?.value;

  return (
    <>
      <SmallTypography>External Alert Frequency</SmallTypography>
      <br/>
      <AlertFrequencyWrapper>
        {
          Object.keys(notificationFrequencyInfo).map((key) => {
            return (
              <Button
                className={props.form['notification_frequency']?.value === key && !externalAlertDisabled ?
                  'alert-frequency-button-selected' : ''}
                key={key}
                onClick={() => {
                  props.handleChange(null, 'notification_frequency', key as NotificationFrequencyType);
                }}
                disabled={externalAlertDisabled}

                styleType={props.form['notification_frequency']?.value === key ?
                  StyleType.PRIMARY : StyleType.SECONDARY}
              >
                {notificationFrequencyInfo[key as NotificationFrequencyType]}
              </Button>
            );
          })
        }
      </AlertFrequencyWrapper>

      <AlertScheduleWrapper enabled={!alertScheduleDisabled}>
        <SmallTypography>External Alert Schedule</SmallTypography>
        <Checkbox
          key={'custom_schedule'}
          disabled={externalAlertDisabled}
          value={props.form.custom_schedule?.value}
          label={'Custom alert schedule'}
          onChange={(e) => {
            props.handleChange(null, 'custom_schedule', e);
          }}
        />
      </AlertScheduleWrapper>

      <DaysOfWeekWrapper>
        {
          Object.keys(dayOfWeekFormInfo).map((key) => {
            const dayOfWeekKey = key as keyof typeof dayOfWeekFormInfo;

            return (
              <DayButtonWrapper
                selected={props.form[dayOfWeekKey]?.value}
                disabled={alertScheduleDisabled}
              >
                <QuickFilter
                  onClick={(e) => {
                    props.handleChange(e, dayOfWeekKey, !props.form[dayOfWeekKey]?.value);
                    return !props.form[dayOfWeekKey]?.value;
                  }}
                  negated={props.form[dayOfWeekKey]?.value}
                  disabled={alertScheduleDisabled}
                >
                  {dayOfWeekFormInfo[dayOfWeekKey].label}
                </QuickFilter>
              </DayButtonWrapper>
            );
          })
        }
      </DaysOfWeekWrapper>

      <NotificationTimeWrapper>
        <RemedyDatePicker
          labelText={'From'}
          timePickerOnly
          disabled={alertScheduleDisabled}
          onChange={(e) => {
            props.handleChange(null, 'from_time', e);
            return e;
          }}
          value={props.form.from_time?.value}
          externalErrorText={"From time must be before To time"}
          externalError={props.form.from_time?.value >= props.form.to_time?.value}
        />

        <RemedyDatePicker
          labelText={'To'}
          timePickerOnly
          disabled={alertScheduleDisabled}
          onChange={(e) => {
            props.handleChange(null, 'to_time', e);
            return e;
          }}
          value={props.form.to_time?.value}
        />
      </NotificationTimeWrapper>
    </>
  );
};
