import styled from '@emotion/styled';
import Button from "patient-ping-remedy/packages/button";
import {colors} from "patient-ping-remedy/packages/theme";
import Icon from "patient-ping-remedy/packages/icon";
import {StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";

export const UploadButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  height: 0;
`;

export const StyledUploadButton = styled(Button)`
  position: relative;
  top: -51px;
  color: ${colors.tertiary2};
  border-color: ${colors.tertiary2};
  padding: 8px 14px;

  &:hover {
    background-color: ${colors.purple1};
    border-color: ${colors.tertiary2};
  }
`;

export const StyledSemiBoldTypography = styled(StandardSemiBoldTypography)`
  color: ${colors.tertiary2};
  font-size: 16px;
`;

export const StyledUploadIcon = styled(Icon)`
  margin-right: 5px;
  font-size: 18px;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RoundButton = styled(Button)`
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
    
  &:hover {
    background-color: ${colors.purple1};
  }
`;

export const RoundIconWrapper= styled.div`
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
    
  &:hover {
    background-color: ${colors.purple1};
  }
`;

export const StyledFileIcon = styled(Icon)`
  font-size: 18px;
`;
