import {fontSize} from "patient-ping-remedy/packages/theme";
import styled from "@emotion/styled";
import {css} from "@emotion/css";

const NameHeading = styled.span`
  font-size: ${fontSize.heading};
  font-weight: 700;
  transition: color 0.15s ease;
`;

const flexContainer = css`
  display: flex;
`;

const marginRightAuto = css`
  margin-right: auto;
`;

export {NameHeading, flexContainer, marginRightAuto};
