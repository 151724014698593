import {colors} from 'patient-ping-remedy/packages/theme';
import styled from '@emotion/styled';

type Props = {
  margin?: string;
  marginTop?: boolean;
}

const Header = styled.h1<Props>((props) => {
  const margin = props.margin || '2rem';

  return {
    fontSize: '2.4rem',
    margin: `${props.marginTop ? margin : 0} 0 ${margin} 0`,
    color: colors.primary1,
  };
});

export default Header;
