import {PureComponent} from "react";

class SafePureComponent extends PureComponent {
  componentDidMount() {
    try {
      this.safeComponentDidMount && this.safeComponentDidMount();
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    try {
      return this.safeRender();
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export default SafePureComponent;
