import React, {useContext} from "react";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useRosterStore} from "../../../../../store/roster_store";
import {ClientProfile} from "../../../../../api/dto/client-profile";
import {useQuery} from "@tanstack/react-query";
import {StyledIframe} from "./PdmpTab.styles";
import Loading from "../../../../common/Loading";
import Header from "../../../../common/pages/Header";
import {AxiosError} from "axios";
import PdmpError from "./PdmpError";
import useIframeResizer from "../../../../../hooks/useIframeResizer";

type Props = {
  patient: ClientProfile;
}

const PdmpTab = (props: Props) => {
  const patientId = props.patient?.patient?.patientId;
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentRoster } = useRosterStore();

  const { data: pdmpPatientResponse, isLoading: pdmpPatientDataLoading, error: pdmpPatientError } = useQuery({
    queryKey: ['pdmp', 'patientResponse', patientId],
    queryFn: () => carecoApi?.getPatientRequest(
      currentRoster!.id,
      patientId!
    ),
    enabled: !!carecoApi && !!currentRoster && !!patientId
  });

  const { data: pdmpReportResponse, isLoading: pdmpReportLoading, error: pdmpError } = useQuery({
    queryKey: ['pdmp', 'reportResponse', patientId],
    queryFn: () => carecoApi?.getReportRequest(
      currentRoster!.id,
      patientId!,
      pdmpPatientResponse!.reportURL
    ),
    enabled: !!carecoApi && !!currentRoster && !!patientId && !!pdmpPatientResponse?.reportURL
  });

  const { data: pdmpReportHtml, isLoading: pdmpReportHtmlLoading } = useQuery({
    queryKey: ['pdmp', 'reportHtml', patientId],
    queryFn: () => carecoApi?.getReportLink(pdmpReportResponse!.reportLink),
    enabled: !!carecoApi && !!currentRoster && !!patientId && !!pdmpReportResponse?.reportLink
  });

  const { customHtml } = useIframeResizer({ html: pdmpReportHtml!, observerElementId: 'vs-app' });

  const isLoading = pdmpPatientDataLoading || pdmpReportLoading || pdmpReportHtmlLoading;
  const error = (pdmpPatientError || pdmpError) as AxiosError;
  const hasData = !!pdmpPatientResponse && !!pdmpReportResponse && !!pdmpReportHtml && !!customHtml;

  if(isLoading) return <Loading iconSize={'4x'}/>;
  if(error || !hasData) return <PdmpError error={error} isTile={false} />;

  return (
    <>
      <Header margin={'3rem'}>Prescription monitoring program</Header>
      <StyledIframe data-testid={'pdmp-report-html'} src={`data:text/html,${encodeURIComponent(customHtml)}`} />
    </>
  );
};

export default PdmpTab;
