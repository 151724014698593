import {NotificationDisplayText, NotificationDto} from "../../../../api/dto/notification";
import {useRosterStore} from "../../../../store/roster_store";
import {useAuthStore} from "../../../../store/auth_store";
import DisplayHelpers from "../../../../helpers/display_helpers";
import {router} from "../../../../router";
import {SmallTypography, StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";
import {NotificationIcons} from "../NotificationIcon";
import {colors} from "patient-ping-remedy/packages/theme";
import Spacer from "patient-ping-remedy/packages/spacer";
import React from "react";
import {
  Wrapper,
  IconWithBackground,
  NotificationElement,
  TimePassedComponent, NotificationDescription, IconWrapper
} from "./NotificationPopoverItem.styles";
import NotificationViewedIcon from "./NotificationViewedIcon";
import ClientNameLinkComponent from "../../clients/ClientNameLinkComponent";
import {useNotifications} from "../../../../hooks/useNotifications";

type Props = {
  notification: NotificationDto,
}

function NotificationPopoverItem(props: Props) {
  let { notification } = props;
  const { currentRoster } = useRosterStore();
  const { currentUser } = useAuthStore();

  const { setNotificationViewed } = useNotifications({
    currentRosterId: currentRoster?.id || 0,
    onlyShowUnreadNotifications: currentUser?.only_show_unread_notifications || false,
    enabled: !!currentRoster,
  });

  const clientResponse = notification.clientResponse;
  const eventAndCategoryText = NotificationDisplayText[notification.eventType][notification.eventSubtype];
  const timePassed = <SmallTypography>
    {DisplayHelpers.timeSince(new Date(notification.eventDate), true)}
  </SmallTypography>;

  return (
    <Wrapper>
      <IconWrapper>
        <IconWithBackground>
          {NotificationIcons[notification.eventType]}
        </IconWithBackground>
      </IconWrapper>
      <NotificationElement>
        <StandardSemiBoldTypography color={colors.primary1}>
          {eventAndCategoryText} <Spacer itemWidth={'5px'}/>
        </StandardSemiBoldTypography>
        <Spacer itemHeight={'5px'}/>
        <NotificationDescription>
          <span>
            <ClientNameLinkComponent
              client={clientResponse}
              testId={`client-link-for-notification-${notification.id}`}
              handleClick={() => {
                router.navigate('/client_profile?p=' + clientResponse.patientId + '&r=' + currentRoster?.id);
                if (!notification.viewed) {
                  setNotificationViewed(notification.id, true);
                }
              }}
            />
          </span> had a {eventAndCategoryText.toLowerCase()} event at {notification.groupName}
          <Spacer itemWidth={'10px'}/>
        </NotificationDescription>
      </NotificationElement>
      <TimePassedComponent>
        {timePassed}
      </TimePassedComponent>
      <NotificationViewedIcon
        onClick={() => setNotificationViewed(notification.id, !notification.viewed)}
        viewed={notification.viewed}
      />
    </Wrapper>
  );
}

export default NotificationPopoverItem;
