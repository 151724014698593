import styled from '@emotion/styled';
import {LabelFormFieldTypography, SmallTypography, StandardTypography} from "patient-ping-remedy/packages/typography";
import {colors, sizes} from "patient-ping-remedy/packages/theme";

export const StyledRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 28rem;
  }
`;

export const StyledSmallTypography = styled(SmallTypography)`
  display: block;
  margin-bottom: ${sizes.small};
  & > span {
    color: ${colors.red5};
  }
`;

export const StyledLabelFormFieldTypography = styled(LabelFormFieldTypography)`
  display: block;
  margin-bottom: 5px;
`;

export const StyledStandardTypography = styled(StandardTypography)`
  font-size: 1.5rem;
`;
