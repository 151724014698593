import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import {SmallTypography} from "patient-ping-remedy/packages/typography";

const StyledGroupDetailHeader = styled('div')`
  display: flex;
  flex-direction: column;
  flex: "auto";
  margin-bottom: 24px;
  gap: 1rem;
`;

const RowDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

const GroupItem = styled('div')`
  flex: 1;
`;

const GroupLabel = styled(SmallTypography)`
  font-size: 14px
  font-color: ${colors.gray4};
  font-weight: 400;
  margin-bottom: 5px;
`;

const StyledGroupData = styled('p')`
  font-size: 16px;
  color: ${colors.charcoal};
  font-weight: 400;
  margin-top: 1rem;
  line-height: 20px;
  font-family: Libre Franklin;
`;

export { StyledGroupDetailHeader, GroupLabel, GroupItem, StyledGroupData, RowDiv };
