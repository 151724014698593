import React, {useContext, useEffect} from 'react';
import Button from "patient-ping-remedy/packages/button";
import {AppSettings} from "../../api/dto/dto";
import DeploymentFooter from "../DeploymentFooter";
import {CarecoApiContext} from "../../app-context/careco-api-context";
import Wrapper from '../common/pages/Wrapper';
import Header from '../common/pages/Header';
import Validate from "../../helpers/validate";
import {useForm, createFormFieldConfig} from "../../hooks/useForm";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {css} from "@emotion/css";
import ValidatedInput from "../common/ValidatedInput";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import {useMutation} from "@tanstack/react-query";
import {useAlertStore} from "../../store/alert_store";
import {GENERICERROR} from "./clients/manual_encounter/constants/BannerMessages";
import {AxiosError} from "axios";
import Helpers from "../../helpers/helpers";

const AppSettingsPage = () => {
  const {carecoApi} = useContext(CarecoApiContext);
  const { isValid, form, setInitialForm, handleChange, handleSubmit } = useForm(submit);
  const { addAlert } = useAlertStore();

  const updateAppSettingsMutation = useMutation({
    mutationFn: async (appSettings: AppSettings) => carecoApi?.postAppSettings(appSettings),
    onSuccess: () => {
      addAlert({content: 'App settings updated successfully.', type: ToastType.SUCCESS});
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to update app settings. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  function submit() {
    if (!carecoApi) return;

    const appSettings: AppSettings = {
      attestationExpirationSeconds: parseInt(form.attestationExpirationSeconds.value),
      clientProfileArtificialDelay: parseInt(form.clientProfileArtificialDelay.value),
      prefetchClientProfiles: form.prefetchClientProfiles.value,
      logClientProfileData: form.logClientProfileData.value,
      userExpirationSeconds: parseInt(form.userExpirationSeconds.value),
      passwordExpirationSeconds: parseInt(form.passwordExpirationSeconds.value),
    };

    updateAppSettingsMutation.mutate(appSettings);
  }

  useEffect(() => {
    if (!carecoApi) {
      return;
    }

    carecoApi.getAppSettings()
      .then(appSettings => {
        setInitialForm({
          ...createFormFieldConfig(
            'attestationExpirationSeconds',
            appSettings.attestationExpirationSeconds?.toString(),
            { valid: true, message: '' },
            [Validate.required, Validate.validateInteger],
            true
          ),
          ...createFormFieldConfig(
            'clientProfileArtificialDelay',
            appSettings.clientProfileArtificialDelay?.toString(),
            { valid: true, message: '' },
            [Validate.required, Validate.validateInteger],
            true
          ),
          ...createFormFieldConfig(
            'prefetchClientProfiles',
            appSettings.prefetchClientProfiles,
            { valid: true, message: '' },
            null,
            false
          ),
          ...createFormFieldConfig(
            'logClientProfileData',
            appSettings.logClientProfileData,
            { valid: true, message: '' },
            null,
            false
          ),
          ...createFormFieldConfig(
            'userExpirationSeconds',
            appSettings.userExpirationSeconds?.toString(),
            { valid: true, message: '' },
            [Validate.required, Validate.validateInteger],
            true
          ),
          ...createFormFieldConfig(
            'passwordExpirationSeconds',
            appSettings.passwordExpirationSeconds?.toString(),
            { valid: true, message: '' },
            [Validate.required, Validate.validateInteger],
            true
          ),
        });
      });
  }, [carecoApi, setInitialForm]);

  return (
    <Wrapper>
      <Header>
        App Settings
      </Header>

      <ValidatedInput
        id={'app-settings-attestation-exp'}
        labelText={'Attestation expiration (seconds)'}
        required={'required'}
        value={form.attestationExpirationSeconds?.value || ''}
        onChange={(e) => handleChange(e, 'attestationExpirationSeconds', e.target.value)}
        validate={() => form.attestationExpirationSeconds?.valid ? '' : 'Must be an integer'}
      />

      <ValidatedInput
        id={'app-settings-password-exp'}
        labelText={'Password expiration (seconds)'}
        required={'required'}
        value={form.passwordExpirationSeconds?.value || ''}
        onChange={(e) => handleChange(e, 'passwordExpirationSeconds', e.target.value)}
        validate={() => form.passwordExpirationSeconds?.valid ? '' : 'Must be an integer'}
      />

      <ValidatedInput
        id={'app-settings-art-delay'}
        labelText={'Client profile API call artificial delay (seconds) - for test purposes'}
        required={'required'}
        value={form.clientProfileArtificialDelay?.value || ''}
        onChange={(e) => handleChange(e, 'clientProfileArtificialDelay', e.target.value)}
        validate={() => form.clientProfileArtificialDelay?.valid ? '' : 'Must be an integer'}
      />

      <Checkbox
        id={'app-settings-prefetch-client-profiles'}
        value={form.prefetchClientProfiles?.value}
        onChange={(e) => handleChange(null, 'prefetchClientProfiles', e)}
        label={'Prefetch client profiles'}
      />

      <br/>
      <br/>

      <Checkbox
        id={'app-settings-log-client-profile-data'}
        value={form.logClientProfileData?.value}
        onChange={(e) => handleChange(null, 'logClientProfileData', e)}
        label={'Log client profile data to STDOUT'}
      />

      <br/>
      <br/>

      <ValidatedInput
        id={'app-settings-user-exp'}
        labelText={'User expiration (seconds) - for test purposes'}
        required={'required'}
        value={form.userExpirationSeconds?.value || ''}
        onChange={(e) => handleChange(e, 'userExpirationSeconds', e.target.value)}
        validate={() => form.userExpirationSeconds?.valid ? '' : 'Must be an integer'}
      />

      <br/>

      <Button
        className={css({marginTop: "20px"})}
        type="submit"
        onClick={handleSubmit}
        disabled={!isValid()}
      >Submit</Button>

      <DeploymentFooter />
    </Wrapper>
  );
};

export default AppSettingsPage;
