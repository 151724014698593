import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form/dist/index.ie11';

import DatePicker from 'patient-ping-remedy/packages/date-picker';
import DropdownList from '../../../../../pings/dropdown_list/DropdownList';
import Spacer from 'patient-ping-remedy/packages/spacer';
import {sizes} from 'patient-ping-remedy/packages/theme';
import {CarecoApiContext} from "../../../../../../app-context/careco-api-context";
import {StyledDisclaimerText, StyledLabelFormFieldTypography} from "./AdmitHookForm.styles";
import {StyledRow} from "../../encounter_client_search/PatientInformationForm.styles";
import FacilitySelect from "../../encounter_update_status/pings/forms/FacilitySelect";

const requiredField = 'Required field';
const validateInputLength = (value) => {
  const trimmedValueLength = value?.label?.trim('')?.length;
  return trimmedValueLength > 0 && trimmedValueLength < 151;
};

const AdmitHookForm = ({ patient, isSnf, isPcc, isEditing, groupId }) => {
  const [settingOptions, setSettingOptions] = useState([]);
  const [showUpdateDisclaimer, setShowUpdateDisclaimer] = useState(false);
  const { carecoApi } = useContext(CarecoApiContext);

  const { control, errors, formState, unregister } = useFormContext();
  const { dirtyFields } = formState;

  useEffect(function getSettingOptions() {
    carecoApi?.readSettings(groupId).then((options) => {
      setSettingOptions(options);
    });
    return () => {
      unregister('admittedFrom');
      unregister('date');
      unregister('setting');
    };
  }, []);

  useEffect(
    function scrollModalToDisclaimer() {
      if (showUpdateDisclaimer) {
        const disclaimer = document.getElementById('admitUpdateDisclaimer');
        disclaimer?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [showUpdateDisclaimer],
  );

  const checkDisclaimer = () => {
    setShowUpdateDisclaimer(dirtyFields?.setting);
  };

  const canShowDisclaimer = isSnf && isEditing && !!patient.currentPingEncounter;

  return (
    <Fragment>
      <StyledLabelFormFieldTypography className="requiredField">Admitted From</StyledLabelFormFieldTypography>
      <Controller
        name="admittedFrom"
        control={control}
        rules={{
          validate: validateInputLength,
        }}
        render={({ onChange, value, onBlur }) => (
          <FacilitySelect
            id="admittedFrom"
            testId="admittedFrom"
            onSelect={onChange}
            onBlur={onBlur}
            value={value.label}
            groupId={groupId}
            floatOnModal
            error={!!errors.admittedFrom}
            disabled={isPcc}
          />
        )}
      />
      <Spacer itemHeight={sizes.medium} />
      <StyledRow>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              maxDate={new Date()}
              value={value}
              externalError={errors.date}
              externalErrorText={requiredField}
              id="admitDate"
              labelText="Admitted Date"
              required="required"
            />
          )}
        />
        {isSnf && (
          <Controller
            name="setting"
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <DropdownList
                handleChange={(event) => {
                  onChange(event.value);
                  canShowDisclaimer && checkDisclaimer();
                }}
                onBlur={onBlur}
                value={settingOptions.find((option) => option.value === value)}
                id="setting"
                items={settingOptions}
                label="Admitted To - Setting"
                isWithinModal
                required
                error={errors.setting}
                errorMessage={requiredField}
              />
            )}
          />
        )}
      </StyledRow>
      {showUpdateDisclaimer && (
        <StyledDisclaimerText id="admitUpdateDisclaimer">
          Are you trying to transfer this client? Please use the Update Client Status flow instead by clicking on the
          pencil on the client card. Editing the client status here will not produce a Ping for anyone on this
          client's care team.
        </StyledDisclaimerText>
      )}
    </Fragment>
  );
};

export default AdmitHookForm;
