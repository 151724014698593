import {SmallTypography} from "patient-ping-remedy/packages/typography";
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import RadioButton from "patient-ping-remedy/packages/radio-button";
import styled from "@emotion/styled";

const StyledSmallTypography = styled(SmallTypography)`
  display: block;
  max-width: 60rem;
  margin-bottom: ${sizes.small};

  & > span {
    color: ${colors.red5};
  }  
`;

const StyledSameEncounter = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${sizes.small};
`;

const StyledSameRadioButton = styled(RadioButton)`
  margin-right: ${sizes.small};
`;

export {StyledSameEncounter, StyledSameRadioButton, StyledSmallTypography};
