import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {colors} from "patient-ping-remedy/packages/theme";
import {router} from "../../router";
import {Button, IconWrapper, NavItemWrapper, StyledIcon, StyledSemiBoldTypography} from "./NavButton.styles";
import {useLocation} from "react-router-dom";

type Props = {
  title?: string;
  iconClass: IconName;
  route: string;
}

const NavButton = (props: Props) => {
  const location = useLocation();
  const isActive = location.pathname === props.route;

  return (
    <Button
      onClick={() => router.navigate(props.route)}
    >
      <NavItemWrapper>
        <IconWrapper active={isActive}>
          <StyledIcon
            iconClass={props.iconClass}
            color={isActive ? colors.primary1 : colors.white}
            weight={'far'}
          />
        </IconWrapper>
        <StyledSemiBoldTypography>
          {props.title}
        </StyledSemiBoldTypography>
      </NavItemWrapper>
    </Button>
  );
};

export default React.memo(NavButton);
