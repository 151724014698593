import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import React, {Fragment, useEffect} from "react";
import {StyledOptionContainer} from "./ChooseAdmitClient.styles";
import RadioButton from "patient-ping-remedy/packages/radio-button";
import Helpers from "../../../../../helpers/helpers";
import {LabelFormFieldTypography} from "patient-ping-remedy/packages/typography";
import {EncounterClient} from "../../../../../api/dto/encounter";

interface AdmitClientChooseProps {
  matchedClients: EncounterClient[],
  handleChange: (event: (React.ChangeEvent | null), name: string, value: any) => void,
}

const ChooseAdmitClient = ({matchedClients, handleChange}: AdmitClientChooseProps) => {
  const firstClientId = matchedClients[0].patientId;
  const hasMatched = matchedClients.length > 1;

  useEffect(() => {
    handleChange(null, 'choosePatient', firstClientId);
  }, [firstClientId]);

  useEffect(() => {
    if(matchedClients.length === 1){
      const createNewButton = document.getElementById(`chosenPatient${firstClientId}`) as HTMLInputElement;
      createNewButton.checked = true;
    }
  }, [matchedClients]);

  function getLabel(client: EncounterClient): string {
    const firstName = Helpers.isDefined(client.firstName) ? client.firstName : '';
    const middleName = Helpers.isDefined(client.middleName) ? client.middleName : '';
    const lastName = Helpers.isDefined(client.lastName) ? client.lastName : '';
    const fullName = firstName + " " + middleName + " " + lastName;
    return `${fullName} (${client.gender}), DOB ${client.dateOfBirth}`;
  }

  return (
    <Fragment>
      {hasMatched &&
        <Banner type={BannerTypes.WARNING}>
          You have previously admitted a similar client. Select an existing client or a new client.
        </Banner>
      }
      {!hasMatched &&
        <LabelFormFieldTypography>No Existing Client Found</LabelFormFieldTypography>
      }
      {matchedClients.map((client: EncounterClient) => {
        const patientId = client.patientId;
        const label = patientId !== 'NEW' ? getLabel(client) : 'New Client';
        return (
          <StyledOptionContainer key={patientId}>
            <RadioButton
              name={'choosePatient'}
              defaultValue={patientId}
              value={patientId || ""}
              defaultChecked={patientId === firstClientId}
              id={`chosenPatient${patientId}`}
              label={label}
              onChange={(e) => handleChange(e, 'choosePatient', e.target.value)}
            />
          </StyledOptionContainer>
        );
      })}
    </Fragment>
  );
};

export default ChooseAdmitClient;
