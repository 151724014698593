import React from 'react';
import styled from '@emotion/styled';
import { colors, sizes } from 'patient-ping-remedy/packages/theme';

const StyledItem = styled('div')`
  background-color: ${({ highlighted }) => (highlighted ? colors.purple1 : colors.white)};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${colors.charcoal};
  padding: ${sizes.xSmall};
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
`;

const InputDropdownItem = ({ highlighted, label, onMouseEnter, onClick, onMouseDown }) => {
  return (
    <StyledItem
      className="input-dropdown-item"
      highlighted={highlighted}
      onMouseEnter={onMouseEnter}
      onMouseDown={onMouseDown}
      onClick={onClick}
    >
      {label}
    </StyledItem>
  );
};

export default InputDropdownItem;
