import React, {useContext, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {fontSize} from "patient-ping-remedy/packages/theme";
import {BackendVersions} from "../api/dto/dto";
import {CarecoApiContext} from "../app-context/careco-api-context";

const Wrapper = styled('div')`
  position: fixed;
  
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  
  font-size: ${fontSize.small};
`;

const Table = styled('table')`
  border-collapse: collapse;
  margin: 20px auto;

  tr th:first-child {
    border-left: none;
  }

  tr th:last-child {
    border-right: none;
  }

  tr td:first-child {
    border-left: none;
    font-weight: bold;
  }

  tr td:last-child {
    border-right: none;
  }

  tr:first-child th {
    border-top: none;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const HeaderCell = styled('th')`
  border: 1px solid black;
  border-top: none;
  padding: 2px 8px;
`;

const Cell = styled('td')`
  border: 1px solid black;
  padding: 2px 8px;
`;

const DeploymentFooter = () => {
  const [backendVersions, setBackendBackendVersions] = useState<BackendVersions>();

  const {carecoApi} = useContext(CarecoApiContext);

  useEffect(() => {
    if (!carecoApi) {
      return;
    }

    carecoApi.getBackendVersions()
      .then(backendVersion => {
        setBackendBackendVersions(backendVersion);
      }).catch(error => {
        console.log("Error fetching back end version: " + error);
      });
  }, [carecoApi]);

  return (
    <Wrapper>
      <Table>
        <tr>
          <HeaderCell/>
          <HeaderCell>
            Branch
          </HeaderCell>
          <HeaderCell>
            Commit
          </HeaderCell>
        </tr>
        <tr>
          <Cell>
            careco-ui
          </Cell>
          <Cell>
            {process.env.REACT_APP_GIT_BRANCH || 'local'}
          </Cell>
          <Cell>
            {process.env.REACT_APP_GIT_COMMIT || 'local'}
          </Cell>
        </tr>
        <tr>
          <Cell>
            careco-webapp
          </Cell>
          <Cell>
            { backendVersions?.carecoWebapp?.GIT_BRANCH || '???' }
          </Cell>
          <Cell>
            { backendVersions?.carecoWebapp?.GIT_COMMIT || '???' }
          </Cell>
        </tr>
        <tr>
          <Cell>
            careco-events
          </Cell>
          <Cell>
            { backendVersions?.carecoEvents?.GIT_BRANCH || '???' }
          </Cell>
          <Cell>
            { backendVersions?.carecoEvents?.GIT_COMMIT || '???' }
          </Cell>
        </tr>
      </Table>
    </Wrapper>
  );
};

export default DeploymentFooter;
