import Spinner from "patient-ping-remedy/packages/spinner";
import React from "react";
import {LoadingWrapper} from "./Loading.styles";
import {IconSize} from "patient-ping-remedy/packages/icon";

type Props = {
  iconSize?: IconSize;
}

const Loading = (props: Props) => {
  return (
    <LoadingWrapper>
      <div data-testid="loading-spinner">
        <Spinner iconSize={props.iconSize || '2x'}/>
      </div>
    </LoadingWrapper>
  );
};

export default Loading;
