import styled from '@emotion/styled';
import {css} from '@emotion/react';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledMessage = styled.div((props: { color: string }) => (
  css`
    font-size: 16px;
    margin: 0 0 0 10px;
    color: ${props.color};
    display: inline-block;
  `
));
