import Table from "../../../common/Table";
import React from "react";
import InfoIconTooltip from "../../../common/InfoIconTooltip";

type Props = {
    groups: {
        groupName : string;
        status : string;
    }[];
}

const groupInfo =
    (<p>A <b>group</b> consists of single facilities, specific programs, and/or
defined rosters of clients. Groups are managed and coordinated 
by the organization in which the group falls under.</p>);

const OrganizationGroups = (props: Props) => {
  const groupHeading = (
    <>
      <span>Group </span>
      <InfoIconTooltip
        html={groupInfo}
      />
    </>
  );

  const columnHeadings = [groupHeading, "Active"];

  return (
    <>
      <Table data={props.groups} header={columnHeadings}/>
    </>
  );
};

export default OrganizationGroups;
