import {TimelineVerticalWrapper} from "./Timeline.styles";
import {PropsWithChildren} from "react";

const Timeline = (props : PropsWithChildren) => {
  return (
    <TimelineVerticalWrapper>
      { props.children }
    </TimelineVerticalWrapper>
  );
};

export default Timeline;
