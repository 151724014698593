import React from 'react';

import Tooltip from 'patient-ping-remedy/packages/tool-tip';
import {StyledInsuranceName, truncate} from "./TruncatedText.styles";


export default class TruncatedText extends React.Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  state = {
    hasTruncatedText: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.calculateShouldTruncate);

    // Note: calling this causes double renders of this component but it's required because the element must be in the
    // dom before we can figure out if it has been truncated
    this.calculateShouldTruncate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateShouldTruncate);
  }

  calculateShouldTruncate = () => {
    const element = this.element.current;
    const { showFullText } = this.props;
    if (!showFullText) {
      // need to round to accommodate IE11 bugginess (scrollWidth is whole number but width is not)
      const boundingWidth = Math.ceil(element.getBoundingClientRect().width);
      const hasTruncatedText = boundingWidth < element.scrollWidth;

      this.setState({ hasTruncatedText }); // eslint-disable-line react/no-did-mount-set-state
    }
  };

  render() {
    const { showFullText, text } = this.props;
    const { hasTruncatedText } = this.state;

    const content = (
      <StyledInsuranceName ref={this.element} className={showFullText ? {} : truncate}>
        {text}
      </StyledInsuranceName>
    );

    if (!showFullText && hasTruncatedText) {
      return (
        <Tooltip text={text} position="top">
          {content}
        </Tooltip>
      );
    }
    return content;
  }
}
