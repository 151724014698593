import React, {ReactNode} from "react";
import {SmallTypography, StandardTypography} from "patient-ping-remedy/packages/typography";
import {StyledTable} from "./Table.styles";

type SimpleTableProps = {
    data: object[],
    header? : ReactNode[] | string[]
}

const Table : React.FC<SimpleTableProps> = ({ data, header }) => {
  const getHeaders = (header: string[] | ReactNode[]): React.ReactNode => {
    return (
      <tr>
        {header.map((item, index) => (
          <th key={index}>
            <SmallTypography>{item}</SmallTypography>
          </th>
        ))}
      </tr>
    );
  };

  const getBody = (data: object[]): React.ReactNode => {
    return (
      <React.Fragment>
        {data.map((trItem: { [index: string]: any }, index) => (
          <tr key={index}>
            {Object.keys(trItem).map((objectKey: string, index) => (
              <td key={index}>
                <StandardTypography>{trItem[objectKey]}</StandardTypography>
              </td>
            ))}
          </tr>
        ))}
      </React.Fragment>
    );
  };

  return (
    <StyledTable>
      {header ? <thead>{getHeaders(header)}</thead> : null}
      <tbody>{getBody(data)}</tbody>
    </StyledTable>
  );
};

export default Table;
