import React, {useContext, useEffect, useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button, { StyleType } from "patient-ping-remedy/packages/button";
import { useAuthStore } from "../../store/auth_store";
import CarecoApi from '../../api/careco-api';
import {Attestation} from '../../api/dto/dto';
import Modal from "patient-ping-remedy/packages/modal";
import {css} from "@emotion/css";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import {CarecoApiContext} from "../../app-context/careco-api-context";
import ErrorPage from "./error_pages/ErrorPage";
import {router} from "../../router";
import {useMutation} from "@tanstack/react-query";
import EncounterApi from "../../api/encounter-api";
import {getMixPanelEventProperties} from "../../helpers/mixpanel_helpers";
import {MixpanelEventType} from "../../api/dto/mixpanel";

type Props = {
  login?: boolean;
}

// After login, user is redirected here to check attestation status (with login prop)
// When logged in, user might be redirected here to check attestation status (without login prop)
const ConsentPage = (props: Props) => {
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const {
    attestationCheck, 
    setAttestationCheck, 
    phoneVerificationCheck, 
    setPhoneVerificationCheck,
    returnToRoute
  } = useAuthStore();
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [attestation, setAttestation] = useState<Attestation>();
  const [loadError, setLoadError] = useState(false);
  const { carecoApi, updateState } = useContext(CarecoApiContext);
  const postAttestationMutation = useMutation({
    mutationFn: async (document_version: string) => carecoApi?.postAttestation(document_version),
    onSuccess: () => {
      router.navigate(returnToRoute ? returnToRoute : '/clients');
    },
  });

  const checkLogin = async () => {
    let carecoApi = new CarecoApi(getAccessTokenSilently);
    let encounterApi = new EncounterApi(getAccessTokenSilently);
    updateState({ carecoApi: carecoApi, encounterApi: encounterApi });

    if(!attestationCheck || !phoneVerificationCheck) {
      (props.login
        ? carecoApi.login()
        : carecoApi.getLoginPrompts()
      )
        .then((response) => {
          if (!response.phone_verified) {
            logout({
              logoutParams: {
                returnTo: window.location.origin + '/failed-phone-verification',
              },
            });
          } else if (response && response.attestation) {
            setPhoneVerificationCheck(true);

            setAttestation(response.attestation);
            setShowConsentPopup(true);
          } else {
            setPhoneVerificationCheck(true);

            // no attestation needed, set sessionStorage
            setAttestationCheck(true);
            sessionStorage.setItem('access_check', btoa(JSON.stringify({
              phone_verification_check: true,
              attestation_check: true,
              timestamp: new Date().getTime()
            })));
            setAttestation(null!);
            setShowConsentPopup(false);

            const mixpanelEvent = getMixPanelEventProperties(MixpanelEventType.BIH_USER_LOGGED_IN, returnToRoute);
            carecoApi.postMixpanelEvent(mixpanelEvent);

            router.navigate(returnToRoute ? returnToRoute : '/clients');
          }
        })
        .catch((error) => {
          console.error(error);
          setLoadError(true);
        });
    }
  };

  useEffect(() => {
    if(isLoading) return;

    let params = new URLSearchParams(window.location.search);

    if(!isAuthenticated) {
      sessionStorage.removeItem('access_check');

      if(params.get('error_description') === "user is blocked") {
        router.navigate('/unauthorized');
        return;
      }

      loginWithRedirect();
      return;
    }

    checkLogin();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, attestationCheck]);

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sessionStorage.removeItem('access_check');
    logout({
      logoutParams: {
        returnTo: window.location.origin + '/complete-attestation',
      },
    });
  };

  const handleAgreeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // agreed to attestation, set sessionStorage
    setAttestationCheck(true);
    setPhoneVerificationCheck(true);
    sessionStorage.setItem('access_check', btoa(JSON.stringify({
      phone_verification_check: true,
      attestation_check: true,
      timestamp: new Date().getTime()
    })));
    setShowConsentPopup(false);
    if(attestation?.document_version) postAttestationMutation.mutate(attestation?.document_version);
  };

  if(loadError) {
    return <ErrorPage />;
  }

  return (
    <>
      <Modal
        id={"consent-modal"}
        headerText={"Consent"}
        isOpen={showConsentPopup}
        buttons={[
          <Button key={'cancelButton'} onClick={handleCancelClick} styleType={StyleType.TERTIARY}>Cancel</Button>,
          <Button key={'agreeButton'} onClick={handleAgreeClick}>I Agree</Button>
        ]}
        className={css({width: '30%'})}
      >
        <SmallTypography>
          {attestation?.text}
        </SmallTypography>
      </Modal>
    </>
  );
};

export default ConsentPage;
