import {colors, zIndices} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

export const FullOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  font-family: 'Libre Franklin', sans-serif;
  opacity: 0.7;
  z-index: ${zIndices.modal + 1};
`;

export const AlertWrapper = styled.div`
    opacity: 1;
    background-color: ${colors.purple1};
    border-top: solid 1px ${colors.purple2};
    border-bottom: solid 1px ${colors.purple2};
    width: 100%;
    padding: 25px;
    text-align: center;
    font-size: 1.8rem;
    top: 52px;
    margin: 0 auto 50px;
    position: fixed;
    font-family: 'Libre Franklin', sans-serif;
    z-index: ${zIndices.modal + 1};
    
    > div {
      margin: 0 0 1.5rem 0;
    }
`;

export const StyledDisplayLargeTypography = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 2.4rem;
  line-height: 4rem;
  margin-bottom: 2rem;
  color: ${colors.charcoal};
`;
