import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const RemoveRosterDiv = styled('div')`
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    color: ${colors.gray5};
`;

const RosterDiv = styled('div')`
    display: flex;
`;

const TextAlignLeft = styled('div')`
    text-align: left;
`;

const Spacer = styled('div')`
    margin-bottom: 1rem;
    height: 15px;
    border-top: 1px solid ${colors.gray2};
`;

export { RemoveRosterDiv, RosterDiv, TextAlignLeft, Spacer };
