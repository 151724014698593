import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';
import {SmallTypography} from "patient-ping-remedy/packages/typography";

export const CSScoreDiv =  styled.div`
  width: 100%;
  border: 1px solid ${colors.gray2};
  border-radius: 8px;
  padding: 2rem;
  background-color: ${colors.white};
`;

export const ORSTileHeading = styled.div`
    margin: 0 auto 15px auto;
    color: ${colors.gray4};
    font-size: 1.6rem;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 1050px) {
        width: 250px;
    }
`;

export const ORSMainDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    
    @media (max-width: 1050px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const ORSGraph = styled.div`
    height: 150px;
    margin-bottom: 2rem;
    width: 100%;
    overflow: hidden;
    font-size: 0;
    text-align: center;

    @media (min-width: 900px) and (max-width: 1050px) {
        width: 50%;
    }
    //  Note: The color codes here are sourced from narxcare-reports.
    //  Since these colors are not part of the Remedy design system, it's acceptable to use them here.
    //  Future designs may also not include these colors, as they are not designed by CareCo or Pings.
    .uors-g-background {
        fill: #DAD2F3;
    }

    .uors-g-background--below {
        fill: #EBEAF3;
    }

    .uors-g-background--above {
        fill: #AD9DD5;
    }

    .uors-g-foreground {
        fill: #A68FE2;
    }

    .uors-g-foreground--below {
        fill: #CCC5E0;
    }

    .uors-g-foreground--above {
        fill: #4F30A5;
    }

    // TODO will be hiding the risk category label until we get this returned from BLUE
    .uors-g-label {
        visibility: hidden;
        fill: ${colors.gray4};
        font-size: 1.6rem;
        text-transform: uppercase;
        text-anchor: middle;
    }
    
    .uors-g-score {
        font-weight: bold;
        fill: ${colors.charcoal};
        font-size: 4.4rem;
        text-anchor: middle;
    }
`;

export const ORSScores = styled.div`
    display: flex;
    color: ${colors.gray4};
    font-weight: bold;
    justify-content: space-around;
    text-align: center;
    width: 100%;

    @media (min-width: 900px) and (max-width: 1050px) {
        width: 50%;
    }
`;

export const ORSScore = styled.div`
`;

export const ORSLabel = styled.div`
  fill: ${colors.gray4};
  font-size: 16px;
  font-weight: 400;
  text-anchor: middle;
  dominant-baseline: central;
`;

export const ORSValue = styled.div`
  font-size: 24px;
  font-weight: 400;
  margin: 7px 0 20px 0;
  color: ${colors.charcoal};
`;

export const ORSDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledSmallTypography = styled(SmallTypography)`
    word-break: break-word;
`;

export const StyledTooltipHeader = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: ${colors.charcoal};
`;
