import {NotificationDto} from "../../../api/dto/notification";
import React from "react";
import {NotificationGroup, TimeCategoryHeader, TodayCategoryHeader} from "./NotificationList.styles";
import NotificationItem from "./NotificationItem";
import Spacer from "patient-ping-remedy/packages/spacer";
import NotificationHelpers from "../../../helpers/notification_helpers";
import {MarkAllAsReadLink, NoNotificationsWrapper} from "./popover/NotificationPopoverContent.styles";
import {StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";

type Props = {
  notifications: NotificationDto[],
  handleMarkAllAsRead: () => void
}

function NotificationList(props: Props) {
  const { notifications } = props;

  let categorizedNotifications = NotificationHelpers.getCategorizedNotifications(notifications);

  let todayNotifications = categorizedNotifications.todayNotifications;
  let yesterdayNotifications = categorizedNotifications.yesterdayNotifications;
  let olderNotifications = categorizedNotifications.olderNotifications;

  if (notifications?.length === 0) {
    return <NoNotificationsWrapper>
      <StandardSemiBoldTypography>All caught up! No unread notifications.</StandardSemiBoldTypography>;
    </NoNotificationsWrapper>;
  }

  return (
    <>
      <NotificationGroup>
        <TodayCategoryHeader>
          <TimeCategoryHeader>TODAY</TimeCategoryHeader>
          <MarkAllAsReadLink
            onClick={() => props.handleMarkAllAsRead()}
            data-testid="mark-all-as-read"
          >
            Mark all as read
          </MarkAllAsReadLink>
        </TodayCategoryHeader>

        {
          todayNotifications.map((notification: NotificationDto) => {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            );
          })
        }
      </NotificationGroup>

      <NotificationGroup>
        <TimeCategoryHeader>YESTERDAY</TimeCategoryHeader>
        <Spacer itemHeight={'30px'} />
        {
          yesterdayNotifications.map((notification: NotificationDto) => {
            return (
              <NotificationItem notification={notification} key={notification.id}/>
            );
          })
        }
      </NotificationGroup>

      <NotificationGroup>
        <TimeCategoryHeader>OLDER</TimeCategoryHeader>
        <Spacer itemHeight={'30px'} />
        {
          olderNotifications.map((notification: NotificationDto) => {
            return (
              <NotificationItem notification={notification} key={notification.id}/>
            );
          })
        }
      </NotificationGroup>
    </>
  );
}

export default NotificationList;
