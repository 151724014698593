import React, {useContext} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {ToastType} from 'patient-ping-remedy/packages/toast';
import {CarecoApiContext} from '../../../app-context/careco-api-context';
import {Client} from '../../../api/dto/dto';
import {ClientNameLink, EventDate, EventType} from "./ClientsTable.styles";
import {router} from "../../../router";
import {useRosterStore} from "../../../store/roster_store";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import {useAuthStore} from "../../../store/auth_store";
import {useAlertStore} from '../../../store/alert_store';
import Helpers from "../../../helpers/helpers";
import DisplayHelpers from "../../../helpers/display_helpers";
import SortableTable, {TableColumn, TableData} from "../../common/SortableTable";
import AssignedToClient from "./AssignedToClient";
import {AssignUserParams} from './AssignedToClientList';
import {FilterQuery, nonGroupedValues} from '../../common/FilterComponent';
import {GENERICERROR} from "./manual_encounter/constants/BannerMessages";
import {AxiosError} from "axios";
import display_helpers from "../../../helpers/display_helpers";

type Props = {
  clients: (Client | undefined)[];
  searchTotal: number,
  loading: boolean,
  loadError: Error | null,
  searchTerm: string,
  searchFunction: Function,
  searchPlaceholder?: string,
  query?: FilterQuery,
}

const ClientsTable = (props: Props) => {
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentRoster } = useRosterStore();
  const { currentUser } = useAuthStore();
  const { addAlert } = useAlertStore();
  const queryClient = useQueryClient();

  const [resetTableFilters, setResetTableFilters] = React.useState<boolean>(false);

  const { data: assignedToFilterOptions } = useQuery({
    queryKey: ['assignedToFilterOptions', currentRoster?.id],
    queryFn: () => carecoApi?.getUsersAssignedToClients(currentRoster!.id).then(users =>
      users.map((user): nonGroupedValues => ({
        id: user.id,
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      })),
    ),
  });

  const { mutateAsync: assignUser } = useMutation({
    mutationFn: async (params: AssignUserParams) => {
      return params.delete
        ? carecoApi?.deleteClientUserAssignment(params.client.patientId, params.user.id, currentRoster!.id)
        : carecoApi?.postClientUserAssignment(params.client.patientId, params.user.id, currentRoster!.id);
    },
    onSuccess: (_, params) => {
      params.client.userAssignments = params.delete
        ? params.client.userAssignments.filter((userAssignment) => userAssignment.user.id !== params.user.id)
        : [...params.client.userAssignments, { user: params.user }];
      queryClient.invalidateQueries({ queryKey: ['assignedToFilterOptions', currentRoster?.id] })
        .then(r => console.log(r));
    },
    onError: (error: AxiosError, params) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: params.delete ? `Failed to unassign user from client. ${GENERICERROR} ${Helpers.traceId(traceId)}` :
          `Failed to assign user to client. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR,
      });
      console.error(error);
    },
  });

  const tableData: TableData[] = props.clients.map((client): TableData => {
    let clientName = client ? DisplayHelpers.formatName(client.firstName, client.middleName, client.lastName) : "";

    return {
      name: {
        tableHeader: 'Name',
        key: 'name',
        type: 'string',
        value: clientName,
        displayValue: (
          PermissionsHelpers.canForUserFeatureRoleRoster(currentUser!, currentRoster!,
            Helpers.getAppFeatureRoleDetails().levels.VIEW,
            Helpers.getAppFeatureRoleDetails().permissions.CLIENT_PROFILE)
            ? <ClientNameLink
              onClick={() => router.navigate('/client_profile?p=' + client?.patientId + '&r=' + currentRoster?.id)}>
              { clientName }
            </ClientNameLink>
            : clientName
        )
      },
      dob: {
        tableHeader: 'Date of Birth',
        key: 'dob',
        type: 'string',
        value: client?.dateOfBirth ?? '',
        displayValue: client && client.dateOfBirth ? DisplayHelpers.formatDateOfBirthWithAge(client.dateOfBirth) : ""
      },
      lastEvent: {
        tableHeader: 'Last Event',
        key: 'lastEvent',
        type: 'string',
        value: '',
        displayValue: (
          client?.lastEventType &&
          <>
            <EventType>{display_helpers.readableEncounterType(client.lastEventType) + ' event'}</EventType>
            <br />
            <EventDate>{
              DisplayHelpers.formatDate(client.lastEventDate)
            }</EventDate>
          </>
        )
      },
      assignedTo: {
        tableHeader: 'Assigned to',
        key: 'assignedTo',
        type: 'custom',
        value: '',
        displayValue: client ? <AssignedToClient client={client} assignUser={assignUser} /> : ""
      }
    };
  });

  return (
    <>
      <SortableTable
        id={'clients-table'}
        data={tableData}
        loading={props.loading}
        loadError={props.loadError}
        header={Object.values(tableData[0] ?? [])?.map((value: TableColumn) => value.tableHeader)}
        searchPlaceholder={props.searchPlaceholder ?? 'Search by client name'}
        resetTableFilters={resetTableFilters}
        setResetTableFilters={setResetTableFilters}
        searchColumns={[]}
        sortableColumns={[]}
        filterOptions={props.query && [{
          type: 'multiselect-search',
          label: 'Assignee',
          dataType: 'ajax',
          options: assignedToFilterOptions ?? [],
          key: 'assignedTo',
          query: props.query,
        }]}
        tableOverrides={{
          searchFunction: props.searchFunction,
          searchTerm: props.searchTerm
        }}
      />
    </>
  );
};

export default ClientsTable;
