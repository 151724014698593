import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Icon from "patient-ping-remedy/packages/icon";
import SortableTable, {TableColumn, TableData} from "../../common/SortableTable";
import {User} from "../../../api/dto/dto";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {colors} from "patient-ping-remedy/packages/theme";
import DisplayHelpers from "../../../helpers/display_helpers";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import UserDetailsDrawer from "./user_details/UserDetailsDrawer";
import {AxiosResponse} from "axios";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import RosterHelpers from "../../../helpers/roster_helpers";
import Helpers from "../../../helpers/helpers";
import {AddNewUserButton, AddNewUserButtonWrapper} from "./UsersTab.styles";
import CreateUserModal from "./CreateUserModal";
import {useAlertStore} from "../../../store/alert_store";
import {useAuthStore} from "../../../store/auth_store";

const UsersTab = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableHeaders, setTableHeaders] = useState<ReactNode[]>([]);
  const [sortableColumns, setSortableColumns] = useState<TableColumn[]>([]);
  const [quickFilterOptions, setQuickFilterOptions] = useState<any[]>([]);
  const [resetTableFilters, setResetTableFilters] = useState<boolean>(false);
  const [slidePaneUserId, setSlidePaneUserId] = useState<string | undefined>(undefined);
  const [showCreateUserModal, setShowCreateUserModal] = React.useState(false);
  const { addAlert } = useAlertStore();
  const { currentUser } = useAuthStore();
  const { carecoApi } = useContext(CarecoApiContext);

  const { data: users, isLoading, error } = useQuery({
    queryKey: ['users'],
    queryFn: () => carecoApi?.getUsers(),
    enabled: !!carecoApi
  });

  const { data: rosters } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const {
    data: slidePaneUserData,
    isLoading: isLoadingUserById,
    error: getUserByIdError,
    refetch: refetchSidePanelUserData
  } = useQuery({
    queryKey: ['slidePaneUserData', slidePaneUserId],
    queryFn: () => {
      return carecoApi?.getUser(slidePaneUserId!);
    },
    enabled: !!slidePaneUserId && !!carecoApi
  });

  const dropdownOptions = useMemo(() => RosterHelpers.getUserFiltersDropdownOptions(rosters), [rosters]);

  const mapTableUserData = useCallback((user: User) : TableData => {
    let rosterNames: string[]  = [];
    let featureRoles: string[] = [];
    let dataRoles: string[] = [];

    if(PermissionsHelpers.isBamboo(user)) {
      featureRoles.push('Bamboo Admin');
      dataRoles.push('Bamboo Admin');
    }

    if(PermissionsHelpers.isSuperAdmin(user)) {
      featureRoles.push('Super Admin');
      dataRoles.push('Super Admin');
    }

    user.rosters.forEach(userRoster => {
      if(!PermissionsHelpers.isSuperAdmin(user) && !PermissionsHelpers.isBamboo(user)) {
        if (userRoster.feature_role?.name && !featureRoles.includes(userRoster.feature_role?.name)) {
          featureRoles.push(userRoster.feature_role?.name);
        }

        if (userRoster.data_role?.name && !dataRoles.includes(userRoster.data_role?.name)) {
          dataRoles.push(userRoster.data_role?.name);
        }
      }

      rosterNames.push(userRoster.roster?.name);
    });

    return {
      name: {
        tableHeader: 'Name',
        key: 'name',
        type: 'string',
        value: `${user.first_name} ${user.last_name}`,
        displayValue: (
          <Button
            onClick={() => {
              setSlidePaneUserId(user.id);
            }}
            styleType={StyleType.TERTIARY}>
            { `${user.first_name ? `${user.first_name} ${user.last_name}` : 'User needs to set name.'}` }
          </Button>
        )
      },
      email: {
        tableHeader: 'Email',
        key: 'email',
        type: 'string',
        value: user.email,
        displayValue: user.email
      },
      dataRoles: {
        tableHeader: 'Data role',
        key: 'dataRoles',
        type: 'array',
        value: dataRoles,
        displayValue: dataRoles.length > 1
          ? `${dataRoles[0]}, +${dataRoles.length - 1}`
          : dataRoles[0]
      },
      featureRoles: {
        tableHeader: 'Feature role',
        key: 'featureRoles',
        type: 'array',
        value: featureRoles,
        displayValue: featureRoles.length > 1
          ? `${featureRoles[0]}, +${featureRoles.length - 1}`
          : featureRoles[0]
      },
      groups: {
        tableHeader: 'Groups',
        key: 'groups',
        type: 'array',
        value: rosterNames,
        displayValue: rosterNames.length > 1
          ? `${rosterNames[0]}, +${rosterNames.length - 1}`
          : rosterNames[0]
      },
      organizations: {
        tableHeader: 'Organizations',
        key: 'organizations',
        type: 'array',
        value: user.organizations,
        displayValue: user.organizations.length > 1
          ? `${user.organizations[0]}, +${user.organizations.length - 1}`
          : user.organizations[0]
      },
      status: {
        tableHeader: 'Status',
        key: 'status',
        type: 'string',
        value: user.enabled ? 'Enabled' : 'Disabled',
        displayValue: user.enabled ? 'Enabled' : 'Disabled'
      },
      lastLogin: {
        tableHeader: 'Last login',
        key: 'lastLogin',
        type: 'date',
        value: user.last_login,
        displayValue: DisplayHelpers.formatDate(user.last_login)
      },
      actions: {
        tableHeader: '',
        key: 'actions',
        type: 'custom',
        value: '',
        displayValue: (
          <Button
            onClick={handleClick}
            style={{ background: 'none', padding: '0', margin: '0'}}>
            <Icon iconClass={'ellipsis-h'} color={colors.gray4} />
          </Button>
        )
      }
    };
  }, []);

  useEffect(() => {
    if(users) {
      let tableData = users.map((user: User) => {
        return mapTableUserData(user);
      });
      setTableData(tableData);
      setTableHeaders(Object.values(tableData[0] ?? [])?.map((value: TableColumn) => value.tableHeader));
      setSortableColumns(tableData[0] ?
        [
          tableData[0]['name'],
          tableData[0]['email'],
          tableData[0]['lastLogin'],
        ]
        : []);
      setQuickFilterOptions([{ key: 'status', displayText: 'Enabled', value: dropdownOptions.statusOptions[0] }]);

    }
  }, [users, mapTableUserData]);

  function handleClick() {
    console.log('clicked');
  }

  const createUserButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowCreateUserModal(true);
  };

  const hideCreateUserModal = () => {
    setShowCreateUserModal(false);
  };

  const afterSave = async (response: AxiosResponse) => {
    await refetchSidePanelUserData();
    setSlidePaneUserId(response.data.id);
  };

  const handleDownload = () => {
    const csvContent = [
      ['Name', 'Email', 'Data role', 'Feature role', 'Groups', 'Organization', 'Status', 'Last Login'],
      ...(users!).map(user => {
        const tableData = mapTableUserData(user);
        const organizations = user.organizations.join('; ');
        const groups = user.rosters.map(userRoster => userRoster.roster.name).join('; ')
        return [
          tableData.name.value,
          tableData.email.value,
          (tableData.dataRoles.value as []).join('; '),
          (tableData.featureRoles.value as []).join('; '),
          groups,
          organizations,
          tableData.status.value,
          tableData.lastLogin.value || ''
        ];
      })
    ]
      .map(row => row.map(cell => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-16;' });

    Helpers.downloadBlob(blob, "users.csv");
  };



  return (
    <>
      <AddNewUserButtonWrapper>
        <Button disabled={isLoading} onClick={handleDownload}>
          <Icon iconClass={'download'}  color={colors.white} style={{ marginRight: '10px' }} />
          Download User List
        </Button>
        {
          PermissionsHelpers.canForUserFeatureRole(currentUser!,
            Helpers.getAppFeatureRoleDetails().levels.MANAGE,
            Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS) &&
          <AddNewUserButton>
            <Button onClick={createUserButtonHandler}>
              <Icon iconClass={'plus'} color={colors.white} style={{ marginRight: '10px' }} />
              Add New User
            </Button>
          </AddNewUserButton>
        }
      </AddNewUserButtonWrapper>

      { showCreateUserModal
        && <CreateUserModal
          isOpen={showCreateUserModal}
          hideCreateUserModal={hideCreateUserModal} alertFn={addAlert} />
      }

      <SortableTable
        id={'users-table'}
        data={tableData}
        loading={isLoading}
        loadError={error}
        quickFilterOptions={quickFilterOptions}
        header={tableHeaders}
        searchPlaceholder={'Search by name or email'}
        searchColumns={['name', 'email']}
        resetTableFilters={resetTableFilters}
        setResetTableFilters={setResetTableFilters}
        sortableColumns={sortableColumns}
        filterOptions={[
          {
            type: 'multiselect-search',
            label: 'Status',
            dataType: 'string',
            options: dropdownOptions.statusOptions,
            key: 'status',
            hasQuickFilter: true
          },
          {
            type: 'multiselect-search',
            label: 'Feature roles',
            dataType: 'array',
            options: dropdownOptions.featureRoleOptions,
            key: 'featureRoles'
          },
          {
            type: 'multiselect-search',
            label: 'Data roles',
            dataType: 'array',
            options: dropdownOptions.dataRoleOptions,
            key: 'dataRoles'
          },
          {
            type: 'multiselect-search',
            label: 'Organizations',
            dataType: 'array',
            options: dropdownOptions.organizationOptions,
            key: 'organizations'
          },
          {
            type: 'multiselect-search',
            label: 'Groups',
            dataType: 'array',
            options: dropdownOptions.rosterOptions,
            key: 'groups'
          },
        ]}
        tableOverrides={{
          initialFilters: {
            status: [dropdownOptions.statusOptions[0]]
          }
        }
        }
      />

      {
        rosters &&
        <UserDetailsDrawer
          user={slidePaneUserData!}
          afterSave={afterSave}
          rosters={rosters}
          close={() => setSlidePaneUserId(undefined)}
          isOpen={!!slidePaneUserId}
          isLoading={isLoadingUserById}
          loadError={getUserByIdError}
        />
      }
    </>
  );
};

export default React.memo(UsersTab);
