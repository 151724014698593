import {sizes} from "patient-ping-remedy/packages/theme";
import {mq} from "../utils/stringUtils";
import styled from "@emotion/styled";

const PatientCardField = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: nowrap;
  line-height: ${sizes.small};
  & > span:first-of-type {
    margin-right: ${sizes.xxSmall};
  }
  ${mq[0]} {
    & > span:first-of-type {
      display: inline-block;
      width: ${sizes.xxLarge};
      flex-shrink: 0;
    }
  }
`;

const DobAndPayorFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq[0]} {
    flex-direction: column;
  }
`;

const StyledInsuranceName = styled.span`
  max-width: 30rem;
`;

export {PatientCardField, DobAndPayorFlexRow, StyledInsuranceName};
