import React, { PropsWithChildren} from "react";
import GlobalNavBar from "./header/GlobalNavBar";
import NavRail from "./navigation/NavRail";
import {Wrapper, Header, LeftAside, Main} from "./Layout.styles";
import {useAlertStore} from "../store/alert_store";
import ToastNotification from "patient-ping-remedy/packages/toast";
import {useLocation} from "react-router-dom";

export default function Layout(props : PropsWithChildren) {
  const { alerts, setAlerts } = useAlertStore();
  const location = useLocation();

  return (
    <Wrapper>
      <Header>
        <GlobalNavBar showFacilityPicker={location.pathname !== '/admin'}/>
      </Header>
      <LeftAside>
        <NavRail />
      </LeftAside>
      <Main>
        {props.children}
      </Main>
      <ToastNotification
        toasts={[...alerts]}
        remove={(index) => setAlerts(alerts.filter((_, i) => i !== index))}
      />
    </Wrapper>
  );
}
