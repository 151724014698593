import React, {ComponentType, useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useAuthStore} from "../store/auth_store";
import Layout from "./Layout";
import Helpers from "../helpers/helpers";
import {CarecoApiContext} from "../app-context/careco-api-context";
import CarecoApi from "../api/careco-api";
import PermissionsHelpers from "../helpers/permissions_helpers";
import {router} from "../router";
import {User} from "../api/dto/dto";
import {useLocation} from "react-router-dom";
import useHandleLogout from "../hooks/useHandleLogout";
import EncounterApi from "../api/encounter-api";
import ErrorPage from "./pages/error_pages/ErrorPage";

type FeaturePermissionsCheck = {
  level: string;
  feature: string;
}

type DataPermissionsCheck = {
  level: string;
  dataSource: string;
}

type Props = {
  Component: ComponentType;
  featurePermissionsCheck?: FeaturePermissionsCheck;
  dataPermissionsCheck?: DataPermissionsCheck;
  role?: string;
}

export default function AuthGuard(props: Props) {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [dataHydrated, setDataHydrated] = useState(false);
  const authStore = useAuthStore();
  const {carecoApi, updateState} = useContext(CarecoApiContext);
  const location = useLocation();
  const handleLogout = useHandleLogout();
  const [isError, setIsError] = useState(false);

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (error) {
      console.error("Error getting access token: ", error);
      handleLogout();
    }
  };

  const hydrateData = () => {
    getAccessToken().then((accessToken) => {
      if (accessToken && !carecoApi) {
        updateState({ carecoApi: new CarecoApi(getAccessTokenSilently), encounterApi: new EncounterApi(getAccessTokenSilently) });
      }
    });
  
    if (!authStore.attestationCheck || !authStore.phoneVerificationCheck) {
      let encoded = sessionStorage.getItem('access_check');
      let decoded = encoded ? JSON.parse(atob(encoded)) : null;

      if(!Helpers.isDefined(decoded) || !decoded.attestation_check || !decoded.phone_verification_check) {
        authStore.setReturnToRoute(location.pathname + location.search + location.hash);
        router.navigate('/attestation');
        return;
      }

      if (decoded && decoded.attestation_check && decoded.phone_verification_check) {
        authStore.setAttestationCheck(decoded.attestation_check);
        authStore.setPhoneVerificationCheck(decoded.phone_verification_check);
      }
    }

    if(!authStore.currentUser) {
      if(user?.sub) {
        carecoApi?.getUser(user.sub)
          .then((response: User) => {
            authStore.setCurrentUser(response);
          }).catch((error) => {
            console.error(error);
            setIsError(true);
          });
      }
    }

    setDataHydrated(true);
  };

  useEffect(() => {
    if (isLoading) return;

    let params = new URL(window.location.toString()).searchParams;

    if(!isAuthenticated) {
      sessionStorage.removeItem('access_check');

      if(params.get('error_description') === "user is blocked") {
        router.navigate('/unauthorized');
        return;
      }

      loginWithRedirect({
        appState: {
          returnTo: location.pathname + location.search + location.hash,
        },
      });
      return;
    }

    hydrateData();
  }, [carecoApi, user, isLoading, isAuthenticated, getAccessTokenSilently, location.pathname]);

  const roleAuthorized = () => {
    // there is no role check or permissions check needed
    if(
      !Helpers.isDefined(props.role) &&
      !Helpers.isDefined(props.featurePermissionsCheck) &&
      !Helpers.isDefined(props.dataPermissionsCheck)
    ) {
      return true;
    }

    if(props.role) {
      return PermissionsHelpers.checkForRole(authStore.currentUser!, props.role);
    }

    if(props.featurePermissionsCheck) {
      return PermissionsHelpers.canForUserFeatureRole(
        authStore.currentUser!,
        props.featurePermissionsCheck.level,
        props.featurePermissionsCheck.feature
      );
    }

    if(props.dataPermissionsCheck) {
      return PermissionsHelpers.canForUserDataRole(
        authStore.currentUser!,
        props.dataPermissionsCheck.level,
        props.dataPermissionsCheck.dataSource
      );
    }

    // default to false
    return false;
  };
  if(isError) {
    return <ErrorPage/>;
  }

  if (isLoading || !dataHydrated || !authStore.currentUser) {
    return null;
  }

  if (!roleAuthorized()) {
    return <Layout><h2>Unauthorized.</h2></Layout>;
  }

  return <Layout><props.Component /></Layout>;
}
