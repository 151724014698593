import {SmallTypography} from "patient-ping-remedy/packages/typography";
import {colors} from "patient-ping-remedy/packages/theme";
import styled from '@emotion/styled';

const iconStyle = {
  height: '12px',
  width: '12px'
};

const StyledOrganizationDetailHeader = styled('div')`
  display: flex;
  flex-direction: column;
  flex: auto;
  margin-bottom: 24px;
  gap: 1rem;
`;

const OrganizationDetailRow = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

const OrganizationItem = styled('div')`
  flex: 1;
`;

const OrganizationLabel = styled(SmallTypography)`
  font-size: 14px;
  color: ${colors.gray4};
  font-weight: 400;
  margin-bottom: 5px;
`;

const StyledOrganizationData = styled('p')`
  font-size: 16px;
  color: ${colors.charcoal};
  font-weight: 400;
  margin-top: 1rem;
  line-height: 20px;
  font-family: Libre Franklin;
`;

export { iconStyle,StyledOrganizationData, OrganizationLabel, OrganizationItem, StyledOrganizationDetailHeader,
  OrganizationDetailRow };
