import styled from '@emotion/styled';
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import {css} from "@emotion/react";

///////////////////////////////
///////// main wrapper/////////
//////////////////////////////
export const TimelineVerticalWrapper = styled.div`
  margin: 30px auto 30px 150px;

  @media (max-width: 100px) {
    margin: 30px 20px 20px 20px;
  }
`;

export const TimelineEventWrapper = styled.div`
    position: relative;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    width: 100%;
    margin: 20px 0 20px 0;
    
    &:before {
        content: '';
        position: absolute;
        width: 2px;
        left: -20px;
        margin: 0;
        padding: 0;
        top: 0;
        height: calc(100% + 20px);
        background: ${colors.gray3};

        @media (max-width: 640px) {
            content: none;
        }
    }
`;


///////////////////////////////
///////// date section ////////
//////////////////////////////
export const TimelineDate = styled.div`
    .timeline__event__date-year {
        padding: 0;
        color: ${colors.gray4};
        font-size: 20px;
        margin: 0;
        display: block;

        @media (max-width: 640px) {
            display: inline-block;
            color: ${colors.gray4};
            font-size: 20px;
            margin: 0 10px 0 0;
        }
    }

    .timeline__event__date-date {
        padding: 0;
        margin: 0;
        color: ${colors.charcoal};
        font-size: 24px;
        display: block;

        @media (max-width: 640px) {
            display: inline-block;
            color: ${colors.gray5};
            font-size: 20px;
        }
    }

    @media (min-width: 640px) {
        position: absolute;
        left: -125px;
        top: 20px;
        text-align: right;
        margin: 0;
    }

    @media (max-width: 640px) {
        position: absolute;
        top: -30px;
        left: 0;
        border: none;
        text-align: right;
    }
}`;


///////////////////////////////
///////// event wrapper////////
//////////////////////////////
export const TimelineContentWrapper = styled.div`
     width: 100%;
     min-height: 150px;
     border: 1px solid ${colors.gray2};
     border-radius: 10px;
     box-shadow: 0 2px 4px rgba(0,0,0,0.1);
     position: relative;

     @media (max-width: 640px) {
       margin: 0 0 40px 0;
     }
`;

export const EventContainer = styled.div((props: {active: boolean}) => (
  css`
  display: flex;
  flex-direction: row;
  background: ${props.active ? colors.white : colors.gray1};
  padding: 20px;
  border-radius: 10px;
      
  &:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 12px;
    width: 6px;
    height: calc(100% - 30px);
    padding: 0;
    background: ${props.active ? colors.primary1 : colors.gray2};
    border-radius: 8px;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`));


///////////////////////////////
///////// event details ///////
//////////////////////////////
export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 240px;
`;

export const EventDetailsSection = styled.div`
`;

export const EventDetailsSectionHeader = styled.div`
    margin-top: 20px;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.gray4};
    
    &.lower {
        border-top: 1px solid ${colors.gray3};
        padding-top: 2rem;
    }
`;

export const EventDetailsSectionDetails = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${colors.charcoal};
`;

export const ExpandEventDetailsSectionDetails = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${colors.charcoal};
    margin-left: 1.5rem;
`;

export const EventType = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin: 5px 0 0 0;
    color: ${colors.primary1};
`;

export const EventIcon = styled.div((props: {active: boolean}) => (
  css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 15px;
  width: 40px;
  height: 40px;
  color: ${colors.white};
  background: ${props.active ? colors.primary1 : colors.gray4};
  border-radius: 50%;
  min-width: 40px;

  & > svg {
    font-size: 20px;
  }

  @media (max-width: 640px) {
    margin: 15px 0 0 15px;
  }
`));

export const EventCurrentStatus = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    margin: 15px 15px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .icon {
    }
    
`;

///////////////////////////////
////// events of the event ////
//////////////////////////////
export const EventContainerEvents = styled.div((props: {active: boolean}) => (
  css`
  display: flex;
  flex-direction: column;
  margin: 40px 20px 20px 60px;
  width: calc(90% - 240px);
`));

export const EventContainerEventsWrapper = styled.div`
    padding: 10px;
    border-radius: 15px;
    display: flex;
`;

export const EventContainerEventsSession = styled.ul`
      border-radius: 12px;
      position: relative;
      list-style: none;
      padding: 0;

      li:last-of-type {
          border-left: 2px solid transparent;
          padding-bottom: 0;
      }
`;

export const EventContainerEventsList = styled.li((props: { active: boolean}) => (
  css`
    padding-bottom: 20px;
    position: relative;
    padding-left: 20px;
    border-left: 2px solid ${colors.gray3};
    margin-left: -10px;
    list-style: none;
    font-size: 16px;

    &:before {
        content: '';
        width: 9px;
        height: 9px;
        background: ${props.active ? colors.green4 : colors.gray4};
        border-radius: 50%;
        position: absolute;
        left: -5px;
    }
  `
));

export const EventContainerEventsTime = styled.div`
    color: ${colors.gray4};
    font-weight: normal;
    font-size: 16px;
    position: relative;
    top: -5px;

    .pipe-separator {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid ${colors.gray4};
    }
`;

export const EventContainerEventsStatus = styled.div`
    font-weight: normal;
    color: ${colors.charcoal};
    
    .event-text-bold {
        font-weight: bold;
    }
    
    .pipe-separator {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid ${colors.gray4};
    }
`;

///////////////////////////////
///// diagnoses & events //////
//////////////////////////////

export const EventLowerSection1 = styled.div((props: { inline?: boolean }) => ({
  margin: '10px 0 10px 20px',
  paddingTop: '20px',
  borderTop: `1px solid ${colors.gray3}`,
  ...(props.inline ? {
    display: 'block',
  } : {
    display: 'flex',
    flexDirection: 'row',
  }),
}));

export const EventSectionHeader = styled.div((props: { inline?: boolean }) => ({
  marginRight: '10px',
  width: 'max-content',
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.charcoal,
  ...(props.inline && {
    display: 'inline',
  }),
}));

export const EventLowerSection1Content = styled.div((props: { inline?: boolean }) => ({
  fontSize: '16px',
  wordBreak: 'break-word',
  color: colors.charcoal,
  ...(props.inline && {
    display: 'inline',
  }),
}));

export const EventLowerSection2 = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0 10px 20px;
    padding-top: 20px;
    border-top: 1px solid ${colors.gray3};
`;

export const EventSectionLink = styled.span`
    margin-left: 1.5rem;
    color: ${colors.blue4};
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
  
    &:hover{
        color: ${colors.tertiary2};
    }
`;

export const EventSectionRow = styled.div`
    display: flex;  
    margin-bottom: ${sizes.xxSmall};
`;
