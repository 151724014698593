import React from 'react';

import DobAndPayerRow from './DobAndPayerRow';
import PatientCardHeader from "./PatientCardHeader";
import EncounterInfoAndPrograms from "./EncounterInfoAndPrograms";
import {StyledPatientCard} from "./PatientCard.styles";

const PatientCard = ({ patient}) => {

  return (
    <StyledPatientCard className="patient-card">
      <PatientCardHeader patient={patient}/>
      <DobAndPayerRow patient={patient} />
      <EncounterInfoAndPrograms patient={patient} />
    </StyledPatientCard>
  );
};

export default PatientCard;
