import {TimelineOptions} from "vis-timeline/types";

const BASE_OPTIONS : TimelineOptions = {
  clickToUse: false,
  showTooltips: true,
  align: 'center',
  margin: {
    item: {
      horizontal: 40,
      vertical: 5
    }
  },
  showCurrentTime: false,
  horizontalScroll: true,
  verticalScroll: false,
  zoomable: false,
  rtl: false, // change to false/true for ascending
  showMajorLabels: false,
  showMinorLabels: true,
  tooltip: {
    followMouse: false,
    overflowMethod: 'none',
    delay: 100,
  },
};

export const BASE_OPTIONS_WEEK : TimelineOptions = {
  ...BASE_OPTIONS,
  timeAxis: {scale: 'week', step: 1},
  format: {
    minorLabels: function (date: any, scale: any, step: any) {
      let startDate = new Date(date);
      let endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 6);

      return `${startDate.toLocaleDateString('en', {
        month: 'numeric',
        day: 'numeric'
      })} - ${endDate.toLocaleDateString('en', {
        month: 'numeric',
        day: 'numeric'
      })}`;
    },
  },
};

export const BASE_OPTIONS_MONTH : TimelineOptions = {
  ...BASE_OPTIONS,
  timeAxis: {scale: 'month', step: 1},
  format: {
    minorLabels: function (date: any, scale: any, step: any) {
      return date.format('MM/YYYY');
    },
  },
  showMajorLabels: true,
};

export const BASE_OPTIONS_YEAR : TimelineOptions = {
  ...BASE_OPTIONS,
  timeAxis: {scale: 'year', step: 1},
  format: {
    minorLabels: function (date: any, scale: any, step: any) {
      return date.format('YYYY');
    },
  },
  showMajorLabels: true,
};

export type ViewOptions = {
  options: TimelineOptions;
  displayName: string;
};

export const Views : {[key: string]: ViewOptions} = {
  WEEK: {
    options: BASE_OPTIONS_WEEK,
    displayName: 'Week'
  },
  MONTH: {
    options: BASE_OPTIONS_MONTH,
    displayName: 'Month'
  },
  YEAR: {
    options: BASE_OPTIONS_YEAR,
    displayName: 'Year'
  },
};
