import styled from '@emotion/styled';
import {LabelFormFieldTypography} from "patient-ping-remedy/packages/typography";
import {sizes} from "patient-ping-remedy/packages/theme";

export const StyledRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 28rem;
  }
`;

export const StyledLabelFormFieldTypography = styled(LabelFormFieldTypography)`
  margin-bottom: ${sizes.xSmall};
  display: block;
`;

export const StyledInstructions = styled('div')`
  margin: ${sizes.medium} 0;
`;
