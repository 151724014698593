import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Organization} from "../../../api/dto/dto";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import Icon from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";
import SortableTable, {TableColumn, TableData} from "../../common/SortableTable";
import OrganizationDetailsDrawer from "./organization_details/OrganizationDetailsDrawer";
import DisplayHelpers from "../../../helpers/display_helpers";

const OrganizationsTab = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [resetTableFilters, setResetTableFilters] = useState<boolean>(false);
  const [slidePaneOrganization, setSlidePaneOrganization] = useState<Organization | undefined>(undefined);
  const { carecoApi} = useContext(CarecoApiContext);

  const { data: organizations, isLoading, error } = useQuery({
    queryKey: ['organizations'],
    queryFn: () => carecoApi?.getOrganizations(),
    enabled: !!carecoApi
  });

  const handleClick = () => {
    console.log('click');
  };

  const mapOrganizationTableData = useCallback((organization: Organization) : TableData => {
    return {
      id: {
        tableHeader: 'ID',
        key: 'id',
        type: 'number',
        value: organization.id,
        displayValue: organization.id,
        columnWidth: '10%'
      },
      name: {
        tableHeader: 'Name',
        key: 'name',
        type: 'string',
        value: organization.name,
        displayValue: (
          <Button
            onClick={() => {
              setSlidePaneOrganization(organization);
            }}
            styleType={StyleType.TERTIARY}>
            { organization.name }
          </Button>
        )
      },
      dateCreated: {
        tableHeader: 'Date created',
        key: 'Date',
        type: 'string',
        value: organization.createdAt,
        displayValue: DisplayHelpers.formatDate(organization.createdAt)
      },
      status: {
        tableHeader: 'Active',
        key: 'type',
        type: 'string',
        value: organization.active ? 'Yes' : 'No',
        displayValue: organization.active ? 'Yes' : 'No',
        columnWidth: '10%'
      },
      actions: {
        tableHeader: '',
        key: 'actions',
        type: 'custom',
        value: '',
        displayValue: (
          <Button
            onClick={handleClick}
            style={{ background: 'none', padding: '0', margin: '0'}}>
            <Icon iconClass={'ellipsis-h'} color={colors.gray4} />
          </Button>
        ),
        columnWidth: '5%'
      }
    };
  }, []);

  useEffect(() => {
    if(organizations) {
      setTableData(
        organizations.map((organization: Organization) => {
          return mapOrganizationTableData(organization);
        })
      );
    }
  }, [organizations, mapOrganizationTableData]);

  return (
    <>
      <SortableTable
        id={'groups-table'}
        data={tableData}
        loading={isLoading}
        loadError={error}
        header={Object.values(tableData[0] ?? [])?.map((value: TableColumn) => value.tableHeader)}
        searchPlaceholder={'Search by organization name'}
        searchColumns={['name']}
        resetTableFilters={resetTableFilters}
        setResetTableFilters={setResetTableFilters}
        sortableColumns={tableData[0] ?
          [
            tableData[0]['name'],
            tableData[0]['id'],
          ]
          : []}
      />
      <OrganizationDetailsDrawer
        organization={slidePaneOrganization}
        close={() => setSlidePaneOrganization(undefined)}
        isOpen={!!slidePaneOrganization}
      />
    </>
  );
};

export default React.memo(OrganizationsTab);
