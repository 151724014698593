import React, {useEffect} from "react";
import Spacer from "patient-ping-remedy/packages/spacer";
import {sizes} from "patient-ping-remedy/packages/theme";
import ValidatedInput from "../../../../common/ValidatedInput";
import {StyledRow} from "./PatientInformationForm.styles";

type GenericObject = { [key: string]: any };

interface PatientInformationFormProps {
  handleChange: (event: (React.ChangeEvent | null), name: string, value: any) => void,
  form: GenericObject,
}

const PatientInformationForm = (props: PatientInformationFormProps) => {

  useEffect(() => {
    const firstNameInput = document.getElementById('search-client-first-name') as HTMLInputElement;
    firstNameInput.focus();
  }, []);

  return (
    <StyledRow>
      <ValidatedInput
        labelText="First Name"
        value={props.form.first_name?.value}
        onChange={(e) => props.handleChange(e, 'first_name', e.target.value)}
        validate={() => props.form.first_name.valid.valid ? '' : props.form.first_name.valid.message}
        required={'required'}
        id="search-client-first-name"
      />
      <Spacer itemWidth={sizes.medium}/>
      <ValidatedInput
        labelText="Last Name"
        value={props.form.last_name?.value}
        onChange={(e) => props.handleChange(e, 'last_name', e.target.value)}
        validate={() => props.form.last_name.valid.valid ? '' : props.form.last_name.valid.message}
        required={'required'}
        id="search-client-last-name"
      />
    </StyledRow>
  );
};

export default PatientInformationForm;
