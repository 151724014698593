import {PAYOR_CHANGED} from "../../constants/PingStatuses";

export function getLatestEventDate(encounter) {
  const result = encounter.events
    .map((ping) => {
      return ping.eventDate;
    })
    .filter((date) => {
      return !!date;
    })
    .sort((l, r) => {
      if (new Date(l).valueOf() > new Date(r).valueOf()) {
        return -1;
      }

      if (new Date(l).valueOf() < new Date(r).valueOf()) {
        return 1;
      }
      return 0;
    });

  return new Date(result[0]);
}

// get all PAYOR_CHANGE events where the event date is after the current ping
// OR the event date is on the same date and the create date is after the current ping's create date
export const payorChangeLaterThanActiveEvent = ({ patient }) => {
  return patient.currentPingEncounter.events.filter(
    (ping) =>
      ping.status === PAYOR_CHANGED &&
      (new Date(ping.eventDate) > new Date(patient.currentPing.eventDate) ||
        (new Date(ping.eventDate) >= new Date(patient.currentPing.eventDate)
          && ping.eventId > patient.currentPing.eventId))
  );
};
