import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";

const NotificationHeader = styled.div`
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const NotificationHeading = styled.div`
    font-size: 1.8rem;
    font-weight: 600;
    color: ${colors.charcoal};
`;

export { NotificationHeader, NotificationHeading };
