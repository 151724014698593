import {AlertWrapper, FullOverlay, StyledDisplayLargeTypography} from "./SessionTimeoutWarning.styles";
import React, {useEffect, useState} from "react";

type Props = {
  handleStillHere: () => void;
  getRemainingTime: Function;
};

const SessionTimeoutWarning = (props: Props) => {
  const [timer, setTimer] = useState(props.getRemainingTime());
  const minutes = Math.floor(timer / 60);
  const seconds = timer - minutes * 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(Math.ceil(props.getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    document.body.addEventListener('click', props.handleStillHere);
    return () => {
      document.body.removeEventListener('click', props.handleStillHere);
    };
  }, []);

  return (
    <>
      <FullOverlay />
      <AlertWrapper data-testid="session-timeout-wrapper">
        <StyledDisplayLargeTypography>
          SESSION TIMEOUT WARNING
        </StyledDisplayLargeTypography>
        <div>
          Your online session will expire in <strong>{minutes} minutes {seconds} seconds</strong>
        </div>
        <div>
          Please click on the screen to keep working...
        </div>
      </AlertWrapper>
    </>
  );
};

export default SessionTimeoutWarning;
