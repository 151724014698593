import React from 'react';
import {css} from "@emotion/css";
import RosterSelection from "./RosterSelection";
import ValidatedInput from "../../common/ValidatedInput";
import DisplayHelpers from "../../../helpers/display_helpers";
import {Form, NameDiv} from "./CreateUserModalContent.styles";
import {RosterResponse} from "../../../api/dto/dto";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";

type GenericObject = { [key: string]: any };

type Props = {
  rosters: RosterResponse;
  availableRosterOptions: Value[];
  form: GenericObject;
  handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
}

const CreateUserModalContent = (props: Props) => {
  return (
    <>
      <Form>
        <NameDiv>
          <ValidatedInput
            className={css({ width: '300px' })}
            labelText="Email"
            value={props.form.email?.value}
            onChange={(e) => props.handleChange(e, 'email', e.target.value)}
            validate={() => props.form.email.valid.valid ? '' : props.form.email.valid.message}
            required={'required'}
            id="create-user-email-input"
          />

          <ValidatedInput
            className={css({ width: '300px' })}
            labelText="Phone"
            value={DisplayHelpers.formatPhoneNumber(props.form.phone?.value)}
            onChange={(e) => props.handleChange(e, 'phone', e.target.value)}
            validate={() => props.form.phone.valid.valid ? '' : props.form.phone.valid.message}
            required={'required'}
            placeholder="(___) ___-____"
            id="create-user-phone-input"
          />
        </NameDiv>
        <NameDiv>
          <ValidatedInput
            className={css({ width: '300px' })}
            labelText="First Name"
            value={props.form.first_name?.value}
            onChange={(e) => props.handleChange(e, 'first_name', e.target.value)}
            validate={() => props.form.first_name.valid.valid ? '' : props.form.first_name.valid.message}
            required={'required'}
            id="create-user-first-name-input"
          />

          <ValidatedInput
            className={css({ width: '300px' })}
            labelText="Last Name"
            value={props.form.last_name?.value}
            onChange={(e) => props.handleChange(e, 'last_name', e.target.value)}
            validate={() => props.form.last_name.valid.valid ? '' : props.form.last_name.valid.message}
            required={'required'}
            id="create-user-last-name-input"
          />
        </NameDiv>
        <RosterSelection
          formRosters={props.form.rosters}
          rosters={props.rosters}
          availableRosterOptions={props.availableRosterOptions}
          rosterOptions={props.rosters.rosterOptions ?? []}
          dataRoleOptions={props.rosters.dataRoleOptions ?? []}
          featureRoleOptions={props.rosters.featureRoleOptions ?? []}
          handleChange={props.handleChange}
          isWithinModal={true}
        />
      </Form>
    </>
  );
};

export default CreateUserModalContent;
