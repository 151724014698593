import React, {useMemo} from "react";
import Helpers from "../../../../../../helpers/helpers";
import {
  EventContainerEventsList,
  EventContainerEventsSession, EventContainerEventsStatus, EventContainerEventsTime,
  EventContainerEventsWrapper
} from "../../../../../common/timeline/Timeline.styles";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import {JusticeInvolvedItem} from "../../../../../../api/dto/justice-involved";

type Props = {
  events: JusticeInvolvedItem,
  active: boolean
}

const JusticeTimeline = ({events, active} : Props) => {

  // Justice Involved event has only three possible statuses: Released, Booked, Arrested
  const displayEvents = useMemo(() => {
    let displayEvents = [];
    if(events.released && Helpers.isDefined(events.releaseDateTime)){
      const eventItem = {
        eventDate: events.releaseDateTime,
        status: "Released",
        message: formatEventMessage(
          "Released",
          events.releaseReason ? `: ${events.releaseReason}` : ""
        ),
      };
      displayEvents.push(eventItem);
    }
    if(Helpers.isDefined(events.bookingDateTime)){
      const eventItem = {
        eventDate: events.bookingDateTime,
        status: "Booked",
        message: formatEventMessage("Booked ", `by ${events.holdingFacility?.name}`),
      };
      displayEvents.push(eventItem);
    }
    if (Helpers.isDefined(events.arrestDateTime)){
      const eventItem = {
        eventDate: events.arrestDateTime,
        status: "Arrested",
        message: formatEventMessage("Arrested ", `by ${events.arrestingAgency?.name}`),
      };
      displayEvents.push(eventItem);
    }
    return displayEvents;
  }, [events]);

  function formatEventMessage(status : string, message: string){
    return <> <span className="event-text-bold">{status}</span> {message}</>;
  }

  return (
    <EventContainerEventsWrapper>
      <EventContainerEventsSession>
        {
          displayEvents.map((event, index) => {
            return (
              <EventContainerEventsList active={active} key={`justice-timeline-${index}`}>
                <EventContainerEventsTime>
                  {DisplayHelpers.formatDate(event.eventDate!)}
                  <span className="pipe-separator">{DisplayHelpers.formatTime(event.eventDate!)}</span>
                </EventContainerEventsTime>
                <EventContainerEventsStatus>
                  {event.message}
                  <span className="pipe-separator">{event.status}</span>
                </EventContainerEventsStatus>
              </EventContainerEventsList>
            );
          })
        }
      </EventContainerEventsSession>
    </EventContainerEventsWrapper>
  );
};

export default JusticeTimeline;
