import {
  Address,
  DataPermission,
  DataRole,
  FeaturePermission,
  FeatureRole,
  Roster,
  RosterResponse,
  User
} from "../api/dto/dto";
import {RosterSelectionState} from "../components/pages/admin/RosterSelection";
import PermissionsHelpers from "./permissions_helpers";
import Helpers from "./helpers";
import {nonGroupedValues} from "../components/common/FilterComponent";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";

export const dataSourceValues: Value[] = [
  {label: 'ADT', value: 'ADT'},
  {label: 'OpenBeds referrals', value: 'OB_REFERRALS'},
  {label: 'OpenBeds crisis', value: 'OB_CRISIS'},
  {label: 'FindHelp referrals', value: 'FINDHELP'},
  {label: 'PDMP', value: 'PDMP'},
  {label: 'Justice involved', value: 'JI'},
  {label: 'Roundtrip', value: 'ROUNDTRIP'},
];

export const featureValues: Value[] = [
  {label: 'Client list', value: 'CLIENT_LIST'},
  {label: 'Client profile', value: 'CLIENT_PROFILE'},
  {label: 'Forms', value: 'FORMS'},
  {label: 'User management', value: 'USER_PERMISSIONS'},
  {label: 'Notifications', value: 'NOTIFICATIONS'},
];

const RosterHelpers = {
  getRosterById(rosters: RosterResponse | undefined, id: string | number | undefined | null) {
    if(!rosters || !id) return;

    return rosters?.data?.data?.find((roster: Roster) => roster.id === parseInt(id as string));
  },
  determineAvailableRosterOptions(form: { [key: string]: any }, rosters: RosterResponse | undefined, currentUser: User | null) {
    if(!currentUser || !rosters) return [];

    let rosterOptionsNotInForm = rosters?.rosterOptions.filter(roster => {
      return !form.rosters?.value.map((set: RosterSelectionState ) => set.roster.value).includes(roster.value);
    });

    if (PermissionsHelpers.hasAdminRole(currentUser!)) {
      return rosterOptionsNotInForm ?? [];
    } else {
      let rosterOptionsUserCanManage = rosterOptionsNotInForm?.filter(roster => {
        let rosterData = this.getRosterById(rosters, roster.value);

        return PermissionsHelpers.canForUserFeatureRoleRoster(currentUser!, rosterData!,
          Helpers.getAppFeatureRoleDetails().levels.MANAGE,
          Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS);
      });

      return rosterOptionsUserCanManage ?? [];
    }
  },
  getFeatureRoleOptions(featureRoleOptions: Value[]) {
    let apiFeatureRoleOptions : nonGroupedValues[] = featureRoleOptions.map((option: Value) => {
      return { value: option.label, label: option.label, id: option.value };
    }) ?? [];

    return [...apiFeatureRoleOptions,
      {value: 'Bamboo Admin', label: 'Bamboo Admin', id: 'bamboo_admin'},
      {value: 'Super Admin', label: 'Super Admin', id: 'super_admin'}
    ];
  },
  getDataRoleOptions(dataRoleOptions: Value[]) {
    let apiDataRoleOptions : nonGroupedValues[] = dataRoleOptions.map((option: Value) => {
      return { value: option.label, label: option.label, id: option.value };
    }) ?? [];

    return [...apiDataRoleOptions,
      {value: 'Bamboo Admin', label: 'Bamboo Admin', id: 'bamboo_admin'},
      {value: 'Super Admin', label: 'Super Admin', id: 'super_admin'}
    ];
  },
  getOrganizationOptions(organizationOptions: Value[]) {
    let apiOrganizationOptions : nonGroupedValues[] = organizationOptions.map((option: Value) => {
      return { value: option.label, label: option.label, id: option.value };
    }) ?? [];

    return apiOrganizationOptions;
  },
  getRosterOptions(rosterOptions: Value[]) {
    let apiRosterOptions : nonGroupedValues[] = rosterOptions.map((option: Value) => {
      return { value: option.label, label: option.label, id: option.value };
    }) ?? [];

    return apiRosterOptions;
  },
  getUserFiltersDropdownOptions(rosters: RosterResponse | undefined) {
    return {
      dataRoleOptions: this.getDataRoleOptions(rosters?.dataRoleOptions ?? []),
      featureRoleOptions: this.getFeatureRoleOptions(rosters?.featureRoleOptions ?? []),
      organizationOptions: this.getOrganizationOptions(rosters?.organizationOptions ?? []),
      rosterOptions: this.getRosterOptions(rosters?.rosterOptions ?? []),
      statusOptions: [
        { value: 'enabled', label: 'Enabled', id: 'enabled', quickFilter: true },
        { value: 'disabled', label: 'Disabled', id: 'disabled' }
      ]
    };
  },
  getGroupFiltersDropdownOptions(rosters: RosterResponse | undefined) {
    return {
      organizationOptions: this.getOrganizationOptions(rosters?.organizationOptions ?? []),
    };
  },
  formatGroupAddress(address: Address | null | undefined): string {
    if (!address) {
      return '';
    }

    const {address1, city, state, zip} = address;
    return `${address1}, ${city}, ${state} ${zip}`;
  },
  groupTypeToSubmission(groupType: string): string {
    switch (groupType) {
    case "SNF":
    case "HHA":
    case "LTAC":
    case "REHAB_HOS":
    case "HOSPICE":
    case "ALF":
    case "HL7_BASED_PROGRAM":
    case "CCRC":
    case "ADH":
    case "URGENT_CARE":
    case "PALLIATIVE":
    case "CRISIS":
    case "HOS":
      return "Dynamic (Event Based) Roster";
    case "OWNING_ORG":
    case "PRIVATE_PROGRAM":
      return "Static Roster";
    default:
      return "Dynamic (Event Based) Roster";
    }
  },
  getAllDataPermissions(data_roles: DataRole[]): DataPermission[] {
    let dataPermissions: DataPermission[] = [];
    data_roles.forEach(
      data_role =>
        data_role.permissions.forEach(
          permission => dataPermissions.push(permission)
        )
    );

    return dataPermissions;
  },
  getAllFeaturePermissions(feature_roles: FeatureRole[]): FeaturePermission[] {
    let featurePermissions: FeaturePermission[] = [];
    feature_roles.forEach(
      feature_role =>
        feature_role.permissions.forEach(
          permission => featurePermissions.push(permission)
        )
    );

    return featurePermissions;
  },
};

export default RosterHelpers;
